import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsChartRange = (): GetContextMenuSection => {
  return (ctx) => {
    if (ctx.isHeaderMenu) return [];

    const ranges = ctx.api.getCellRanges();
    const createChart = ctx.api.createRangeChart;

    if (!ranges?.length) return [];

    const selection = ranges?.reduce(
      (acc, range) => {
        const c = range.columns.map((col) => col.getColId());
        c.map((col) => {
          if (!acc.colIds.includes(col)) {
            acc.colIds.push(col);
          }
          if (!acc.startIndexes.includes(range.startRow?.rowIndex ?? 0)) {
            acc.startIndexes.push(range.startRow?.rowIndex ?? 0);
          }
          if (!acc.endIndexes.includes(range.endRow?.rowIndex ?? 0)) {
            acc.endIndexes.push(range.endRow?.rowIndex ?? 0);
          }
        });
        return acc;
      },
      {
        colIds: [],
        startIndexes: [],
        endIndexes: [],
      } as {
        colIds: string[];
        startIndexes: number[];
        endIndexes: number[];
      },
    ) || { colIds: [], startIndexes: [], endIndexes: [] };

    const cellRange = {
      columns: selection.colIds
        .map((c) => ctx.api.getColumn(c))
        .filter(Boolean),
      rowStartIndex: Math.min(...selection.startIndexes),
      rowEndIndex: Math.max(...selection.endIndexes),
    };

    return [
      {
        name: "View Forward Curve",
        icon: getIcon("barChart"),
        action: () => {
          createChart({
            chartType: "line",
            cellRange,
          });
        },
        subMenu: [
          {
            name: "Column",
            subMenu: [
              {
                name: "Grouped",
                action: () => {
                  createChart({
                    chartType: "groupedColumn",
                    cellRange,
                  });
                },
              },
              {
                name: "Stacked",
                action: () => {
                  createChart({
                    chartType: "stackedColumn",
                    cellRange,
                  });
                },
              },
              {
                name: "100% Stacked",
                action: () => {
                  createChart({
                    chartType: "normalizedColumn",
                    cellRange,
                  });
                },
              },
            ],
          },
          {
            name: "Bar",
            subMenu: [
              {
                name: "Grouped",
                action: () => {
                  createChart({
                    chartType: "groupedBar",
                    cellRange,
                  });
                },
              },
              {
                name: "Stacked",
                action: () => {
                  createChart({
                    chartType: "stackedBar",
                    cellRange,
                  });
                },
              },
              {
                name: "100% Stacked",
                action: () => {
                  createChart({
                    chartType: "normalizedBar",
                    cellRange,
                  });
                },
              },
            ],
          },
          {
            name: "Pie",
            subMenu: [
              {
                name: "Pie",
                action: () => {
                  createChart({
                    chartType: "pie",
                    cellRange,
                  });
                },
              },
              {
                name: "Donut",
                action: () => {
                  createChart({
                    chartType: "donut",
                    cellRange,
                  });
                },
              },
            ],
          },
          {
            name: "Line",
            action: () => {
              createChart({
                chartType: "line",
                cellRange,
              });
            },
          },
          {
            name: "X Y (Scatter)",
            subMenu: [
              {
                name: "Scatter",
                action: () => {
                  createChart({
                    chartType: "scatter",
                    cellRange,
                  });
                },
              },
              {
                name: "Bubble",
                action: () => {
                  createChart({
                    chartType: "bubble",
                    cellRange,
                  });
                },
              },
            ],
          },
          {
            name: "Area",
            subMenu: [
              {
                name: "Area",
                action: () => {
                  createChart({
                    chartType: "area",
                    cellRange,
                  });
                },
              },
              {
                name: "Stacked",
                action: () => {
                  createChart({
                    chartType: "stackedArea",
                    cellRange,
                  });
                },
              },
              {
                name: "100% Stacked",
                action: () => {
                  createChart({
                    chartType: "normalizedArea",
                    cellRange,
                  });
                },
              },
            ],
          },
          {
            name: "Statistical",
            subMenu: [
              {
                name: "Histogram",
                action: () => {
                  createChart({
                    chartType: "histogram",
                    cellRange,
                  });
                },
              },
            ],
          },
          {
            name: "Combination",
            subMenu: [
              {
                name: "Column & Line",
                action: () => {
                  createChart({
                    chartType: "columnLineCombo",
                    cellRange,
                  });
                },
              },
              {
                name: "Area & Column",
                action: () => {
                  createChart({
                    chartType: "areaColumnCombo",
                    cellRange,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
};
