import { useDeepCompareMemo } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";

import {
  pageProductsQueryOptions,
  useActivePageId,
  usePageProducts,
  useProductsByIds,
} from "../../../data";
import {
  useGridSettingsStatusMap,
  useSelectGridSettings,
} from "../../grid-settings";
import { useGetProductsContextMenue } from "../context-menu";
import { genColumnDefs } from "./columnDefs";
import { useUserId } from "../../../context/auth";

export {
  genColumnDefs,
  curveColumnStack,
  mainMonthColumnId,
  validColumnSettings,
  defaultColumnSettings,
  potentiallyInvisibleColumns,
  type CurveColumnStackName,
} from "./columnDefs";

export const useProductColumnDefs = () => {
  const pageId = useActivePageId();
  const pageProducts = usePageProducts(pageId);
  const statusMap = useGridSettingsStatusMap();
  const productIds = (pageProducts.data ?? [])
    .map((p) => p.productId)
    .filter(Boolean);
  const products = useProductsByIds(productIds);
  const mainMonthColumnWidth = useSelectGridSettings(
    (x) => x.mainMonthColumnWidth,
  );
  const flashCellUpdates = useSelectGridSettings((x) => !!x.flashCellUpdates);
  const mainMenuItems = useGetProductsContextMenue({ isHeaderMenu: true });

  const currentPageProducts = (pageProducts.data ?? []).map((p) => {
    const info = products.data?.find((x) => x.id === p.productId);
    return { ...info, ...p };
  });

  const colDefs = genColumnDefs({
    currentPageProducts,
    statusMap,
    products: products.data ?? [],
    mainMonthColumnWidth,
    flashCellUpdates: !!flashCellUpdates,
  });

  return useDeepCompareMemo(() => {
    return colDefs.map((col) => {
      const divCol = ["month", "month-timespread", "blank"].includes(
        col.field ?? "",
      );
      if (divCol) return col;
      return { ...col, mainMenuItems };
    });
  }, [colDefs]);
};

export const useHasProductColumns = () => {
  const pageId = useActivePageId();
  const userId = useUserId();
  return (
    useQuery({
      ...pageProductsQueryOptions(userId, pageId ?? ""),
      select: (x) => x.length > 0,
    }).data ?? false
  );
};
