import { unique } from "remeda";

import {
  selectorsData,
  type TSelectorExchange,
  type TSelectorMaturity,
} from "../selectorsData";
import { useUpdatePageProducts } from "../../../data";
import type { GetContextMenuSection } from "./helpers";
import { captureEvent } from "../../../context/ph";
// import { getSelectors } from "./useDockviewModal";
import menuItems from "../menuItems";
import { getIcon } from "./icons";

export const useGetMenuItemsFieldValue = (): GetContextMenuSection => {
  const update = useUpdatePageProducts();

  return (ctx) => {
    if (!ctx.isProduct) return [];

    const fieldMenuItems = getFieldMenuItems({
      subItemAction: (subItem) => {
        if (ctx.columnId) {
          update.mutateAsync({
            id: ctx.columnId,
            updaterFn: (old) => {
              old.columnFieldSelector = subItem;
            },
          });
          if (ctx.cannedOrSourced)
            captureEvent("changed_field_value", {
              columnId: ctx.columnId,
              productId: ctx.productId,
            });
        }
      },
      exchange: ctx.exchange,
      maturity: ctx.maturity,
      productId: ctx.productId,
    });

    if (fieldMenuItems.length === 1 && fieldMenuItems[0].subMenu.length === 1)
      return [];

    return [
      {
        name: "Field",
        icon: getIcon("field"),
        subMenu: fieldMenuItems,
      },
    ];
  };
};

function getFieldMenuItems({
  subItemAction,
  productId,
  exchange,
  maturity,
}: {
  subItemAction: (subItem: string) => void;
  productId: string;
  exchange: TSelectorExchange;
  maturity: TSelectorMaturity<TSelectorExchange>;
}) {
  const selectorsIntoSubgroups = selectorSubgroup();
  const selectors = getSelectors({ exchange, maturity, productId });

  const groups = unique(Object.values(selectorsIntoSubgroups));

  const fieldMenuItems = groups
    .map((group) => ({
      name: group,
      showInHeader: false,
      subMenu:
        selectors
          .map((selector) => {
            const groupName = selectorsIntoSubgroups[selector.value];

            if (groupName === group) {
              return {
                name: selector.label,
                action: () => subItemAction(selector.value),
              };
            }
          })
          .filter(Boolean) || [],
    }))
    .filter((group) => group.subMenu.length > 0);

  return fieldMenuItems.length
    ? fieldMenuItems
    : [
        {
          name: "VALUE",
          showInHeader: false,
          subMenu: [
            {
              name: "VALUE",
              action: () => subItemAction("value"),
            },
          ],
        },
      ];
}

export function selectorSubgroup(): Record<string, string> {
  return menuItems.reduce((acc, { name, subItems }) => {
    return subItems.reduce((acc, subItem) => {
      return Object.assign({}, acc, { [subItem]: name });
    }, acc);
  }, {});
}

export const productMaturityOverrides: Record<
  string,
  TSelectorMaturity<TSelectorExchange>
> = {
  "8df15c9d-d971-43c6-bb18-d808a7af0fd3": "fx",
  "b8973c43-540b-477c-aa48-5dcd68139eba": "fx",
  "6c772c57-3604-4222-8f7e-3f3ebf453eaf": "fx",
  "46a81843-2ef3-470e-a7f8-4b452102b625": "fx",
};

export type TSelectorUsage<
  T extends TSelectorExchange,
  U extends TSelectorMaturity<T>,
> = keyof (typeof selectorsData)[T][U];

export function selectors({
  exchange,
  maturity,
  usage,
}: {
  exchange: TSelectorExchange;
  maturity: TSelectorMaturity<TSelectorExchange>;
  usage: TSelectorUsage<
    TSelectorExchange,
    TSelectorMaturity<TSelectorExchange>
  >;
}) {
  const exchangeData =
    exchange in selectorsData ? selectorsData[exchange] : null;

  if (!exchangeData) {
    return ["value"];
  }

  const maturityData = maturity in exchangeData ? exchangeData[maturity] : null;

  if (!maturityData) {
    return ["value"];
  }

  const usageData = usage in maturityData ? maturityData[usage] : null;

  return usageData || ["value"];
}

export const fieldNameOverrides: Record<string, string> = {
  fv: "FAIR VALUE",
};

export function getSelectors({
  exchange,
  maturity,
  productId,
}: {
  exchange: TSelectorExchange;
  maturity: TSelectorMaturity<TSelectorExchange>;
  productId: string;
}) {
  const selectorMaturity = productMaturityOverrides?.[productId] || maturity;

  const result = selectors({
    exchange,
    maturity: selectorMaturity,
    usage: "grid",
  });

  return result.map((selector) => {
    const selectorLabel =
      selector in fieldNameOverrides ? fieldNameOverrides[selector] : selector;

    return {
      label: selectorLabel.toUpperCase(),
      value: selector,
      groupValue: "field",
    };
  });
}
