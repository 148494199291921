import { useStore } from "jotai";

import { gridSettingsVisibleAtom } from "../../grid-settings";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsGridSettings = (): GetContextMenuSection => {
  const store = useStore();
  return (ctx) => {
    if (!ctx.isMobile) return [];
    return [
      {
        name: "Grid Settings",
        icon: getIcon("settings"),
        action: () => store.set(gridSettingsVisibleAtom, true),
      },
    ];
  };
};
