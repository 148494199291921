import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { z } from "zod";
import { client } from "../../../../triplit/triplit";
import type { TAlert } from "../../../../triplit/schema";
import {
  monthCodeToShortMonthAndYear,
  relativeRowToRowId,
} from "../../periodHelpers";
import { parseLimitRef } from "../../../utils";
import type { TApolloClient } from "../../../../context/apollo";
import { graphql } from "../../../../graphql";
import { useCallback } from "react";
import { useQuery as useApolloQuery } from "@apollo/client";
import { useUserDetails, useUserId } from "../../../../context/auth";
import { atom, useAtom } from "jotai";
import { store } from "../../../sharedHooks";
import { vapidKeys } from "../../../../globals";
import { parsePushSubscriptions } from "../../../../utils";
import { unique } from "remeda";

const pushSubscriptionAtom = atom<{
  subscription: PushSubscription | null;
  subscribed: boolean;
}>({
  subscription: null,
  subscribed: false,
});
pushSubscriptionAtom.debugLabel = "pushSubscriptionAtom";

navigator?.serviceWorker?.register("/service-worker.js");

navigator?.serviceWorker?.ready.then(async (registration) => {
  const subscription = await registration.pushManager.getSubscription();

  console.log("Service worker ready, push subscription:", subscription);

  store.set(pushSubscriptionAtom, {
    subscription,
    subscribed: false,
  });
});

export function usePushSubscription() {
  const user = useUserDetails();
  const [subscription, setSubscription] = useAtom(pushSubscriptionAtom);

  const subscribe = useCallback(async () => {
    console.log("Subscribing to push notifications...");

    if (!subscription || !user?.id) return;
    const pushSubscriptions = await client.fetchById(
      "pushSubscriptions",
      user.id,
    );

    console.log("User push subscriptions:", pushSubscriptions);

    if (!pushSubscriptions) {
      console.log("Creating push subscription record for user...");

      await client.insert("pushSubscriptions", {
        id: user.id,
        subscriptions: "[]",
      });
    }

    const publicKeyEncoded = vapidKeys?.publicKey;
    if (!publicKeyEncoded) {
      console.error("VAPID public key not found.");
      return;
    }

    const publicKey = urlBase64ToUint8Array(publicKeyEncoded);

    const registration = await navigator.serviceWorker.ready;
    const pushSubscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicKey,
    });

    console.log("Push subscription:", pushSubscription);

    const currentSubscriptions = pushSubscriptions?.subscriptions
      ? parsePushSubscriptions(pushSubscriptions?.subscriptions)
      : [];

    if (
      !currentSubscriptions.some(
        (sub) => sub.endpoint === pushSubscription.endpoint,
      )
    ) {
      currentSubscriptions.push(pushSubscription);

      const updatedSubscriptions = unique(currentSubscriptions.slice(-2));

      await client.update("pushSubscriptions", user.id, (row) => {
        row.subscriptions = JSON.stringify(updatedSubscriptions);
      });
    }

    setSubscription({
      subscription: pushSubscription,
      subscribed: true,
    });
  }, [subscription, user, setSubscription]);

  return {
    subscribe,
    subscription,
  };
}

export const alertValidation = z.object({
  id: z.string(),
  _id: z.string().optional(),
  limit: z.string(),
  limitRef: z
    .object({
      columnId: z.string(),
      productId: z.string(),
      fieldSelector: z.string(),
      rowId: z.string(),
      name: z.string(),
      period: z.string(),
    })
    .optional(),
  note: z.string(),
  status: z.string(),
  recurring: z.boolean().optional(),
  sound: z.boolean().optional(),
  valueBelowLimit: z.boolean().optional(),
  productId: z.string(),
  columnId: z.string(),
  rowId: z.string(),
});

export type TAlertForm = z.infer<typeof alertValidation>;

export function emptyAlert(): TAlertForm {
  return {
    id: nanoid(),
    limit: "",
    note: "",
    status: "Active",
    sound: false,
    productId: "",
    recurring: false,
    columnId: "",
    rowId: "",
  };
}

// Create an object where the key is `columnId-rowId` and the value is an array of rules based on the ones that have the same columnId and rowId.
function formatAlerts(alerts: TAlert[]) {
  const formatted: Record<string, TAlert[]> = {};

  for (const alert of alerts) {
    const key = `${alert.productId}-${alert.rowId}`;
    if (!formatted[key]) {
      formatted[key] = [];
    }

    formatted[key].push(alert);
  }

  return formatted;
}

export function useAlerts(pageId: string) {
  const userId = useUserId();
  const alerts = useQuery({
    queryKey: ["alerts", userId],
    queryFn: () =>
      client.fetch(
        client
          .query("alerts")
          .where("userId", "=", userId)
          .where("pageId", "=", pageId)
          .build(),
      ),
  });

  const formatted = formatAlerts(alerts.data || []);

  return {
    alerts: formatted,
    fetching: alerts.isFetching,
  };
}

// Used to fetch all alerts to display them regardless of the page the user is on.
export function useTriggeredAlerts() {
  const alerts = useQuery({
    queryKey: ["alerts", "Triggered"],
    refetchInterval: 60_000,
    refetchIntervalInBackground: true, // Refetch even if the tab is in the background.
    gcTime: 60_000,
    queryFn: () => {
      return client.fetch(
        client
          .query("alerts")
          .where([
            ["status", "=", "Triggered"],
            ["triggeredAt", "isDefined", true],
          ])
          .build(),
      );
    },
  });

  return alerts.data ?? [];
}

export function getAlertDetails(
  alert: TAlert,
  colName: string,
  limitName?: string | null,
) {
  const period = relativeRowToRowId[alert.rowId];
  const operator = alert.valueBelowLimit ? " >= " : " <= ";

  const limitIsRef = alert.limit.includes(":");
  const parsedLimitRef = limitIsRef ? parseLimitRef(alert.limit) : null;

  const limitDetails =
    parsedLimitRef?.columnId && parsedLimitRef?.rowId
      ? {
          period: monthCodeToShortMonthAndYear(parsedLimitRef.rowId),
        }
      : null;

  return {
    name: colName,
    period,
    operator,
    limitDetails,
    formattedMessage: `${colName} [${period}] ${operator} ${
      limitDetails ? `${limitName} [${limitDetails.period}]` : alert.limit
    }`,
  };
}

let permissionsRequested = false;

export async function requestNotificationPermission() {
  if (permissionsRequested) return false;
  permissionsRequested = true;
  if (typeof Notification === "undefined") {
    console.warn("Notifications are not supported in this browser.");
    return false;
  }

  if (Notification.permission !== "granted") {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        return true;
      }
      console.log("Notification permission denied.");
      return false;
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      return false;
    }
  } else {
    console.log("Notification permission already granted.");
    return true;
  }
}

const productNameQuery = graphql(`
  query productName($id: uuid!) {
    product_by_pk(id: $id) {
      name
    }
  }
`);

export function useProductName(id: string) {
  const { data } = useApolloQuery(productNameQuery, {
    variables: { id },
  });
  return data?.product_by_pk?.name;
}

export async function getProductName(id: string, apolloClient: TApolloClient) {
  const product = await client.fetchById("pageProducts", id, {
    policy: "local-only",
  });
  const productId = product?.productId;
  if (!productId) {
    console.error("No productId found for alert");
    return;
  }
  const productRes = await apolloClient.query({
    query: productNameQuery,
    variables: { id: productId },
  });
  const productName = productRes.data.product_by_pk?.name;
  return productName;
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
