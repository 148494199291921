import type {
  ColDef,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  MenuItemDef,
} from "ag-grid-community";
import { createContext, useContext } from "react";

const GridColumnDefsContext = createContext<ColDef[]>({} as ColDef[]);
GridColumnDefsContext.displayName = "GridColumnDefsContext";
export const GridColumnDefsDefsProvider = GridColumnDefsContext.Provider;
export const useGridColumnDefs = () => useContext(GridColumnDefsContext);

type GetContextMenuItemsHandler = (
  params: GetContextMenuItemsParams | GetMainMenuItemsParams,
) => (string | MenuItemDef)[];
const GridContextMenuContext = createContext<GetContextMenuItemsHandler>(
  () => [],
);
GridContextMenuContext.displayName = "GridContextMenuContext";
export const GridContextMenuProvider = GridContextMenuContext.Provider;
export const useGridContextMenu = () => useContext(GridContextMenuContext);
