import type {
  CellKeyDownEvent,
  SuppressKeyboardEventParams,
} from "ag-grid-community";
import { z } from "zod";

export const globalKeyboardShortcuts = [
  {
    name: "Shortcut Menu",
    description: "Open the shortcut menu",
    shortcuts: ["shift+?"],
    action: () => {
      alert("Shortcut Menu");
    },
  },
] satisfies TGlobalKeyboardShortcut[];

export const artisTypeSchema = z.enum([
  "canned",
  "customer_curve",
  "eod",
  "sourced",
]);

export type TGridKeyboardShortcut = {
  name: string;
  description: string;
  shortcuts: string[];
  sequenceTimeout?: number;
  action: (params: {
    params: CellKeyDownEvent;
    pageId?: string | null;
  }) => void;
};

export type TGlobalKeyboardShortcut = TGridKeyboardShortcut & {
  action: () => void;
};

type TModifierKey = "ctrl" | "alt" | "shift";

export function modifierKeyPressed(event: KeyboardEvent, key: TModifierKey) {
  if (key === "ctrl") return ctrl(event);
  return `${key}Key` in event && event[`${key}Key`];
}

export function keyPressed(event: KeyboardEvent, key: string) {
  return event.key?.toLowerCase() === key?.toLowerCase();
}

export function ctrl(event: KeyboardEvent) {
  return event.ctrlKey || event.metaKey;
}

export function keysMatch(event: KeyboardEvent, shortcutKeys: string) {
  const keys = shortcutKeys.split("+");
  const matches = keys.reduce((keyAcc, key) => {
    ["ctrl", "alt", "shift"].includes(key)
      ? keyAcc.push(modifierKeyPressed(event, key as TModifierKey))
      : keyAcc.push(keyPressed(event, key));
    return keyAcc;
  }, [] as boolean[]);

  return matches.every((m) => m);
}

export function suppressKeyboardEvent(params: SuppressKeyboardEventParams) {
  const event = params.event;

  const modifierPressed =
    modifierKeyPressed(event, "ctrl") || modifierKeyPressed(event, "alt");
  const arrowPressed =
    keyPressed(event, "ArrowUp") || keyPressed(event, "ArrowDown");

  return modifierPressed && arrowPressed;
}
