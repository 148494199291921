import { useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import {
  type TProduct,
  useOptionalActivePageId,
  useProductsByIds,
} from "../../../data";
import { client, type TQuickAccess } from "../../../triplit";
import { useGrid } from "../stores";
import { useUserId } from "../../../context/auth";

export function useQuickAccess() {
  const navigate = useNavigate({ from: "/app/market/$id" });
  const pageId = useOptionalActivePageId();
  const userId = useUserId();
  const [api] = useGrid();

  const quickAccessData = useQuery({
    queryKey: ["quickAccess"],
    queryFn: () =>
      client.fetch(
        client.query("quickAccess").where("userId", "=", userId).build(),
      ),
  });
  const productIds =
    quickAccessData.data?.map((x) => x.productId).filter(Boolean) || [];
  const products = useProductsByIds(productIds);

  const data =
    useMemo(() => {
      const names = getQuickAccessProductNames(
        quickAccessData.data ?? [],
        products?.data ?? [],
      );
      return (quickAccessData.data ?? []).map((qa) => {
        return {
          ...qa,
          productName: names?.[qa.shortcutNumber] || "",
        };
      });
    }, [quickAccessData.data, products.data]) || [];

  const showQuickAccessColumn = useCallback(
    ({
      columnId,
      switchPage = false,
      attempt = 0,
    }: {
      columnId: string;
      switchPage?: boolean;
      attempt?: number;
    }) => {
      if (!api) return;

      const flashDuration = switchPage ? 2000 : 500;

      console.log("Checking for Quick Access Column");

      const column = api.getColumn(columnId);
      console.log("Quick Access Column", column);

      if (!column) {
        console.log("Column not found, retrying...");

        if (attempt > 3) {
          console.error("Failed to find column after 3 attempts");
          return;
        }

        setTimeout(() => {
          showQuickAccessColumn({
            columnId,
            switchPage,
            attempt: attempt + 1,
          });
        }, 500);
        return;
      }

      api.ensureColumnVisible(column);

      // Flash sometimes doesn't work immediately after ensuring column is visible.
      setTimeout(() => {
        api.flashCells({
          columns: [column],
          flashDuration,
          fadeDuration: flashDuration,
        });
      }, 100);
    },
    [api],
  );

  const handleQuickAccess = useCallback(
    (qa: TQuickAccess) => {
      console.log("Quick Access Shortcut: ", qa);

      const switchPage = pageId !== qa.pageId;

      if (switchPage) {
        navigate({ to: "/app/market/$id", params: { id: qa.pageId } });
      }

      showQuickAccessColumn({ columnId: qa.columnId, switchPage });
    },
    [navigate, pageId, showQuickAccessColumn],
  );

  return {
    data,
    handleQuickAccess,
    fetchingLocal: quickAccessData.isFetching || products.isFetching,
  };
}

function getQuickAccessProductNames(
  quickAccess: TQuickAccess[],
  products: TProduct[],
) {
  return quickAccess.reduce(
    (acc, qa) => {
      const product = products?.find((p) => p?.id === qa.productId);
      if (!product) return acc;
      acc[qa.shortcutNumber] = product.description || product.name || "";
      return acc;
    },
    {} as Record<number, string>,
  );
}
