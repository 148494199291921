import type { ISeriesApi } from "lightweight-charts";
import { type MutableRefObject, useEffect, useMemo } from "react";
import { generateLogoWatermark, generateTooltip } from "../Plugins";
import { NoDataBackground } from "../no-data-background/no-data-background";
import { objectKeys } from "../../../utils";

const noDataBackground = {
  light: "#bbbbbb",
  dark: "#555555",
};

// Attaching the primitives when the chart is ready (as we did before) prevents the theme from changing those primitives when it changes. They need to be detached and reattached, and need to be initialized only once otherwise they will be duplicated. They must also be initialized per chart, otherwise they will be shared between all charts (i.e. if one chart has the no data background, all charts will have it).
export function useChartPrimitives(
  mode: "light" | "dark",
  chart: MutableRefObject<ISeriesApi<"Custom"> | null>,
) {
  const primitives = useMemo(() => {
    if (mode) {
      return {
        light: {
          tooltip: generateTooltip("light"),
          watermark: generateLogoWatermark("light"),
          highlighting: new NoDataBackground(() => noDataBackground.light),
        },
        dark: {
          tooltip: generateTooltip("dark"),
          watermark: generateLogoWatermark("dark"),
          highlight: new NoDataBackground(() => noDataBackground.dark),
        },
      };
    }
  }, [mode]);

  useEffect(() => {
    if (chart?.current && primitives) {
      for (const key of objectKeys(primitives)) {
        for (const primitive of Object.values(primitives[key])) {
          chart.current.detachPrimitive(primitive);
        }
      }

      for (const primitive of Object.values(primitives[mode])) {
        chart.current.attachPrimitive(primitive);
      }
    }
  }, [mode, chart.current, primitives]);
}
