import type { CellEditRequestEvent } from "ag-grid-community";
import { useAtom } from "jotai";

import { getManualInstrumentType } from "./statuses/statusLogic";
import { useMemoCallback } from "../../utils/useMemoCallback";
import { useGridSettingsStatusMap } from "../grid-settings";
import { type TUndoRedo, undoRedoAtom } from "./undo-redo";
import { calcWorker } from "../calculations-worker/hooks";
import { colParams } from "../utils";
import { parse } from "../numbers";

export const useOnCellEditRequest = () => {
  const statusMap = useGridSettingsStatusMap();
  const [undoRedo, setUndoRedo] = useAtom(undoRedoAtom);

  const onCellEditRequestCallback = (params: CellEditRequestEvent) => {
    const id = params.data?.id.toString();

    if (!id) return;

    const columnParams = colParams(params.column);
    const columnId = params.column.getColId();
    const productId = columnParams?.productId;

    if (!productId || !columnId) return;

    const status = statusMap?.[productId || ""];

    const storageType = getManualInstrumentType(status);
    if (!storageType) return;

    const newValue = params.newValue;
    const offset = params.rowIndex;
    const monthString = params.data?.monthString;

    if (!monthString)
      throw new Error("month cannot be undefined in onCellEditRequestCallback");

    const offsetIsNotSet = offset !== 0 && !offset;

    if (offsetIsNotSet || newValue === params.oldValue) {
      console.warn("Invalid cell edit request", {
        offset,
        oldValue: params.oldValue,
      });
      return;
    }
    const value = parse(newValue);

    const existingUndoPasted = undoRedo.redo.find((item) => item.pasted);

    const optimisticCellObject = {
      product: productId,
      offset,
      field: "value",
      storageType,
      month: monthString,
      rowId: id,
      pasted: params.source === "paste",
    } satisfies Omit<TUndoRedo, "result">;

    // If the last paste was more than 1 second ago, assume it's a new paste operation.
    const currentTime = new Date().getTime();

    // If the existing undos have a pasted item, it means more than one cell value was changed by a paste operation. So we append data to the existing undo items.
    if (existingUndoPasted && currentTime - undoRedo.time < 1000) {
      setUndoRedo((prevValue) => {
        return {
          undo: [
            ...prevValue.undo,
            { ...optimisticCellObject, result: params.oldValue },
          ],
          redo: [
            ...prevValue.redo,
            { ...optimisticCellObject, result: value ?? undefined },
          ],
          time: new Date().getTime(),
        };
      });
    } else {
      setUndoRedo({
        undo: [{ ...optimisticCellObject, result: params.oldValue }],
        redo: [{ ...optimisticCellObject, result: value ?? undefined }],
        time: new Date().getTime(),
      });
    }
    const { proxy: calculationWorker } = calcWorker?.() || {};
    calculationWorker?.optimisticCellEdit([
      {
        ...optimisticCellObject,
        result: value ?? undefined,
      },
    ]);
  };
  return useMemoCallback(onCellEditRequestCallback);
};
