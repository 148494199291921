import type {
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  MenuItemDef,
} from "ag-grid-community";
import { getProcessedSelectedCellsByRange } from "../../../tableUtils";
import { isMobile } from "../../../shared/hooks";

export type GetContextMenuSection = (
  p: ReturnType<typeof getMenuItemContext>,
) => MenuItemDef[];

export const getMenuItemContext = (props: {
  params: GetContextMenuItemsParams | GetMainMenuItemsParams;
  isHeaderMenu: boolean;
}) => {
  const api = props.params.api;

  const headerParams = props.params.column?.getColDef().headerComponentParams;
  const columnWidth = props.params.column?.getActualWidth();
  const artisType = headerParams?.artisType;
  const exchange = headerParams?.exchange;
  const maturity = headerParams?.maturity;
  const productId = headerParams?.productId;
  const columnId = headerParams?.gridId || "";

  const isProduct = !!productId;
  const isHeaderMenuItem = props.isHeaderMenu || isMobile;
  const cannedOrSourced = ["canned", "sourced"].includes(artisType);
  const isShadowCurve = Boolean(
    columnId?.includes("shadow") ||
      headerParams?.shadowType ||
      (columnId && !isProduct),
  );
  const hasFormula = headerParams?.hasFormula ?? false;
  const selectedRange = getProcessedSelectedCellsByRange({ api });
  const rangeSelection = selectedRange[0] || [];
  const isBlank = headerParams?.shadowType === "blank";

  return {
    api,
    isHeaderMenu: props.isHeaderMenu,
    rangeSelection,
    isProduct,
    isHeaderMenuItem,
    isShadowCurve,
    hasFormula,
    columnId,
    productId,
    exchange,
    maturity,
    cannedOrSourced,
    selectedRange,
    isBlank,
    isMobile,
    columnWidth,
  };
};
