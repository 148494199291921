import { Modal, ModalClose, Sheet } from "@mui/joy";
import { useAtom } from "jotai";

import { Box, Button, Typography } from "@mui/joy";
import { useModal } from "../sharedHooks";
import AddCurvesToPage from "../market-grid/modals/AddCurvesToPage";
import AddChart from "../market-grid/modals/AddChart";
import { useDeepCompareEffect } from "@react-hookz/web";
import { useTriggeredAlerts } from "../market-grid/modals/Alerts/hooks";
import { useApolloClient } from "@apollo/client";
import { sendNotification } from "../market-grid/modals/Alerts/AlertToast";
import { useMemo } from "react";
import { modalAtom } from "../sharedHooks";
import { useApi } from "../../admin/hooks/useApi";
import { useSelectGridSettings } from "../grid-settings";

export function ModalTitle({ title }: { title: string }) {
  return (
    <Typography
      component="h2"
      id="modal-title"
      level="h4"
      textColor="inherit"
      fontWeight="lg"
      mb={1}
    >
      {title}
    </Typography>
  );
}

type TModalButtons = {
  submit: {
    onClick?: () => Promise<void>;
    label?: string;
    type?: "submit" | "button";
  };
  cancel?: { onClick?: () => Promise<void>; label?: string };
  closeOnSubmit?: boolean;
  closeOnCancel?: boolean;
};

export function ModalButtons({
  submit,
  cancel,
  closeOnCancel = true,
  closeOnSubmit = true,
}: TModalButtons) {
  const [_, setModalState] = useModal();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        pt: 2,
        gap: 2,
      }}
    >
      <Button
        type={submit?.type || "button"}
        onClick={async () => {
          if (submit?.onClick) {
            await submit.onClick();
            closeOnSubmit && setModalState(null);
          }
        }}
        variant="outlined"
        color="success"
      >
        {submit.label || "Submit"}
      </Button>
      <Button
        onClick={async () => {
          cancel?.onClick && (await cancel.onClick());
          closeOnCancel && setModalState(null);
        }}
        variant="outlined"
        color="primary"
      >
        {cancel?.label || "Cancel"}
      </Button>
    </Box>
  );
}

export function ModalWrapper() {
  const makeApiRequest = useApi();
  const [modalProps, setModalState] = useAtom(modalAtom);
  const sound = useSelectGridSettings((x) => x.sound);

  const ModalContent = useMemo(() => {
    if (!modalProps) return null;

    switch (modalProps.modalId) {
      case "add-products":
        return <AddCurvesToPage />;
      case "add-chart":
        return <AddChart />;
    }
  }, [modalProps]);
  const triggeredAlerts = useTriggeredAlerts();
  const graphqlClient = useApolloClient();

  useDeepCompareEffect(() => {
    if (!graphqlClient || !triggeredAlerts.length) return;
    triggeredAlerts.forEach((alert) => {
      console.log("udce triggeredAlerts", alert.triggeredAt?.getMilliseconds());
      sendNotification(alert, graphqlClient, makeApiRequest, !!sound);
    });
  }, [triggeredAlerts, makeApiRequest, sound]);

  return (
    <Modal
      disablePortal={true}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={!!modalProps?.modalId}
      onClose={() => setModalState(null)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        {ModalContent}
      </Sheet>
    </Modal>
  );
}
