import { FaBell, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import cn from "mcn";

export const createDebouncedAlertProductChanged = (debounceDelay = 10000) => {
  type AlertedProducts = {
    products: Set<string>;
    timeoutId: number;
  };

  let currentlyAlerted: AlertedProducts | undefined = undefined;

  const showToast = (alertProducts: Array<{ name: string }>) => {
    const title =
      alertProducts.length > 1 ? "Products changed" : "Product changed";
    const list = alertProducts.map((p) => <li key={p.name}>{p.name}</li>);

    toast.custom(
      (t) => (
        <AlertToast
          title={title}
          hidden={!t.visible || t.dismissed}
          onDismiss={() => toast.dismiss(t.id)}
          message={<ul>{list}</ul>}
        />
      ),
      { position: "bottom-right", duration: Number.POSITIVE_INFINITY },
    );
  };

  return (alertProducts: Array<{ name: string }>) => {
    const newProductNames = new Set(alertProducts.map((p) => p.name));

    // Filter out products that have been recently alerted
    const notRecentlyAlerted = currentlyAlerted
      ? Array.from(newProductNames).filter(
          (name) => !currentlyAlerted?.products.has(name),
        )
      : Array.from(newProductNames);

    // If there are new products to alert
    if (notRecentlyAlerted.length > 0) {
      if (currentlyAlerted) {
        window.clearTimeout(currentlyAlerted.timeoutId);
        notRecentlyAlerted.forEach((name) =>
          currentlyAlerted?.products.add(name),
        );
      } else {
        currentlyAlerted = {
          products: new Set(notRecentlyAlerted),
          timeoutId: 0,
        };
      }

      // Show toast immediately for new products
      showToast(notRecentlyAlerted.map((name) => ({ name })));

      // Clear the alerted products after debounce time
      currentlyAlerted.timeoutId = window.setTimeout(() => {
        currentlyAlerted = undefined;
      }, debounceDelay);
    }
  };
};

export const alertProductChanged = createDebouncedAlertProductChanged();

export const AlertToast = ({
  title,
  hidden,
  message,
  onDismiss,
}: {
  title?: string | null;
  hidden?: boolean;
  message?: React.ReactNode | null;
  onDismiss: () => void;
}) => {
  return (
    <div
      className={cn(
        "bg-bg w-96 p-6 rounded-md shadow shadow-fg-muted/50 border border-fg-muted/20 transition-all dark:shadow-fg-muted/5",
        [hidden, "opacity-0 translate-y-10", "opacity-100 translate-y-0"],
      )}
    >
      <div className="flex gap-3 text-[var(--artis-orange)] items-center">
        <FaBell size={16} />
        <h3 className="text-xl flex-1">{title}</h3>
        <button
          type="button"
          className="text-fg-muted p-1 rounded hover:bg-fg-muted/10"
          onClick={onDismiss}
        >
          <FaTimes />
        </button>
      </div>
      <div className="py-2 max-h-44 overflow-y-auto">{message}</div>
    </div>
  );
};
