import type { UTCTimestamp } from "lightweight-charts";
import { z } from "zod";

import type { Product_Artis_Type_Enum } from "../../../__generated__/gql/graphql";
import type { TIndicatorsState, TSample } from "../utils";

export type TInfiniteQueryData = z.infer<typeof infiniteQueryDataSchema>;
export type TRegularQueryData = z.infer<typeof regularQueryDataSchema>;

export type TCachedData = TInfiniteQueryData & { pages: TRegularQueryData[] };

export type TQuestResponse = z.infer<typeof questResponseSchema> | never[];

export type ChartSettings = {
  id: string;
  userId: string;
  productId?: string | undefined;
  sampleTime?: TSample | undefined;
  periodFrom?: string | undefined;
  indicatorsState?: Exclude<TIndicatorsState, null>;
};

export type TFetchedData = {
  data: TQuestResponse;
  fromUTCDate: string;
  toUTCDate: string | null;
  timeTaken: number;
};

export type TLiveChart = ChartSettings & {
  artisType?: Product_Artis_Type_Enum;
};

export type HistoryicalData = {
  time: UTCTimestamp;
  open?: number;
  close?: number;
  low?: number;
  high?: number;
};

type TSpecificPeriodLabel = "1d" | "7d" | "28d";

export type TPriceDifference = Record<
  TSpecificPeriodLabel | string,
  {
    date: Date | undefined;
    latestVisibleDate: Date | undefined;
    close: number | undefined;
    difference: number | undefined;
    className: string | undefined;
  }
>;

const questResponseSchema = z.array(
  z.array(
    z.tuple([z.string(), z.number(), z.number(), z.number(), z.number()]),
  ),
);

export const regularQueryDataSchema = z.object({
  data: questResponseSchema,
  fromUTCDate: z.string(),
  toUTCDate: z.optional(z.string()),
  timeTaken: z.number(),
});

export const infiniteQueryDataSchema = z.object({
  pages: z.array(
    z.object({
      data: questResponseSchema,
    }),
  ),
  pageParams: z.array(
    z.object({
      numberOfBars: z.number(),
      initial: z.optional(z.boolean()),
    }),
  ),
});
