import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  MenuItem,
  MenuList,
  Select,
  Option,
  Typography,
  Alert,
  IconButton,
  Grid,
  Dropdown,
  MenuButton,
  Menu,
  Stack,
  SvgIcon,
  useTheme,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import dayjs from "dayjs";
import type {
  OhlcData,
  IChartApi,
  ISeriesApi,
  ITimeScaleApi,
  Time,
  UTCTimestamp,
} from "lightweight-charts";
import {
  type ComponentProps,
  type MutableRefObject,
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from "react";
import { TimeScale } from ".";
import {
  Autocomplete,
  type TOption,
} from "../components/Autocomplete/Autocomplete";
import { ContextMenu } from "../components/ContextMenu";
import { TradingBottomPanel } from "./TradingBottomPanel";
import { CandlestickRoundedSeries } from "./components/canldestick-series-rounded";
import { Chart } from "./components/chart";
import { useAutocomplete } from "../components/Autocomplete/hooks";
import {
  useChartProducts,
  useFetchHistoricalData,
  useHistoricalChartData,
  type TLiveChart,
  useLivelyUpdateMarketData,
  useResetChart,
  useMergeHistoricalAndLiveData,
  usePriceDifferences,
  type TPriceDifference,
  useBandData,
  useChartSettings,
  useChartPrimitives,
  useCurrentCandleStale,
  useLiveCharts,
  chartLayoutSelectorAtom,
  chartLimit,
} from "./hooks";
import { RoundedCandleSeries } from "./rounded-candles-series/rounded-candles-series";
import {
  defaultIndicatorsState,
  genChartId,
  isMidnight,
  rightOffset,
  specificPeriods,
  type TSample,
  type TIndicatorsState,
  minChartWidth,
} from "./utils";
import { useThemeMode } from "../../context/theme";
import { liveChartsPeriods } from "../market-grid/periodHelpers";
import { ErrorBoundary } from "@sentry/react";
import toast from "react-hot-toast";
import { useThrottledCallback, useWindowSize } from "@react-hookz/web";
import relativeTime from "dayjs/plugin/relativeTime";
import { GoPencil } from "react-icons/go";
import { IndicatorEditModal } from "./components/IndicatorEditModal";
import { datadogRum } from "@datadog/browser-rum";
import { IoCheckmark } from "react-icons/io5";
import {
  BollingerBands,
  KeltnerChannels,
  MovingAverageSeries,
} from "./Plugins";
import html2canvas from "html2canvas";
import { copyImageToClipboard } from "../market-grid/copyAsPng";
import { bottomBarHeight, sideBarWidth } from "../globals";
import { useAtom } from "jotai";
dayjs.extend(relativeTime);

const productSelectWidthMin = 250;
const selectMaxHeight = 22;

export function ProductSelectLiveChart({
  chartId,
  userId,
  defaultProductId,
  defaultPeriodFrom,
  defaultSampleTime,
  timeScaleRef,
  indicatorsState,
  setEditModal,
  period,
  onPeriodChange,
}: {
  chartId: string;
  userId: string;
  defaultProductId?: string;
  defaultPeriodFrom?: string;
  defaultSampleTime?: string;
  timeScaleRef?: MutableRefObject<ITimeScaleApi<Time> | null>;
  indicatorsState?: TIndicatorsState | null;
  setEditModal?: Dispatch<
    SetStateAction<{ open: true; indicatorId: string } | undefined> | undefined
  >;
  period?: string;
  onPeriodChange?: (period: string) => void;
}) {
  const [indicatorMenuOpen, setIndicatorMenuOpen] = useState(false);
  const { settings, setSettings, removeChart } = useChartSettings(chartId);

  const products = useChartProducts();
  const defaultOption = products?.find(
    (p) => p.value === (settings?.productId ?? defaultProductId),
  );

  const useAutocompleteProps = useAutocomplete({
    multiple: false,
    defaultValue: defaultOption,
  });
  const periodOptions = liveChartsPeriods;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        height: "min-content",
      }}
    >
      <Autocomplete
        {...useAutocompleteProps}
        multiple={false}
        value={settings?.productId}
        placeholder={
          defaultOption?.metadata?.name || defaultOption?.label || "Product"
        }
        options={products || []}
        sx={{
          width: timeScaleRef?.current?.width()
            ? timeScaleRef.current.width() - 100
            : productSelectWidthMin,
          maxWidth: 300,
          fontSize: "calc(var(--webapp-fontSize-md) + 1px)",
          maxHeight: selectMaxHeight,
          "--Input-placeholderOpacity": 1,
        }}
        onSelectValue={(v: TOption) => {
          try {
            if (!v) {
              console.warn(
                "Deleting chart because non existing one selected",
                chartId,
              );
              removeChart(chartId);
              return;
            }
            setSettings({
              sampleTime: (defaultSampleTime as TSample) || "1m",
              id: chartId,
              productId: v.value,
              indicatorsState: defaultIndicatorsState,
              userId,
            });
          } catch (e) {
            console.error(e);
            toast.error("Error updating chart");
          }
        }}
      />
      <Select
        size="sm"
        defaultValue={defaultPeriodFrom || ""}
        value={settings?.periodFrom || defaultPeriodFrom || period || ""}
        onChange={(_, v) => {
          if (!v || !settings) return;
          if (onPeriodChange) {
            onPeriodChange(v);
            return;
          }

          setSettings({ ...settings, periodFrom: v });
        }}
        sx={{
          fontSize: "calc(var(--webapp-fontSize-md) + 1px)",
          maxHeight: selectMaxHeight,
          width: 100,
        }}
      >
        <Option value="">Period</Option>
        {periodOptions.map((p) => (
          <Option key={p.value} value={p.value}>
            {p.label}
          </Option>
        ))}
      </Select>
      <Dropdown
        open={indicatorMenuOpen}
        onOpenChange={() => {
          setIndicatorMenuOpen(!indicatorMenuOpen);
        }}
      >
        {indicatorsState && setEditModal && (
          <>
            <MenuButton
              size="sm"
              sx={(theme) => ({
                backgroundColor: theme.palette.background.surface,
                maxHeight: selectMaxHeight,
              })}
            >
              <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                <Typography
                  level="title-md"
                  sx={{
                    fontSize: "calc(var(--webapp-fontSize-md) + 1px)",
                    fontWeight: 400,
                    color:
                      "var(--variant-outlinedColor, var(--liveCharts-palette-neutral-outlinedColor, var(--joy-palette-neutral-700, #32383E)))",
                  }}
                >
                  Indicators
                </Typography>
                <SvgIcon>
                  <path d="M7 10l5 5 5-5z" />
                </SvgIcon>
              </Stack>
            </MenuButton>
            <ClickAwayListener onClickAway={() => setIndicatorMenuOpen(false)}>
              <Menu
                placement="bottom-start"
                sx={{
                  padding: 1,
                  gap: 0.5,
                }}
              >
                {defaultIndicatorsState
                  ?.sort((a, b) => a.id.localeCompare(b.id))
                  .map((state) => {
                    const s =
                      indicatorsState.find((i) => i.id === state.id) ?? state;
                    return (
                      <Stack
                        key={s.id}
                        sx={(theme) => ({
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          width: 220,
                          pl: 1,
                          cursor: "pointer",
                          borderRadius: "xs",
                          "&:hover": {
                            backgroundColor: theme.palette.background.level1,
                          },
                        })}
                        onClick={() => {
                          if (!settings) return;
                          const newState = [
                            ...indicatorsState.filter(
                              (state) => state.id !== s.id,
                            ),
                            {
                              ...s,
                              show: !s.show,
                            },
                          ] satisfies TIndicatorsState;
                          setSettings({
                            ...settings,
                            indicatorsState: newState,
                          });
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            width: 220,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 500,
                              pr: 1,
                            }}
                          >{`${s.stateType.replace(/([A-Z])/g, " $1").trim()} (${s.period})`}</Typography>
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <Stack
                              sx={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {!s.show ? <Box /> : <IoCheckmark />}
                            </Stack>
                            <IconButton
                              onClick={() => {
                                setEditModal({
                                  open: true,
                                  indicatorId: s.id,
                                });
                              }}
                              sx={{
                                margin: 0.5,
                              }}
                            >
                              <GoPencil />
                            </IconButton>
                          </Box>
                        </Box>
                      </Stack>
                    );
                  })}
              </Menu>
            </ClickAwayListener>
          </>
        )}
      </Dropdown>
    </Box>
  );
}

export function UnselectedTradingView({ chartIdx }: { chartIdx: string }) {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in UnselectedTradingView");

  const chartId = genChartId({ chartIdx, userId });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        pt: 10,
        height: "100%",
        backgroundColor: (theme) => theme.palette.background.artis,
      }}
    >
      <ProductSelectLiveChart chartId={chartId} userId={userId} />
    </Box>
  );
}

const padding = 10;

export function TradingView({ chartMetadata }: { chartMetadata: TLiveChart }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { removeChart } = useChartSettings(chartMetadata.id);
  const [charts] = useLiveCharts();
  const liveChartRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();
  const [layout] = useAtom(chartLayoutSelectorAtom);
  const windowSize = useWindowSize();
  const horizontal =
    windowSize.width > theme.breakpoints.values.md && layout === "horizontal";
  const chartsExceedPerRow = charts.length > chartLimit / 2;
  const isChartTooSmall =
    windowSize.width / (chartsExceedPerRow ? chartLimit / 2 : charts.length) <
    minChartWidth;

  const timeScaleRef = useRef<ITimeScaleApi<Time> | null>(null);

  const indicatorsState =
    chartMetadata.indicatorsState || defaultIndicatorsState;

  const [editModal, setEditModal] = useState<
    | {
        open: true;
        indicatorId: string;
      }
    | undefined
  >(undefined);

  const currentState = indicatorsState.find(
    (s) => s.id === editModal?.indicatorId,
  );

  const closeMenu = useCallback(() => {
    const escEvent = new KeyboardEvent("keydown", {
      key: "Escape",
    });
    document.dispatchEvent(escEvent);
  }, []);

  const exportChart = useCallback(async () => {
    try {
      const chart = document.getElementById(`chart-${chartMetadata.id}`);
      if (!chart) return;
      const canvas = await html2canvas(chart, { useCORS: true });
      const dataURL = canvas.toDataURL("image/png");
      await copyImageToClipboard(dataURL);
    } catch (e) {
      console.error(e);
      throw new Error("Error exporting chart");
    }
  }, [chartMetadata.id]);

  const width = useMemo(() => {
    if (!horizontal) {
      return windowSize.width < theme.breakpoints.values.md
        ? "100%"
        : `calc((100% - ${sideBarWidth}px) / 2 + ${padding * 2}px)`;
    }

    if (isChartTooSmall) {
      // If the chart is too small, the width should be half of the window width minus the padding.
      return `calc((100% - ${sideBarWidth}px) / 2 + ${padding * 2}px)`;
    }

    if (chartsExceedPerRow) {
      // If the charts exceed the limit per row, the width should be 100% divided by the limit divided by 2 minus the padding.
      return `calc(100% / ${chartLimit / 2} - ${padding + 1}px)`;
    }

    // Otherwise, the width should be 100% divided by the number of charts minus the padding.
    return `calc(100% / ${charts.length} - ${padding + 1}px)`;
  }, [
    horizontal,
    isChartTooSmall,
    chartsExceedPerRow,
    windowSize.width,
    theme.breakpoints.values.md,
    charts.length,
  ]);

  return (
    <Box
      id={`live-chart-${chartMetadata.id}`}
      ref={liveChartRef}
      sx={{
        position: "relative",
        height: "400px",
        minHeight: "400px",
        width,
        minWidth: width,
        borderColor: (theme) => theme.palette.text.primary,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderRadius: "md",
        overflow: "hidden",
      }}
    >
      {currentState && (
        <IndicatorEditModal
          open={!!editModal}
          onClose={() => setEditModal(undefined)}
          currentState={currentState}
          indicatorsState={indicatorsState}
          chartId={chartMetadata.id}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: 5,
          left: 5,
          zIndex: 12,
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <ProductSelectLiveChart
          chartId={chartMetadata.id}
          userId={chartMetadata.userId}
          defaultProductId={chartMetadata.productId}
          defaultPeriodFrom={chartMetadata.periodFrom}
          defaultSampleTime={chartMetadata.sampleTime}
          timeScaleRef={timeScaleRef}
          indicatorsState={
            chartMetadata.indicatorsState ?? defaultIndicatorsState
          }
          setEditModal={setEditModal}
        />
      </Box>
      <ContextMenu
        onOpenChange={(open) => {
          setMenuOpen(open);
        }}
        trigger={
          <Box
            id={`chart-${chartMetadata.id}`}
            sx={{
              height: "100%",
              position: "relative",
              backgroundColor: (theme) => theme.palette.background.level1,
            }}
          >
            <Box sx={{ height: `calc(100% - ${bottomBarHeight}px + 4px)` }}>
              <ErrorBoundary
                fallback={
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>
                      Unexpected error in chart, try closing and reopening it
                    </Typography>
                  </Box>
                }
              >
                <TradingViewInner
                  chartMetadata={chartMetadata}
                  timeScaleRef={timeScaleRef}
                />
              </ErrorBoundary>
            </Box>
            <TradingBottomPanel
              chartMetadata={chartMetadata}
              timeScaleRef={timeScaleRef}
              liveChartRef={liveChartRef}
            />
          </Box>
        }
      >
        {menuOpen ? (
          <MenuList
            component="div"
            variant="outlined"
            size="sm"
            sx={{
              boxShadow: "sm",
              flexGrow: 0,
              minWidth: 200,
              maxHeight: 240,
              overflow: "auto",
            }}
          >
            <MenuItem
              disabled={charts.length === 1}
              onClick={() => {
                closeMenu();
                removeChart(chartMetadata.id);
              }}
            >
              Remove Chart
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                toast.promise(exportChart(), {
                  loading: "Exporting chart...",
                  success: "Chart copied to clipboard",
                  error: "Error exporting chart",
                });
              }}
            >
              Export as PNG to Clipboard
            </MenuItem>
          </MenuList>
        ) : (
          <></>
        )}
      </ContextMenu>
    </Box>
  );
}
type TTHemeCharts = Record<
  "light" | "dark",
  {
    mode: "light" | "dark";
    candlestick: {
      upColor: string;
      downColor: string;
    };
    layout: ComponentProps<typeof Chart>["layout"];
    grid: ComponentProps<typeof Chart>["grid"];
  }
>;

const themes = {
  light: {
    mode: "light",
    candlestick: {
      upColor: "var(--upColor)",
      downColor: "var(--downColor)",
    },
    layout: {
      attributionLogo: false,
      background: { color: "#F2F2F2" },
      textColor: "#333333",
    },
    grid: {
      vertLines: { color: "#D4D4D4" },
      horzLines: { color: "#D4D4D4" },
    },
  },
  dark: {
    mode: "dark",
    candlestick: {
      upColor: "var(--upColor)",
      downColor: "var(--downColor)",
    },
    layout: {
      attributionLogo: false,
      background: { color: "#141528" },
      textColor: "#D4D4D4",
    },
    grid: {
      vertLines: { color: "#2E2E2E" },
      horzLines: { color: "#2E2E2E" },
    },
  },
} as const satisfies TTHemeCharts;

const priceLineColor = {
  stale: "#4c4c4c",
  live: "", // Uses default color, here in case we want to change it.
};

function TradingViewInner({
  chartMetadata,
  timeScaleRef,
}: {
  chartMetadata: TLiveChart;
  timeScaleRef: MutableRefObject<ITimeScaleApi<Time> | null>;
}) {
  const chartRef = useRef<ISeriesApi<"Custom"> | null>(null);
  const chart = chartRef.current;
  const timeScale = timeScaleRef.current;
  const wrapperChartRef = useRef<IChartApi | null>(null);

  const [currentCandleCache, setCurrentCandleCache] = useState<
    OhlcData<Time> | undefined
  >();

  const {
    data: historicalData,
    error,
    fetchPreviousPage,
    fetchNextPage: fetchNext,
  } = useHistoricalChartData({
    chartMetadata,
    chart,
    timeScale,
  });

  // useChartGapFiller({
  //   chartMetadata,
  //   chart,
  //   timeScale,
  // });

  const mode = useThemeMode();

  useChartPrimitives(mode, chartRef);

  const latestHistoricalCandle = historicalData?.[historicalData.length - 1];

  const fetchNextPage = useThrottledCallback(
    () => {
      setTimeout(() => {
        const t = performance.now();
        fetchNext()
          .then(() => {
            console.log("fetched next page in ", performance.now() - t, "ms");
          })
          .catch((e) => {
            console.error("Error fetching next page", e);
          });
      }, 100);
    },
    [fetchNext],
    1000,
    true,
  );

  const { currentCandle, setCurrentCandle } = useLivelyUpdateMarketData({
    latestHistoricalCandle,
    chartMetadata,
    chart,
    currentCandleCache,
    setCurrentCandleCache,
    fetchNextPage,
  });

  useEffect(() => {
    if (chartMetadata.productId) {
      setCurrentCandle(null);
    }
  }, [chartMetadata.productId, setCurrentCandle]);

  const data = useMergeHistoricalAndLiveData(
    historicalData,
    currentCandle,
    currentCandleCache,
  );

  const currentCandleStale = useCurrentCandleStale({
    chartId: chartMetadata.id,
    productId: chartMetadata.productId ?? "",
    sampleTime: chartMetadata.sampleTime ?? "",
  });

  const fetchHistoricalData = useFetchHistoricalData({
    fetchPreviousPage,
    chart,
    timeScale,
    productId: chartMetadata.productId,
    sampleTime: chartMetadata.sampleTime,
    periodFrom: chartMetadata.periodFrom,
  });

  const resetChart = useResetChart(timeScale);

  const customSeriesView = useMemo(
    () => new RoundedCandleSeries({ rounded: false }),
    [],
  );

  const onVisibleLogicalRangeChangeCallback = useCallback(() => {
    if (!timeScaleRef.current) return;
    const pos = timeScaleRef.current.scrollPosition();
    if (pos > rightOffset) {
      timeScaleRef.current.scrollToPosition(rightOffset, false);
    }
  }, [timeScaleRef]);

  const loaded = useMemo(() => {
    const isLoaded =
      (data && data.length > 0) ||
      (currentCandle &&
        typeof currentCandle.time === "number" &&
        typeof currentCandle.close === "number");

    return isLoaded;
  }, [data, currentCandle]);

  useEffect(() => {
    if (!loaded) {
      datadogRum.startDurationVital("LoadingChart");
    } else {
      datadogRum.stopDurationVital("LoadingChart");
    }
  }, [loaded]);

  const priceDifferences = usePriceDifferences({
    currentCandle,
    historicalData: historicalData ?? [],
    chartMetadata,
  });

  const bandData = useBandData({
    timeScale,
    data,
    indicatorsState: chartMetadata?.indicatorsState ?? defaultIndicatorsState,
  });

  if (error && !historicalData) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Alert variant="outlined" color="danger">
          {error.message}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          display: loaded ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          backgroundColor: themes[mode].layout.background.color,
        }}
      >
        <Alert variant="outlined" color="neutral">
          Loading...
        </Alert>
      </Box>
      <Chart
        ref={wrapperChartRef}
        onDblClick={() => {
          resetChart();
        }}
        autoSize
        {...themes[mode]}
        wrapperHeight="100%"
        crosshair={{
          horzLine: {
            visible: true,
            labelVisible: true,
            labelBackgroundColor: "#666666",
          },
          vertLine: {
            visible: true,
            labelVisible: true,
            labelBackgroundColor: "#666666",
          },
        }}
        timeScale={{
          rightOffset,
          secondsVisible: true,
          timeVisible: true,
          rightBarStaysOnScroll: true,
          tickMarkFormatter: (time: UTCTimestamp) => {
            if (!isMidnight(time)) return null;

            // time needs to be UTC
            const date = dayjs(time * 1000).utc();
            return date.format("ddd D MMM");
          },
        }}
      >
        {loaded && priceDifferences && (
          <TradingViewPriceDifferences
            priceDifferences={priceDifferences}
            mode={mode}
          />
        )}
        <CandlestickRoundedSeries
          ref={chartRef}
          view={customSeriesView}
          reactive
          data={data}
          priceLineColor={
            currentCandleStale ? priceLineColor.stale : priceLineColor.live
          }
        />
        {chartMetadata.indicatorsState
          ?.filter((s) => s.show)
          .map((s) => {
            switch (s.stateType) {
              case "MovingAverage":
                return <MovingAverageSeries key={s.id} data={data} {...s} />;
              case "BollingerBands":
                return (
                  <BollingerBands
                    key={s.id}
                    bandData={bandData.bb}
                    data={data}
                    {...s}
                  />
                );
              case "KeltnerChannels":
                return (
                  <KeltnerChannels
                    key={s.id}
                    bandData={bandData.kc}
                    data={data}
                    {...s}
                  />
                );
              default:
                return null;
            }
          })}
        <TimeScale
          onVisibleTimeRangeChange={fetchHistoricalData}
          ref={timeScaleRef}
          onVisibleLogicalRangeChange={onVisibleLogicalRangeChangeCallback}
        />
      </Chart>
    </>
  );
}

function TradingViewPriceDifferences({
  priceDifferences,
  mode = "light",
}: {
  priceDifferences: TPriceDifference;
  mode?: "dark" | "light";
}) {
  const labels = specificPeriods.map((p) => p.label);
  const values = Object.values(priceDifferences);

  const width = 160;
  const xs = 4;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 62, // TimeScale height + sample time bar height + 2px margin
        left: 3, // Dockview 1px border + 2px margin
        zIndex: 11,
        pl: 1,
        background:
          mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        borderRadius: "sm",
        ".positive": {
          color: "green",
        },
        ".negative": {
          color: "red",
        },
      }}
    >
      <Typography fontWeight={500}>Rolling Change</Typography>
      <Grid container columnSpacing={1} width={width}>
        {labels.map((label, idx) => (
          <Grid xs={xs} key={label}>
            <Typography
              sx={{
                color: (theme) =>
                  values[idx]?.difference
                    ? "inherit"
                    : theme.palette.text.disabled,
              }}
              fontWeight={500}
            >
              {label}
            </Typography>
          </Grid>
        ))}
        {values.map((v, idx) => (
          <Grid key={labels[idx]} xs={xs}>
            <Typography
              sx={{
                height: (theme) => theme.spacing(2.2),
                fontWeight: 500,
              }}
              slotProps={{
                root: {
                  className: v.className || "",
                },
              }}
            >
              {v.difference ? `${v.difference.toFixed(2)}` : ""}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
