import { useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import type React from "react";

import Loading from "../../webapp/components/Loading";
import type { JWT } from "../../globals";

export const Authorize = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useAuth0();
  useTokenRefresh();

  if (isLoading) {
    return <Loading showLogo workerLoading={false} syncing={false} />;
  }

  return <>{children}</>;
};

const useTokenRefresh = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const qry = useQueryClient();

  // Token refresh handler
  useEffect(() => {
    let refreshTimeout: NodeJS.Timeout;

    const scheduleTokenRefresh = (expirationTime: number) => {
      // Get current time in seconds
      const now = Math.floor(Date.now() / 1000);
      // Calculate time until expiration in seconds
      const timeUntilExpiration = expirationTime - now;
      // Refresh 5 minutes before expiration
      const refreshDelay = Math.max(0, (timeUntilExpiration - 300) * 1000);

      refreshTimeout = setTimeout(async () => {
        try {
          const response = await getAccessTokenSilently({
            detailedResponse: true,
            cacheMode: "off",
          });
          const newToken = jwtDecode<JWT>(response.id_token);
          qry.invalidateQueries({ queryKey: ["auth"] });
          // Schedule next refresh based on new token
          if (newToken.exp) {
            scheduleTokenRefresh(newToken.exp);
          }
        } catch (error) {
          console.error("Error refreshing token", error);
        }
      }, refreshDelay);
    };

    // Initial token setup
    const fetchToken = async () => {
      try {
        const response = await getAccessTokenSilently({
          detailedResponse: true,
        });

        const { id_token } = response;
        const parsedToken = jwtDecode<JWT>(id_token);
        qry.invalidateQueries({ queryKey: ["auth"] });
        // Schedule refresh based on token expiration
        if (parsedToken.exp) {
          scheduleTokenRefresh(parsedToken.exp);
        }
      } catch (error) {
        console.error("Error fetching the token", error);
        loginWithRedirect({ appState: { returnTo: "/" } });
      }
    };

    fetchToken();

    return () => refreshTimeout && clearTimeout(refreshTimeout);
  }, [getAccessTokenSilently, loginWithRedirect, qry]);
};
