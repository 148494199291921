import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { atom, useAtom } from "jotai";

import { useSessionState } from "../../context/auth";

export function useScrollableTabs() {
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  useEffect(() => {
    const tabContainer = tabContainerRef.current;
    if (!tabContainer) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setIsScrollable(entry.target.scrollWidth > entry.target.clientWidth);
      }
    });

    resizeObserver.observe(tabContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const scrollToNextTab = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollBy({
      left: 100, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };

  const scrollToPreviousTab = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollBy({
      left: -100, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };

  const scrollToBeginning = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToEnd = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollTo({
      left: tabContainerRef.current.scrollWidth,
      behavior: "smooth",
    });
  };

  return {
    tabContainerRef,
    isScrollable,
    scrollToNextTab,
    scrollToPreviousTab,
    scrollToBeginning,
    scrollToEnd,
  };
}

// Prevent multiple refresh timeouts from being set.
export const refreshTimeoutSet = atom<boolean>(false);
refreshTimeoutSet.debugLabel = "refreshTimeoutAtom";

export function useRefreshTimeout({
  hour = 1,
  minute = 0,
  offset = 30,
}: {
  hour?: number;
  minute?: number;
  offset?: number;
}) {
  const [timeoutSet, setTimeoutSet] = useAtom(refreshTimeoutSet);
  const { isLoading: tokenLoading } = useAuth0();
  const session = useSessionState();

  useEffect(() => {
    if (timeoutSet) {
      console.log("timeout already set");
      return;
    }

    if (session.data?.access_token && !tokenLoading) {
      const today = new Date();

      // 1 AM based on the user's local time.
      const refreshDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1,
        hour,
        minute,
        0,
      );

      // 1 AM based on GMT, adjusted for the user's local time.
      const timeDiff = today.getTimezoneOffset() / 60;
      const livePricesResetDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1,
        hour - timeDiff,
        minute,
        0,
      );

      const offsetInMs = offset * 60 * 1000;
      const randomOffset =
        Math.floor(Math.random() * (offsetInMs * 2)) - offsetInMs;

      const timeUntilRefresh =
        refreshDate.getTime() - today.getTime() + randomOffset;

      const timeUntilLivePricesReset =
        livePricesResetDate.getTime() - today.getTime() + randomOffset;

      const timeoutRefresh = timeUntilRefresh < 0 ? 0 : timeUntilRefresh;

      const timeoutLivePricesReset =
        timeUntilLivePricesReset < 0 ? 0 : timeUntilLivePricesReset;

      const partsRefresh = new Date(timeUntilRefresh)
        .toISOString()
        .substring(11, 19)
        .split(":");

      const partsLivePricesReset = new Date(timeUntilLivePricesReset)
        .toISOString()
        .substring(11, 19)
        .split(":");

      console.log(
        "Time until refresh:",
        `${partsRefresh[0]} hour(s), ${partsRefresh[1]} minute(s), ${partsRefresh[2]} second(s) - offset: ${offset} minutes - ${refreshDate.toISOString()} (User's local time - without offset)`,
      );

      console.log(
        "Time until live prices reset:",
        `${partsLivePricesReset[0]} hour(s), ${partsLivePricesReset[1]} minute(s), ${partsLivePricesReset[2]} second(s)- offset: ${offset} minutes - ${livePricesResetDate.toISOString()} (GMT - without offset)`,
      );

      setTimeout(() => {
        console.log("doing scheduled page reload");
        window.location.reload();
      }, timeoutRefresh);

      setTimeoutSet(true);
    }
  }, [
    session.data?.access_token,
    tokenLoading,
    hour,
    minute,
    offset,
    timeoutSet,
    setTimeoutSet,
  ]);
}
