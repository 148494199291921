import { Box, Sheet, Stack, Tooltip, Typography, Skeleton } from "@mui/joy";
import { Chip } from "@mui/joy";
import { useAtomValue } from "jotai";
import {
  formatProductFormula,
  formulaRegex,
  sortFormulasByMonth,
} from "../../../utils/formula";
import { gridModalDockviewAtom } from "./modalAtoms";

import { ModalButtons } from "./Buttons";
import type { SxProps } from "@mui/joy/styles/types";
import { useMemo } from "react";
import {
  useAllProductsSuspended,
  useProductById,
  type TProduct,
} from "../../../data";

const chipStyle = {
  maxHeight: 24,
  height: 24,
  borderWidth: 2,
  borderRadius: "sm",
} satisfies SxProps;

const chipGap = 0.5;

/**
 * Selector may contain incrementors or decrementors which specify the row index the formula is referencing.
 * An incrementor is represented by a ' and a decrementor by a ^.
 * So .value'' would reference the value two rows below the current row and .value^ would reference the value one row above the current row.
 */
function relativeRowIndexForSelector({ selector }: { selector: string }) {
  if (!selector) return null;
  if (selector.includes("'")) {
    return `-${selector.match(/'/g)?.length}`;
  }
  if (selector.includes("^")) {
    return `+${selector.match(/\^/g)?.length}`;
  }
  return null;
}

function ProductChip({
  name,
  selectorAdjacent = false,
  selector,
  fetching,
}: {
  name: string;
  selectorAdjacent?: boolean;
  selector?: string;
  fetching: boolean;
}) {
  const [productName, productPackage] = name.split(":");
  function getTitle(selector: string, productPackage: string) {
    const title = `Curve: ${productName}\n Field: ${selector
      ?.trim()
      .replace(/^\./, "")}\nPackage: ${productPackage}`;
    if (!productPackage) {
      return `Curve: ${productName}`;
    }
    return title;
  }

  function displayedCurveName(product: string) {
    if (fetching) return "Loading...";
    if (!productPackage) return "MISSING PERMISSION";
    const regex = /\s*\([^)]*\)/g;
    return product.replace(regex, "");
  }

  const title = getTitle(selector || "", productPackage);
  const rowIndex = relativeRowIndexForSelector({ selector: selector || "" });

  return (
    <div>
      <Tooltip
        title={title}
        placement="top-start"
        sx={{ whiteSpace: "pre-line" }}
      >
        <Chip
          variant="plain"
          sx={{
            width: "auto",
            borderColor: "var(--artis-orange)",
            backgroundColor:
              productPackage || fetching ? "var(--artis-orange)" : "red",
            color: "white",
            borderTopRightRadius: selectorAdjacent ? 0 : "inherit",
            borderBottomRightRadius: selectorAdjacent ? 0 : "inherit",
            ...chipStyle,
          }}
        >
          {displayedCurveName(productName)}
        </Chip>
      </Tooltip>
      {rowIndex ? (
        <>
          {" "}
          <RowIndexChip rowIndex={rowIndex} />
        </>
      ) : null}
    </div>
  );
}

function OperatorChip({ name }: { name: string }) {
  const operator = name.replace(/\*/g, "×").replace(/\//g, "÷");
  return (
    <Box
      sx={{
        ...chipStyle,
        borderWidth: 0,
      }}
    >
      <Typography>{operator}</Typography>
    </Box>
  );
}

function Parenthesis({ name }: { name: string }) {
  return (
    <Box
      color="primary"
      sx={{
        fontSize: "15px",
        fontWeight: "bold",
        paddingTop: "1px",
      }}
    >
      {name}
    </Box>
  );
}

function NumberChip({ name }: { name: string }) {
  return (
    <Chip variant="plain" sx={chipStyle}>
      {name}
    </Chip>
  );
}

function MonthChip({ month }: { month?: string }) {
  if (!month) return null;
  return (
    <Chip
      sx={(theme) => ({
        ...chipStyle,
        marginRight: "10px",
        color: "white",
        background: "#4393E4",
      })}
    >
      {month}
    </Chip>
  );
}

function RowIndexChip({ rowIndex }: { rowIndex?: string }) {
  if (!rowIndex) return null;
  return (
    <Tooltip
      title={
        <span>
          Numbers in brackets indicate an offset <br /> to the currently
          referenced period, <br /> e.g. [-1] on M3 would reference M2 instead.
        </span>
      }
      placement="top"
    >
      <Chip
        sx={(theme) => ({
          ...chipStyle,
          height: "10px",
          color: "white",
          background: theme.palette.primary[400],
          paddingBottom: "2px",
        })}
      >
        [{rowIndex}]
      </Chip>
    </Tooltip>
  );
}

const boxStyle = {
  display: "flex",
  fontWeight: "bold",
  fontSize: "xs",
  alignItems: "center",
  gap: chipGap,
};

export function StyledFormula({
  fetching,
  products,
  month,
  formulaString,
}: {
  fetching: boolean;
  products: TProduct[];
  month: string;
  formulaString: string;
}) {
  function parseReplace(formula: string) {
    const formatted = formatProductFormula(products, formula);
    if (!formatted) {
      return <Box sx={boxStyle}>Value: {formula}</Box>;
    }
    const parts = formatted.split(formulaRegex).flatMap((part, index) => {
      return index % 2 === 1 ? part : part?.trim().split(/(\+|-|\*|\/|\(|\))/);
    });

    const styled = parts.reduce<React.ReactNode[]>((acc, part, index) => {
      const key = part + index;
      if (part?.trim()?.match(/^-?\d+\.?\d*$/)) {
        acc.push(<NumberChip key={key} name={part} />);
        return acc;
      }
      if (part === "MANUAL") {
        acc.push(
          <Chip variant="plain" color="neutral" sx={chipStyle} key={key}>
            MANUAL
          </Chip>,
        );
        return acc;
      }

      if (part?.length === 1) {
        if (["+", "-", "*", "/"].includes(part)) {
          acc.push(<OperatorChip key={key} name={part} />);
          return acc;
        }

        if (["(", ")"].includes(part)) {
          acc.push(<Parenthesis key={key} name={part} />);
          return acc;
        }
      }

      if (index % 2 === 1) {
        const nextPart = parts?.[index + 1];
        acc.push(
          <ProductChip
            key={key}
            fetching={fetching}
            name={part}
            selectorAdjacent={nextPart?.charAt(0) === "."}
            selector={nextPart?.charAt(0) === "." ? nextPart : undefined}
          />,
        );
      } else if (part !== "" && part.charAt(0) !== ".") {
        acc.push(part);
      }

      return acc;
    }, []);

    return (
      <Box sx={boxStyle}>
        <MonthChip month={month} />
        {styled}
        <Box minWidth={8} minHeight={8} />
      </Box>
    );
  }

  return parseReplace(formulaString);
}

export function FormulaViewer() {
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const ranges = dockviewValue["formula-viewer"].selectedRange ?? [];
  const params = ranges.flat().map((cell) => {
    return {
      index: cell.rowIndex,
      productId: cell.productId,
      headerParams: { name: cell.headerName, columnId: cell.columnId },
      value: 29,
      columnId: cell.columnId,
    };
  });

  const latestCell = params[params.length - 1];
  const latestProductId = latestCell?.productId || "";
  const { data, isFetching: fetching } = useAllProductsSuspended();
  const { data: selectedProduct } = useProductById(latestProductId);
  const configs = selectedProduct?.product_configs || [];
  const sortedFormulas =
    useMemo(() => sortFormulasByMonth(configs), [configs]) || [];

  return (
    <>
      {fetching || !selectedProduct ? (
        <Box sx={{ padding: "16px" }}>
          <Typography
            level="title-md"
            sx={{
              width: "100%",
              marginBottom: "15px",
            }}
          >
            {`${selectedProduct?.code}${
              selectedProduct?.description &&
              selectedProduct?.description !== selectedProduct?.code
                ? ` (${selectedProduct?.description})`
                : ""
            }`}
          </Typography>
          <Stack gap={1}>
            {sortedFormulas.map(({ formula, month }) => (
              <Skeleton
                key={`${formula}-${month}`}
                variant="rectangular"
                height={48}
                animation="wave"
                sx={{
                  marginBottom:
                    `${formula}-${month}` ===
                    `${sortedFormulas[sortedFormulas.length - 1].formula}-${sortedFormulas[sortedFormulas.length - 1].month}`
                      ? 0
                      : "5px",
                  borderRadius: "var(--webapp-radius-sm)",
                }}
              />
            ))}
          </Stack>
          <ModalButtons
            parentPanel="formula-viewer"
            cancelLabel="Close"
            hideSave
          />
        </Box>
      ) : (
        <Box height="100%" sx={{ padding: 2, overflow: "auto" }}>
          <Typography
            level="title-md"
            sx={{
              width: "100%",
              marginBottom: "15px",
            }}
          >
            {`${selectedProduct?.code}${
              selectedProduct?.description &&
              selectedProduct?.description !== selectedProduct?.code
                ? ` (${selectedProduct?.description})`
                : ""
            }`}
          </Typography>
          <Stack gap={1}>
            {sortedFormulas.map(({ formula, month }) => (
              <Sheet
                key={`${formula}-${month}`}
                variant="soft"
                sx={{
                  padding: 1.5,
                  borderRadius: "sm",
                  width: "100%",
                  overflowY: "auto",
                  minWidth: "100%",
                }}
              >
                <StyledFormula
                  fetching={fetching}
                  products={data || []}
                  month={month ?? "-"}
                  formulaString={formula ?? "MANUAL"}
                />
              </Sheet>
            ))}
          </Stack>
          <ModalButtons
            parentPanel="formula-viewer"
            cancelLabel="Close"
            hideSave
          />
        </Box>
      )}
    </>
  );
}
