import { atomWithStorage } from "jotai/utils";
import { useMemo } from "react";
import { useAtom, useStore } from "jotai";
import { useUserId } from "../../../context/auth";
import type { ChartSettings } from "./types";
import { genChartId } from "../utils";

const chartSettingsAtom = atomWithStorage<Array<ChartSettings>>(
  "chartSettings",
  [],
);

export const chartLayoutSelectorAtom = atomWithStorage<
  "horizontal" | "vertical"
>("chartLayoutSelector", "horizontal");

export function useChartSettings(chartId: string) {
  const [chartSettings, setChartSettings] = useAtom(chartSettingsAtom);
  const settings = useMemo(
    () => chartSettings.find((x) => x.id === chartId),
    [chartSettings, chartId],
  );

  const setSettings = (newSettings: ChartSettings) => {
    console.log("newSettings", newSettings);
    const existingIdx = chartSettings.findIndex((x) => x.id === chartId);
    if (existingIdx >= 0) {
      const newChartSettings = [...chartSettings];
      newChartSettings[existingIdx] = {
        ...newChartSettings[existingIdx],
        ...newSettings,
      };
      setChartSettings(newChartSettings);
    } else {
      setChartSettings([...chartSettings, newSettings]);
    }
  };

  const removeChart = (id?: string) => {
    setChartSettings(
      chartSettings.filter((x) => x.id !== id || x.id !== chartId),
    );
  };

  return {
    settings,
    setSettings,
    removeChart,
  };
}

export function useLiveCharts() {
  const [charts, setCharts] = useAtom(chartSettingsAtom);
  // Filter charts without a periodFrom or productId
  const filtered = useMemo(
    () => charts.filter((x) => x.periodFrom && x.productId),
    [charts],
  );
  return [filtered, setCharts] as const;
}

export const chartLimit = 6;

/** Inserts a chart into the layout using the first free slot or replacing the last slot */
export const useInsertChart = () => {
  const userId = useUserId();
  const store = useStore();
  return (chart: Omit<ChartSettings, "id">) => {
    const settings = store.get(chartSettingsAtom);
    if (settings.length < chartLimit) {
      store.set(chartSettingsAtom, [
        ...settings,
        {
          ...chart,
          id: genChartId({ chartIdx: settings.length.toString(), userId }),
        },
      ]);
    }
  };
};
