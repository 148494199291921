import { useApolloClient } from "@apollo/client";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  RouterProvider,
  createRoute,
  createRouter,
  redirect,
} from "@tanstack/react-router";
import { ErrorBoundary } from "react-error-boundary";
import { Fragment } from "react/jsx-runtime";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import {
  auditLogRoute,
  consoleRoute,
  orgsRoute,
  createOrganisationRoute,
  editOrganisationRoute,
  reportsEditRoute,
  reportsExchangesRoute,
  reportsGlobalPermissionsRoute,
  reportsRoute,
  rootAdminRoute,
  singleOrgAgreementsRoute,
  singleOrgCreateUserRoute,
  singleOrgEditUserRoute,
  singleOrgGlobalPermissionsRoute,
  singleOrgPackagesRoute,
  singleOrgCreatePackageRoute,
  singleOrgPermissionsRoute,
  singleOrgProductsRoute,
  singleOrgRoute,
  singleOrgSingleUserPackagesRoute,
  singleOrgSingleUserProductsRoute,
  singleOrgUsersRoute,
} from "./admin/adminRoutes";
import { rootRoute, type AppRouterContext } from "./baseRoutes";
import {
  managePagesRoute,
  marketHomeRoute,
  marketPageRoute,
  rootWebappRoute,
  tradingChartRoute,
  userGuideRoute,
} from "./webapp/Routes";
import Loading from "./webapp/components/Loading";

import { ApolloClientProvider } from "./context/apollo";
import { AnalyticsProvider } from "./context/ph";
import { TriplitProvider } from "./triplit/provider";
import { Authorize, AuthProvider } from "./context/auth";
import { AppQueryProvider } from "./context/query";

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: () => {
    throw redirect({
      to: "/app/market",
    });
  },
});

export const catchAllRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/*",
  beforeLoad: () => {
    throw redirect({
      to: "/app/market",
    });
  },
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  rootWebappRoute.addChildren([
    marketHomeRoute,
    marketPageRoute,
    managePagesRoute,
    tradingChartRoute,
    userGuideRoute,
  ]),
  rootAdminRoute.addChildren([
    orgsRoute,
    createOrganisationRoute,
    editOrganisationRoute,
    singleOrgRoute.addChildren([
      singleOrgPermissionsRoute,
      singleOrgGlobalPermissionsRoute,
      singleOrgUsersRoute,
      singleOrgCreateUserRoute,
      singleOrgEditUserRoute,
      singleOrgPackagesRoute,
      singleOrgCreatePackageRoute,
      singleOrgSingleUserPackagesRoute,
      singleOrgProductsRoute,
      singleOrgSingleUserProductsRoute,
      singleOrgAgreementsRoute,
    ]),
    consoleRoute,
    auditLogRoute,
    reportsRoute.addChildren([
      reportsExchangesRoute,
      reportsEditRoute,
      reportsGlobalPermissionsRoute,
    ]),
  ]),
  catchAllRoute,
]);

const createAppRouter = (context: AppRouterContext) =>
  createRouter({
    routeTree,
    defaultPendingComponent: () => <Loading showLogo syncing={false} />,
    defaultErrorComponent: (e) => {
      console.error(e);
      return <div>TODO Error Component</div>;
    },
    context,
  });

declare module "@tanstack/react-router" {
  interface Register {
    router: ReturnType<typeof createAppRouter>;
  }
}

const AuthRedirect = withAuthenticationRequired(Fragment, {
  loginOptions: {
    appState: {
      returnTo: window.location.pathname,
    },
  },
});

export function App() {
  return (
    <ErrorBoundary
      fallback={
        <div>
          <h1>Something went wrong.</h1>
        </div>
      }
    >
      <AppQueryProvider>
        <AuthProvider>
          <AuthRedirect>
            <ApolloClientProvider>
              <TriplitProvider>
                <AnalyticsProvider>
                  <Authorize>
                    <Router />
                  </Authorize>
                </AnalyticsProvider>
              </TriplitProvider>
            </ApolloClientProvider>
          </AuthRedirect>
        </AuthProvider>
      </AppQueryProvider>
    </ErrorBoundary>
  );
}

const Router = () => {
  const apolloClient = useApolloClient();
  const queryClient = useQueryClient();
  const { user } = useAuth0();

  const router = useMemo(
    () => createAppRouter({ apolloClient, queryClient, userId: user?.sub }),
    [apolloClient, queryClient, user?.sub],
  );
  return <RouterProvider router={router} />;
};
