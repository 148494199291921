import {
  Button,
  Stack,
  Typography,
  LinearProgress,
  Sheet,
  Radio,
  RadioGroup,
} from "@mui/joy";
import { GridModalContainer } from "./GridModal";
import { ModalButtons } from "./Buttons";
import { capitalizeFirstLetter } from "../../../utils";
import { closeDockviewModal } from "./modalComponents";
import {
  halfLookups,
  monthLookups,
  quarterLookups,
  seasonLookups,
  yearLookups,
} from "..";
import { useState } from "react";
import { client } from "../../../triplit/triplit";

import {
  parseAdhocSpreadsJSON,
  periodTypeSchema,
  type TAdhocSpread,
  type TSpread,
} from "./timespreadHelpers";
import { isMobile } from "../../../shared/hooks";
import { useActivePageId, usePage } from "../../../data";

export type TTimeSpreadsModalProps = {
  rowId?: string;
};

const monthValues = Object.values(monthLookups);
const quarterValues = Object.values(quarterLookups);
const halfValues = Object.values(halfLookups);
const calValues = Object.values(yearLookups);
const seasonValues = Object.values(seasonLookups);

function getPeriodValues(periodType: string | undefined) {
  switch (periodType) {
    case "months":
      return monthValues;
    case "quarters":
      return quarterValues;
    case "halves":
      return halfValues;
    case "cals":
      return calValues;
    case "seasons":
      return seasonValues;
    default:
      return monthValues;
  }
}

function TimeTypeSelector({
  field,
  spread,
  setSpread,
}: {
  field: "from" | "to";
  spread: TSpread;
  setSpread: React.Dispatch<React.SetStateAction<TSpread>>;
}) {
  const options = getPeriodValues(spread?.periodType || "months");

  return (
    <Stack alignItems={"space-between"} width="50%">
      <Stack gap={1} padding={2}>
        <RadioGroup
          value={spread?.periodType ?? "months"}
          size="lg"
          onChange={(e) => {
            const value = periodTypeSchema.safeParse(e?.target?.value);

            if (!value.success) return;

            setSpread((prev) => ({
              ...prev,
              periodType: value.data,
            }));
          }}
          sx={{ gap: 1 }}
        >
          <Stack
            flexDirection={"row"}
            flexWrap="wrap"
            justifyContent={"center"}
            gap={1}
          >
            {["months", "quarters", "halves", "cals", "seasons"].map((type) => {
              return (
                <Sheet
                  key={type}
                  sx={(theme) => ({
                    p: 1,
                    borderRadius: "md",
                    boxShadow: "sm",
                    width: `calc(50% - ${theme.spacing(1)})`,
                  })}
                >
                  <Radio
                    label={capitalizeFirstLetter(type)}
                    overlay
                    disableIcon
                    value={type}
                    slotProps={{
                      label: ({ checked }) => ({
                        sx: {
                          fontWeight: "lg",
                          fontSize: "md",
                          color: checked ? "text.primary" : "text.secondary",
                        },
                      }),
                      action: ({ checked }) => ({
                        sx: (theme) => ({
                          ...(checked && {
                            "--variant-borderWidth": "2px",
                            "&&": {
                              borderColor: theme.vars.palette.primary[500],
                            },
                          }),
                        }),
                      }),
                    }}
                  />
                </Sheet>
              );
            })}
          </Stack>
        </RadioGroup>
      </Stack>
      <Stack
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Typography>{`${capitalizeFirstLetter(field)}: `}</Typography>
        <select
          value={spread?.periodValue ?? ""}
          className="min-w-[60px] border border-gray-300 rounded-lg p-2 shadow-sm"
          onChange={(e) => {
            const value = e.target.value;
            setSpread((prev) => ({
              ...prev,
              periodValue: value,
            }));
          }}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </Stack>
    </Stack>
  );
}

export function TimeSpreadsModalWithState({
  pageId,
  rowId,
  adhocSpreads,
}: {
  pageId: string;
  rowId: string;
  adhocSpreads: TAdhocSpread | null;
}) {
  const index = adhocSpreads?.findIndex((spread) => spread.rowId === rowId);
  const adhocSpread = index !== undefined ? adhocSpreads?.[index] : null;

  const [from, setFrom] = useState<TSpread>({
    periodType: adhocSpread?.from?.periodType ?? "months",
    periodValue:
      adhocSpread?.from?.periodValue ??
      getPeriodValues(adhocSpread?.from?.periodType ?? "months")[0],
  });

  const [to, setTo] = useState<TSpread>({
    periodType: adhocSpread?.to?.periodType ?? "months",
    periodValue:
      adhocSpread?.to?.periodValue ??
      getPeriodValues(adhocSpread?.to?.periodType ?? "months")[0],
  });

  return (
    <GridModalContainer
      panel="tspds"
      body={
        <Stack
          sx={
            isMobile
              ? { gap: 2, flexDirection: "column", alignItems: "center" }
              : {
                  flexDirection: "row",
                }
          }
        >
          <TimeTypeSelector field="from" spread={from} setSpread={setFrom} />
          <TimeTypeSelector field="to" spread={to} setSpread={setTo} />
        </Stack>
      }
      buttons={
        <ModalButtons
          parentPanel="tspds"
          additionalButtons={
            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              type="button"
              onClick={async () => {
                await client.update("pages", pageId, (row) => {
                  row.adhocSpreadsJSON = JSON.stringify(
                    adhocSpreads?.filter((spread) => spread.rowId !== rowId),
                  );
                });

                closeDockviewModal("tspds");
              }}
            >
              Clear
            </Button>
          }
          onSave={async () => {
            const newSpread = {
              rowId,
              from,
              to,
            } satisfies TAdhocSpread[number];
            if (index === undefined || !adhocSpreads) {
              const newSpreads = JSON.stringify([
                ...(adhocSpreads ?? []),
                { rowId, from, to },
              ]);
              console.log("Adding Spread", newSpreads);
              await client.update("pages", pageId, (row) => {
                row.adhocSpreadsJSON = newSpreads;
              });
            } else {
              if (index < 0) {
                adhocSpreads.push(newSpread);
              } else {
                adhocSpreads[index] = newSpread;
              }

              console.log("Updating Spread", adhocSpreads);
              await client.update("pages", pageId, (row) => {
                row.adhocSpreadsJSON = JSON.stringify(adhocSpreads);
              });
            }
          }}
        />
      }
    />
  );
}

export default function TimeSpreadsModal({ rowId }: TTimeSpreadsModalProps) {
  const pageId = useActivePageId();
  const page = usePage(pageId);

  const adhocSpreads = parseAdhocSpreadsJSON(page.data?.adhocSpreadsJSON) || [];

  if (page.isFetching || !rowId || !pageId) return <LinearProgress />;

  return (
    <TimeSpreadsModalWithState
      pageId={pageId}
      rowId={rowId}
      adhocSpreads={adhocSpreads}
    />
  );
}
