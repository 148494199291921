import { wrap, type Remote } from "comlink";
import type { CalculationsWorker } from "../webapp/calculations-worker";

export type WorkerTypes = Blob | string | ReturnWorkerTypes;
export type ReturnWorkerTypes = () =>
  | Exclude<WorkerTypes, ReturnWorkerTypes>
  | Worker
  | SharedWorker;

export interface Comlink<T> {
  /** the Worker itself, exposed for mocking, debugging, etc */
  worker: Worker;
  /** Comlink proxy through `Comlink.wrap(worker)` */
  proxy: Remote<T>;
}

/**
 * Creates one instance of a worker that can be instantiated inside components and share a global state.
 * It always loads the worker before mounting any components, so most likely the worker will be
 * ready to use when called inside a component.
 *
 * NOTE: This creates ONE instance of the worker, with a global state, and it should be
 * used very carefully (and sparsingly). The global state won't show the same value on other
 * mounted components unless you query it. One of the problems with using this is that sometimes
 * the browser might FREE the worker during a GC
 *
 * @see https://github.com/GoogleChromeLabs/comlink/issues/63
 *
 * @example
 *  const useMyMethods = createComlinkSingleton(new Worker('./worker.js')) // outside the component, it's a factory
 *
 *  const MyComponent = () => {
 *    const myMethods = useMyMethods() // returns { proxy, worker }
 *
 *    useEffect(() => {
 *      (async function(){
 *        await myMethods.proxy.someMethod()
 *      })()
 *
 *      return () => myMethods.worker.terminate() // bad things may happen!
 *    }, [myMethods]) // myMethods is a memoed object, so it's considered stable
 *
 *    return (<div />)
 *  }
 */
export function createComlinkSingleton(
  initWorker: SharedWorker | Worker,
): () => {
  worker: Worker | SharedWorker;
  proxy: Remote<typeof CalculationsWorker>;
} {
  // Process the worker or message port
  const worker = initWorker;
  const isSharedWorker = !(worker instanceof Worker);

  console.log("isSharedWorker", isSharedWorker);

  const proxy = wrap<typeof CalculationsWorker>(
    isSharedWorker ? worker.port : worker,
  );
  console.log("starting calcworker", worker);

  return () => ({
    worker,
    proxy,
  });
}

export let calcWorkerInstance:
  | ReturnType<typeof createComlinkSingleton>
  | undefined;

export function initializecalcWorker(worker: Worker | SharedWorker) {
  if (typeof window !== "undefined" && !calcWorkerInstance) {
    calcWorkerInstance = createComlinkSingleton(worker);
  }
}
