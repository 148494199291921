import { useQueries, type UseQueryOptions } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

import {
  sampleRoundingIndex,
  sampleTimeToSeconds,
  validSampleOrFirst,
} from "../utils";
import {
  type TLiveChart,
  type TRegularQueryData,
  regularQueryDataSchema,
} from "./types";
import { checkCacheForData, fetchQuestData, parseQuestDBData } from "./helpers";
import { defaultSelector } from "../../market-grid/copyRangeSelectionHelpers";

export function useSpecificHistoricalChartData({
  chartMetadata,
  dates,
}: {
  chartMetadata: TLiveChart;
  dates: Array<{
    from: string;
    to: string;
  }>;
}) {
  const { productId, periodFrom } = chartMetadata;
  const sampleTime = validSampleOrFirst(chartMetadata.sampleTime);

  if (!productId || !sampleTime) {
    throw new Error("productId and sampleTime are required");
  }

  const { round, unit } = sampleRoundingIndex[sampleTime];
  const sampleTimeSeconds = sampleTimeToSeconds[sampleTime];

  const { getAccessTokenSilently } = useAuth0();
  const artisType = chartMetadata.artisType || "global";
  const field = defaultSelector(artisType);
  const isCalc = field === "value";

  const queries = dates.map(({ from }, idx) => {
    const fromUTCDate = dayjs.utc(from).round(round, unit).toISOString();
    const toUTCDate = dayjs
      .utc(from)
      .round(round, unit)
      .add(sampleTimeSeconds, "seconds")
      .toISOString();

    const queryKey = [
      "specificHistoricalChartData",
      productId,
      sampleTime,
      periodFrom,
      fromUTCDate,
    ];

    return {
      queryKey,
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        const params = {
          token,
          field,
          periodFrom: periodFrom || "",
          fromUTCDate,
          toUTCDate,
          product: productId,
          sampleTime: idx === 0 ? "10s" : sampleTime,
          isCalc,
        };

        const cachedData = checkCacheForData({
          productId,
          sampleTime,
          periodFrom,
          fromUTCDate,
          toUTCDate,
        });

        if (cachedData?.data?.length) {
          return cachedData;
        }

        const res = await fetchQuestData(params);

        if ((res.data.length && res.data?.[0]?.length) || idx !== 0) {
          return res;
        }

        let attempt = 1;

        while (attempt < 4) {
          const fromUTCDate = dayjs
            .utc(params.fromUTCDate)
            .subtract(attempt, "day")
            .toISOString();

          const toUTCDate = dayjs
            .utc(params.toUTCDate)
            .subtract(attempt, "day")
            .add(12, "hours")
            .toISOString();

          const res = await fetchQuestData({
            ...params,
            fromUTCDate,
            toUTCDate,
          });

          if (res.data.length && res.data?.[0]?.length) {
            return res;
          }

          attempt++;
        }
        return {
          data: [],
          fromUTCDate,
          toUTCDate,
          timeTaken: 0,
        };
      },
      select: (data: unknown) => {
        const parsed = regularQueryDataSchema.safeParse(data);

        if (!parsed.success) {
          return [];
        }

        return selectRegular(parsed.data);
      },
    } satisfies UseQueryOptions;
  });

  return useQueries({
    queries,
  });
}

function selectRegular(data: TRegularQueryData) {
  if (data.data.length === 0) {
    return [];
  }

  return parseQuestDBData(data.data[0]);
}
