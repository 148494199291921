import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { Button } from "@mui/joy";
import { useState } from "react";

import EodDateSelectPopup from "./EodDateSelectPopup";
import { useGridSettingsEodDate } from "../../grid-settings";

export function EodHeader() {
  const [anchor, setPopupAnchor] = useState<HTMLElement | null>(null);
  const selectedDate = useGridSettingsEodDate();

  return (
    <>
      {anchor && (
        <ClickAwayListener onClickAway={() => setPopupAnchor(null)}>
          <BasePopup
            open={Boolean(anchor)}
            anchor={anchor}
            offset={4}
            placement="bottom-start"
          >
            <EodDateSelectPopup
              selectedDate={selectedDate}
              handleClose={() => setPopupAnchor(null)}
            />
          </BasePopup>
        </ClickAwayListener>
      )}
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          color: "inherit",
          background: "transparent",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
            background: "transparent",
          },
        }}
        onClick={(e) => setPopupAnchor(e.currentTarget)}
      >
        {selectedDate ?? "Set EOD Date"}
      </Button>
    </>
  );
}
