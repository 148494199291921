import duration, { type DurationUnitType } from "dayjs/plugin/duration";
import type { Dayjs } from "dayjs";
export type { Dayjs } from "dayjs";

type DayjsFactoryType = typeof import("dayjs");
type DayjsClassType = typeof Dayjs;

declare module "dayjs" {
  interface Dayjs {
    round(amount: number, unit: DurationUnitType): Dayjs;
  }
}

export default (
  option: unknown,
  dayjsClass: DayjsClassType,
  dayjsFactory: DayjsFactoryType,
) => {
  dayjsFactory.extend(duration);

  const _round = function (
    this: Dayjs,
    amount: number,
    unit: DurationUnitType,
  ) {
    const duration = dayjsFactory.duration(amount, unit);
    return dayjsFactory(
      Math.floor(this.valueOf() / duration.as("milliseconds")) *
        duration.as("milliseconds"),
    );
  };

  /**
   * Rounds down the current date to the nearest specified unit.
   * Only works when rounding down within the same day.
   * @param {number} amount - The amount to round to.
   * @param {DurationUnitType} unit - The unit of time to round to.
   * @returns {Dayjs} The date rounded to the nearest specified unit.
   */
  dayjsClass.prototype.round = function (
    amount: number,
    unit: DurationUnitType,
  ) {
    return _round.call(this, amount, unit);
  };
};
