import { useState, useEffect, useCallback } from "react";
import { Typography } from "@mui/joy";

const cityTimeZones = {
  "New York": "America/New_York",
  London: "Europe/London",
  Geneva: "Europe/Zurich",
  Dubai: "Asia/Dubai",
  Singapore: "Asia/Singapore",
};

const cityAbbreviations = {
  "New York": "NYC",
  London: "LON",
  Geneva: "GVA",
  Dubai: "DUB",
  Singapore: "SIN",
};

const defaultCities = [
  "New York",
  "London",
  "Geneva",
  "Dubai",
  "Singapore",
] as const;

export function WorldClock({ cities = defaultCities }) {
  const [times, setTimes] = useState<Record<string, string>>({});

  const updateTimes = useCallback(() => {
    const newTimes: Record<string, string> = {};
    cities.forEach((city) => {
      const timeZone = cityTimeZones[city];
      if (timeZone) {
        const time = new Intl.DateTimeFormat("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone,
          hour12: false,
        }).format(new Date());
        newTimes[city] = time;
      }
    });
    setTimes(newTimes);
  }, [cities]);

  useEffect(() => {
    const now = new Date();
    const millisecondsUntilNextMinute =
      (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

    const timeoutId = setTimeout(() => {
      updateTimes();
      const intervalId = setInterval(updateTimes, 60000); // Update every minute
      return () => clearInterval(intervalId);
    }, millisecondsUntilNextMinute);

    return () => clearTimeout(timeoutId);
  }, [updateTimes]);

  useEffect(() => {
    updateTimes(); // Initial update
  }, [updateTimes]);

  return (
    <div className="flex pl-4 items-center">
      {cities.map((city, index) => (
        <Typography key={city} sx={{ mr: index < cities.length - 1 ? 2 : 0 }}>
          {cityAbbreviations[city]}: {times[city]}
        </Typography>
      ))}
    </div>
  );
}
