import { pagesQueryOptions, pageProductsQueryOptions } from "./pages";
import { productsInfoQueryOptions } from "./products/index";

import type { AppRouterContext } from "../baseRoutes";

export * from "./products/index";
export * from "./sources";
export * from "./pages";
export * from "./user";
export * from "./org";

export const loadMarketPageData = (ctx: AppRouterContext, pageId: string) =>
  Promise.all([
    ctx.queryClient.ensureQueryData(pagesQueryOptions()),
    ctx.queryClient.ensureQueryData(
      pageProductsQueryOptions(ctx.userId, pageId),
    ),
    ctx.queryClient.ensureQueryData(productsInfoQueryOptions(ctx)),
  ]);
