import { useStore } from "jotai";

import {
  liveChartsPeriods,
  monthCodeToShortMonthAndYear,
} from "../periodHelpers";
import { useUserId, useUserSubscriptionTier } from "../../../context/auth";
import { liveChartsOpenAtom } from "../../live-charts/utils";
import { useInsertChart } from "../../live-charts/hooks";

import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsCharts = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();
  const insertLiveChart = useInsertChart();
  const userId = useUserId();
  const store = useStore();

  return (ctx) => {
    if (!ctx.isProduct) return [];
    return [
      {
        name: "View Historical Chart",
        disabled: disabledFeatures?.plotLiveChart,
        tooltip: disabledFeatures?.plotLiveChart
          ? "Please upgrade your subscription to access this feature"
          : undefined,
        icon: getIcon("chartCandlestick"),
        action: () => {
          const cell = ctx.rangeSelection?.[0];
          if (!cell) return;

          const productId = cell?.productId;
          const rowId = cell?.rowId;
          if (!productId || !rowId) return;

          const month = monthCodeToShortMonthAndYear(rowId);
          const periodFrom = liveChartsPeriods.find(
            (p) => p.label === month,
          )?.value;

          insertLiveChart({ userId, periodFrom, productId, sampleTime: "1m" });
          store.set(liveChartsOpenAtom, true);
        },
      },
    ];
  };
};
