import type { ApolloClient } from "@apollo/client";
import type { GridApi } from "ag-grid-community";
import { registerExtension } from "msgpack-es";
import * as R from "remeda";

import { store } from "../../../sharedHooks";
import { client } from "../../../../triplit/triplit";
import {
  availablePeriods,
  gridChartsAtom,
  gridChartsQuery,
  maxChartMonths,
  type TChartData,
} from "../../../grid-charts";
import type { TGridCharts, TStatusMap } from "../../../../triplit/schema";
import type {
  TData,
  TGridDataEntry,
  TGridDataRowId,
} from "../../../calculations-worker/sharedStores";
import { calcWorker } from "../../../calculations-worker/hooks";
import {
  colParams,
  defaultFieldNameSelector,
  isListOnlyPermissions,
} from "../../../utils";

import { getFragmentData } from "../../../../__generated__/gql";
import type { useAdhocSpreads } from "./../../modals/timespreadHooks";
import {
  gridProductsByIds,
  ProductFragmentGrid,
} from "../../../../data/products/queries";
import type { TPageProductWithInfo } from "../../../../data";

registerExtension(
  // id used for keywords in live-prices
  3,
  String,
  // we don't encode anything so don't bother implementing
  () => new Uint8Array(),
  // treat keywords as strings but strip :
  (keyword) => new TextDecoder().decode(keyword).substring(1),
);

let apiIssueCount = 0;

function updateCharts(
  // resolvedValues is an array of objects where the index of the array
  // is the row index and the object has a key of the product id and value of calcite data (e.g {Ok: 123})
  resolvedValues: TGridDataEntry[],
  gridChartsConfig: TGridCharts[],
) {
  const newChartData = gridChartsConfig
    .map((chart) => {
      if (!chart.productId || !chart.period || !chart.selector)
        return {
          id: chart.id,
          period: chart.period || 12,
          productId: chart.productId || "",
          selector: chart.selector || "",
          data: [],
        };
      const periods = R.range(0, chart.period);
      const data: TChartData[number]["data"] = [];
      for (const period of periods) {
        const resolvedValue = resolvedValues[period]?.[chart.productId];
        if (
          resolvedValue &&
          !resolvedValue?.Err &&
          "Ok" in resolvedValue &&
          typeof resolvedValue?.Ok !== "string"
        ) {
          data.push({
            type: availablePeriods[period],
            value: resolvedValue.Ok,
          });
        }
      }
      return {
        id: chart.id,
        period: chart.period,
        productId: chart.productId,
        selector: chart.selector,
        data,
      };
    })
    .filter(Boolean) satisfies TChartData;

  store.set(gridChartsAtom, newChartData);
}

// Exported functions
export function getAllRowIds(api: GridApi<TData>): TGridDataRowId[] {
  const rows: TGridDataRowId[] = [];
  if (!api || api.isDestroyed()) return rows;
  api.forEachNode((node) => {
    if (!node?.data) throw new Error("Node data is missing");
    rows.push([node.data.rowType, node.data.id]);
  });

  return rows;
}

export function addInfoToPageProduct(
  product: TPageProductWithInfo,
  statusMap: TStatusMap,
) {
  if (!product || !product.productId || !product.artis_type) return;

  const artisType = product?.artis_type;
  const fieldSelector =
    product?.columnFieldSelector ??
    defaultFieldNameSelector(artisType || "customer_curve");

  const permissions = product?.packageByPackage?.permissions;
  const isListOnlyPermission = isListOnlyPermissions(permissions);

  const isPermissioned = permissions?.length && !isListOnlyPermission;

  const eodId =
    artisType === "eod"
      ? product.eod_product_dep || product.productId
      : product.eod_product_dep;

  return {
    ...product,
    columnId: product.id,
    eodId,
    productId: product.productId,
    selector: fieldSelector,
    isPermissioned: !!isPermissioned,
    productConfigs: product.product_configs,
    hasSharedCell: !!product.has_shared_cell,
    artisType,
    status: statusMap?.[product.productId] || "listen",
  };
}

const today = new Date().toISOString().split("T")[0];

export async function updateGrid(
  apolloClient: ApolloClient<object>,
  adhocSpreads: ReturnType<typeof useAdhocSpreads>["formattedAdhocSpreads"],
  api: GridApi<TData>,
  userId: string,
  products: TPageProductWithInfo[],
  statusMap: Record<string, string>,
) {
  try {
    const calculationWorker = calcWorker?.().proxy;

    const eodEvalDate = (await calculationWorker?.getEodDate()) || today;

    if (!api || api.isDestroyed()) {
      console.warn("api not ready", api);
      apiIssueCount++;
      if (apiIssueCount > 20) {
        console.error("reloading cause of api issue");
        window.location.reload();
        return;
      }
      return;
    }

    apiIssueCount = 0;
    const transactions: TData[] = [];
    const productsWithKey = products
      .map((product) => addInfoToPageProduct(product, statusMap))
      .filter(Boolean);

    const allRowIds = getAllRowIds(api);
    const isChartsOpen = localStorage.getItem("bottomPanelOpen") === "true";
    if (isChartsOpen) {
      const gridChartsMap = await client.fetch(gridChartsQuery.build(), {
        policy: "local-only",
      });
      const charts = gridChartsMap;
      if (charts.length) {
        const chartProductsToFetch = charts
          .map((chart) => {
            if (products.find((p) => p.productId === chart.productId)) {
              return null;
            }
            return chart.productId;
          })
          .filter(Boolean);

        apolloClient
          .query({
            query: gridProductsByIds,
            variables: { ids: chartProductsToFetch, folioUser: userId },
          })
          .then(async (res) => {
            if (!res) return;
            const resProduct = getFragmentData(
              ProductFragmentGrid,
              res?.data?.product,
            );
            const missingChartProducts =
              resProduct?.map((p) => ({
                ...p,
                productId: p.id,
              })) || [];
            const chartProducts = [...productsWithKey, ...missingChartProducts]
              .filter((product) =>
                charts.find((c) => c.productId === product.productId),
              )
              .map((product) =>
                addInfoToPageProduct(
                  {
                    ...product,
                    id: product.productId,
                    columnType: "product",
                    productId: product.productId,
                    columnFieldSelector: charts.find(
                      (c) => c.productId === product.productId,
                    )?.selector,
                    // the below are just there to keep the types simple
                    userId,
                    idx: "",
                    columnWidth: 100,
                    pageId: "",
                    decimalPlaces: 0,
                    thousandsSeparator: false,
                  },
                  statusMap,
                ),
              )
              .filter(Boolean);

            const resolvedChartData = await calculationWorker?.getGridData({
              rowIds: R.take(allRowIds, maxChartMonths),
              columns: chartProducts,
              eodEvalDate,
            });
            updateCharts(resolvedChartData || [], charts);
          });
      }
    }
    const resolvedGridData = await calculationWorker?.getGridData({
      rowIds: allRowIds,
      columns: productsWithKey,
      eodEvalDate,
      adhocSpreads,
    });

    if (!resolvedGridData || !api || api.isDestroyed()) return;

    api.forEachNode((node, idx) => {
      if (node.data?.blank || !node.data) return;
      const rowUpdates = node.data;
      const monthCode = node.id;
      if (typeof monthCode !== "string") return;

      const columns = api.getColumns();
      const resolvedCell = resolvedGridData[idx];

      if (!columns) return;

      const columnsWithoutPermissions = columns.filter((c) => {
        const params = colParams(c);
        if (
          (!params?.permissions ||
            (params?.permissions && !params?.permissions?.length)) &&
          params?.productId
        ) {
          return c;
        }
      });

      for (const column of productsWithKey) {
        const colId = column.id;
        const productId = column.productId;

        if (!productId || productId === "period" || !resolvedCell) return;

        const resolvedValue = resolvedCell[colId];

        if (resolvedValue && !resolvedValue?.Err && "Ok" in resolvedValue) {
          const newValue = resolvedValue.Ok;
          if (rowUpdates[colId] !== newValue) {
            rowUpdates[colId] = newValue;
          }
        } else if (resolvedValue?.Err) {
          // in the future we can add error visualisations here like excel
          rowUpdates[colId] = undefined;
        } else {
          rowUpdates[colId] = undefined;
        }
      }

      for (const column of columnsWithoutPermissions) {
        rowUpdates[column.getColId()] = undefined;
      }

      transactions.push(rowUpdates);
    });

    api.applyTransactionAsync({
      update: transactions,
    });
  } catch (error) {
    console.error("updateGrid error", error);
  }
}
