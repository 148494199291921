import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  client,
  reportSyncError,
  type TQuickAccess,
  type TriplitEntity,
} from "../../../triplit";
import { useQuickAccess } from "../keyboard/useQuickAccess";
import type { GetContextMenuSection } from "./helpers";
import { useUserId } from "../../../context/auth";
import { useActivePageId } from "../../../data";
import { getIcon } from "./icons";

export const useGetMenuItemsQuickAccess = (): GetContextMenuSection => {
  const upsert = useUpsertQuickAccess();
  const quickAccess = useQuickAccess();
  const pageId = useActivePageId();
  const userId = useUserId();

  return (ctx) => {
    if (!ctx.isProduct) return [];
    return [
      {
        name: "Add to Quick Access",
        icon: getIcon("keyboard"),
        subMenu: new Array(5).fill(null).map((_, i) => ({
          icon: quickAccess.data.find((q) => q.shortcutNumber === i + 1)
            ? getIcon("chevron")
            : undefined,
          name: `Quick Access Curve ${i + 1} (Alt + Shift + ${i + 1})`,
          tooltip: getQuickAccessName(i + 1, quickAccess.data),
          action: () =>
            upsert.mutateAsync({
              userId,
              columnId: ctx.columnId,
              pageId,
              productId: ctx.productId,
              shortcutNumber: i + 1,
            }),
        })),
      },
    ];
  };
};

function getQuickAccessName(
  shortcutNumber: number,
  quickAccess: (TQuickAccess & { productName?: string | undefined })[],
) {
  const existing = quickAccess?.find(
    (qa) => qa.shortcutNumber === shortcutNumber,
  );

  return existing?.productName || "";
}

export const useUpsertQuickAccess = () => {
  const qry = useQueryClient();

  return useMutation({
    mutationKey: ["quickAccess"],
    mutationFn: async (ent: Omit<TriplitEntity<"quickAccess">, "id">) => {
      const id = `${ent.userId}-${ent.shortcutNumber}`;
      const exists = await client.fetchById("quickAccess", id);
      if (exists) {
        return client.update("quickAccess", id, (entity) => {
          entity.pageId = ent.pageId;
          entity.columnId = ent.columnId;
        });
      }
      return client.insert("quickAccess", {
        id,
        userId: ent.userId,
        pageId: ent.pageId,
        columnId: ent.columnId,
        productId: ent.productId,
        shortcutNumber: ent.shortcutNumber,
      });
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["quickAccess"] });
      reportSyncError(trx?.txId, "quickAccess", "insert");
    },
  });
};
