import { atom } from "jotai";
import {
  getManualInstrumentType,
  knownStatusList,
  type TStatus,
} from "./statusLogic";
import { useUserId } from "../../../context/auth";
import { useCallback } from "react";
import { calcWorker, type TWorker } from "../../calculations-worker/hooks";

import type { TOptimisticCell } from "../../calculations-worker/subscriptionHandlers";
import type { ProductFragmentGridFragment } from "../../../__generated__/gql/graphql";
import { objectEntries } from "../../../utils";
import {
  knownStatusOptions,
  knownStatusRules,
  statusTransitions,
  type TKnownStatusRules,
  type TStatusOption,
} from "./consts";
import {
  useGridSettingsStatusMap,
  useUpdateGridSettings,
} from "../../grid-settings";
import { monthRowIds, offsetToRow } from "../periodHelpers";
import type { TGridSettings } from "../../../triplit";
import {
  useGlobalPackagesByProductIds,
  useGlobalProductsByProductIds,
} from "../../../data";

export const changeAllHoverAtom = atom<boolean>(false);

export function useUpdateColumnStatus() {
  const statusMap = useGridSettingsStatusMap();
  const userId = useUserId();
  const update = useUpdateGridSettings();

  return useCallback(
    (toStatus: TStatus, productIds: string[]) => {
      const next = { ...statusMap };
      productIds.forEach((productId) => {
        next[productId] = toStatus;
      });

      return update.mutateAsync({
        id: userId,
        updaterFn: (data) => {
          data.statusMap = JSON.stringify(next);
        },
      });
    },
    [statusMap, userId, update.mutateAsync],
  );
}

async function writeStatusInstruments(
  {
    to,
    from,
    curveId,
    gridId,
  }: {
    to: TStatus;
    from: TStatus;
    curveId: string;
    gridId: string;
  },
  getData: TWorker["getGridData"],
  updateCell: TWorker["optimisticCellEdit"],
) {
  const storageType = getManualInstrumentType(to);
  if (!storageType || !knownStatusList.includes(to)) {
    console.error(`can't write when going to status: ${to}`);
    return;
  }
  const rowIds = monthRowIds.map((month, idx) => ["mth", month, idx] as const);
  const rawCellValues = await getData({
    rowIds,
    columns: [
      {
        eodId: undefined,
        columnId: gridId,
        productId: curveId,
        artisType: "customer_curve",
        hasSharedCell: true,
        selector: "value",
        status: from,
        isPermissioned: true,
      },
    ],
  });
  if (!rawCellValues) return;
  const cells: TOptimisticCell[] = [];
  for (const rowId of rowIds) {
    const rowIdx = rowId[2];
    const cell = rawCellValues[rowIdx];
    const month = offsetToRow[rowIdx];
    const result = cell?.[gridId]?.Ok ?? undefined;
    if (typeof result === "string") {
      console.error(`Result is string: ${result}`);
      continue;
    }
    cells.push({
      result,
      month,
      storageType,
      offset: rowIdx,
      product: curveId,
      field: "value",
      rowId: rowId[1],
    });
  }
  updateCell(cells);
}

export function useWriteStatusInstruments() {
  const worker = calcWorker?.().proxy;
  return useCallback(
    async (params: Parameters<typeof writeStatusInstruments>[0]) => {
      if (!worker) {
        throw new Error("Worker not available");
      }
      await writeStatusInstruments(
        { ...params },
        worker.getGridData,
        worker.optimisticCellEdit,
      );
    },
    [worker],
  );
}

export function checkCanBroadcast(
  permissions:
    | ProductFragmentGridFragment["packageByPackage"]["permissions"]
    | null
    | undefined,
) {
  return permissions?.some(
    (perm) => perm.permission === "broadcast" || perm.permission === "write",
  );
}

export function verifyKnownStatusRules(
  rules: TKnownStatusRules | undefined,
  resolvedRules: Required<TKnownStatusRules>,
) {
  return (
    !rules ||
    objectEntries(rules).every(([rule, bool]) => bool === resolvedRules[rule])
  );
}

const sourceToGlobalPackageIcons: Record<number, string> = {
  33: "OB_favicon.png",
  29: "OB_favicon.png",
  4: "logo-TP.png",
  369: "logo-TP.png",
  5: "logo-ICAP.png",
  368: "logo-ICAP.png",
  6: "logo-PVM.png",
  367: "logo-PVM.png",
  28: "logo-Artis.svg",
};

export function useGlobalDependencyOptions(
  products: ProductFragmentGridFragment[] | null | undefined,
) {
  const dependencies =
    products?.flatMap((product) => product.product_global_dependencies) || [];

  const { data: globalProducts } = useGlobalProductsByProductIds(
    dependencies.map((dep) => dep.global_product),
  );
  const { data: globalDependenciesDetails } = useGlobalPackagesByProductIds(
    dependencies.map((dep) => dep.global_product),
  );
  if (!products || !globalDependenciesDetails) return [];

  const uniqueOptions = new Set<number>();

  return dependencies.reduce<TStatusOption[]>((acc, dep) => {
    const productId = dep.global_product;
    const productDetail = globalProducts?.find((prod) => prod.id === productId);
    const globalPackageId = productDetail?.global_package;
    const globalPackageDetail = globalDependenciesDetails.find(
      (pkg) => pkg.id === globalPackageId,
    );

    const iconPath = globalPackageDetail?.source
      ? `/icons/${sourceToGlobalPackageIcons[globalPackageDetail.source]}`
      : undefined;

    const option = {
      value: productId,
      label: globalPackageDetail?.description || "",
      style: undefined,
      icon: undefined,
      image: false,
      logo: iconPath || undefined,
    } satisfies TStatusOption;

    if (
      globalPackageDetail?.source &&
      !uniqueOptions.has(globalPackageDetail.source)
    ) {
      uniqueOptions.add(globalPackageDetail.source);
      acc.push(option);
    }

    return acc;
  }, []);
}

export function genSelectStatusOptions({
  resolvedRules,
  globalOptions,
  settings,
}: {
  resolvedRules: Required<TKnownStatusRules>;
  globalOptions: ReturnType<typeof useGlobalDependencyOptions>;
  settings: TGridSettings;
}) {
  return [
    ...knownStatusOptions
      // to compare the hard coded rules in statusOptions with the resolved rules
      // belonging to the product in question.
      .filter(({ value }) => {
        let userDisabled = false;
        switch (value) {
          case "eod":
            userDisabled = !!settings?.hideEOD;
            break;
          case "private":
            userDisabled = !!settings?.hidePrivate;
            break;
          case "broadcast":
            userDisabled = !!settings?.hideBroadcast;
            break;
        }
        return (
          !userDisabled &&
          verifyKnownStatusRules(knownStatusRules[value], resolvedRules)
        );
      }),
    ...(settings?.hideGlobal ? [] : globalOptions),
  ] satisfies TStatusOption[];
}

export function genStatusTransitionActions({
  from,
  to,
}: {
  from: string | undefined;
  to: string;
}) {
  return statusTransitions.find(
    (entry) => (entry.from === "*" || entry.from === from) && entry.to === to,
  );
}
