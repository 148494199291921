import type { ColumnMovedEvent } from "ag-grid-community";
import { generateKeyBetween } from "fractional-indexing";

import {
  useActivePageId,
  usePageProducts,
  useUpdatePageProducts,
} from "../../data";
import { colParams } from "../utils";

export const useOnColumnMoved = () => {
  const updatePage = useUpdatePageProducts();
  const pageId = useActivePageId();
  const products = usePageProducts(pageId);

  const onColumnMoved = (params: ColumnMovedEvent) => {
    if (params.source === "gridOptionsChanged" || !params.finished) return;

    const api = params.api;
    const columnId =
      colParams(params.column)?.gridId ?? params.column?.getColId();
    const toIndex = params.toIndex;

    // Ensure columnId and toIndex are valid
    if (!columnId) {
      console.error("Invalid column ID");
      return;
    }
    if (toIndex === undefined || toIndex === null) {
      console.error("Invalid target index");
      return;
    }

    const gridColumns = api.getAllGridColumns();

    // Ensure gridColumns are available
    if (!gridColumns || gridColumns.length === 0) {
      console.error("Grid columns are not available");
      return;
    }

    const prevColumn = gridColumns[toIndex - 1];
    const nextColumn = gridColumns[toIndex + 1];

    try {
      // EDGE CASE 1: target position is first
      // index 0 is month column
      if (toIndex === 1) {
        const nextColumnId = colParams(nextColumn)?.gridId || "";

        if (!nextColumnId) {
          console.error("Next column ID is not available");
          return;
        }

        const nextProduct = products.data?.find((p) => p.id === nextColumnId);
        if (!nextProduct) {
          console.error("Next product is not available");
          return;
        }

        console.log("in debouncedOnColumnMoved INSERT FIRST");
        const newIdx = generateKeyBetween(null, nextProduct.idx);
        updatePage.mutate({
          id: columnId,
          updaterFn: (entry) => {
            entry.idx = newIdx;
          },
        });
        return;
      }

      // EDGE CASE 2: target position is last
      if (toIndex === gridColumns.length - 1) {
        const prevColumnId = colParams(prevColumn)?.gridId || "";

        if (!prevColumnId) {
          console.error("Previous column ID is not available");
          return;
        }

        const prevProduct = products.data?.find((p) => p.id === prevColumnId);
        if (!prevProduct) {
          console.error("Previous product is not available");
          return;
        }

        console.log("in debouncedOnColumnMoved INSERT LAST");
        const newIdx = generateKeyBetween(prevProduct.idx, null);
        updatePage.mutate({
          id: columnId,
          updaterFn: (entry) => {
            entry.idx = newIdx;
          },
        });
        return;
      }

      // BASE CASE
      const prevColumnId = colParams(prevColumn)?.gridId || "";
      const nextColumnId = colParams(nextColumn)?.gridId || "";

      if (!prevColumnId || !nextColumnId) {
        return;
      }

      const prevProduct = products.data?.find((p) => p.id === prevColumnId);
      const nextProduct = products.data?.find((p) => p.id === nextColumnId);

      if (!prevProduct || !nextProduct) {
        return;
      }

      if (prevProduct.idx >= nextProduct.idx) {
        return;
      }

      console.log("in debouncedOnColumnMoved BASE CASE");
      const newIdx = generateKeyBetween(prevProduct.idx, nextProduct.idx);
      updatePage.mutate({
        id: columnId,
        updaterFn: (entry) => {
          entry.idx = newIdx;
        },
      });
    } catch (error) {
      console.error("Error in debouncedOnColumnMoved:", error);
    }
  };

  return onColumnMoved;
};
