import {
  openDockviewModal,
  getPanelId,
  type TGridModalDockview,
  type TParentPanel,
  gridModalComponents,
} from "./modalComponents";
import {
  DockviewDefaultTab,
  DockviewReact,
  type DockviewReadyEvent,
  type IDockviewPanelHeaderProps,
} from "dockview";
import { Box, Divider, Stack } from "@mui/joy";
import { useAtom, useAtomValue } from "jotai";
import "dockview/dist/styles/dockview.css";
import { useThemeMode } from "../../../context/theme";
import {
  bottomBarHeight,
  dockviewZIndex,
  sideBarWidth,
  topBarHeight,
} from "../../globals";
import { gridModalDockviewAtom } from "./modalAtoms";
import { objectKeys } from "../../../utils";
import { memo, useState } from "react";

const DockviewTab = (props: IDockviewPanelHeaderProps) => {
  return <DockviewDefaultTab hideClose={true} {...props} />;
};

function getCurrentPanels(modalDockview: TGridModalDockview): TParentPanel[] {
  return modalDockview ? objectKeys(modalDockview) : [];
}

function GridModalWrapperRaw({ gridHeight }: { gridHeight?: string }) {
  const [modalDockview, setModalDockview] = useAtom(gridModalDockviewAtom);
  const currentPanels = getCurrentPanels(modalDockview);
  const mode = useThemeMode();

  function onReady(event: DockviewReadyEvent, parentPanel: TParentPanel) {
    const api = event.api;
    if (api && modalDockview && currentPanels.includes(parentPanel)) {
      setModalDockview((prev) => ({
        ...prev,
        [parentPanel]: { ...prev[parentPanel], dockviewApi: api },
      }));
      openDockviewModal(modalDockview[parentPanel]);
    }
  }

  if (!currentPanels.length) return null;

  return (
    <>
      {currentPanels.map((parentPanel) => {
        const panelId = getPanelId(parentPanel);
        return (
          <Box
            id={panelId}
            key={parentPanel}
            sx={(theme) => ({
              height: {
                xs: `calc(100% - ${bottomBarHeight}px)`,
                sm: gridHeight,
              },
              width: {
                xs: "100dvw",
                md: `calc(100dvw - ${sideBarWidth}px)`,
              },
              display: modalDockview ? "block" : "none",
              position: "absolute",
              ".dv-resize-container": {
                boxShadow: theme.shadow.md,
                borderRadius: theme.radius.sm,
                overflow: "hidden",
                zIndex: `${dockviewZIndex} !important`,
              },
              ...(panelId !== "panel-live-charts" && {
                ".dv-resize-handle-bottomright": {
                  display: "none",
                },
                ".dv-resize-handle-bottomleft": {
                  display: "none",
                },
                ".dv-resize-handle-bottom": {
                  display: "none",
                },
                ".dv-resize-handle-right": {
                  display: "none",
                },
                ".dv-resize-handle-left": {
                  display: "none",
                },
                ".dv-resize-handle-top": {
                  display: "none",
                },
                ".dv-resize-handle-topright": {
                  display: "none",
                },
                ".dv-resize-handle-topleft": {
                  display: "none",
                },
              }),
              ".content-container": {
                overflow: "auto",
              },
              ".dv-dockview .dv-watermark-container": {
                display: "none",
              },
            })}
          >
            <DockviewReact
              className={[
                mode === "dark"
                  ? "dockview-theme-dark"
                  : "dockview-theme-light",
                parentPanel === "live-charts" ? "live-charts" : "dock",
              ].join(" ")}
              onReady={(event) => onReady(event, parentPanel)}
              components={gridModalComponents[parentPanel]}
              defaultTabComponent={DockviewTab}
              floatingGroupBounds={"boundedWithinViewport"}
            />
          </Box>
        );
      })}
    </>
  );
}

const buttonsHeight = 56;

export function GridModalContainer({
  panel,
  body,
  buttons,
  justifyContent = "space-between",
  padding = 2,
}: {
  panel: TParentPanel;
  body: React.ReactNode;
  buttons: React.ReactNode;
  justifyContent?: "flex-start" | "space-between" | "center";
  padding?: number;
}) {
  const panelId = getPanelId(panel);

  const modalDockview = useAtomValue(gridModalDockviewAtom);
  const dockviewApi = modalDockview[panel]?.dockviewApi;

  const [height, setHeight] = useState<number>();

  dockviewApi?.activePanel?.api?.onDidDimensionsChange(() => {
    const reactPart = document.querySelector(`#${panelId} .content-container`);
    if (reactPart) {
      setHeight(reactPart.getBoundingClientRect().height);
    }
  });

  return (
    <Stack
      justifyContent={justifyContent}
      sx={{
        height: {
          xs: `calc(100dvh - ${buttonsHeight}px - ${topBarHeight}px)`,
          sm: height ?? "100%",
        },
      }}
      overflow="hidden"
      position={"relative"}
    >
      <Box
        padding={padding}
        maxHeight={`
          calc(100dvh - ${buttonsHeight}px - ${topBarHeight}px)
        `}
      >
        {body}
      </Box>
      <Box
        position={"absolute"}
        bottom={0}
        width={"100%"}
        height={buttonsHeight}
      >
        <Divider />
        {buttons}
      </Box>
    </Stack>
  );
}

export const GridModalWrapper = memo(GridModalWrapperRaw);
