import { graphql } from "../graphql";

export const ORG_BY_ID_QUERY = graphql(`
  query orgById($id: Int!) @cached {
    organisation_by_pk(id: $id) {
      sourceBySource {
        name
        description
        alias
        source_type
      }
    }
  }
`);
