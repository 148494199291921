import type { IMenuActionParams } from "ag-grid-community";

import { useUserSubscriptionTier } from "../../../context/auth";
import { useDeletePageProducts } from "../../../data";
import type { GetContextMenuSection } from "./helpers";
import { colParams } from "../../utils";
import { getIcon } from "./icons";

export const useGetMenuItemsRemoveColumn = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();
  const remove = useDeletePageProducts();

  const handleRemove = (e: IMenuActionParams) => {
    const id = colParams(e.column)?.gridId;
    if (id) remove.mutateAsync(id);
  };

  return (ctx) => {
    if (ctx.isProduct) {
      const isMultiColumn =
        new Set(ctx.rangeSelection.map((x) => x.columnId)).size > 1;
      return [
        {
          name: "Remove Curve",
          disabled: disabledFeatures?.removeCurve || isMultiColumn,
          tooltip: disabledFeatures?.removeCurve
            ? "Please upgrade your subscription to access this feature"
            : undefined,
          icon: getIcon("remove"),
          action: handleRemove,
        },
      ];
    }
    if (ctx.isShadowCurve) {
      return [
        {
          name: "Remove Column",
          icon: getIcon("remove"),
          action: handleRemove,
        },
      ];
    }
    return [];
  };
};
