import type { GetRowIdFunc, GridReadyEvent } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { memo, useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import type { TData } from "../calculations-worker/sharedStores";
import {
  bottomBarHeight,
  bottomPanelHeight,
  gridSettingsTransitionMatch,
  rowHeight,
  topBarHeight,
} from "../globals";
import { useGridSettingsWidth } from "../grid-settings";

import {
  ColumnHeaderProduct,
  ColumnHeaderSelectMemo,
  ColumnHeaderStatus,
  ColumnMonthTimespread,
  ColumnMonths,
  TooltipProduct,
} from "./components";
import AddCurvesToPage from "./modals/AddCurvesToPage";
import { GridModalWrapper } from "./modals/GridModal";
import { isMobile } from "../../shared/hooks";
import { NumberCellEditor } from "./AgNumberCellEditor";
import { useHasProductColumns, useProductColumnDefs } from "./column-defs";
import { useThemeMode } from "../../context/theme";
import { RegisterGridPlugins } from "./plugins";
import { useSetGrid } from "./stores";
import { useGridOptions } from "./useGridOptions";
import { useGetProductsContextMenue } from "./context-menu";
import { useRangeSelectionChanged } from "./useRangeSelectionChange";
import { useOnCellEditRequest } from "./useOnCellEditRequest";
import { useOnColumnResized } from "./useOnColumnResized";
import { useOnColumnMoved } from "./useOnColumnMoved";
import {
  GridColumnDefsDefsProvider,
  GridContextMenuProvider,
  useGridColumnDefs,
  useGridContextMenu,
} from "./context";

export const MarketAgGrid = memo(
  ({ isBottomPanelOpen }: { isBottomPanelOpen: boolean }) => {
    const getContextMenue = useGetProductsContextMenue({ isHeaderMenu: false });
    const isDark = useThemeMode() === "dark";
    const left = useGridSettingsWidth();

    return (
      <GridContextMenuProvider value={getContextMenue}>
        <GridColumnDefsDefsProvider value={useProductColumnDefs()}>
          <Grid
            isBottomPanelOpen={isBottomPanelOpen}
            isDark={isDark}
            left={left}
          />
        </GridColumnDefsDefsProvider>
      </GridContextMenuProvider>
    );
  },
);

const Grid = memo(GridRaw);

const gridCustomComponents = {
  agColumnHeaderProducts: ColumnHeaderProduct,
  agColumnHeaderDropdown: ColumnHeaderSelectMemo,
  agColumnHeaderStatus: ColumnHeaderStatus,
  agColumnMonths: ColumnMonths,
  agColumnMonthTimespread: ColumnMonthTimespread,
  agTooltipProduct: TooltipProduct,
  agNumberCellEditor: NumberCellEditor,
};

function GridRaw({
  isBottomPanelOpen,
  isDark,
  left,
}: {
  isBottomPanelOpen: boolean;
  isDark: boolean;
  left: number;
}) {
  const onRangeSelectionChanged = useRangeSelectionChanged();
  const getContextMenuItems = useGridContextMenu();
  const onCellEditRequest = useOnCellEditRequest();
  const onColumnResized = useOnColumnResized();
  const onColumnMoved = useOnColumnMoved();
  const gridOptions = useGridOptions();
  const colDefs = useGridColumnDefs();

  const gridRef = useRef<AgGridReact<TData> | null>(null);
  const hasProducts = useHasProductColumns();
  const setGrid = useSetGrid();

  const resolvedBottomPanelHeight = isBottomPanelOpen ? bottomPanelHeight : 0;
  const gridHeight = `calc(100dvh - ${topBarHeight}px - ${bottomBarHeight}px - ${resolvedBottomPanelHeight}px)`;

  const onGridPreDestroyed = useCallback(() => {
    setGrid(null);
  }, [setGrid]);

  const onGridReadyCallback = useCallback(
    (params: GridReadyEvent) => {
      console.log("Grid ready");
      setGrid(params.api);
    },
    [setGrid],
  );

  const getRowId: GetRowIdFunc = useCallback((params) => params.data.id, []);

  console.log("Grid render");
  if (!hasProducts) return <NoCurvesBox />;

  return (
    <div
      id="marketGrid"
      className={
        isDark
          ? "ag-theme-quartz-dark ag-theme-dark-custom"
          : "ag-theme-quartz ag-theme-light-custom"
      }
      style={{
        height: gridHeight,
        left,
        position: "relative",
        transition: gridSettingsTransitionMatch,
      }}
    >
      <RegisterGridPlugins />
      {!isMobile && <GridModalWrapper gridHeight={gridHeight} />}
      <AgGridReact<TData>
        columnDefs={colDefs}
        defaultColDef={{ sortable: false }}
        ref={gridRef}
        tooltipShowDelay={500}
        getRowId={getRowId}
        scrollbarWidth={8}
        readOnlyEdit
        onCellEditRequest={onCellEditRequest}
        rowHeight={rowHeight}
        gridOptions={gridOptions}
        onGridReady={onGridReadyCallback}
        onGridPreDestroyed={onGridPreDestroyed}
        enableCharts
        suppressDragLeaveHidesColumns
        suppressColumnMoveAnimation
        suppressCsvExport
        suppressHeaderFocus
        suppressModelUpdateAfterUpdateTransaction
        animateRows={false}
        enableFillHandle
        enterNavigatesVertically
        enterNavigatesVerticallyAfterEdit
        onColumnMoved={onColumnMoved}
        onColumnResized={onColumnResized}
        cellSelection
        columnMenu={"new"}
        loading={false}
        suppressMenuHide={true}
        suppressRowHoverHighlight
        onRangeSelectionChanged={onRangeSelectionChanged}
        suppressRowVirtualisation={
          import.meta.env.VITE_CONTEXT !== "production"
        }
        components={gridCustomComponents}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  );
}

function NoCurvesBox() {
  return (
    <div
      className="flex justify-center items-center"
      style={{
        height: `calc(100dvh - ${topBarHeight}px - ${bottomBarHeight}px)`,
      }}
    >
      <AddCurvesToPage allowCancel={false} />
    </div>
  );
}
