import { useState } from "react";
import { Box, Typography } from "@mui/joy";
import {
  faSparkles,
  faBook,
  faKeyboard,
  faPhone,
  faTable,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Theme } from "@mui/joy/styles/types";
import { uniqueBy } from "remeda";
import type { NewFeature } from "../../../src/__generated__/sanity/graphql-request";
import { Link } from "@tanstack/react-router";
import { useUserSubscriptionTier } from "../../context/auth";

export function DocumentationContextMenu({
  announcements,
  onAnnouncementClick,
  onHelpClick,
  onKeyboardShortcutsClick,
  onContactUsClick,
  onPricingClick,
  viewedAnnouncements,
  theme,
}: {
  announcements: NewFeature[];
  onAnnouncementClick: (announcement: NewFeature) => void;
  onHelpClick: () => void;
  onKeyboardShortcutsClick: () => void;
  onContactUsClick: () => void;
  onPricingClick: () => void;
  viewedAnnouncements: Map<
    string,
    { announcementId: string; title: string; viewedAt: Date; userId: string }
  >;
  theme: Theme;
}) {
  const { liteUser } = useUserSubscriptionTier();
  const [isOpen, setIsOpen] = useState(true);
  const announcementsWithViewStatus = announcements.map((announcement) => {
    const isViewed = Array.from(viewedAnnouncements.values()).some(
      (viewed) => viewed.announcementId === announcement._id,
    );

    return {
      ...announcement,
      viewed: isViewed,
    };
  });

  const sortedAnnouncements = announcementsWithViewStatus.sort(
    (a, b) =>
      new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime(),
  );
  const lastTwoAnnouncements = sortedAnnouncements.slice(0, 2);

  const unviewedAnnouncements = sortedAnnouncements
    .filter((a) => !a.viewed)
    .slice(0, 5);

  const uniqueAnnouncements = uniqueBy(
    [...unviewedAnnouncements, ...lastTwoAnnouncements],
    (announcement) => announcement._id,
  );

  return isOpen ? (
    <Box>
      <Box className="documentation-context-menu-parent">
        <Box sx={{ marginBottom: 2 }}>
          <Typography level="title-md">What's new</Typography>
        </Box>

        {uniqueAnnouncements.map((announcement) => (
          <Box
            key={announcement._id}
            onClick={() => {
              setIsOpen(false);
              onAnnouncementClick(announcement);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 1,
              cursor: "pointer",
              "&:hover": {
                background: theme.palette.neutral[50],
              },
            }}
          >
            <FontAwesomeIcon
              icon={faSparkles}
              style={{
                marginRight: 8,
                color: !announcement.viewed
                  ? theme.palette.primary[500]
                  : theme.palette.neutral[500],
              }}
            />
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {announcement.title}
            </Typography>
          </Box>
        ))}

        <Box
          sx={{
            marginTop: 3,
            marginBottom: 2,
          }}
        >
          <Typography level="title-md">Help</Typography>
        </Box>

        <Link
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
          to="/app/user-guide"
        >
          <Box
            className="documentation-context-menu-item"
            onClick={() => {
              setIsOpen(false);
              onHelpClick();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 1,
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon={faBook}
              style={{ marginRight: 8, width: "13px" }}
            />
            <Typography>User Guide</Typography>
          </Box>
        </Link>
        <Box
          className="documentation-context-menu-item"
          onClick={() => {
            setIsOpen(false);
            onKeyboardShortcutsClick();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon
            icon={faKeyboard}
            style={{ marginRight: 8, width: "13px" }}
          />
          <Typography>Keyboard shortcuts</Typography>
        </Box>

        <Box
          className="documentation-context-menu-item"
          onClick={() => {
            setIsOpen(false);
            onContactUsClick();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            style={{ marginRight: 8, width: "13px" }}
          />
          <Typography>Contact us</Typography>
        </Box>
        {liteUser && (
          <Box
            className="documentation-context-menu-item"
            onClick={() => {
              setIsOpen(false);
              onPricingClick();
            }}
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <FontAwesomeIcon
              icon={faTable}
              style={{ marginRight: 8, width: "13px" }}
            />
            <Typography>Subscriptions</Typography>
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
}
