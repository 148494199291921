import ReactDOM from "react-dom";
import { CssBaseline, Typography } from "@mui/joy";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy";
import { Box } from "@mui/system";
import { Link, Outlet, useRouterState } from "@tanstack/react-router";
import { memo, useState } from "react";
import { useWebappTheme, webappTheme } from "../../styles/extendTheme";
import {
  CreateCurvesIcon,
  EditCurvesIcon,
  InfoIcon,
  ManagePagesIcon,
  MarketIcon,
  SignOutIcon,
} from "../icons";
import {
  CollapsibleSettings,
  ToggleGridSettingsButton,
  SyncGridSettings,
} from "../grid-settings";
import { sideBarWidth, topBarHeight, triplitStatusBarZIndex } from "../globals";
import { CustomTooltip } from "../components/Tooltip";
import { ToasterContainer } from "../components/Toaster";
import { isMobile } from "../../shared/hooks";
import { useRefreshTimeout } from "../market-pages";
import { ModalWrapper } from "../components/Modal";
import { client } from "../../triplit/triplit";
import {
  useCurrentUser,
  useSessionState,
  useUserSubscriptionTier,
} from "../../context/auth";
import { isDev } from "../../globals";
import { useConnectionStatus } from "@triplit/react";
import { useDynamicCssClassRules } from "../market-grid/useDynamicCssClassRules";
import {
  useConditionalFormattingRules,
  usePageFormatting,
} from "../market-grid/modals/formatCellHelpers";

import { GridModalWrapper } from "../market-grid/modals/GridModal";
import { useNewFeatures, usePricing } from "../../sanity/queries";
import { DocumentationContextMenu } from "../infoSection/InfoSectionMenu";
import { AnnouncementModal } from "../infoSection/Announcements";
import { PricingModal } from "../infoSection/Pricing";
import { ContactUsModal } from "../infoSection/ContactUs";
import {
  useViewedAnnouncements,
  insertViewedAnnouncement,
  hasUnviewedAnnouncements,
} from "../infoSection/hooks";
import type { TViewedAnnouncement } from "../../triplit/schema";
import type { NewFeature } from "../../../src/__generated__/sanity/graphql-request";
import { KeyboardShortcutsModal } from "../infoSection/KeyboardShortcuts";
import { ThemeModeProvider } from "../../context/theme";
import { useOptionalActivePageId, useSyncPages } from "../../data";
import { useShortcuts } from "../market-grid/keyboard";
import {
  useConnectCalcWorkerClientHandlers,
  useSetupCalcWorker,
} from "./hooks";
import { LicenceAgreement } from "../licensing";
import { SessionProvider } from "../../context/sessions";

function Sidebar() {
  const theme = useWebappTheme();
  const { user } = useCurrentUser();
  const { disabledFeatures } = useUserSubscriptionTier();

  const username = user?.username;
  const email = user?.email;
  const userId = user?.id || "";

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<NewFeature | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isKeyboardShortcutsOpen, setIsKeyboardShortcutsOpen] = useState(false);
  const { data: viewedAnnouncements } = useViewedAnnouncements(userId);
  const viewedAnnouncementsMap =
    viewedAnnouncements instanceof Map
      ? viewedAnnouncements
      : new Map((viewedAnnouncements ?? []).map((item) => [item.id, item]));

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopperOpen(!isPopperOpen);
  };

  const handleClose = () => {
    setIsPopperOpen(false);
    setIsPopupOpen(false);
  };

  const { data } = useNewFeatures();
  const newFeatures = data?.allNewFeature || [];

  const { data: pricingResult } = usePricing();
  const pricingData = pricingResult?.allPricing || [];

  const pricingTable = pricingData.find(
    (item) => item.title === "Pricing Table",
  );

  const handleAnnouncementClick = async (announcement: NewFeature) => {
    if (!userId) {
      console.error("User ID is undefined, cannot record viewed announcement.");
      return;
    }
    if (!announcement || !announcement._id || !announcement.title) {
      console.error("Invalid announcement or missing _id");
      return;
    }

    try {
      await insertViewedAnnouncement(userId, {
        announcementId: announcement._id,
        id: announcement._id,
        title: announcement.title,
        viewedAt: new Date(),
        userId: userId,
      });
      setSelectedAnnouncement(announcement);
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error recording viewed announcement:", error);
    }
  };
  const unviewedAnnouncements = hasUnviewedAnnouncements(
    newFeatures,
    viewedAnnouncementsMap,
  );
  const popperContent = (
    <Box
      sx={{
        position: "absolute",
        top: anchorEl
          ? anchorEl.getBoundingClientRect().bottom + window.scrollY
          : 0,
        left: anchorEl
          ? anchorEl.getBoundingClientRect().left + window.scrollX
          : 0,
        transform: "translateX(27%) translateY(-90%)",
        p: 1.5,
        bgcolor: "background.surface",
        borderRadius: "sm",
        width: 160,
        boxShadow: "lg",
        zIndex: 100,
      }}
      onClick={handleClose}
    >
      <DocumentationContextMenu
        announcements={newFeatures}
        onAnnouncementClick={(announcement) => {
          handleAnnouncementClick(announcement);
        }}
        onHelpClick={() => console.log("Help clicked")}
        onKeyboardShortcutsClick={() => setIsKeyboardShortcutsOpen(true)}
        onContactUsClick={() => setIsContactUsOpen(true)}
        onPricingClick={() => setIsPricingOpen(true)}
        viewedAnnouncements={
          viewedAnnouncementsMap instanceof Map
            ? viewedAnnouncementsMap
            : new Map<string, TViewedAnnouncement>()
        }
        theme={theme}
      />
    </Box>
  );

  const isManagePagesDisabled = disabledFeatures?.managePages;
  const isCreateCurveDisabled = disabledFeatures?.createCurve;
  const isEditCurveDisabled = disabledFeatures?.editCurve;

  const openInNewWindow = (path: string, isDisabled: boolean | undefined) => {
    if (isDisabled) return;
    window.open(
      `${import.meta.env.VITE_OLD_WEBAPP_URL}${path}`,
      "_blank",
      "location=yes,scrollbars=yes,status=yes",
    );
  };

  return (
    <div
      className={
        "bg-sideBar fixed left-0 top-0 bottom-0 w-sideBar hidden md:flex flex-col justify-between py-4 z-[92] pl-safe-left pr-safe-right items-center"
      }
    >
      <div className={`flex flex-col gap-4 ${isMobile ? "pl-safe-left" : ""}`}>
        <CustomTooltip title="Market">
          <Link to="/app/market">
            <MarketIcon />
          </Link>
        </CustomTooltip>
        <CustomTooltip
          title={
            isManagePagesDisabled
              ? "Please upgrade your subscription to access this feature"
              : "Manage Pages"
          }
        >
          <Box
            sx={{ cursor: isManagePagesDisabled ? "not-allowed" : "pointer" }}
            onClick={() =>
              openInNewWindow("/manage-pages", isManagePagesDisabled)
            }
          >
            <ManagePagesIcon disabled={isManagePagesDisabled} />
          </Box>
        </CustomTooltip>
        <CustomTooltip
          title={
            isCreateCurveDisabled
              ? "Please upgrade your subscription to access this feature"
              : "Create Curves"
          }
        >
          <Box
            sx={{ cursor: isCreateCurveDisabled ? "not-allowed" : "pointer" }}
            onClick={() =>
              openInNewWindow("/create-curve", isCreateCurveDisabled)
            }
          >
            <CreateCurvesIcon disabled={isCreateCurveDisabled} />
          </Box>
        </CustomTooltip>
        <CustomTooltip
          title={
            isEditCurveDisabled
              ? "Please upgrade your subscription to access this feature"
              : "Edit Curves"
          }
        >
          <Box
            sx={{ cursor: isEditCurveDisabled ? "not-allowed" : "pointer" }}
            onClick={() => openInNewWindow("/edit-curve", isEditCurveDisabled)}
          >
            <EditCurvesIcon disabled={isEditCurveDisabled} />
          </Box>
        </CustomTooltip>
      </div>
      <div className={`flex flex-col gap-4 ${isMobile ? "pl-safe-left" : ""}`}>
        <CustomTooltip title={email}>
          <Typography
            id="nav-username"
            fontSize={12}
            textAlign={"center"}
            color="neutral"
            textTransform="uppercase"
          >
            {username}
          </Typography>
        </CustomTooltip>
        <ToggleGridSettingsButton />
        <CustomTooltip title="Info">
          <Box onClick={handleOpen}>
            <InfoIcon
              unviewedAnnouncements={unviewedAnnouncements}
              theme={theme}
            />
          </Box>
        </CustomTooltip>
        <CustomTooltip title="Log Out">
          <SignOutIcon />
        </CustomTooltip>
      </div>
      {isPopperOpen &&
        anchorEl &&
        ReactDOM.createPortal(
          <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
            <Box>{popperContent}</Box>
          </ClickAwayListener>,
          document.body,
        )}
      {selectedAnnouncement && (
        <AnnouncementModal
          open={isPopupOpen}
          setClose={() => setIsPopupOpen(false)}
          announcement={selectedAnnouncement}
          theme={theme}
        />
      )}
      {isKeyboardShortcutsOpen && (
        <KeyboardShortcutsModal
          open={isKeyboardShortcutsOpen}
          setClose={() => setIsKeyboardShortcutsOpen(false)}
          theme={theme}
        />
      )}
      {isContactUsOpen && (
        <ContactUsModal
          open={isContactUsOpen}
          setClose={() => setIsContactUsOpen(false)}
          theme={theme}
        />
      )}
      {isPricingOpen && pricingTable && (
        <PricingModal
          open={isPricingOpen}
          setClose={() => setIsPricingOpen(false)}
          theme={theme}
          pricingData={pricingTable}
          email={email}
        />
      )}
    </div>
  );
}

function TriplitStatusBar() {
  const currentRoute = useRouterState();
  const isChartRoute =
    currentRoute.resolvedLocation.pathname === "/app/trading-chart";

  const connectionStatus = useConnectionStatus(client);
  if (!isDev || connectionStatus === "OPEN") return null;
  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        top: 0,
        right: isChartRoute ? "auto" : 0,
        left: isChartRoute ? sideBarWidth : "auto",
        height: topBarHeight,
        zIndex: triplitStatusBarZIndex,
        backgroundColor:
          connectionStatus === "CLOSED"
            ? theme.palette.danger[500]
            : theme.palette.warning[500],
        color: "white",
        padding: 1,
        textAlign: "center",
      })}
    >
      {connectionStatus === "CLOSED" && "Server connection offline"}
      {connectionStatus === "CONNECTING" && "Connecting to server..."}
    </Box>
  );
}

export function Layout() {
  return (
    <SessionProvider>
      <JoyCssVarsProvider
        modeStorageKey="webapp"
        theme={webappTheme}
        defaultMode="light"
      >
        <ThemeModeProvider section="webappTheme">
          <RegisterListeners />
          <SyncGridSettings />
          <CssBaseline />
          <ToasterContainer />

          <Sidebar />
          <TriplitStatusBar />

          {isMobile && <GridModalWrapper />}

          <LicenceAgreement>
            <div className="webapp w-full relative md:pl-sideBar">
              <CollapsibleSettings />
              <Outlet />
            </div>
          </LicenceAgreement>

          <ModalWrapper />
        </ThemeModeProvider>
      </JoyCssVarsProvider>
    </SessionProvider>
  );
}

const RegisterListeners = memo(() => {
  const session = useSessionState();
  const pageId = useOptionalActivePageId() ?? "";

  useShortcuts();
  useSyncPages();
  useRefreshTimeout({});
  useSetupCalcWorker(session.data);
  useDynamicCssClassRules();
  usePageFormatting(pageId);
  useConditionalFormattingRules(pageId);
  useConnectCalcWorkerClientHandlers();

  return null;
});
