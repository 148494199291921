import { atom } from "jotai";
import {
  genMonthList,
  nthNextMonths,
  rowStringToCode,
} from "../market-grid/periodHelpers";
import dayjs from "dayjs";

export const addChartButtonWidth = 22;

export const minChartWidth = 300;
export const maxChartWidth = 500;

export const chartWidth = 500;
export const spreaderWidth = 520;

export const maxChartMonths = 24;

export const monthListMonths = genMonthList(maxChartMonths, dayjs());
export const nextMonths = nthNextMonths(monthListMonths);
export const availablePeriods = nextMonths.map((m) => rowStringToCode(m[1]));
export type TChartData = {
  id: string;
  period: number;
  productId: string;
  selector: string;
  data: { type: string; value: number | undefined }[];
}[];
export const gridChartsAtom = atom<TChartData>([]);
gridChartsAtom.debugLabel = "gridChartsAtom";
