import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useApolloClient } from "@apollo/client";

import type { ProductFragmentGridFragment } from "../../__generated__/gql/graphql";
import type { TApolloClient } from "../../context/apollo";
import { useUserId } from "../../context/auth";
import { readFragment } from "../../graphql";

import {
  allProducts,
  globalPackageByProductId,
  globalPackagesByProductsIds,
  globalProductsByProductsIds,
  globalProductsForLiveCharts,
  ProductFragmentGrid,
} from "./queries";

export type TProduct = ProductFragmentGridFragment;

// PRODUCT INFO
export const productsInfoQueryOptions = (props: {
  apolloClient: TApolloClient;
  userId?: string;
}) =>
  queryOptions({
    queryKey: ["products"],
    gcTime: 10000,
    queryFn: async () => {
      const res = await props.apolloClient.query({
        query: allProducts,
        variables: { folioUser: props.userId ?? "" },
        fetchPolicy: "no-cache",
      });
      const products = readFragment(ProductFragmentGrid, res.data?.product);
      return products;
    },
  });

export const useAllProductsSuspended = () => {
  const apolloClient = useApolloClient();
  const userId = useUserId();
  return useSuspenseQuery(productsInfoQueryOptions({ apolloClient, userId }));
};

export const useProductById = (id: string) => {
  const apolloClient = useApolloClient();
  const userId = useUserId();
  return useQuery({
    ...productsInfoQueryOptions({ apolloClient, userId }),
    select: (data) => data.find((p) => p.id === id),
  });
};

export const useProductsByIds = (ids: string[]) => {
  const apolloClient = useApolloClient();
  const userId = useUserId();
  return useQuery({
    ...productsInfoQueryOptions({ apolloClient, userId }),
    select: (data) => data.filter((p) => ids.includes(p.id)),
  });
};

export const useGlobalProducts = () => {
  const apolloClient = useApolloClient();
  const userId = useUserId();
  return useQuery({
    queryKey: ["global_product", "by-user", userId],
    gcTime: 10000,
    queryFn: async () => {
      const res = await apolloClient.query({
        query: globalProductsForLiveCharts,
        variables: { folioUser: userId },
      });
      return res.data.global_product;
    },
  });
};

export function useGlobalPackageByProductId(productId: string) {
  const apolloClient = useApolloClient();
  return useQuery({
    queryKey: ["global_package", productId],
    gcTime: 30000,
    queryFn: async () => {
      const res = await apolloClient.query({
        query: globalPackageByProductId,
        variables: { productId },
      });
      return res.data.global_package;
    },
  });
}

export function useGlobalPackagesByProductIds(productIds: string[]) {
  const apolloClient = useApolloClient();
  return useQuery({
    queryKey: ["global_package", productIds],
    gcTime: 30000,
    queryFn: async () => {
      const res = await apolloClient.query({
        query: globalPackagesByProductsIds,
        variables: { productIds: productIds },
      });
      return res.data.global_package;
    },
  });
}

export function useGlobalProductsByProductIds(productIds: string[]) {
  const apolloClient = useApolloClient();
  return useQuery({
    queryKey: ["global_product", productIds],
    gcTime: 10000,
    queryFn: async () => {
      const res = await apolloClient.query({
        query: globalProductsByProductsIds,
        variables: { productIds: productIds },
      });
      return res.data.global_product;
    },
  });
}
