import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const queryClient = new QueryClient();

broadcastQueryClient({
  queryClient,
  broadcastChannel: "artis-query",
});

export const AppQueryProvider = (p: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>{p.children}</QueryClientProvider>
  );
};
