import type { Entity } from "@triplit/client";
import type { SerializedDockview } from "dockview";
import type { ComponentProps } from "react";
import type { Chart } from "../webapp/live-charts";
import type { TIndicatorsState, TSample } from "../webapp/live-charts/utils";
import { z } from "zod";

import type { TSchema } from "@package/triplit";
export { schema, type TSchema } from "@package/triplit";

export type TPendingOrganisation = Entity<TSchema, "pendingOrganisation">;

export type TPendingUser = Entity<TSchema, "pendingUser">;
export type TPendingEditUser = Entity<TSchema, "pendingEditUser">;

export type TPendingPackage = Entity<TSchema, "pendingPackage">;

export const statusMapSchema = z.record(z.string(), z.string()).optional();
export const incrementMapSchema = z.record(z.string(), z.number()).optional();

export type TStatusMap = z.infer<typeof statusMapSchema>;
export type TIncrementMap = z.infer<typeof incrementMapSchema>;

export type TGridSettings = Partial<Entity<TSchema, "gridSettings">>;

export const spreaderStateRowDataSchema = z.object({
  title: z.string(),
  productLeft: z.string(),
  from: z.string(),
  fromSelector: z.string(),
  operandLeft: z.string(),
  offsetLeft: z.number(),
  opSelector: z.string(),
  productRight: z.string(),
  to: z.string(),
  toSelector: z.string(),
  operandRight: z.string(),
  offsetRight: z.number(),
});

export const spreaderStateSchema = z.object({
  id: z.string(),
  userId: z.string(),
  pageId: z.string(),
  rowIdx: z.number(),
  rowData: spreaderStateRowDataSchema.optional(),
});

export type TSpreaderStateRowData = z.infer<typeof spreaderStateRowDataSchema>;

export type TSpreaderState = z.infer<typeof spreaderStateSchema>;

export function parseSpreaderStateRowData(rowData?: unknown) {
  const parseResult = spreaderStateRowDataSchema.safeParse(rowData);
  if (parseResult.success) {
    return parseResult.data;
  }
  console.error("Invalid TSpreaderStateRowData", {
    rowData,
    parseResult,
  });
  throw new Error("Invalid TSpreaderStateRowData");
}

export function parseSpreaderState(state?: unknown) {
  const parseResult = spreaderStateSchema.safeParse(state);
  if (parseResult.success) {
    return parseResult.data;
  }
  console.error("Invalid TSpreaderState", {
    state,
    parseResult,
  });
  throw new Error("Invalid TSpreaderState");
}

export type TPage = Entity<TSchema, "pages">;
export type TPageProduct = Entity<TSchema, "pageProducts">;
export type TSharedCell = Entity<TSchema, "sharedCells">;
export type TPageSettings = Entity<TSchema, "pages">;

export type TLiveChart = Omit<
  Entity<TSchema, "liveCharts">,
  "sampleTime" | "indicatorsState"
> & {
  sampleTime: TSample;
  indicatorsState: TIndicatorsState;
};

export type TLiveChartStyle = Pick<
  ComponentProps<typeof Chart>,
  "layout" | "grid"
> & {
  candlestick: { upColor: string; downColor: string };
  mode: "light" | "dark";
};

export type TChartSettings = Omit<
  Entity<TSchema, "chartSettings">,
  "style" | "layoutState"
> & {
  layoutState: SerializedDockview | undefined;
  style: TLiveChartStyle | undefined;
};
export type TChartLayout = Entity<TSchema, "chartSettings">;
export type TTableState = Entity<TSchema, "tableState">;
export type TTableColumnState = Entity<TSchema, "tableColumnState">;
export type TTheme = Omit<
  Entity<TSchema, "theme">,
  "lumiTheme" | "webappTheme"
> & {
  lumiTheme: "light" | "dark";
  webappTheme: "light" | "dark";
  liveChartsTheme: "light" | "dark";
};

export type TGridCharts = Entity<TSchema, "gridCharts">;
export type TConditionalFormattingRules = Entity<
  TSchema,
  "conditionalFormattingRules"
>;

export type TAlert = Entity<TSchema, "alerts">;

export type TViewedAnnouncement = Entity<TSchema, "viewedAnnouncements">;

export type TQuickAccess = Entity<TSchema, "quickAccess">;
