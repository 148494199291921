import { useApolloClient, useSubscription } from "@apollo/client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, type ReactNode } from "react";
import type React from "react";

import {
  ActiveSessionSub,
  DeleteSessionAndLogoutRequest,
  StartSessionMut,
} from "./queries";
import { LogoutProvider, useLogout, useSessionState, useUserId } from "../auth";
import { isMobile } from "../../shared/hooks";
import { client } from "../../triplit";
import { SessionView } from "./dialog";

export const SessionProvider = (p: { children: React.ReactNode }) => {
  const isMultiSessionUser = useIsMultiSessionUser();
  if (isMultiSessionUser) return <>{p.children}</>;
  return <MultiSessionGuard>{p.children}</MultiSessionGuard>;
};

const MultiSessionGuard = (p: { children: ReactNode }) => {
  const startSession = useHandlStartSession();
  const endSession = useHandleEndSession();
  const session = useSessionState();
  const logout = useLogout();
  const userId = useUserId();

  const activeSub = useSubscription(ActiveSessionSub, {
    variables: { user: userId, mobile: isMobile },
  });
  const active = activeSub.data?.session_by_pk;

  const notActiveUser =
    active?.id &&
    session.data?.session_id &&
    active?.id !== session.data?.session_id &&
    active?.is_mobile === isMobile;

  const onLogout = useCallback(async () => {
    await endSession.mutateAsync().catch(console.error);
    return logout();
  }, [logout, endSession.mutateAsync]);

  // Logout the user if they are not the active session and their current
  // session is older than the active session
  // NOTE: last_seen is the time that the session token was created not actually the last time the user was active
  useEffect(() => {
    if (!notActiveUser) return;
    if (!session.data?.session_start || !active.last_seen) return;
    if (session.data?.session_start > active.last_seen) return;
    onLogout();
  }, [notActiveUser, onLogout, session.data, active]);

  // If no current active session make current session active
  useEffect(() => {
    if (activeSub.loading || active) return;
    startSession.mutate();
  }, [activeSub.loading, active, startSession.mutate]);

  if (notActiveUser) {
    return (
      <SessionView
        userId={userId}
        activeSession={active}
        currentSession={session.data}
        onLogout={logout}
        onStartSession={startSession.mutate}
      />
    );
  }

  return <LogoutProvider value={onLogout}>{p.children}</LogoutProvider>;
};

const useHandlStartSession = () => {
  const apollo = useApolloClient();
  const session = useSessionState();
  const userId = useUserId();
  return useMutation({
    mutationFn: async () => {
      if (!session.data?.session_id) return;
      await apollo.mutate({
        mutation: StartSessionMut,
        variables: {
          user: userId,
          mobile: isMobile,
          session: session.data?.session_id,
          last_seen: session.data.session_start,
        },
      });
      return Promise.resolve();
    },
  });
};

const useHandleEndSession = () => {
  const apollo = useApolloClient();
  const userId = useUserId();
  return useMutation({
    mutationFn: () =>
      apollo.mutate({
        mutation: DeleteSessionAndLogoutRequest,
        variables: {
          user: userId,
          context: isMobile ? "mobile" : "desktop",
          mobile: isMobile,
        },
      }),
  });
};

const useIsMultiSessionUser = () => {
  const userId = useUserId();
  return useQuery({
    queryKey: ["userGroups", "multisession", userId],
    initialData: false,
    queryFn: async () =>
      client
        .fetchOne(
          client
            .query("userGroups")
            .id("multisession")
            .select(["users"])
            .where(["users", "has", userId])
            .build(),
        )
        .then((x) => !!x),
  }).data;
};
