import {
  Box,
  Button,
  DialogTitle,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAtom, useAtomValue } from "jotai";
import { useMemo, useState } from "react";
import {
  bottomBarHeight,
  liveChartTopBarHeight,
  sideBarWidth,
} from "../globals";
import {
  ProductSelectLiveChart,
  TradingView,
  UnselectedTradingView,
} from "./LiveChart";
import {
  chartFetchingAtom,
  chartLayoutSelectorAtom,
  chartLimit,
  queryClient,
  useChartSettings,
  useLiveCharts,
} from "./hooks";
import "../../styles/liveCharts.css";
import { genChartId } from "./utils";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { useThemeMode } from "../../context/theme";
import { liveChartsTheme } from "../../styles/extendTheme";
import { QueryClientProvider } from "@tanstack/react-query";
import { ThemeModeProvider, useSetThemeMode } from "../../context/theme";
import { useProductArtisType } from "./hooks";
import { useUserId } from "../../context/auth";
import { FaPlus } from "react-icons/fa";
import { BottomBar } from "../components/BottomBar";
import { WorldClock } from "../components/WorldClock";
import { useWindowSize } from "@react-hookz/web";

function LayoutSelector() {
  const [layout, setLayout] = useAtom(chartLayoutSelectorAtom);

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography level="title-md">Layout</Typography>
      <Select<"horizontal" | "vertical">
        size="sm"
        value={layout}
        onChange={(_, v) => {
          if (!v) return;
          setLayout(v);
        }}
      >
        <Option value="horizontal">Horizontal</Option>
        <Option value="vertical">Vertical</Option>
      </Select>
    </Stack>
  );
}

function ThemeSelector() {
  const setMode = useSetThemeMode();
  const mode = useThemeMode();

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography level="title-md">Dark Mode</Typography>
      <Select<"light" | "dark">
        size="sm"
        value={mode}
        onChange={async (_, v) => {
          if (!v) return;
          await setMode(v);
        }}
      >
        <Option value="dark">On</Option>
        <Option value="light">Off</Option>
      </Select>
    </Stack>
  );
}

function TopBar({
  setShowAddChartModal,
}: {
  setShowAddChartModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [charts] = useLiveCharts();
  const isFetching = useAtomValue(chartFetchingAtom);
  return (
    <Box
      className="top-bar"
      sx={{
        height: liveChartTopBarHeight,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Typography level="h3">Live Charts</Typography>
      {isFetching && <Typography>Fetching Data...</Typography>}
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            gap: 1,
          }}
        >
          <LayoutSelector />
          <ThemeSelector />
        </Box>
        <Button
          variant="solid"
          color="primary"
          startDecorator={<FaPlus />}
          sx={{ ml: 1 }}
          disabled={charts.length >= chartLimit}
          onClick={() => setShowAddChartModal(true)}
        >
          Add New Chart
        </Button>
      </Box>
    </Box>
  );
}

export function ChartComponent({
  chartIdx,
  chartId,
}: { chartIdx: string; chartId: string }) {
  const { settings } = useChartSettings(chartId);
  const artisType = useProductArtisType(settings?.productId);

  const metadata = useMemo(() => {
    if (!settings) return null;
    return { ...settings, artisType };
  }, [settings, artisType]);

  if (metadata?.productId && metadata?.sampleTime && metadata?.periodFrom) {
    return <TradingView chartMetadata={metadata} />;
  }

  return <UnselectedTradingView chartIdx={chartIdx} />;
}

export function AddChartModal({
  setShowAddChartModal,
}: {
  setShowAddChartModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [charts] = useLiveCharts();

  const userId = useUserId();
  const [period, setPeriod] = useState<string>();

  const chartId = genChartId({ chartIdx: charts.length.toString(), userId });
  const { settings, setSettings, removeChart } = useChartSettings(chartId);

  return (
    <Modal open>
      <ModalDialog sx={{ gap: 2 }}>
        <DialogTitle>Add New Chart</DialogTitle>
        <ProductSelectLiveChart
          chartId={chartId}
          userId={userId}
          period={period}
          onPeriodChange={(v) => setPeriod(v)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Button
            variant="solid"
            color="neutral"
            sx={{
              pl: 2,
              pr: 2,
            }}
            onClick={() => {
              removeChart(chartId);
              setPeriod(undefined);
              setShowAddChartModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            color="primary"
            sx={{
              pl: 2,
              pr: 2,
            }}
            onClick={() => {
              if (!period || !settings) return;
              setSettings({ ...settings, periodFrom: period });
              setPeriod(undefined);
              setShowAddChartModal(false);
            }}
          >
            Save
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}

export function ChartsLayoutInner({
  floatingWindow,
}: { floatingWindow: boolean }) {
  const [charts] = useLiveCharts();
  const [showAddChartModal, setShowAddChartModal] = useState(false);
  const windowSize = useWindowSize();
  const mode = useThemeMode();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: (theme) =>
          mode === "dark"
            ? theme.palette.neutral[900]
            : theme.palette.background.body,
      }}
    >
      <TopBar setShowAddChartModal={setShowAddChartModal} />
      <Stack
        sx={{
          position: "absolute",
          right: floatingWindow ? "auto" : 0,
          left: floatingWindow ? 0 : "auto",
          top: liveChartTopBarHeight,
          overflowX: "hidden",
          overflowY: "auto",
          height: floatingWindow
            ? `calc(100% - ${liveChartTopBarHeight}px)`
            : `calc(100% - ${liveChartTopBarHeight}px - ${bottomBarHeight}px)`,
          width: (theme) =>
            floatingWindow || windowSize.width < theme.breakpoints.values.md
              ? "100%"
              : `calc(100% - ${sideBarWidth}px)`,
          flexWrap: "wrap",
          gap: 2,
          padding: 2,
          flexDirection: "row",
          alignContent: "flex-start",
        }}
      >
        {(charts.length === 0 || showAddChartModal) && (
          <AddChartModal setShowAddChartModal={setShowAddChartModal} />
        )}
        {charts.map((chart, idx) => {
          return (
            <ChartComponent
              key={chart.id}
              chartId={chart.id}
              chartIdx={idx.toString()}
            />
          );
        })}
      </Stack>
    </Box>
  );
}

export function ChartsLayout() {
  // persistOptions={{
  //   buster: "v1.4",
  //   persister,
  //   maxAge: Number.POSITIVE_INFINITY,
  //   dehydrateOptions: {
  //     // do not persist queries that are not marked as persist i.e. live data
  //     shouldDehydrateQuery: (query) => !!query.meta?.persist,
  //   },
  return (
    <JoyCssVarsProvider
      disableNestedContext
      theme={liveChartsTheme}
      attribute="data-live-charts-color-scheme"
      defaultMode="light"
    >
      <ThemeModeProvider section="liveChartsTheme">
        <QueryClientProvider client={queryClient}>
          <div className="live-charts h-dvh">
            <ChartsLayoutInner floatingWindow={false} />
            <BottomBar>
              <WorldClock />
            </BottomBar>
          </div>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeModeProvider>
    </JoyCssVarsProvider>
  );
}
