import { Typography } from "@mui/joy";

import { useLatestEditInfo } from "../../calculations-worker/hooks";
import { getManualInstrumentType } from "../statuses/statusLogic";
import type { colParams } from "../../utils";

export function LastEditedInfoHeader({
  colInfo,
  status,
}: {
  colInfo: ReturnType<typeof colParams>;
  status: string;
}) {
  const storageType = status.includes("-")
    ? "global"
    : getManualInstrumentType(status);
  const productId = storageType === "global" ? status : colInfo?.productId;
  const lastEditedInfo = useLatestEditInfo({
    storageType,
    productId,
  });
  return (
    <Typography
      sx={{
        color: "inherit",
      }}
      fontSize={10}
      fontWeight={700}
    >
      {lastEditedInfo}
    </Typography>
  );
}
