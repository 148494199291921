import type { GridApi } from "ag-grid-community";
import { unique } from "remeda";

import { openFormattingDockview } from "./useDockviewModal";
import type { TRangeSelection } from "../../sharedHooks";
import { getCellIds } from "../modals/formatCellHelpers";
import { useActivePageId, useUpdatePage } from "../../../data";
import { getIcon } from "./icons";
import type { GetContextMenuSection } from "./helpers";
import {
  useDeleteConditionalFormattingRules,
  useQueryConditionalFormattingRulesGrouped,
} from "../modals/ConditionalFormatting/hooks";
import {
  monthCodeToShortMonthAndYear,
  rowIdToRelativeRow,
} from "../periodHelpers";
import {
  compressJsonString,
  parsePageFormatting,
} from "../../../utils/compressedStringify";
import { useGridSettingsIncrementMap } from "../../grid-settings";

export const useGetMenuItemsFormatting = (): GetContextMenuSection => {
  const incrementMap = useGridSettingsIncrementMap();
  const pageId = useActivePageId();
  const clear = useClearFormatting();
  return (ctx) => {
    const { selectedRange: ranges, api } = ctx;

    const createFormatMenuItem = (
      item: (typeof formatSubMenu)[number]["item"],
      type: (typeof formatSubMenu)[number]["type"],
    ) => ({
      name: getFormatMenuItemName({ api, selection: ranges, item }),
      action: () =>
        clear({
          type,
          cellIds: getCellIds({ type: "range", ranges, api }).cellIds,
          colIds: getCellIds({ type: "column", ranges, api }).cellIds,
          periods: getCellIds({ type: "period", ranges, api }).cellIds,
        }),
    });

    if (
      ctx.isHeaderMenu ||
      !(ctx.isProduct || (ctx.isShadowCurve && !ctx.isBlank))
    ) {
      return [];
    }
    return [
      {
        name: "Format",
        // requirements: [!isHeaderMenu, isProduct || (isShadowCurve && !isBlank)],
        icon: getIcon("format"),
        subMenu: [
          ...formatSubMenu.map(({ item, type }) => ({
            name: getFormatMenuItemName({
              api: ctx.api,
              selection: ranges,
              item,
            }),
            action: () =>
              openFormattingDockview({
                pageId,
                type,
                incrementMap,
                columnId: ctx.columnId,
                currentTab: "color",
                selectedRange: ranges,
                productId: ctx.productId,
              }),
          })),
          {
            name: "Clear Formatting",
            subMenu: [
              createFormatMenuItem("cell", "range"),
              createFormatMenuItem("column", "column"),
              createFormatMenuItem("period", "period"),
              {
                name: "All",
                action: () => {
                  clear({
                    type: "all",
                    cellIds: getCellIds({ type: "range", ranges, api }).cellIds,
                    colIds: getCellIds({ type: "column", ranges, api }).cellIds,
                    periods: getCellIds({ type: "period", ranges, api })
                      .cellIds,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
};

const formatSubMenu = [
  { item: "cell", type: "range" },
  { item: "column", type: "column" },
  { item: "period", type: "period" },
] satisfies Array<{
  item: "cell" | "column" | "period";
  type: "range" | "column" | "period";
}>;

function getFormatMenuItemName({
  api,
  selection,
  item,
}: {
  api: GridApi;
  selection: TRangeSelection;
  item: "cell" | "column" | "period";
}) {
  try {
    if (!api) return "";

    // Sort in ascending order so the largest row index is last.
    const sortedRangeSelection = selection?.flat()?.sort((a, b) => {
      return a?.rowIndex ?? 0 - (b?.rowIndex ?? 0);
    });

    if (!sortedRangeSelection?.length) return "";

    const firstCell = sortedRangeSelection[0];
    const lastCell = sortedRangeSelection[sortedRangeSelection.length - 1];

    const firstPeriod = firstCell?.rowId;
    const lastPeriod = lastCell?.rowId;

    switch (item) {
      case "cell":
        return sortedRangeSelection.length > 1 ? "Range" : "Cell";
      case "column":
        return unique(sortedRangeSelection.map((cell) => cell.columnId))
          .length > 1
          ? "Columns"
          : "Column";
      case "period":
        return firstPeriod === lastPeriod
          ? `Period: ${firstPeriod}`
          : `Periods: ${firstPeriod} - ${lastPeriod}`;
    }
  } catch (e) {
    console.error("Error getting format menu item name", e);
    return "";
  }
}

type TClearCellConditionalFormatting = {
  type: "range" | "column" | "period" | "all";
  periods?: string[];
  cellIds?: string[];
  colIds?: string[];
};

export const useClearFormatting = () => {
  const clearCellConditionalFormatting = useClearConditionalFormatting();
  const updatePage = useUpdatePage();
  const pageId = useActivePageId();

  return (params: TClearCellConditionalFormatting) => {
    clearCellConditionalFormatting(params);
    const { type, periods, cellIds, colIds } = params;
    updatePage.mutateAsync({
      id: pageId,
      updaterFn: async (current) => {
        const parsedHighlights = await parsePageFormatting(current);

        async function clearCell() {
          try {
            if (current.cellHighlights && cellIds?.length) {
              const currentCellHighlights = parsedHighlights.cellHighlights;

              for (const cellId of cellIds) {
                delete currentCellHighlights[cellId];
              }

              current.cellHighlights = await compressJsonString(
                JSON.stringify(currentCellHighlights || {}),
              );
            }
          } catch (e) {
            console.error("Error parsing cellHighlights", e);
          }
        }

        async function clearColumn() {
          try {
            if (current.columnHighlights && colIds?.length) {
              const currentColumnHighlights = parsedHighlights.columnHighlights;

              for (const colId of colIds) {
                delete currentColumnHighlights[colId];
              }

              current.columnHighlights = await compressJsonString(
                JSON.stringify(currentColumnHighlights || {}),
              );
            }
          } catch (e) {
            console.error("Error parsing columnHighlights", e);
          }
        }

        async function clearPeriod() {
          try {
            if (current.periodHighlights && periods?.length) {
              const currentPeriodHighlights = parsedHighlights.periodHighlights;

              for (const period of periods) {
                delete currentPeriodHighlights[period];
              }

              current.periodHighlights = await compressJsonString(
                JSON.stringify(currentPeriodHighlights || {}),
              );
            }
          } catch (e) {
            console.error("Error parsing periodHighlights", e);
          }
        }

        if (type === "range") {
          await clearCell();
        }

        if (type === "column") {
          await clearColumn();
        }

        if (type === "period") {
          await clearPeriod();
        }

        if (type === "all") {
          await clearCell();
          await clearColumn();
          await clearPeriod();
        }
      },
    });
  };
};

const useClearConditionalFormatting = () => {
  const pageId = useActivePageId();
  const rules = useQueryConditionalFormattingRulesGrouped(pageId);
  const remove = useDeleteConditionalFormattingRules();

  return ({
    type,
    periods,
    cellIds,
    colIds,
  }: TClearCellConditionalFormatting) => {
    async function clearCell() {
      const toRemove =
        cellIds
          ?.flatMap((cellId) => rules.data?.[cellId]?.map((r) => r.id))
          ?.filter(Boolean) || [];
      return toRemove.map((id) => remove.mutateAsync(id));
    }

    function clearColumn() {
      (
        colIds
          ?.flatMap((colId) => {
            return Object.values(rules.data || {}).flatMap((rules) => {
              return rules?.map((r) => {
                if (r.columnId === colId) {
                  return r.id;
                }
              });
            });
          })
          ?.filter(Boolean) || []
      ).map((id) => remove.mutateAsync(id));
    }

    function clearPeriod() {
      (
        periods
          ?.flatMap((code) => {
            const period = monthCodeToShortMonthAndYear(code);
            const rowId = rowIdToRelativeRow?.[period];

            return Object.values(rules.data || {}).flatMap((rules) => {
              return rules?.map((r) => {
                if (r.rowId === rowId) {
                  return r.id;
                }
              });
            });
          })
          ?.filter(Boolean) || []
      ).map((id) => remove.mutateAsync(id));
    }

    if (type === "range") {
      clearCell();
    }

    if (type === "column") {
      clearColumn();
    }

    if (type === "period") {
      clearPeriod();
    }

    if (type === "all") {
      clearCell();
      clearColumn();
      clearPeriod();
    }
  };
};
