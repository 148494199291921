import { Box, Button, Stack } from "@mui/joy";
import type { MouseEventHandler } from "react";
import { type TParentPanel, closeDockviewModal } from "./modalComponents";

export function CancelButton({
  onClick,
  label = "Cancel",
  parentPanel,
}: {
  onClick?: MouseEventHandler<HTMLElement>;
  label?: string;
  parentPanel: TParentPanel;
}) {
  return (
    <Button
      size="sm"
      variant="outlined"
      color="neutral"
      type="button"
      onClick={(e) => {
        onClick?.(e);
        closeDockviewModal(parentPanel);
      }}
    >
      {label}
    </Button>
  );
}

export function SaveButton({
  onClick,
  label = "Save",
  parentPanel,
  disabled = false,
}: {
  onClick?: MouseEventHandler<HTMLElement>;
  label: string;
  parentPanel: TParentPanel;
  disabled?: boolean;
}) {
  return (
    <Button
      type="submit"
      variant="solid"
      size="sm"
      sx={{
        width: "100%",
        background: "var(--artis-orange)",
      }}
      onClick={(e) => {
        onClick?.(e);
        closeDockviewModal(parentPanel);
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

export function ModalButtons({
  onCancel,
  onSave,
  cancelLabel = "Cancel",
  saveLabel = "Save",
  hideSave,
  parentPanel,
  additionalButtons = null,
  saveDisabled = false,
}: {
  onCancel?: MouseEventHandler<HTMLElement>;
  onSave?: MouseEventHandler<HTMLElement>;
  cancelLabel?: string;
  saveLabel?: string;
  hideSave?: boolean;
  parentPanel: TParentPanel;
  additionalButtons?: React.ReactNode;
  saveDisabled?: boolean;
}) {
  return (
    <Box display="flex" justifyContent={"center"}>
      <Stack gap={2} direction={"row"} padding={2}>
        <CancelButton
          onClick={onCancel}
          label={cancelLabel}
          parentPanel={parentPanel}
        />
        {additionalButtons}
        {!hideSave && (
          <SaveButton
            onClick={onSave}
            disabled={saveDisabled}
            label={saveLabel}
            parentPanel={parentPanel}
          />
        )}
      </Stack>
    </Box>
  );
}
