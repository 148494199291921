import { useMemo, useRef } from "react";

/**
 * Returned a memoized function caller that will always call the latest version of the callback
 */
export const useMemoCallback = <A extends unknown[], R>(
  cb: (...args: A) => R,
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  return useMemo(() => {
    const fn = (...args: A) => cbRef.current(...args);
    // For debugging purposes
    Object.defineProperty(fn, "name", {
      value: `useMemoCallback(${cbRef.current.name || "anonymous"})`,
      writable: false,
    });
    return fn;
  }, []);
};
