import * as R from "remeda";

import type { TOption } from "../components/Autocomplete/Autocomplete";
import { productName, sortByStringCaseInsensitive } from "../utils";
import { useAllProductsSuspended, useGlobalProducts } from "../../data";

export function useAllProductsOptions(props?: {
  disableListPermission?: boolean;
  disabledIds?: string[];
}) {
  const { disableListPermission, disabledIds: excludeIds } = props || {};
  const { data, isFetching } = useAllProductsSuspended();

  const options = R.pipe(
    data,
    R.groupBy((p) => p.packageByPackage.name),
    R.mapValues(R.piped(R.sortBy([R.prop("name"), "asc"]))),
    R.values(),
    R.flat(),
    R.map((p) => ({
      label: productName(p),
      value: p.id,
      disabled:
        (disableListPermission &&
          p.packageByPackage.permissions?.[0]?.permission === "list") ||
        excludeIds?.includes(p.id),
      groupValue: p.packageByPackage.name,
      metadata: p,
    })),
  );

  return { data: options, fetching: isFetching };
}

export type TProductIdAndWidth = {
  gridId: string;
  productId?: string;
  width: number;
  columnFieldSelector?: string;
  columnSettings?: {
    decimalPlaces?: number | null;
    thousandsSeparator?: boolean | null;
  };
};

export function useGlobalProductOptions() {
  const { data, isLoading: fetching, error } = useGlobalProducts();

  if (error) console.error("error fetching global product options", { error });

  if (!data) {
    return { data: undefined, fetching };
  }

  const mappedData = data.map((product) => ({
    ...product,
    label: product.description || product.name,
  }));

  const groupedData = R.groupBy(
    mappedData,
    (product) => product.globalPackageByGlobalPackage.name,
  );

  const parsedData = R.mapValues(groupedData, (group) => {
    const packageName = group[0].globalPackageByGlobalPackage.name;
    return sortByStringCaseInsensitive("name", group).map(
      (p) =>
        ({
          label: p.label,
          value: p.id,
          disabled:
            p.globalPackageByGlobalPackage.global_permissions?.[0]
              ?.permission === "list",
          groupValue: packageName,
          metadata: undefined,
        }) as const satisfies TOption,
    );
  });

  const options = R.values(parsedData).flat();

  return { data: options, fetching };
}
