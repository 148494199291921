import { graphql } from "../../graphql";

export const ActiveSessionSub = graphql(`
  subscription UserSession($user: String!, $mobile: Boolean!) {
    session_by_pk(folio_user: $user, is_mobile: $mobile) {
      id
      last_seen
      is_mobile
    }
  }
`);

export const StartSessionMut = graphql(`
  mutation StartSession(
    $user: String!
    $mobile: Boolean!
    $session: String!
    $last_seen: timestamptz!
  ) {
    insert_session_one(
      object: {
        folio_user: $user
        is_mobile: $mobile
        id: $session
        last_seen: $last_seen
      }
      on_conflict: { constraint: session_pkey, update_columns: [id, last_seen] }
    ) {
      id
      last_seen
    }
    delete_logout_request(where: { folio_user: { _eq: $user } }) {
      affected_rows
    }
  }
`);

export const DeleteSessionAndLogoutRequest = graphql(`
  mutation DeleteSessionAndLogoutRequest(
    $user: String!
    $mobile: Boolean!
    $context: String!
  ) {
    update_folio_user_by_pk(
      pk_columns: { id: $user }
      _set: { logout_requested_at: "now()", last_seen: "now()" }
    ) {
      id
    }
    delete_session(
      where: { folio_user: { _eq: $user }, is_mobile: { _eq: $mobile } }
    ) {
      affected_rows
    }
    delete_logout_request(
      where: {
        _and: [
          { folio_user: { _eq: $user } }
          { context: { _in: ["all", $context] } }
        ]
      }
    ) {
      affected_rows
    }
  }
`);
