import { requestDockviewModal } from "../modals/modalComponents";
import { useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsConditionalFormatting =
  (): GetContextMenuSection => {
    const { disabledFeatures } = useUserSubscriptionTier();

    return (ctx) => {
      if (!ctx.isProduct || ctx.isHeaderMenu) return [];
      return [
        {
          name: "Conditional Formatting",
          disabled: disabledFeatures?.conditionalFormatting,
          tooltip: disabledFeatures?.conditionalFormatting
            ? "Please upgrade your subscription to access this feature"
            : undefined,
          icon: getIcon("format"),
          subMenu: [
            {
              name: "Add Rule",
              action: () => {
                requestDockviewModal({
                  parentPanel: "conditional",
                  currentTab: "conditional-format-add",
                  params: {
                    width: 700,
                    height: 470,
                    selectedRange: ctx.selectedRange,
                    type: "range",
                  },
                });
              },
            },
            {
              name: "Manage",
              action: () => {
                requestDockviewModal({
                  parentPanel: "conditional",
                  currentTab: "conditional-format-manage",
                  params: {
                    width: 730,
                    height: 470,
                    selectedRange: ctx.selectedRange,
                    type: "range",
                  },
                });
              },
            },
          ],
        },
      ];
    };
  };
