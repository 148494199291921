import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/joy";
import cn from "mcn";

export function BottomBar({ children }: { children: ReactNode }) {
  const isStandalone =
    (navigator as Navigator & { standalone?: boolean }).standalone ?? false;
  const [isLandscape, setIsLandscape] = useState(
    window.screen.orientation.type.includes("landscape"),
  );

  useEffect(() => {
    function handleOrientationChange() {
      setIsLandscape(window.screen.orientation.type.includes("landscape"));
    }
    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange,
    );
    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange,
      );
    };
  }, []);

  const heightClass =
    isStandalone || isLandscape
      ? "h-standaloneBottomBar pb-safeAreaBottom "
      : "h-bottomBar";

  const marginClasses = isLandscape
    ? "[margin-right:env(safe-area-inset-right)] [margin-left:env(safe-area-inset-left)]"
    : "";

  return (
    <div
      className={`bottom-bar flex gap-2 px-3 items-center fixed bottom-0 right-0 left-0 bg-bottomBar ${heightClass} md:left-sideBar pr-safe-right pl-[calc(env(safe-area-inset-left)+0.55rem)]`}
    >
      {children}
    </div>
  );
}

export function BottomBarButton(props: {
  children?: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
  tooltip?: string;
  onClick?: () => void;
}) {
  const btn = (
    <button
      onClick={props.onClick}
      type="button"
      disabled={props.disabled}
      className={cn(
        "[&>svg]:w-10 [&>svg]:h-10 disabled:opacity-50 disabled:pointer-events-none",
        props.className,
      )}
    >
      {props.icon}
      {props.children}
    </button>
  );
  if (props.tooltip) {
    return <Tooltip title={props.tooltip}>{btn}</Tooltip>;
  }
  return btn;
}
