import type { GridApi } from "ag-grid-community";
import type { TConditionalFormattingRulesGrouped } from "./hooks";
import { getNodePeriod, type TConditionalRule } from "./helpers";
import { unique } from "remeda";
import { relativeRowToRowId, rowIdToRelativeRow } from "../../periodHelpers";
import type { TRangeSelection } from "../../../sharedHooks";

export function formatRuleForSave(
  rule: TConditionalRule,
  userId: string,
  pageId: string,
) {
  return {
    _id: rule._id,
    rule: rule.rule,
    limit: rule?.limitRef
      ? `${rule.limitRef.columnId}:${rule.limitRef.rowId}`
      : rule.limit,
    bgColor: rule.formatting.bgColor,
    boldText: rule.formatting.boldText,
    invertTextColor: rule.formatting.invertTextColor,
    note: rule.note,
    stopIfTrue: rule.stopIfTrue,
    userId,
    pageId,
  };
}

export function getColumnPeriods(
  gridApi?: GridApi | null,
  rules?: TConditionalFormattingRulesGrouped,
  rule?: TConditionalRule,
) {
  if (!gridApi || !rules || !rule) return [];

  const ruleValues = Object.values(rules).flat();

  const ruleGroup = ruleValues.filter(
    (item) =>
      item.rule === rule.rule &&
      (item.limit === rule.limit ||
        item.limit === `${rule.limitRef?.columnId}:${rule.limitRef?.rowId}`) &&
      item.bgColor === rule.formatting.bgColor &&
      item.boldText === rule.formatting.boldText &&
      item.invertTextColor === rule.formatting.invertTextColor &&
      item.note === rule.note &&
      item.stopIfTrue === rule.stopIfTrue,
  );

  if (!ruleGroup.length) return [];

  return ruleGroup.map((group) => {
    const columnId = group.columnId;
    const columnRules = ruleValues
      .filter((rule) => rule.rowId === group.rowId)
      .sort((a, b) => b.rowId.localeCompare(a.rowId));

    const periods = unique(
      columnRules.map((rule) => relativeRowToRowId[rule.rowId]),
    );

    const columnName = gridApi?.getColumn(columnId)?.getColDef()?.headerName;

    const periodsString =
      periods.length > 1
        ? `${periods[0]} - ${periods[periods.length - 1]}`
        : periods[0];

    return {
      columnId,
      columnName,
      periods,
      string: `${columnName} [${periodsString}]`,
    };
  });
}

export function formatRules(
  gridApi: GridApi | null,
  rules: TConditionalFormattingRulesGrouped,
  filter: "page" | "range",
  rangeSelection: TRangeSelection,
) {
  if (!rules) return [];

  const cells = rangeSelection
    .flatMap((range) => {
      return range.map((cell) => {
        const rowNode = gridApi?.getRowNode(cell.rowId);
        const period = getNodePeriod(rowNode);
        const rowId = period ? rowIdToRelativeRow[period] : rowNode?.id;

        if (rowId) {
          return {
            columnId: cell.columnId,
            rowId,
          };
        }
        return null;
      });
    })
    .filter(
      (cell): cell is { columnId: string; rowId: string } => cell !== null,
    );

  const ruleValues = Object.values(rules).flat();
  const cellIds = cells.map((cell) => `${cell.columnId}-${cell.rowId}`);

  const filteredRules =
    filter === "page"
      ? ruleValues
      : ruleValues.filter((rule) =>
          cellIds.includes(`${rule.columnId}-${rule.rowId}`),
        );

  return filteredRules
    .reduce((acc: TConditionalRule[], rule) => {
      const match = acc.find(
        (item) =>
          item.rule === rule.rule &&
          item.limit === rule.limit &&
          item.formatting.bgColor === rule.bgColor &&
          item.formatting.boldText === rule.boldText &&
          item.formatting.invertTextColor === rule.invertTextColor &&
          item.note === rule.note &&
          item.stopIfTrue === rule.stopIfTrue,
      );

      if (match?.ids) {
        match.ids.push(rule.id);
      } else {
        const [limitRef, limit] = rule.limit.includes(":")
          ? [
              {
                columnId: rule.limit.split(":")[0],
                rowId: rule.limit.split(":")[1],
                name:
                  gridApi?.getColumn(rule.limit.split(":")[0])?.getColDef()
                    ?.headerName || "",
                period: rule.limit.split(":")[1],
              },
              "",
            ]
          : [undefined, rule.limit];

        acc.push({
          _id: rule.id,
          id: rule.id,
          rule: rule.rule as TConditionalRule["rule"],
          limit,
          limitRef,
          formatting: {
            bgColor: rule.bgColor,
            boldText: rule.boldText,
            invertTextColor: rule.invertTextColor,
          },
          note: rule.note,
          stopIfTrue: rule.stopIfTrue,
          columnId: rule.columnId,
          rowId: rule.rowId,
          ids: [rule.id],
        });
      }

      return acc;
    }, [])
    .sort((a, b) => {
      const aIds = a.ids || [];
      const bIds = b.ids || [];
      return aIds[0].localeCompare(bIds[0]);
    });
}
