import {
  Box,
  Button,
  Checkbox,
  Input,
  Option,
  Select,
  Stack,
  Table,
  Typography,
  Tooltip,
} from "@mui/joy";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import { useState } from "react";
import { FaTimes, FaTrash } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import {
  type UseFormReturn,
  useForm,
  useFieldArray,
  Controller,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ColorPicker } from "../../../grid-settings/ColorPicker";
import { generateNKeysBetween } from "fractional-indexing";
import type { TConditionalFormattingRules } from "../../../../triplit/schema";
import { rowIdToRelativeRow } from "../../periodHelpers";
import { useAtomValue } from "jotai";
import { gridModalDockviewAtom } from "../modalAtoms";
import toast from "react-hot-toast";
import {
  emptyRule,
  getNodePeriod,
  ruleValidation,
  useResetLimitSelection,
  type TConditionalRule,
} from "./helpers";
import { unique } from "remeda";
import { useGrid } from "../../stores";
import { GridModalSelectionInfo } from "../modalComponents";
import { PointerIcon } from "../../../icons";
import {
  type TConditionalFormattingRulesGrouped,
  useQueryConditionalFormattingRulesGrouped,
  useInsertConditionalFormattingRules,
  useQueryConditionalFormattingRules,
} from "./hooks";
import { useActivePageId, useOptionalActivePageId } from "../../../../data";
import { useThemeMode } from "../../../../context/theme";
import { captureEvent } from "../../../../context/ph";
import { getColumnPeriods } from "./utils";
import {
  ConditionalFormattingHeader,
  conditionalFormattingTableStyle,
} from "./components";

export function ConditionalFormattingRow({
  form,
  rule,
  index,
  remove,
  type,
}: {
  groupedRules?: TConditionalFormattingRulesGrouped;
  form: UseFormReturn<{ rules: TConditionalRule[] & { ids?: Array<string> } }>;
  rule: TConditionalRule;
  index: number;
  remove: (index: number) => void;
  type: "create" | "manage";
}) {
  const pageId = useOptionalActivePageId();
  const groupedFormattingRules = useQueryConditionalFormattingRulesGrouped(
    pageId ?? "",
  );

  const resetLimitSelection = useResetLimitSelection();
  const conditionalFormattingRules = groupedFormattingRules.data ?? {};
  const [gridApi] = useGrid();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const { register, control } = form;
  const output = useWatch({
    control,
    name: "rules",
  });

  const columnPeriods = getColumnPeriods(
    gridApi,
    conditionalFormattingRules,
    rule,
  );

  const mode = useThemeMode();

  const affectedString = unique(
    columnPeriods.map((period) => period.string),
  ).join(", ");

  const items = {
    rule: (
      <td
        key="rule"
        style={{
          width: "100px",
        }}
      >
        <Controller
          name={`rules.${index}.rule`}
          control={control}
          render={({ field }) => (
            <Select
              sx={{
                height: "30px",
              }}
              value={field.value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              size="sm"
            >
              <Option value={"equals"}>Equals</Option>
              <Option value={"isNotEqual"}>Is not equal</Option>
              <Option value={"lessThan"}>Less than</Option>
              <Option value={"moreThan"}>More than</Option>
              <Option value={"lessThanOrEqual"}>Less than or equal</Option>
              <Option value={"moreThanOrEqual"}>More than or equal</Option>
            </Select>
          )}
        />
      </td>
    ),
    limit: (
      <td
        key="limit"
        style={{
          width: "88px",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Stack flexDirection={"row"} gap={1}>
          {output[index]?.limitRef?.name ? (
            <Stack
              flexDirection={"row"}
              gap={1}
              overflow="hidden"
              width="100%"
              justifyContent={"space-between"}
            >
              <Controller
                name={`rules.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px",
                      border: (theme) =>
                        `1px solid ${theme.palette.neutral.outlinedBorder}`,
                      padding: "4px 8px",
                      paddingRight: "0",
                      borderRadius: "sm",
                      width: "fit-content",
                      gap: "8px",
                      backgroundColor: (theme) =>
                        theme.palette.background.surface,
                      maxWidth: "106px",
                    }}
                  >
                    <Tooltip
                      title={`${output[index]?.limitRef?.name}[${output[index]?.limitRef?.period}]`}
                      placement="top-start"
                      arrow
                    >
                      <Typography
                        fontSize="xs"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {output[index]?.limitRef?.name}[
                        {output[index]?.limitRef?.period}]
                      </Typography>
                    </Tooltip>

                    <Button
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={() => {
                        field.onChange(undefined);
                      }}
                      sx={{
                        minWidth: "auto",
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </Box>
                )}
              />
            </Stack>
          ) : (
            <>
              <Input
                {...register(`rules.${index}.limit`)}
                type="number"
                size="sm"
                sx={{
                  height: "30px",
                  gap: "10px",
                  width: "49px",
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
              />
              <Controller
                name={`rules.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    sx={{
                      display: "flex",
                      minWidth: "49px",
                      height: "30px",
                      padding: "8px 16px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    onClick={() => {
                      if (
                        gridApi?.getGridOption("rowClass") ===
                        "ag-cell-selection"
                      ) {
                        resetLimitSelection();
                        return;
                      }

                      gridApi?.setGridOption("onCellClicked", (e) => {
                        resetLimitSelection();

                        const columnId = e.column.getColId();
                        const name = e.colDef.headerName;
                        const period = getNodePeriod(e.node);
                        const rowId = period
                          ? rowIdToRelativeRow[period]
                          : e.node.id;

                        field.onChange({
                          columnId,
                          rowId,
                          name,
                          period,
                        });
                      });

                      document.querySelectorAll(".ag-row").forEach((cell) => {
                        cell.classList.add("ag-cell-selection");
                      });
                    }}
                  >
                    <PointerIcon mode={mode} />
                  </Button>
                )}
              />
            </>
          )}
        </Stack>
      </td>
    ),
    formatting: (
      <td
        key="formatting"
        style={{
          width: "73px",
        }}
      >
        <Controller
          name={`rules.${index}.formatting`}
          control={control}
          render={({ field }) => (
            <ColorPicker
              show={showColorPicker}
              setShow={setShowColorPicker}
              onChange={(newValue) => {
                field.onChange({
                  bgColor: newValue.color,
                  boldText: newValue.boldText,
                  invertTextColor: newValue.invertTextColor,
                });
              }}
              value={{
                color: output[index].formatting.bgColor,
                boldText: output[index].formatting.boldText,
                invertTextColor: output[index].formatting.invertTextColor,
              }}
              showSwatches
              showTextOptions
              width={"73px"}
              height={"26px"}
            />
          )}
        />
      </td>
    ),
    affected: (
      <td key="affected">
        <Typography
          fontSize="sm"
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          title={affectedString}
        >
          {affectedString}
        </Typography>
      </td>
    ),
    note: (
      <td
        key="note"
        style={{
          width: "77px",
        }}
      >
        <Input
          sx={{
            width: "90px",
            height: "30px",
          }}
          {...register(`rules.${index}.note`)}
          size="sm"
        />
      </td>
    ),
    actions: (
      <td
        key="actions"
        style={{
          width: "53px",
        }}
      >
        <Stack gap={1} flexDirection={"row"} justifyContent={"center"}>
          <Button
            sx={{
              display: "flex",
              height: "30px",
              minWidth: "49px",
              padding: "4px 8px",
              justifyContent: "center",
              // alignItems: "center",
            }}
            size="sm"
            variant="soft"
            color="neutral"
            onClick={() => remove(index)}
            disabled={output.length === 1}
          >
            <FaTrash />
          </Button>
        </Stack>
      </td>
    ),
    stopIfTrue: (
      <td key="stopIfTrue" style={{ width: "60px" }}>
        <Controller
          name={`rules.${index}.stopIfTrue`}
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={output[index].stopIfTrue}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              size="lg"
              sx={{
                float: "left",
                paddingBottom: "3.5px",
                "& .MuiCheckbox-checkbox": {
                  width: "25px",
                  height: "25px",
                },
                "& svg": {
                  fontSize: "25px",
                },
              }}
            />
          )}
        />
      </td>
    ),
  };

  const gridItems =
    type === "create"
      ? [
          items.rule,
          items.limit,
          items.formatting,
          items.note,
          items.actions,
          items.stopIfTrue,
        ]
      : [
          items.limit,
          items.rule,
          items.formatting,
          items.affected,
          items.note,
          items.actions,
          items.stopIfTrue,
        ];

  return <tr key={rule._id}>{gridItems}</tr>;
}

export function CreateConditionalFormattingRule() {
  const pageId = useActivePageId();
  const rules = useQueryConditionalFormattingRules(pageId);
  const insert = useInsertConditionalFormattingRules();
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.conditional?.selectedRange || [];
  const [gridApi] = useGrid();

  const form = useForm<{
    rules: TConditionalRule[];
  }>({
    defaultValues: {
      rules: [emptyRule()],
    },
    resolver: zodResolver(ruleValidation),
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "rules",
  });

  const formData = useWatch({
    control: form.control,
    name: "rules",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  const cells = rangeSelection.flatMap((range) => {
    return range.map((cell) => {
      const rowNode = gridApi?.getRowNode(cell.rowId);
      const period = getNodePeriod(rowNode);
      const rowId = period ? rowIdToRelativeRow[period] : rowNode?.id;

      return {
        columnId: cell.columnId,
        rowId,
      };
    });
  });
  const canSave = formData.every((rule) => rule.limit || rule.limitRef);

  async function handleSave() {
    try {
      if (!pageId) throw new Error("pageId is not defined");
      if (!userId) throw new Error("userId is not defined");
      if (!formData.length) throw new Error("No rules defined");

      const cellIds = cells.map((cell) => `${cell.columnId}-${cell.rowId}`);

      const existingRules = rules.data || [];

      const existingCellRules = existingRules.filter((rule) =>
        cellIds.includes(`${rule.columnId}-${rule.rowId}`),
      );

      const lastPriority = existingCellRules.length
        ? existingCellRules[existingCellRules.length - 1].priorityIdx
        : null;

      const priorityIdxs = generateNKeysBetween(
        lastPriority,
        null,
        formData.length,
      );

      const formattedRules = formData.flatMap((rule, index) => {
        return cells.map((cell) => {
          return {
            _id: rule._id,
            rule: rule.rule,
            limit: rule?.limitRef
              ? `${rule.limitRef.columnId}:${rule.limitRef.rowId}`
              : rule.limit,
            priorityIdx: priorityIdxs[index],
            bgColor: rule.formatting.bgColor,
            boldText: rule.formatting.boldText,
            invertTextColor: rule.formatting.invertTextColor,
            note: rule.note,
            stopIfTrue: rule.stopIfTrue,
            columnId: cell.columnId,
            rowId: cell.rowId || "",
            userId,
            pageId,
          } satisfies Omit<TConditionalFormattingRules, "id">;
        });
      });

      const inserts = formattedRules.map((rule) => {
        captureEvent("created_conditional_formatting_rule", {
          pageId,
          columnId: rule.columnId,
        });
        return insert.mutateAsync(rule);
      });
      gridApi?.setGridOption("onCellClicked", undefined);
      document.querySelectorAll(".ag-cell-selection").forEach((cell) => {
        cell.classList.remove("ag-cell-selection");
      });

      await Promise.all(inserts);
    } catch (error) {
      console.error(error);
      toast.error("Failed to save conditional formatting rules");
    }
  }

  return (
    <GridModalContainer
      panel="conditional"
      padding={0}
      body={
        <>
          <Box
            sx={{
              padding: "15px 10px",
            }}
          >
            <Typography textColor="text.primary" sx={{ paddingBottom: "2px" }}>
              Selected Column and Cell
            </Typography>
            <GridModalSelectionInfo selectedRange={rangeSelection} />
          </Box>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <Box
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    maxHeight: "210px",
                    overflowY: "auto",
                  })}
                >
                  <Table sx={conditionalFormattingTableStyle} borderAxis="x">
                    <ConditionalFormattingHeader type="create" />
                    <tbody>
                      {fields.map((rule, index) => (
                        <ConditionalFormattingRow
                          key={rule._id}
                          form={form}
                          rule={rule}
                          index={index}
                          remove={remove}
                          type="create"
                        />
                      ))}
                    </tbody>
                  </Table>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => append(emptyRule())}
                    sx={{
                      cursor: "pointer",
                      display: "inline",
                      pl: 2,
                      marginLeft: "15px",
                    }}
                  >
                    + Add Rule
                  </Button>
                </Box>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"conditional"}
          onCancel={() => {
            gridApi?.setGridOption("onCellClicked", undefined);
            document.querySelectorAll(".ag-cell-selection").forEach((cell) => {
              cell.classList.remove("ag-cell-selection");
            });
          }}
          onSave={() => {
            handleSave();
          }}
          saveDisabled={!canSave}
        />
      }
    />
  );
}
