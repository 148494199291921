//import "./wydr";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./styles/common.css";
import { LicenseManager } from "ag-grid-enterprise";
import { AG_KEY } from "./globals";
import "jotai-devtools/styles.css";
import { DevTools } from "jotai-devtools";

import "./context/ph/initialize";

LicenseManager.setLicenseKey(AG_KEY);

const rootEl = document.getElementById("root");

if (!rootEl) {
  throw new Error("No root element");
}

ReactDOM.createRoot(rootEl).render(
  <React.StrictMode>
    <DevTools position="bottom-right" />
    <App />
  </React.StrictMode>,
);
