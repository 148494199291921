import { useIntervalEffect } from "@react-hookz/web";
import { useState, useEffect, useCallback } from "react";

import {
  calcWorkerInstance,
  initializecalcWorker,
} from "../../utils/useComlink";
import type { TManualStoreKey } from "./sharedStores";
import { isDev } from "../../globals";
import { isMobile } from "../../shared/hooks";

const isSharedWorker =
  !isDev && !isMobile && typeof SharedWorker !== "undefined";

export function createWorker() {
  const worker = isSharedWorker
    ? new SharedWorker(new URL("./index", import.meta.url), {
        type: "module",
      })
    : new Worker(new URL("./index", import.meta.url), {
        type: "module",
      });

  console.log("starting worker - isSharedWorker", isSharedWorker);
  initializecalcWorker(worker);
}

createWorker();

export const calcWorker = calcWorkerInstance;

export type TWorker = ReturnType<NonNullable<typeof calcWorker>>["proxy"];
export function useLatestEditInfo({
  productId,
  storageType,
}: {
  storageType?: TManualStoreKey["storageType"] | null;
  productId?: TManualStoreKey["productId"];
}) {
  const worker = calcWorker?.()?.proxy;
  const [editInfo, setEditInfo] = useState<string | null | undefined>(null);

  const fetchManualProductInfo = useCallback(async () => {
    if (worker && productId && storageType) {
      try {
        const info = await worker.getManualProductInfo({
          productId,
          storageType,
        });
        setEditInfo(info?.latestEditInfo);
      } catch (error) {
        console.error(error);
      }
    }
  }, [worker, productId, storageType]);

  // Fetch immediately on mount
  useEffect(() => {
    fetchManualProductInfo();
  }, [fetchManualProductInfo]);

  // Continue fetching every second
  useIntervalEffect(() => {
    fetchManualProductInfo();
  }, 1000);

  return editInfo || "";
}
