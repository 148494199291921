import type { CellClassParams, CellStyle } from "ag-grid-community";
import {
  type TColumnHeaderProps,
  rowIdToRelativeRow,
  rowStringToCode,
} from ".";
import type { ProductFragmentGridFragment } from "../../__generated__/gql/graphql";
import type { TData } from "../calculations-worker/sharedStores";
import { calculateTextColor } from "../colorUtils";
import { rangeSelectionAtom, store } from "../sharedHooks";
import { isListOnlyPermissions, parseLimitRef } from "../utils";
import {
  conditionalFormattingRulesAtom,
  pageFormattingAtom,
} from "./modals/formatCellHelpers";
import { parse } from "../numbers";
import type { TKnownStatus } from "./statuses/statusLogic";
import { getNodePeriod } from "./modals/ConditionalFormatting/helpers";
import { mainMonthColumnId } from "./column-defs";
import type { TPageFormatting } from "../../utils/compressedStringify";

function getHighlight(colId: string, period: string) {
  const pageFormatting = store.get(pageFormattingAtom);
  if (!pageFormatting || !period) return null;

  const { cellHighlights, columnHighlights, periodHighlights } = pageFormatting;

  // this is not addressing aggregates
  // it happens to work for quarters and halves because Q and H are valid month codes
  // but fails at Win 24. This is a bug.
  const rowId = rowIdToRelativeRow[period];
  if (!rowId) {
    console.error(`No rowId found for period: ${period}`);
    return null;
  }

  return mergeRules(
    periodHighlights[rowStringToCode(period)],
    columnHighlights[colId],
    cellHighlights[`${colId}-${rowId}`],
  );
}

const mergeRules = (
  ...rules: (TPageFormatting[keyof TPageFormatting] | null | undefined)[]
) =>
  rules.reduce<TPageFormatting[keyof TPageFormatting] | null>((acc, b) => {
    if (!b) return acc;
    if (!acc) return b;
    return {
      color: b.color || acc.color,
      boldText: b.boldText || acc.boldText,
      invertTextColor: b.invertTextColor || acc.invertTextColor,
    };
  }, null);

function getCellValueByRef(params: CellClassParams<TData>, ref: string) {
  const { columnId, rowId } = parseLimitRef(ref);

  if (!columnId || !rowId) return;

  const rowNode = params.api.getRowNode(rowId);
  if (!rowNode) return;

  const value = params.api.getCellValue({ rowNode, colKey: columnId });

  return value;
}

function conditionalRuleMet(rule: string, source: number, target: number) {
  switch (rule) {
    case "equals":
      return source === target;
    case "isNotEqual":
      return source !== target;
    case "lessThan":
      return source < target;
    case "moreThan":
      return source > target;
    case "lessThanOrEqual":
      return source <= target;
    case "moreThanOrEqual":
      return source >= target;
  }
}

type TConditionalFormattingRule = {
  bgColor?: string | null;
  invertTextColor: boolean;
  boldText: boolean;
  stopIfTrue: boolean;
  limit: string;
  rule: string;
};

function ruleMet(
  params: CellClassParams<TData>,
  rule: TConditionalFormattingRule,
  cellValue: number,
  acc?: TConditionalFormattingRule,
) {
  const limitIsRef = rule.limit.includes(":");

  const limitNumber = limitIsRef
    ? getCellValueByRef(params, rule.limit)
    : parse(rule.limit);

  if (limitNumber !== 0 && !limitNumber) return acc || undefined;

  return conditionalRuleMet(rule.rule, cellValue, limitNumber);
}

function getConditionalFormatting(params: CellClassParams<TData>) {
  const conditionalFormattingRules = store.get(conditionalFormattingRulesAtom);
  if (!conditionalFormattingRules) return;

  const colId = params.column.getColId();
  const period = getNodePeriod(params.node);

  if (!period) return;

  const rowId = rowIdToRelativeRow[period];

  if (!(`${colId}-${rowId}` in conditionalFormattingRules)) return;

  const rules = conditionalFormattingRules[`${colId}-${rowId}`];

  if (!rules.length) return;

  const cellValue = params.value;

  if (typeof cellValue !== "number") return;

  const accumulatedFormatting: Partial<TConditionalFormattingRule> = {};

  for (const currentRule of rules) {
    if (ruleMet(params, currentRule, cellValue)) {
      if (currentRule.bgColor) {
        accumulatedFormatting.bgColor = currentRule.bgColor;
      }

      if (currentRule.invertTextColor !== undefined) {
        accumulatedFormatting.invertTextColor = currentRule.invertTextColor;
      }

      if (currentRule.boldText !== undefined) {
        accumulatedFormatting.boldText = currentRule.boldText;
      }

      accumulatedFormatting.limit = currentRule.limit;
      accumulatedFormatting.rule = currentRule.rule;

      if (currentRule.stopIfTrue) {
        break;
      }
    }
  }

  if (Object.keys(accumulatedFormatting).length > 0) {
    return accumulatedFormatting as TConditionalFormattingRule;
  }

  return;
}

export function findRangeSelectionCell(params: CellClassParams, group: number) {
  const rangeSelection = store.get(rangeSelectionAtom);
  // only check for match in array gorup
  if (rangeSelection.length <= group) return false;
  if (rangeSelection.length > 2) return false;
  for (let i = 0; i < rangeSelection[group].length; i++) {
    if (
      rangeSelection[group][i].rowId === params.data.id &&
      rangeSelection[group][i].columnId === params.colDef.field
    ) {
      return true;
    }
  }

  return false;
}

export function getColorFromVarName(varName: string) {
  if (varName === "inherit") return "inherit";

  // If the variable is in the format var(--variable-name), get the content between the parentheses.
  const variable = varName.includes("(")
    ? varName?.match(/\(([^)]+)\)/)?.[1]
    : varName;

  return getComputedStyle(document.documentElement).getPropertyValue(
    variable || "",
  );
}

function applyCellStyle(params: CellClassParams<TData>) {
  const period = getNodePeriod(params.node);

  const highlight = getHighlight(params.column.getColId(), period);

  const rule = getConditionalFormatting(params);

  if (rule) {
    const bg = rule.bgColor || highlight?.color || "inherit";
    const textColor = calculateTextColor(bg, rule.invertTextColor);
    return {
      background: bg,
      color: textColor,
      fontWeight: rule.boldText ? "bold" : "normal",
    } satisfies CellStyle;
  }

  if (highlight) {
    const textColor = calculateTextColor(
      highlight.color,
      highlight.invertTextColor,
    );
    return {
      background: highlight.color || "inherit",
      color: textColor,
      fontWeight: highlight.boldText ? "bold" : "normal",
    } satisfies CellStyle;
  }

  const headerParams = params.column?.getUserProvidedColDef()
    ?.headerComponentParams as TColumnHeaderProps | null;
  const status = headerParams?.status || "listen";
  const sharedCell =
    params?.data?.rowType === "mth" &&
    headerParams?.sharedCellOffsets?.includes(params.rowIndex?.toString());

  const background = sharedCell ? getStatusClass(status) : "inherit";
  const computedColor = getColorFromVarName(background);

  return {
    color: calculateTextColor(computedColor, false),
    background,
    fontWeight: "normal",
  } satisfies CellStyle;
}

const statusClasses = {
  listen: "listenColour",
  hybrid_broadcast: "hybridColour",
  private: "localColour",
  eod: "eodColour",
  broadcast: "broadcastColour",
} satisfies Record<TKnownStatus, string>;

export function getStatusClass(status: string) {
  if (status.length === 36) {
    return "var(--globalColour)";
  }
  if (status in statusClasses) {
    return `var(--${statusClasses[status as keyof typeof statusClasses]})`;
  }
  console.error(`Unknown status: ${status}`);
  return "inherit";
}

export function processCellStyle(
  params: CellClassParams<TData>,
  product?: ProductFragmentGridFragment | undefined | null,
) {
  const isNotMonthColumn = params.colDef.field !== mainMonthColumnId;
  const isNotSpreadColumn = params.colDef.field !== "month-timespread";
  const perms = product?.packageByPackage.permissions;
  const isListOnly = isListOnlyPermissions(perms);
  const noPermissions = !perms;

  if (isNotMonthColumn && isNotSpreadColumn && (isListOnly || noPermissions)) {
    return {
      background: "var(--disabled-column)",
      color: "inherit",
      fontWeight: "normal",
    };
  }

  return applyCellStyle(params);
}
