import { type ApolloClient, useApolloClient } from "@apollo/client";
import { useAuth0, type User } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import posthog from "posthog-js";
import * as R from "remeda";

import { ORG_BY_ID_QUERY } from "../../data";
import type { JWT, TEnv } from "../../globals";
import { client } from "../../triplit/triplit";
import { useGlobalEnv } from "../environment";
import { parse } from "../../webapp/numbers";

export { default as captureEvent } from "./eventLogger";
export { logError } from "./error";

export const AnalyticsProvider = (p: { children: React.ReactNode }) => {
  const apollo = useApolloClient();
  const auth = useAuth0();
  const env = useGlobalEnv();

  useEffect(() => {
    auth
      .getAccessTokenSilently()
      .then((token) => initializeAnalytics(apollo, env, token, auth.user))
      .catch((error) => {
        console.error("Failed to get access token:", error);
      });
  }, [apollo, env, auth.getAccessTokenSilently, auth.user]);

  return p.children;
};

const initializeAnalytics = (
  apollo: ApolloClient<object>,
  env: TEnv,
  token: string,
  user?: User,
) => {
  if (!user) return;

  try {
    const jwt = jwtDecode<JWT>(token);
    const auth0OrgId = jwt.org_id;
    const hasuraOrgId = parse(
      jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-org-id"],
    );

    try {
      posthog.identify(jwt.sub, R.omit(user, ["folio-webapp"]));
      datadogRum.setUser({
        id: jwt.sub,
        username: `${user.given_name} ${user.family_name}`,
        email: user.email,
        hasuraOrgId,
        auth0OrgId,
        env,
      });
      Sentry.setUser({
        id: jwt.sub,
        username: `${user.given_name} ${user.family_name}`,
        email: user.email,
      });
    } catch (error) {
      console.error("Failed to set analytics identifiers:", error);
    }

    setTimeout(() => {
      if (!hasuraOrgId) {
        console.error("No hasura org id found in token");
        return;
      }

      apollo
        .query({ query: ORG_BY_ID_QUERY, variables: { id: hasuraOrgId } })
        .then(async (res) => {
          try {
            const source = res.data?.organisation_by_pk?.sourceBySource;
            if (!source) {
              console.error("No source found for org id", hasuraOrgId);
              return;
            }

            posthog.group("org", hasuraOrgId.toString(), {
              env,
              alias: source.alias,
              name: source.name,
              description: source.description,
            });

            const groupsQuery = client
              .query("userGroups")
              .select(["id"])
              .where(["users", "has", "$session.SESSION_USER_ID"])
              .build();

            const groups = await client.fetch(groupsQuery).catch((error) => {
              console.error("Error fetching user groups", error);
              return null;
            });
            if (!groups) return;

            for (const { id } of groups) {
              try {
                posthog.group("userGroup", id);
              } catch (error) {
                console.error("Failed to set posthog user group:", error);
              }
            }
          } catch (error) {
            console.error("Error processing organisation data:", error);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch organisation:", error);
        });
    }, 5000);
  } catch (error) {
    console.error("Failed to initialize analytics:", error);
  }
};
