import type React from "react";

import { useCurrentUsername } from "../../context/auth";
import { LicenceAgreementForm } from "./licensing";
import { useLicenceAgreement } from "./hooks";

export function LicenceAgreement(p: { children: React.ReactNode }) {
  const { licenceAgreement, sourceId } = useLicenceAgreement();
  const username = useCurrentUsername();
  if (!licenceAgreement) return p.children;
  return (
    <LicenceAgreementForm
      agreementType={licenceAgreement}
      username={username}
      sourceId={sourceId}
    />
  );
}
