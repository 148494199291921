import { useQuery as useApolloQuery, useSubscription } from "@apollo/client";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useMemo } from "react";
import { z } from "zod";
import { ORG_BY_ID_QUERY } from "../../data";
import { useUrlEnv } from "../../context/environment";
import { parse } from "../../webapp/numbers";
import {
  DEV_AUTH0_USERS_URL,
  type JWT,
  PROD_AUTH0_USERS_URL,
  UAT_AUTH0_USERS_URL,
  getAuthOrgId,
} from "../../globals";
import { graphql, readFragment } from "../../graphql";
import { parseRoles } from "../../utils/users";

export const CurrentUserFragment = graphql(`
  fragment CurrentUserFragment on folio_user {
    __typename
    id
    username
    email
    roles
    subscription_tier
  }
`);

const currentUserQuery = graphql(`
  query CurrentUser($id: String!) {
    folio_user_by_pk(id: $id) {
      ...CurrentUserFragment
    }
  }
`);

export function useCurrentUser() {
  const { user: authUser } = useAuth0();
  const id = authUser?.sub || "";
  const res = useApolloQuery(currentUserQuery, {
    skip: !id,
    variables: { id },
  });
  const user = readFragment(CurrentUserFragment, res.data?.folio_user_by_pk);
  const roles = user?.roles ? parseRoles(user.roles) : undefined;
  const username = user?.username;
  return { user, roles, username, ...res };
}

export function useUserHasRole(role: string) {
  const { roles: userRoles } = useCurrentUser();
  return Boolean(userRoles?.has(role));
}

export const subscriptionTierSchema = z.enum([
  "artis_enhanced",
  "artis_enhanced_noexch",
  "artis_lite",
  "artis_professional",
]);

export function useUserId(encoded = false) {
  const { user } = useAuth0();
  const userId = user?.sub ?? "";

  if (encoded) {
    return encodeURIComponent(userId);
  }
  return userId;
}

export const useUserDetails = () => {
  const { user } = useCurrentUser();

  const subscriptionTier = user?.subscription_tier;
  const liteUser = subscriptionTier === subscriptionTierSchema.enum.artis_lite;
  const enhancedUser =
    subscriptionTier === subscriptionTierSchema.enum.artis_enhanced;
  const enhancedNoExchUser =
    subscriptionTier === subscriptionTierSchema.enum.artis_enhanced_noexch;

  // Based on: https://artis.works/#pricing
  const disabledFeatures =
    !liteUser && !enhancedUser
      ? undefined
      : {
          showFormula: liteUser,
          headerSize: liteUser,
          curveDetails: liteUser,
          spreader: liteUser,
          managePages: liteUser,
          pauseExchangeData: liteUser,
          changeSource: liteUser,
          statusRow: liteUser,
          charts: liteUser,
          createPage: liteUser,
          createCurve: liteUser || enhancedUser,
          editCurve: liteUser || enhancedUser,
          insertCurve: liteUser,
          removeCurve: liteUser,
          priceAlerts: liteUser || enhancedUser,
          plotLiveChart: liteUser,
          conditionalFormatting: liteUser,
        };

  return {
    ...user,
    subscriptionTier: {
      liteUser,
      enhancedUser,
      enhancedNoExchUser,
      disabledFeatures,
    },
  };
};

const useAccessTokenQueryOptions = () => {
  const { getAccessTokenSilently } = useAuth0();
  return queryOptions({
    queryKey: ["auth", "access_token"],
    queryFn: () => getAccessTokenSilently({ detailedResponse: true }),
  });
};

export const useAccessToken = () => useQuery(useAccessTokenQueryOptions());

export const useIdToken = () =>
  useQuery({
    ...useAccessTokenQueryOptions(),
    select: (data) => jwtDecode<JWT>(data.id_token),
  });

export const useClaims = () =>
  useQuery({
    ...useAccessTokenQueryOptions(),
    select: (data) => jwtDecode<JWT>(data.access_token),
  });

export type TSession =
  | {
      session_id: string;
      access_token: string;
      session_start: string;
    }
  | null
  | undefined;

export const useSessionState = () =>
  useQuery({
    ...useAccessTokenQueryOptions(),
    select: ({ access_token, id_token }) => {
      const parsedToken = jwtDecode<JWT>(id_token);
      const session_id = parsedToken.sid;
      const session_start = new Date(parsedToken.iat * 1000).toISOString();
      return {
        access_token,
        session_id,
        session_start,
      };
    },
  });

export function useCurrentUserOrg() {
  const claims = useClaims();
  const orgId = parse(
    claims.data?.["https://hasura.io/jwt/claims"]?.["x-hasura-org-id"],
  );
  return useApolloQuery(ORG_BY_ID_QUERY, {
    variables: { id: orgId ?? 7 },
    skip: !orgId,
  });
}

export function useCurrentUsername() {
  const { user } = useAuth0();
  return `${user?.given_name} ${user?.family_name}`;
}

export function useRoles() {
  const claims = useClaims();

  return useMemo(() => {
    if (!claims.data) {
      return [];
    }

    const claimsRoles =
      claims.data?.["https://hasura.io/jwt/claims"]?.[
        "x-hasura-allowed-roles"
      ] || [];
    if (claimsRoles.includes("umi-internal-super-admin")) {
      return [
        "umi-internal-super-admin",
        "umi-internal-admin",
        "umi-internal-write",
        "umi-internal-read",
      ];
    }
    if (claimsRoles.includes("umi-internal-admin")) {
      return ["umi-internal-admin", "umi-internal-write", "umi-internal-read"];
    }
    if (claimsRoles.includes("umi-internal-write")) {
      return ["umi-internal-write", "umi-internal-read"];
    }
    return claimsRoles;
  }, [claims.data]);
}

export function useAuth0Config() {
  const [env] = useUrlEnv();
  const auth0_url =
    !env || env === "dev"
      ? DEV_AUTH0_USERS_URL
      : env === "uat"
        ? UAT_AUTH0_USERS_URL
        : PROD_AUTH0_USERS_URL;

  const auth0_org_id = getAuthOrgId(env);

  return { auth0_url, auth0_org_id, env } as const;
}

export function useUserSubscriptionTier() {
  const { user } = useCurrentUser();
  const subscriptionTier =
    useSubscribeSubscriptionTier() ?? user?.subscription_tier ?? "artis_lite";

  const liteUser = subscriptionTier === subscriptionTierSchema.enum.artis_lite;
  const enhancedUser =
    subscriptionTier === subscriptionTierSchema.enum.artis_enhanced;
  const enhancedNoExchUser =
    subscriptionTier === subscriptionTierSchema.enum.artis_enhanced_noexch;

  return useMemo(() => {
    // Based on: https://artis.works/#pricing
    const disabledFeatures =
      !liteUser && !enhancedUser
        ? undefined
        : {
            showFormula: liteUser,
            headerSize: liteUser,
            curveDetails: liteUser,
            spreader: liteUser,
            managePages: liteUser,
            pauseExchangeData: liteUser,
            changeSource: liteUser,
            statusRow: liteUser,
            charts: liteUser,
            createPage: liteUser,
            createCurve: liteUser || enhancedUser,
            editCurve: liteUser || enhancedUser,
            insertCurve: liteUser,
            removeCurve: liteUser,
            priceAlerts: liteUser || enhancedUser,
            plotLiveChart: liteUser,
            conditionalFormatting: liteUser,
          };

    return {
      liteUser,
      enhancedUser,
      enhancedNoExchUser,
      disabledFeatures,
    };
  }, [liteUser, enhancedUser, enhancedNoExchUser]);
}

export const useSubscribeSubscriptionTier = () => {
  const { user } = useCurrentUser();
  const userId = user?.id;
  const { data } = useSubscription(UserSubscriptionTier, {
    skip: !userId,
    variables: { id: userId ?? "" },
  });
  return data?.folio_user_by_pk?.subscription_tier;
};

const UserSubscriptionTier = graphql(`
  subscription UserSubscriptionTier($id: String!) {
    folio_user_by_pk(id: $id) {
      subscription_tier
    }
  }
`);
