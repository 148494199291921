import { useThrottledCallback } from "@react-hookz/web";
import { useCallback, useEffect } from "react";

import type { TQuickAccess } from "../../../triplit";
import { useQuickAccess } from "./useQuickAccess";
import {
  type TGlobalKeyboardShortcut,
  ctrl,
  globalKeyboardShortcuts,
  keysMatch,
  modifierKeyPressed,
} from "./utils";

export function useShortcuts(debounce?: number) {
  const { data: quickAccess, handleQuickAccess } = useQuickAccess();

  const debouncedAction = useThrottledCallback(
    (shortcut: TGlobalKeyboardShortcut) => {
      shortcut.action();
    },
    [],
    debounce ?? 1000,
    true,
  );

  const listenerKeydown = useCallback(
    (event: KeyboardEvent) => {
      const qaShortcut = matchQuickAccessShortcut(quickAccess, event);

      if (qaShortcut) {
        handleQuickAccess(qaShortcut);
        return;
      }

      const shortcut = matchGlobalShortcut(event);

      if (shortcut) {
        debouncedAction(shortcut);
      }
    },
    [debouncedAction, quickAccess, handleQuickAccess],
  );

  useEffect(() => {
    window.addEventListener("keydown", listenerKeydown);

    return () => {
      window.removeEventListener("keydown", listenerKeydown);
    };
  }, [listenerKeydown]);
}

function matchQuickAccessShortcut(
  quickAccess: TQuickAccess[],
  event: KeyboardEvent,
) {
  if (
    (!ctrl(event) && !modifierKeyPressed(event, "alt")) ||
    !modifierKeyPressed(event, "shift")
  ) {
    return false;
  }

  const key = event.code.includes("Digit")
    ? event.code.replace("Digit", "")
    : event.key;

  const shortcut = quickAccess.find(
    (qa) => qa.shortcutNumber.toString() === key.toString(),
  );

  return shortcut;
}

export function matchGlobalShortcut(
  event: KeyboardEvent,
): TGlobalKeyboardShortcut | null {
  for (const shortcut of globalKeyboardShortcuts) {
    const shortcutMatch = shortcut.shortcuts.reduce((acc, sc) => {
      if (keysMatch(event, sc)) {
        acc.push(shortcut);
      }
      return acc;
    }, [] as TGlobalKeyboardShortcut[])?.[0];

    if (shortcutMatch) {
      return shortcutMatch;
    }
  }

  return null;
}
