import posthog from "posthog-js";

interface AppEvents {
  failed_triplit_sync: { entity: string };

  created_alert: { pageId: string; productId: string };
  created_conditional_formatting_rule: { pageId: string; columnId: string };
  inserted_column: { type: string; pageId: string; productId?: string };
  changed_settings: undefined;
  changed_curve_status: { from: string; to: string };
  changed_field_value: { columnId: string; productId: string };
  changed_curve_field_in_grid: undefined;
}

const captureEvent = <K extends keyof AppEvents>(
  ...[eventName, info]: AppEvents[K] extends undefined ? [K] : [K, AppEvents[K]]
) => {
  // Check if posthog is blocked or unavailable
  if (typeof posthog === "undefined" || !posthog.capture) {
    console.debug("Analytics tracking is disabled or blocked");
    return;
  }

  try {
    posthog.capture(eventName, { ...info });
  } catch (error) {
    console.error("Error capturing event:", error);
  }
};

export default captureEvent;
