import { Box, Button, Typography } from "@mui/joy";
import { isDev } from "../../globals";

export type SessionType = {
  id: string;
  last_seen?: string | null;
  is_mobile: boolean;
};

export const SessionView = ({
  currentSession,
  userId,
  activeSession: session,
  onLogout,
  onStartSession,
}: {
  currentSession?: { session_id: string; session_start: string } | undefined;
  userId: string;
  activeSession?: SessionType | null;
  onLogout: () => void;
  onStartSession: () => void;
}) => {
  const deviceType = session?.is_mobile ? "mobile" : "desktop";

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80vw",
          maxWidth: "400px",
          textAlign: "center",
          color: "black",
        }}
      >
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "16px",
          }}
        >
          Active session limit reached
        </Box>
        <Box
          sx={{
            fontSize: "16px",
            marginBottom: "16px",
          }}
        >
          {`You have reached your limit of 1 active ${deviceType} session on Artis. If you continue to sign in, the following session will be logged out.`}
        </Box>
        {isDev && (
          <textarea
            className="w-full bg-[#f4f4f4] p-3 rounded-lg"
            rows={8}
            readOnly
            value={JSON.stringify(
              {
                userId,
                current_session_id: currentSession?.session_id,
                current_session_start: currentSession?.session_start,
                active_session_id: session?.id,
                active_session_start: session?.last_seen,
                deviceType,
              },
              null,
              2,
            )}
          />
        )}
        {session && (
          <Box
            sx={{
              marginTop: "16px",
              textAlign: "left",
              marginBottom: "8px",
              border: "1px solid #ccc",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
              }}
            >{`Session ID: ${session?.id} `}</Box>
            <Typography
              sx={{
                fontStyle: "italic",
              }}
            >
              {deviceType}
            </Typography>
            {session.last_seen && (
              <Box
                sx={{
                  color: "#888",
                }}
              >{`Last Seen: ${session.last_seen}`}</Box>
            )}
          </Box>
        )}
      </Box>
      <Button
        onClick={onStartSession}
        data-testid="log-out-other-session"
        color="danger"
        sx={{
          marginTop: "16px",
          color: "danger",
          padding: "12px 24px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        {`Log out other ${deviceType} session`}
      </Button>
      <Button
        onClick={onLogout}
        color="neutral"
        sx={{
          marginTop: "16px",
          padding: "12px 24px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        Return to login page
      </Button>
    </Box>
  );
};
