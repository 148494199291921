import { useGridSettings, useUpdateGridSettings } from "../../grid-settings";
import { potentiallyInvisibleColumns } from "../../market-grid/column-defs";
import { useUserId, useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsCurveDetails = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();
  const update = useUpdateGridSettings();
  const settings = useGridSettings();
  const userId = useUserId();

  return (ctx) => {
    if (!ctx.isProduct || !ctx.isHeaderMenuItem) return [];
    return [
      {
        disabled: disabledFeatures?.curveDetails,
        name: "Curve Details",
        tooltip: disabledFeatures?.curveDetails
          ? "Please upgrade your subscription to access this feature"
          : undefined,
        icon: getIcon("details"),
        subMenu: potentiallyInvisibleColumns.map(
          ({ name, label, defaultVisible }) => {
            const selected = settings?.[name] ?? defaultVisible;
            return {
              name: label,
              icon: selected ? getIcon("check") : "",
              action: () => {
                const newValue = !selected;
                update.mutate({
                  id: userId,
                  updaterFn: (data) => {
                    data[name] = newValue;
                  },
                });
              },
            };
          },
        ),
      },
    ];
  };
};
