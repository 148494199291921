import { requestDockviewModal } from "../modals/modalComponents";
import { useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsShowFormula = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();

  return (ctx) => {
    if (!ctx.hasFormula) return [];
    return [
      {
        name: "Show Formula",
        disabled: disabledFeatures?.showFormula,
        icon: getIcon("function"),
        action: () =>
          requestDockviewModal({
            parentPanel: "formula-viewer",
            params: {
              height: 350,
              width: 600,
              selectedRange: ctx.selectedRange,
            },
          }),
      },
    ];
  };
};
