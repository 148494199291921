import { useIntervalEffect } from "@react-hookz/web";
import { useApolloClient } from "@apollo/client";

import { useActivePageId, usePageProductsWithInfo } from "../../../../data";
import { useAdhocSpreads } from "../../modals/timespreadHooks";
import { useUserId } from "../../../../context/auth";
import { updateGrid } from "./helpers";
import { useAtomValue } from "jotai";
import { gridApiAtom } from "../../stores";
import { useGridSettingsStatusMap } from "../../../grid-settings";

export const useLivePricesPlugin = () => {
  const pageProducts = usePageProductsWithInfo(useActivePageId());
  const { formattedAdhocSpreads } = useAdhocSpreads();
  const statusMap = useGridSettingsStatusMap();
  const graphqlClient = useApolloClient();
  const api = useAtomValue(gridApiAtom);
  const userId = useUserId();
  useIntervalEffect(
    () => {
      if (api) {
        updateGrid(
          graphqlClient,
          formattedAdhocSpreads || [],
          api,
          userId,
          pageProducts,
          statusMap,
        );
      }
    },
    pageProducts.length > 0 ? 250 : undefined,
  );
};
