import type { OhlcData } from "lightweight-charts";
import { isNullish } from "remeda";
import { useMemo } from "react";
import dayjs from "dayjs";

import { useSpecificHistoricalChartData } from "./useSpecificHistoricalChartData";
import type { HistoryicalData, TLiveChart, TPriceDifference } from "./types";
import { specificPeriods } from "../utils";

export function usePriceDifferences({
  currentCandle,
  historicalData,
  chartMetadata,
}: {
  currentCandle: OhlcData | undefined;
  historicalData: HistoryicalData[];
  chartMetadata: TLiveChart;
}) {
  const latestCandleTime = currentCandle?.time;

  const latestHistoricalData = useMemo(() => {
    return latestCandleTime
      ? currentCandle
      : findClosestPrice(Number(latestCandleTime), historicalData ?? []);
  }, [latestCandleTime, currentCandle, historicalData]);

  const periodDates = useMemo(() => {
    if (!latestCandleTime) return [];
    return getSpecificHistoricalDataDates(Number(latestCandleTime));
  }, [latestCandleTime]);

  const specificChartData = useSpecificHistoricalChartData({
    chartMetadata,
    dates: periodDates,
  });

  const priceDifferences = useMemo(() => {
    if (!latestHistoricalData) return {};

    return specificChartData.reduce((acc, curr, idx) => {
      const label = periodDates[idx].label;
      const targetTime = dayjs(periodDates[idx].from).unix();

      const data = findClosestPrice(targetTime, curr.data ?? []);

      const close = data?.close;
      const difference =
        !isNullish(latestHistoricalData?.close) && !isNullish(close)
          ? latestHistoricalData.close - close
          : undefined;
      const time =
        data?.time && typeof data.time === "number" ? data.time * 1000 : null;

      acc[label] = {
        date: time ? new Date(time) : undefined,
        latestVisibleDate: latestHistoricalData?.time
          ? new Date(Number(latestHistoricalData.time) * 1000)
          : undefined,
        close,
        difference,
        className: isNullish(difference)
          ? undefined
          : difference > 0
            ? "positive"
            : difference < 0
              ? "negative"
              : "",
      };
      return acc;
    }, {} as TPriceDifference);
  }, [specificChartData, periodDates, latestHistoricalData]);

  return priceDifferences;
}

function findClosestPrice(targetTime: number, data: HistoryicalData[]) {
  if (!data?.length) return null;

  return data.reduce((prev, curr) => {
    if (isNullish(curr.time)) return prev;
    if (isNullish(prev.time)) return curr;

    return Math.abs(Number(curr.time) - targetTime) <
      Math.abs(Number(prev.time) - targetTime)
      ? curr
      : prev;
  });
}

function getSpecificHistoricalDataDates(latestVisibleCandleTime: number) {
  const oneDaySeconds = 60 * 60 * 24;

  const periodDates = specificPeriods.map((p) => {
    const from = latestVisibleCandleTime - oneDaySeconds * p.value;
    const fromUTC = dayjs(from * 1000)
      .utc()
      .toISOString();
    const to = from + 3600;
    const toUTC = dayjs(to * 1000)
      .utc()
      .toISOString();
    return { from: fromUTC, to: toUTC, label: p.label };
  });

  return periodDates;
}
