import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Input,
  Divider,
  Button,
  useTheme,
} from "@mui/joy";
import { HexColorPicker } from "react-colorful";
import type { TIndicatorsState } from "../utils";
import { useChartSettings } from "../hooks";

interface IndicatorEditModalProps {
  open: boolean;
  onClose: () => void;
  indicatorsState: TIndicatorsState;
  currentState: TIndicatorsState[number];
  chartId: string;
}

export function IndicatorEditModal({
  open,
  onClose,
  indicatorsState,
  currentState,
  chartId,
}: IndicatorEditModalProps) {
  const theme = useTheme();
  const { settings, setSettings } = useChartSettings(chartId);

  const [newColor, setNewColor] = useState<string | undefined>(undefined);
  const [newPeriod, setNewPeriod] = useState<number | undefined>(undefined);

  const handleSave = () => {
    if (!currentState || !settings) return;
    const newState = [
      ...indicatorsState.filter((state) => state.id !== currentState.id),
      {
        ...currentState,
        period: newPeriod || currentState.period,
        color: newColor || currentState.color,
      },
    ] satisfies TIndicatorsState;
    setSettings({ ...settings, indicatorsState: newState });
    onClose();
  };

  return (
    <Modal
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "none",
            opacity: 0.2,
            backgroundColor: "black",
          },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: theme.palette.background.body,
          p: 2,
          width: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Typography level="h3">Edit indicator</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Typography>Period</Typography>
          <Input
            type="number"
            value={newPeriod || currentState?.period}
            fullWidth
            onChange={(e) => {
              setNewPeriod(Number(e.target.value));
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography>Color</Typography>
          <HexColorPicker
            color={newColor || currentState?.color}
            onChange={setNewColor}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: "100%",
          }}
        >
          <Button onClick={handleSave}>Save</Button>
          <Button color="neutral" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
