import {
  Box,
  Button,
  Input,
  LinearProgress,
  Option,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/joy";
import { useState, useEffect } from "react";
import type { TPageProduct } from "../../../triplit/schema";
import { ColumnWidthSetting } from "./SetAllColumnWidths";
import { defaultColumnSettings, validColumnSettings } from "../column-defs";
import { ModalButtons } from "./Buttons";
import { GridModalContainer } from "./GridModal";
import { useAtom, useAtomValue } from "jotai";
import {
  cancelCellFormatting,
  initialFormattingAndSettingsAtom,
} from "./formatCellHelpers";
import { store } from "../../sharedHooks";
import { gridModalDockviewAtom } from "./modalAtoms";
import { GridModalSelectionInfo } from "./modalComponents";
import { colParams } from "../../utils";
import {
  useGridSettingsIncrementMap,
  useUpdateGridSettings,
} from "../../grid-settings";
import { useGrid } from "../stores";
import { usePageProduct, useUpdatePageProducts } from "../../../data";
import { useUserId } from "../../../context/auth";
export type ContextMenuColumnSettings = {
  show: boolean;
  columnId?: string;
};

type InitialColumnSettings = {
  columnWidth?: number;
  decimalPlaces?: number;
  thousandsSeparator?: boolean;
  increment?: number;
};

export function updateIncrementMap(
  changes: { increment?: number },
  incrementMap: Record<string, number>,
) {
  const modalDockview = store.get(gridModalDockviewAtom);
  const ranges = modalDockview?.formatting?.selectedRange;

  if (!ranges) return;

  const { columnIds, productIds } = ranges.reduce(
    (acc: { columnIds: string[]; productIds: string[] }, range) => {
      range.map((cell) => {
        if (!acc.columnIds.includes(cell.columnId)) {
          acc.columnIds.push(cell.columnId);
        }

        if (cell?.productId && !acc.productIds.includes(cell.productId)) {
          acc.productIds.push(cell.productId);
        }
      });

      return acc;
    },
    { columnIds: [], productIds: [] },
  );

  if (!columnIds.length) return;

  if (productIds.length) {
    for (const productId of productIds) {
      if (changes.increment) {
        incrementMap[productId] = changes.increment;
      }
    }

    return incrementMap;
  }
}

function ColumnSettingsModalWithState({
  pageId,
  product,
  initialSettings,
}: {
  pageId: string;
  product?: TPageProduct;
  initialSettings: InitialColumnSettings;
}) {
  const [api] = useGrid();
  const userId = useUserId();
  const updatePage = useUpdatePageProducts();
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.formatting?.selectedRange || [];
  const incrementMap = useGridSettingsIncrementMap();
  const updateSettings = useUpdateGridSettings();
  const editable = rangeSelection
    ?.flatMap((range) => {
      return range.flatMap((cell) => {
        const params = colParams(api?.getColumn(cell.columnId));
        return params?.hasSharedCell;
      });
    })
    .some((c) => c);

  const [columnWidth, setColumnWidth] = useState<number>(
    product?.columnWidth || defaultColumnSettings.columnWidth,
  );

  const initialFormatting = useAtomValue(
    initialFormattingAndSettingsAtom,
  )?.formatting;

  const [incrementValue, setIncrementValue] = useState<number | undefined>(
    initialSettings?.increment,
  );

  useEffect(() => {
    if (!product?.id) return;
    const timeout = setTimeout(() => {
      updatePage.mutate({
        id: product.id,
        updaterFn: (current) => {
          current.columnWidth = columnWidth;
        },
      });
    }, 300);
    return () => clearTimeout(timeout);
  }, [columnWidth, product?.id, updatePage.mutate]);

  if (!product) return <LinearProgress />;

  const { decimalPlaces, thousandsSeparator, increment } = validColumnSettings({
    decimalPlaces: product.decimalPlaces,
    thousandsSeparator: product.thousandsSeparator,
  });

  return (
    <GridModalContainer
      panel="formatting"
      body={
        <>
          <GridModalSelectionInfo selectedRange={rangeSelection} />
          <Stack padding={2}>
            <Box>
              <Box>
                <Box overflow={"hidden"}>
                  <Typography>Number of decimal places to show</Typography>
                  <Select
                    sx={{ width: 120, mt: 1 }}
                    variant="outlined"
                    defaultValue={decimalPlaces}
                    onChange={(_, value) =>
                      updatePage.mutateAsync({
                        id: product.id,
                        updaterFn: (current) => {
                          current.decimalPlaces = Number(value);
                        },
                      })
                    }
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <Option key={i.toString()} value={i}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                </Box>
                {editable && (
                  <Box mt={1} overflow={"hidden"}>
                    <Typography>Increment</Typography>
                    <Stack
                      flexDirection={"row"}
                      gap={1}
                      mt={1}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      height={27}
                    >
                      <Input
                        type="number"
                        value={incrementValue ?? increment}
                        onChange={(e) =>
                          setIncrementValue(Number(e.target.value))
                        }
                        sx={{
                          width: 120,
                        }}
                      />
                      <Button
                        variant="solid"
                        color="neutral"
                        onClick={() => {
                          setIncrementValue(defaultColumnSettings.increment);
                        }}
                      >
                        Set to Default
                      </Button>
                    </Stack>
                  </Box>
                )}
                <Box mt={1} overflow={"hidden"}>
                  <Typography>Thousands separator</Typography>
                  <Switch
                    sx={{ mt: 1 }}
                    size="lg"
                    checked={thousandsSeparator}
                    onChange={() =>
                      updatePage.mutateAsync({
                        id: product.id,
                        updaterFn: (current) => {
                          current.thousandsSeparator =
                            !product.thousandsSeparator;
                        },
                      })
                    }
                  />
                </Box>
                <Box mt={1} overflow={"hidden"}>
                  <Typography>Column Width</Typography>
                  <ColumnWidthSetting
                    columnWidth={columnWidth}
                    setColumnWidth={setColumnWidth}
                  />
                </Box>
              </Box>
            </Box>
          </Stack>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel="formatting"
          onCancel={() => {
            const nextMap = updateIncrementMap(
              { ...initialSettings },
              incrementMap,
            );
            if (nextMap) {
              updateSettings.mutate({
                id: userId,
                updaterFn: (data) => {
                  data.incrementMap = JSON.stringify(nextMap);
                },
              });
            }
            updatePage.mutate({
              id: product.id,
              updaterFn: (current) => {
                current.columnWidth =
                  initialSettings.columnWidth ??
                  defaultColumnSettings.columnWidth;
                current.decimalPlaces =
                  initialSettings.decimalPlaces ??
                  defaultColumnSettings.decimalPlaces;
                current.thousandsSeparator =
                  initialSettings.thousandsSeparator ??
                  defaultColumnSettings.thousandsSeparator;
              },
            });
            if (initialFormatting) {
              cancelCellFormatting(pageId, initialFormatting);
            }
          }}
          onSave={() => {
            const nextMap = updateIncrementMap(
              { ...initialSettings },
              incrementMap,
            );
            if (nextMap) {
              updateSettings.mutate({
                id: userId,
                updaterFn: (data) => {
                  data.incrementMap = JSON.stringify(nextMap);
                },
              });
            }
          }}
        />
      }
    />
  );
}

export type TColumnSettingsModalProps = {
  columnId: string;
  initialColumnWidth: number;
  initialThousandsSeparator: boolean;
  initialDecimalPlaces: number;
  initialIncrement?: number;
  pageId: string;
};

export default function ColumnSettingsModal({
  pageId,
  columnId,
  initialColumnWidth,
  initialThousandsSeparator,
  initialDecimalPlaces,
}: TColumnSettingsModalProps) {
  const incrementMap = useGridSettingsIncrementMap();
  const product = usePageProduct(pageId, columnId);

  const productId = product.data?.productId || "";

  const [initialFormattingAtom, setInitialFormattingAtom] = useAtom(
    initialFormattingAndSettingsAtom,
  );

  useEffect(() => {
    if (product.data && !initialFormattingAtom.settings) {
      const initialSettings = validColumnSettings({
        columnWidth: initialColumnWidth,
        decimalPlaces: initialDecimalPlaces,
        thousandsSeparator: initialThousandsSeparator,
        increment: incrementMap?.[productId],
      });

      setInitialFormattingAtom({
        ...initialFormattingAtom,
        settings: { ...initialSettings },
      });
    }
  }, [
    product.data,
    initialFormattingAtom,
    initialColumnWidth,
    initialThousandsSeparator,
    initialDecimalPlaces,
    incrementMap,
    productId,
    setInitialFormattingAtom,
  ]);

  if (!product) return <LinearProgress />;

  const initialSettings = validColumnSettings({
    columnWidth: initialColumnWidth,
    decimalPlaces: initialDecimalPlaces,
    thousandsSeparator: initialThousandsSeparator,
    increment: incrementMap?.[productId],
  });

  return (
    <ColumnSettingsModalWithState
      pageId={pageId}
      product={product.data}
      initialSettings={initialSettings}
    />
  );
}
