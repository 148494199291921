import type { CellKeyDownEvent } from "ag-grid-community";

import { useMemoCallback } from "../../../utils/useMemoCallback";
import { useKeyboardShortcuts } from "./useKeyboardShortcuts";
import { useActivePageId } from "../../../data";
import {
  keysMatch,
  modifierKeyPressed,
  type TGridKeyboardShortcut,
} from "./utils";

export const useOnCellKeyDown = () => {
  const pageId = useActivePageId();
  const shortcuts = useKeyboardShortcuts();
  const onCellKeyDown = (params: CellKeyDownEvent) => {
    if (!params || !pageId || !("column" in params)) return;

    // CellKeyDownEvent's type has "Event" as the type of the event property, but it is actually a KeyboardEvent. We need to cast it to the correct type.
    const event = params.event as KeyboardEvent;

    if (!("key" in event)) return;

    event.preventDefault();
    event.stopPropagation();

    if (modifierKeyPressed(event, "ctrl") || modifierKeyPressed(event, "alt")) {
      const shortcutMatch = matchGridShortcut(event, shortcuts);
      if (shortcutMatch) {
        return shortcutMatch.action({ params, pageId });
      }
    }
  };

  return useMemoCallback(onCellKeyDown);
};

export function matchGridShortcut(
  event: KeyboardEvent,
  shortcuts: TGridKeyboardShortcut[],
): TGridKeyboardShortcut | null {
  for (const shortcut of shortcuts) {
    const shortcutMatch = shortcut.shortcuts.reduce((acc, sc) => {
      if (keysMatch(event, sc)) {
        acc.push(shortcut);
      }
      return acc;
    }, [] as TGridKeyboardShortcut[])?.[0];

    if (shortcutMatch) {
      return shortcutMatch;
    }
  }

  return null;
}
