import type React from "react";
import { useEffect, useId, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import cn from "mcn";

export const SimpleColorPicker = (p: {
  value: string;
  onChange: (color: string) => void;
  position: "top" | "bottom";
  disabled?: boolean;
  additionalSettings?: React.ReactNode;
}) => {
  const [value, setValue] = useState<string>(p.value);
  const onChange = useRef(p.onChange);
  const ignore = useRef(false);
  const id = useId();
  const transparentBg =
    "linear-gradient(45deg, #c8c8c8 25%, transparent 25%, transparent 75%, #c8c8c8 75%, #c8c8c8 100%),linear-gradient(45deg, #c8c8c8 25%, white 25%, white 75%, #c8c8c8 75%, #c8c8c8 100%)";

  useEffect(() => {
    if (!ignore.current) return setValue(p.value);
    ignore.current = false;
  }, [p.value]);

  // We debounce the onChange event to avoid sending too many events when user is dragging the color picker
  onChange.current = p.onChange;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (p.value === value) return;
      ignore.current = true;
      onChange.current(value);
    }, 150);
    return () => clearTimeout(timeout);
  }, [value, p.value]);

  return (
    <button
      className={cn("relative group w-14 h-8 rounded-lg", {
        "cursor-not-allowed opacity-50": p.disabled,
      })}
      style={{
        backgroundColor: value ?? "",
        backgroundImage: value === "inherit" ? transparentBg : "",
        backgroundSize: "20px 20px",
        backgroundPosition: "0px 0px, 10px 10px",
      }}
      type="button"
      disabled={p.disabled}
    >
      <dialog
        className={cn(
          "bg-bg absolute w-68 group-focus-within:flex hidden z-50 rounded-xl flex-col shadow-md",
          {
            "-left-52 top-9": p.position === "bottom",
            "-left-52 bottom-9": p.position === "top",
            "pointer-events-none": p.disabled,
          },
        )}
      >
        <HexColorPicker
          color={value === "inherit" ? "#000000" : value}
          onChange={setValue}
          className="[&>*]:!rounded-b-none !h-48"
        />
        <div className="w-full flex justify-between items-center p-6">
          <label htmlFor={id} className="text-sm">
            No Colour
          </label>
          <input
            id={id}
            type="checkbox"
            className="m-0 mr-2"
            checked={p.value === "inherit"}
            onChange={() => {
              p.onChange("inherit");
              setValue("inherit");
            }}
          />
        </div>
        {p.additionalSettings}
        <div className="w-full flex justify-between items-center p-6 border-t">
          <input
            id={id}
            type="text"
            className="m-0 w-full border rounded py-2 px-3"
            value={
              p.value === "inherit" || p.value.startsWith("var(")
                ? ""
                : (p.value ?? "")
            }
            onChange={(e) => setValue(e.target.value || "")}
          />
        </div>
      </dialog>
    </button>
  );
};
