import type { IMenuActionParams, MenuItemDef } from "ag-grid-community";

import { useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { colParams } from "../../utils";
import { getIcon } from "./icons";

export const useGetMenuItemsEditCurve = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();

  const handleEditCurve = (e: IMenuActionParams) => {
    if (disabledFeatures?.editCurve) return;

    const curveId = colParams(e.column)?.productId;
    if (!curveId) {
      console.error("No curveId found");
      return;
    }

    window.open(
      `${import.meta.env.VITE_OLD_WEBAPP_URL}/edit-curve/${curveId}`,
      "_blank",
      "location=yes,scrollbars=yes,status=yes",
    );
  };

  return (ctx) => {
    // Only show for product columns and when exactly one column is selected
    if (!ctx.isProduct || !ctx.columnId || ctx.cannedOrSourced) {
      return [];
    }

    const column = ctx.api.getColumn(ctx.columnId);
    const headerParams = colParams(column);
    const hasWritePermission =
      headerParams?.permissions?.some((p) => p.permission === "write") ?? false;

    const isDisabled = disabledFeatures?.editCurve || !hasWritePermission;
    console.log({
      hasWritePermission,
      isDisabled,
      headerParams,
    });

    return [
      {
        name: "Edit Curve",
        disabled: isDisabled,
        tooltip: disabledFeatures?.editCurve
          ? "Please upgrade your subscription to access this feature"
          : !hasWritePermission
            ? "You don't have write permission for this package"
            : undefined,
        icon: getIcon("function"),
        action: handleEditCurve,
      } as MenuItemDef,
    ];
  };
};
