import type { getProcessedSelectedCellsByRange } from "../../../tableUtils";
import { client } from "../../../triplit/triplit";
import { columnWidth } from "../../globals";
import {
  type TFormattingTabs,
  requestDockviewModal,
} from "../modals/modalComponents";
import { validColumnSettings } from "../column-defs";

export async function openFormattingDockview({
  pageId,
  type,
  columnId,
  productId,
  currentTab,
  incrementMap,
  selectedRange,
}: {
  pageId: string;
  type: "range" | "column" | "period";
  columnId: string;
  productId: string;
  currentTab: TFormattingTabs;
  incrementMap: Record<string, number>;
  selectedRange: ReturnType<typeof getProcessedSelectedCellsByRange>;
}) {
  const product = await client.fetchById("pageProducts", columnId);

  const initialSettings = validColumnSettings({
    columnWidth: product?.columnWidth || columnWidth,
    decimalPlaces: product?.decimalPlaces,
    thousandsSeparator: product?.thousandsSeparator,
    increment: incrementMap?.[productId],
  });

  requestDockviewModal({
    parentPanel: "formatting",
    currentTab,
    params: {
      selectedRange,
      columnId,
      pageId,
      tab: "colors",
      type,
      initialColumnWidth: initialSettings.columnWidth,
      initialThousandsSeparator: initialSettings.thousandsSeparator,
      initialDecimalPlaces: initialSettings.decimalPlaces,
      initialIncrement: initialSettings.increment,
      width: 300,
      height: 550,
    },
  });
}
