import { Button } from "@mui/joy";
import type { TAlert } from "../../../../triplit/schema";
import { getAlertDetails, getProductName } from "./hooks";
import { client } from "../../../../triplit/triplit";
import toast, { type Toast } from "react-hot-toast";
import { alertSounds } from "../../../globals";
import type { TApolloClient } from "../../../../context/apollo";
import { nanoid } from "nanoid";
import type { useApi } from "../../../../admin/hooks/useApi";
import { vapidKeys } from "../../../../globals";
import { v4 as uuidv4 } from "uuid";
import { AlertToast } from "../../../alerts";

function AlertToastMessage({
  t,
  alert,
  productName,
  limitName,
}: {
  t: Toast;
  alert: TAlert;
  productName: string;
  limitName?: string | null;
}) {
  const { formattedMessage } = getAlertDetails(alert, productName, limitName);
  const duplicateAlert: TAlert = {
    id: nanoid(),
    _id: uuidv4(),
    columnId: alert.columnId,
    rowId: alert.rowId,
    limit: alert.limit,
    note: alert.note,
    recurring: alert.recurring,
    sound: alert.sound,
    valueBelowLimit: alert.valueBelowLimit,
    status: "Paused",
    triggeredAt: null,
    productId: alert.productId,
    fieldSelector: alert.fieldSelector,
    userId: alert.userId,
    pageId: alert.pageId,
  };

  const message = (
    <>
      <p className="py-2">{formattedMessage}</p>
      {!alert.recurring ? (
        <Button
          color="neutral"
          sx={{ width: "90px" }}
          onClick={() => {
            toast.dismiss(alert.id);
            client
              .delete("alerts", alert.id)
              .then(() => {
                console.log("Alert dismissed", alert);
              })
              .catch((error) => {
                console.error("Error dismissing alert", error);
              });
            client.insert("alerts", duplicateAlert);
          }}
        >
          Re-set alert
        </Button>
      ) : null}
    </>
  );

  return (
    <AlertToast
      title={`${alert.note || "Alert"} triggered`}
      hidden={!t.visible || t.dismissed}
      message={message}
      onDismiss={async () => {
        toast.dismiss(alert.id);

        const existing = await client.fetchById("alerts", alert.id);

        client
          .delete("alerts", alert.id)
          .then(() => {
            console.log("Alert dismissed", alert);
          })
          .catch((error) => {
            console.error("Error dismissing alert", error);
          });

        if (alert.recurring && existing) {
          client.insert("alerts", duplicateAlert);
        }
      }}
    />
  );
}

export async function sendNotification(
  alert: TAlert,
  graphqlClient: TApolloClient,
  makeApiRequest: ReturnType<typeof useApi>,
  sound: boolean,
) {
  if ((sound || alert.sound) && !document.getElementById(alert.id)) {
    new Audio(alertSounds.huthut).play();
  }
  const productName =
    (await getProductName(alert.columnId, graphqlClient)) || "";
  const limitId = alert.limit.includes(":") ? alert.limit.split(":")[0] : null;
  const limitName = limitId
    ? await getProductName(limitId, graphqlClient)
    : null;

  console.log("Alert triggered", {
    alert,
    productName,
    limitName,
  });

  if (alert.status !== "Triggered" || !alert.triggeredAt) {
    client.update("alerts", alert.id, (row) => {
      row.status = "Triggered";
      row.triggeredAt = alert.triggeredAt || new Date();
    });
  }

  toast.custom(
    (t) => (
      <AlertToastMessage
        t={t}
        alert={alert}
        productName={productName || ""}
        limitName={limitName}
      />
    ),
    {
      id: alert.id,
      position: "bottom-right",
      duration: Number.POSITIVE_INFINITY,
    },
  );

  if (!Notification) {
    console.warn("Desktop notifications not available.");
    return;
  }

  if (Notification.permission !== "granted") {
    console.warn("Notification permission not granted.");
    return;
  }

  const pushSubscriptions = await client.fetchById(
    "pushSubscriptions",
    alert.userId,
  );

  if (pushSubscriptions && !alert.pushNotificationSent) {
    const body = {
      publicKey: vapidKeys.publicKey,
      privateKey: vapidKeys.privateKey,
      subscriptions: pushSubscriptions.subscriptions,
      title: `${alert.note || "Alert"} triggered`,
      body: getAlertDetails(alert, productName, limitName).formattedMessage,
    };

    console.log("Attempting to send push notification...", body);

    await client.update("alerts", alert.id, (row) => {
      row.pushNotificationSent = true;
    });

    makeApiRequest("/api/send-notification", "POST", JSON.stringify(body));
  }

  if (!alert.desktopNotificationSent) {
    await client.update("alerts", alert.id, (row) => {
      row.desktopNotificationSent = true;
    });

    const notification = new Notification(
      `${alert.note || "Alert"} triggered`,
      {
        body: getAlertDetails(alert, productName, limitName).formattedMessage,
      },
    );

    notification.onclick = () => {
      window.focus();
      notification.close();
      client.update("alerts", alert.id, (row) => {
        row.status = "Triggered";
        row.triggeredAt = null;
      });
    };

    return notification;
  }
}
