import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import { useEffect } from "react";
import posthog from "posthog-js";
import { nanoid } from "nanoid";

import { type TriplitEntity, client, reportSyncError } from "../triplit";
import { type TProduct, useProductsByIds } from "./products";
import { useUserId } from "../context/auth";
import { captureEvent } from "../context/ph";
import { queryClient } from "../context/query";

// PAGES
const pagesQuery = client.query("pages").order("idx", "ASC").build();

export const pagesQueryOptions = () =>
  queryOptions({
    queryKey: ["pages"],
    queryFn: () => client.fetch(pagesQuery),
  });

export const usePagesSuspended = () => useSuspenseQuery(pagesQueryOptions());

export const usePageSuspended = (pageId: string) => {
  const pages = usePagesSuspended();
  return pages.data?.find((p) => p.id === pageId);
};

export const usePage = (pageId: string) =>
  useQuery({
    ...pagesQueryOptions(),
    select: (data) => data.find((p) => p.id === pageId),
  });

export const useUpdatePage = () => {
  const qry = useQueryClient();
  return useMutation({
    networkMode: "always",
    mutationKey: ["pages"],
    mutationFn: async (arg: {
      id: string;
      updaterFn: (page: TriplitEntity<"pages">) => void;
    }) => client.update("pages", arg.id, arg.updaterFn),
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pages"] });
      reportSyncError(trx?.txId, "pages");
    },
  });
};

export const useAppendNewPage = () => {
  const qry = useQueryClient();
  const userId = useUserId();

  return useMutation({
    mutationKey: ["pages"],
    mutationFn: async (p: { page: string }) => {
      const existing =
        qry.getQueryData<TriplitEntity<"pages">[]>(["pages"]) ?? [];
      const highestIdx = existing[existing.length - 1]?.idx;
      const idx = existing.length ? highestIdx + 1 : 1;

      const trx = await client.insert("pages", {
        id: nanoid(),
        page: p.page,
        idx,
        userId: userId,
      });

      posthog.capture("page_created", {
        initial_page: false,
        page_name: p.page,
        idx,
      });
      return trx;
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pages"] });
      reportSyncError(trx?.txId, "pages", "insert");
    },
  });
};

export const useDeletePage = () => {
  const qry = useQueryClient();
  return useMutation({
    mutationKey: ["pages"],
    mutationFn: async (id: string) => {
      return client.transact(async (tx) => {
        const products = await tx.fetch(
          client.query("pageProducts").where("pageId", "=", id).build(),
        );
        return Promise.all([
          ...products.map((x) => tx.delete("pageProducts", x.id)),
          tx.delete("pages", id),
        ]);
      });
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pages"] });
      reportSyncError(trx?.txId, "pages");
    },
  });
};

export const useActivePageId = () => useParams({ from: "/app/market/$id" }).id;

export const useOptionalActivePageId = () => useParams({ strict: false }).id;

export const useSyncPages = () => {
  useEffect(() => {
    return client.subscribeBackground(pagesQuery);
  }, []);
};

// PAGE PRODUCTS

export function pageProductsQueryKey(userId?: string, pageId?: string) {
  return ["pageProducts", userId, pageId];
}

export function getPageProducts(
  userId?: string,
  pageId?: string,
): TriplitEntity<"pageProducts">[] | undefined {
  return queryClient.getQueryData(pageProductsQueryKey(userId, pageId));
}

export const pageProductsQueryOptions = (userId?: string, pageId?: string) =>
  queryOptions({
    enabled: !!pageId,
    queryKey: pageProductsQueryKey(userId, pageId),
    queryFn: () =>
      client.fetch(
        client
          .query("pageProducts")
          .where([
            ["userId", "=", userId ?? ""],
            ["pageId", "=", pageId ?? ""],
          ])
          .order("idx", "ASC")
          .build(),
      ),
  });

export const usePageProductsSuspended = (pageId?: string) => {
  const userId = useUserId();
  return useSuspenseQuery(pageProductsQueryOptions(userId, pageId));
};

export const usePageProducts = (pageId?: string) => {
  const userId = useUserId();
  return useQuery({
    enabled: !!pageId,
    ...pageProductsQueryOptions(userId, pageId ?? ""),
  });
};

export type TPageProductWithInfo = TProduct & TriplitEntity<"pageProducts">;

export const usePageProductsWithInfo = (
  pageId?: string,
): TPageProductWithInfo[] => {
  const products = usePageProducts(pageId);
  const pageProducts = products.data ?? [];
  const ids = pageProducts.map((p) => p.productId).filter(Boolean);
  const allProducts = useProductsByIds(ids);
  return pageProducts
    .map((x) => {
      const info = allProducts.data?.find((p) => p.id === x.productId);
      if (!info) return null;
      return { ...info, ...x };
    })
    .filter(Boolean);
};

export const usePageProduct = (pageId: string, productId: string) => {
  const userId = useUserId();
  return useQuery({
    enabled: !!pageId,
    ...pageProductsQueryOptions(userId, pageId ?? ""),
    select: (data) => data.find((p) => p.id === productId),
  });
};

export const useInsertPageProducts = () => {
  const qry = useQueryClient();
  return useMutation({
    mutationKey: ["pageProducts"],
    mutationFn: (product: TriplitEntity<"pageProducts">[]) => {
      return client.transact((tx) =>
        Promise.all(product.map((p) => tx.insert("pageProducts", p))),
      );
    },
    onSuccess: (_, products) => {
      products.forEach((p) =>
        captureEvent("inserted_column", {
          type: p.columnType,
          pageId: p.pageId,
          productId: p.productId,
        }),
      );
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pageProducts"] });
      reportSyncError(trx?.txId, "pageProducts", "insert");
    },
  });
};

export const useUpdatePageProducts = () => {
  const qry = useQueryClient();
  return useMutation({
    networkMode: "always",
    mutationKey: ["pageProducts", "update"],
    mutationFn: async (arg: {
      id: string;
      updaterFn: (page: TriplitEntity<"pageProducts">) => void;
    }) => client.update("pageProducts", arg.id, arg.updaterFn),
    onSettled: (trx, _, arg) => {
      qry.invalidateQueries({ queryKey: ["pageProducts"] });
      reportSyncError(trx?.txId, "pageProducts");
    },
  });
};

export const useDeletePageProducts = () => {
  const qry = useQueryClient();
  return useMutation({
    mutationKey: ["pageProducts"],
    mutationFn: async (id: string) => {
      return client.transact(async (tx) => {
        const products = await tx.fetch(
          client.query("pageProducts").where("pageId", "=", id).build(),
        );
        return Promise.all([
          ...products.map((x) => tx.delete("pageProducts", x.id)),
          tx.delete("pageProducts", id),
        ]);
      });
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pageProducts"] });
      reportSyncError(trx?.txId, "pageProducts");
    },
  });
};
