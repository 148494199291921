import type { useApolloClient } from "@apollo/client";
import type { Column } from "ag-grid-community";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import type {
  ProductFragmentGridFragment,
  Product_Artis_Type_Enum,
  User_Right_Enum,
} from "../__generated__/gql/graphql";
import type { TPageProduct } from "../triplit/schema";
import { defaultCurvesUom, exchangeCurvesUom } from "./market-grid/uomData";
import { pointerWithin, rectIntersection } from "@dnd-kit/core";
import type { TColumnHeaderProps } from "./market-grid/components";
import {
  relativeRowToRowId,
  relativeRowToRowType,
  rowStringToCode,
} from "./market-grid/periodHelpers";

dayjs.extend(utc);
dayjs.extend(timezone);

export function colParams(
  column: Column | null | undefined,
): TColumnHeaderProps | undefined {
  if (!column) return undefined;
  return column?.getUserProvidedColDef()?.headerComponentParams;
}

export function resolveUom(
  artisType: Product_Artis_Type_Enum,
  source: string,
  productId: string,
  selector: string,
  cellLabel: string,
) {
  const cannedOrSourced = ["canned", "sourced"].includes(artisType);

  if (selector === "fv") {
    return cannedOrSourced
      ? (exchangeCurvesUom[source]?.[productId]?.[selector] ?? cellLabel)
      : cellLabel;
  }
  return cannedOrSourced
    ? (defaultCurvesUom[selector] ?? cellLabel)
    : cellLabel;
}

export function uomToLabel({
  artisType,
  source,
  productId,
  selector,
  cellLabel,
}: {
  artisType: Product_Artis_Type_Enum;
  source: string;
  productId: string;
  selector: string;
  cellLabel: string;
}) {
  const resolvedUom = resolveUom(
    artisType,
    source,
    productId,
    selector,
    cellLabel,
  );

  const parsedUom = () => {
    switch (resolvedUom) {
      case "usd_bbl":
        return "$/bbl";
      case "usc_bbl":
        return "c/bbl";
      case "usd_ton":
        return "$/ton";
      case "usd_gal":
        return "$/gal";
      case "usc_gal":
        return "c/gal";
      case "percent":
        return "%";
      case "usd_thousands":
        return "$'000s";
      case "kg_m3":
        return "kg/m³";
      case "days":
        return "days";
      case "usd_day":
        return "$/day";
      case "usd_mmbtu":
        return "$/mmbtu";
      case "usdmm":
        return "$mm";
      case "p_thm":
        return "p/thm";
      case "none":
        return "Misc";
      case "ws_and_usd_ton":
        return "ws & $/ton";
      default:
        return resolvedUom;
    }
  };

  return parsedUom()?.replace(/000S/, "000s") ?? selector;
}

export function defaultFieldNameSelector(artisType: Product_Artis_Type_Enum) {
  switch (artisType) {
    case "eod":
    case "customer_curve":
      return "value";
    default:
      return "fv";
  }
}

export function fieldNameSelectorToLabel(fieldName: string) {
  return fieldName.replace("-", " ").toUpperCase();
}

export function londonDate() {
  const londonDateTime = dayjs().tz("Europe/London");
  const date = dayjs(londonDateTime).subtract(1, "hour");
  return date;
}

export function productName({
  name,
  description,
  packageByPackage,
}: {
  name: string;
  description?: string | null;
  packageByPackage: { name: string };
}) {
  const displayName = name === description ? name : `${name} (${description})`;
  return `${displayName}: ${packageByPackage.name}`;
}

export function productNameComparator(a: string, b: string) {
  const [a1, a2] = a.split(":");
  const [b1, b2] = b.split(":");
  const majorCompare = a1.localeCompare(b1);
  const minorCompare = a2.localeCompare(b2);
  return majorCompare === 0 ? minorCompare : majorCompare;
}

export function sortByStringCaseInsensitive<T>(k: keyof T, coll: T[]) {
  return coll.sort((a, b) => {
    const aLower = (a[k] || "").toString().toLowerCase();
    const bLower = (b[k] || "").toString().toLowerCase();
    return aLower.localeCompare(bLower);
  });
}

export function compareStrings(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

// *
// * Extracts dependencies from a set of product IDs
// *
// * @param products - a dictionary of products with product_configs containing formulas
// * @param lookupIds - The set of product IDs to extract dependencies from
// * @returns The set of unique dependencies
// *
export function extractFormulaDependencies(
  products: {
    [key: string]: { product_configs: { formula: string | null }[] };
  },
  lookupIds: Set<string>,
): Set<string> {
  const formulaPattern = /\[(.*?)\]/g;

  // The function to extract unique dependencies from a given set of product IDs
  function deps(lookupIds: Set<string>): Set<string> {
    const result = new Set<string>();

    for (const id of lookupIds) {
      const product = products[id];

      // Safely skip if the product does not exist or has no configurations
      if (!product || !product.product_configs) continue;

      // Process each configuration
      for (const config of product.product_configs) {
        const { formula } = config;

        if (!formula) continue;

        let match: RegExpExecArray | null;
        while (true) {
          match = formulaPattern.exec(formula);
          if (match === null) break;
          result.add(match[1]);
        }
      }
    }

    return result;
  }

  const dependencies = new Set(
    [...lookupIds].filter((id) =>
      Object.prototype.hasOwnProperty.call(products, id),
    ),
  );
  let toExplore = new Set([...lookupIds]);

  while (toExplore.size > 0) {
    const nextToExplore = deps(toExplore);
    toExplore = new Set<string>();

    for (const id of nextToExplore) {
      if (!dependencies.has(id)) {
        toExplore.add(id);
        dependencies.add(id);
      }
    }
  }

  return dependencies;
}

export function insert<T>(coll: T[], index: number, element: T): T[] {
  return [...coll.slice(0, index), element, ...coll.slice(index)];
}

export function isListOnlyPermissions(
  permissions:
    | ProductFragmentGridFragment["packageByPackage"]["permissions"]
    | undefined
    | null,
) {
  return permissions?.length === 1 && permissions[0].permission === "list";
}

export function isGivenPermission(
  permissions:
    | ProductFragmentGridFragment["packageByPackage"]["permissions"]
    | undefined
    | null,
  permission: User_Right_Enum,
) {
  return permissions?.find((entry) => entry.permission === permission);
}

export function evictNoLongerPermissionedProductsFromCache({
  apolloClient,
  pageProducts,
  products,
}: {
  apolloClient: ReturnType<typeof useApolloClient>;
  pageProducts: TPageProduct[];
  products: readonly ProductFragmentGridFragment[] | undefined | null;
}) {
  for (const pageProduct of pageProducts) {
    const productId = pageProduct.productId;
    const isPermissioned = products?.find(
      (product) => product.id === productId,
    );

    if (!isPermissioned) {
      apolloClient.cache.evict({
        id: `product:${productId}`,
      });
    }
  }
}

export function collisionDetection(args: Parameters<typeof pointerWithin>[0]) {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = pointerWithin(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return rectIntersection(args);
}

export function parseLimitRef(limitRef: string) {
  try {
    const parts = limitRef.split(":");
    const relativeRow = parts[3];
    const fieldSelector = parts[2];
    const period = relativeRowToRowId[relativeRow];
    const type = relativeRowToRowType(relativeRow);

    if (!period) {
      return { columnId: null, rowId: null };
    }

    return {
      columnId: parts[0],
      rowId: rowStringToCode(period),
      productId: parts[1],
      fieldSelector,
      rowType: type,
    } as const;
  } catch (e) {
    console.error("Failed to parse limit ref", limitRef, e);
    return { columnId: null, rowId: null };
  }
}

export const tryParse = <T>(value: string | null | undefined, def: T): T => {
  if (!value) return def;
  try {
    return JSON.parse(value) as T;
  } catch {
    return def;
  }
};

export function validHexChars(c: string[], alpha = false) {
  const length = alpha ? 8 : 6;
  const chars = c?.length > 0 && c[0] === "#" ? c.slice(1) : c;
  return (
    chars.every((char) => /[0-9a-fA-F]/.test(char)) && chars.length <= length
  );
}

export const compareIdx = <T extends { idx?: string }>(a: T, b: T) => {
  const aIdx = a.idx || "0";
  const bIdx = b.idx || "0";
  if (aIdx === bIdx) return 0;
  return aIdx < bIdx ? -1 : 1;
};
