import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { atom, useAtom, useAtomValue, useStore } from "jotai";
import { useMemo, useEffect } from "react";

import {
  client,
  reportSyncError,
  type TriplitEntity,
} from "../../triplit/triplit";
import { initGridSettings } from "../market-grid/defaultSettings";
import { calcWorker } from "../calculations-worker/hooks";
import { gridSettingsWidth } from "../globals";
import { useUserId } from "../../context/auth";
import { tryParse } from "../utils";
import { captureEvent } from "../../context/ph";

const getGridSettingsQuery = (userId: string) =>
  queryOptions({
    queryKey: ["gridSettings"],
    queryFn: () => client.fetchById("gridSettings", userId),
  });

export const useGridSettings = (): TriplitEntity<"gridSettings"> => {
  const settings = useQuery(getGridSettingsQuery(useUserId()));
  return { id: "", ...initGridSettings, ...settings.data };
};

export const useSelectGridSettings = <T,>(
  select: (x: TriplitEntity<"gridSettings">) => T,
) => {
  const selected = useQuery({
    ...getGridSettingsQuery(useUserId()),
    select: (x) => (x ? select(x) : undefined),
  });
  return selected.data ?? select({ id: "", ...initGridSettings });
};

export const gridSettingsAtom = atom<TriplitEntity<"gridSettings">>({
  id: "",
  ...initGridSettings,
});
gridSettingsAtom.debugLabel = "gridSettingsAtom";

export const useUpdateGridSettings = () => {
  const qry = useQueryClient();
  return useMutation({
    networkMode: "always",
    mutationKey: ["gridSettings"],
    mutationFn: async (arg: {
      id: string;
      updaterFn: (page: TriplitEntity<"gridSettings">) => void;
    }) => client.update("gridSettings", arg.id, arg.updaterFn),
    onSuccess: () => captureEvent("changed_settings"),
    onError: (err) => {
      console.error(err);
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["gridSettings"] });
      reportSyncError(trx?.txId, "gridSettings");
    },
  });
};

export const useGridSettingsStatusMap = () => {
  const statusMapStr = useSelectGridSettings((x) => x.statusMap);
  return useMemo(
    () => tryParse<Record<string, string>>(statusMapStr, {}),
    [statusMapStr],
  );
};

export const useGridSettingsIncrementMap = () => {
  const incrementMapStr = useSelectGridSettings((x) => x.incrementMap);
  return useMemo(
    () => tryParse<Record<string, number>>(incrementMapStr, {}),
    [incrementMapStr],
  );
};

export const eodDateAtom = atom<string | null>(null);
eodDateAtom.debugLabel = "eodDateAtom";
export const useGridSettingsEodDate = () => useAtomValue(eodDateAtom);

export const gridSettingsVisibleAtom = atom<boolean>(false);
gridSettingsVisibleAtom.debugLabel = "gridSettingsVisibleAtom";
export const useGridSettingsVisible = () => useAtom(gridSettingsVisibleAtom);

export const gridSettingsWidthAtom = atom((get) =>
  get(gridSettingsVisibleAtom) ? gridSettingsWidth : 0,
);
gridSettingsWidthAtom.debugLabel = "gridSettingsWidthAtom";
export const useGridSettingsWidth = () => useAtomValue(gridSettingsWidthAtom);

export const SyncGridSettings = () => {
  const eodDate = useSelectGridSettings((x) => x.eodDate);
  const store = useStore();

  useEffect(() => {
    const getPriorEodDate = calcWorker?.()?.proxy?.getPriorEodDate;
    if (eodDate !== "prior" || !getPriorEodDate) {
      store.set(eodDateAtom, eodDate ?? null);
      return;
    }
    getPriorEodDate().then((date) => {
      return store.set(eodDateAtom, date);
    });
  }, [store, eodDate]);

  return null;
};
