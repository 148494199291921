import { requestDockviewModal } from "../modals/modalComponents";
import { useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsAlerts = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();

  return (ctx) => {
    if (!ctx.isProduct || ctx.isHeaderMenu) return [];
    return [
      {
        name: "Alerts",
        disabled:
          ctx.rangeSelection.some((r) => r.rowId.startsWith("Adhoc")) ||
          disabledFeatures?.priceAlerts,
        tooltip: disabledFeatures?.priceAlerts
          ? "Please upgrade your subscription to access this feature"
          : undefined,
        icon: getIcon("alerts", "var(--context-menu-red)"),
        subMenu: [
          {
            name: "Add Alert",
            disabled: ctx.rangeSelection?.length > 1,
            tooltip:
              ctx.rangeSelection?.length > 1
                ? "Select a single cell to add an alert"
                : undefined,
            action: () => {
              requestDockviewModal({
                parentPanel: "alerts",
                currentTab: "alert-add",
                params: {
                  width: 700,
                  height: 470,
                  selectedRange: ctx.selectedRange,
                  type: "range",
                },
              });
            },
          },
          {
            name: "Manage",
            action: () => {
              requestDockviewModal({
                parentPanel: "alerts",
                currentTab: "alert-manage",
                params: {
                  width: 700,
                  height: 470,
                  selectedRange: ctx.selectedRange,
                  type: "range",
                },
              });
            },
          },
        ],
      },
    ];
  };
};
