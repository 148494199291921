import type { ExcelCell, IMenuActionParams } from "ag-grid-community";

import { defaultSelector } from "../copyRangeSelectionHelpers";
import type { TColumnHeaderProps } from "../components";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsExportData = (): GetContextMenuSection => {
  return (ctx) => {
    return [
      {
        name: "Export Page",
        icon: getIcon("export"),
        action: (params) => exportToExcelWithMetadata(params),
      },
    ];
  };
};

export function exportToExcelWithMetadata(params: IMenuActionParams) {
  const api = params.api;
  if (!api) throw new Error("No grid api provided");

  const columnDefs = api?.getColumns()?.map((column) => column?.getColDef());

  const headers = columnDefs?.map((column) => {
    const columnParams: TColumnHeaderProps = column.headerComponentParams;
    const selector = defaultSelector(columnParams?.artisType || "");

    return {
      packageName: column.headerName,
      ...columnParams,
      selector,
    };
  });

  const data = headers?.map((header) => {
    const labels = header.columnLabels;

    return {
      name: header?.packageName,
      description: labels?.commodityGroup,
      productId: header?.productId,
      package: labels?.package,
      uom: labels?.uom,
      selector:
        header?.packageName?.toLowerCase() === "month"
          ? undefined
          : header?.selector,
    };
  });

  const names =
    data?.map((header) => cell(header?.name?.toString() || "")) || [];
  const descriptions =
    data?.map((header) => cell(header?.description?.toString() || "")) || [];
  const productIds =
    data?.map((header) => cell(header?.productId?.toString() || "")) || [];
  const packages =
    data?.map((header) => cell(header?.package?.toString() || "")) || [];
  const uoms = data?.map((header) => cell(header?.uom?.toString() || "")) || [];
  const fieldSelectors =
    data?.map((header) => cell(header?.selector?.toString() || "")) || [];

  const content = [
    names,
    descriptions,
    productIds,
    packages,
    uoms,
    fieldSelectors,
  ].map((row) => {
    return { cells: row };
  });

  api?.exportDataAsExcel({
    skipColumnHeaders: true,
    skipColumnGroupHeaders: true,
    prependContent: content,
  });
}

function cell(value: string): ExcelCell {
  return { data: { value, type: "String" } };
}
