import {
  Box,
  Stack,
  Table,
  Checkbox,
  Input,
  Option,
  Select,
  Typography,
  Button,
  Tooltip,
} from "@mui/joy";
import { unique } from "remeda";
import { useActivePageId, useOptionalActivePageId } from "../../../../data";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import { GridModalSelectionInfo } from "../modalComponents";
import { useAtomValue } from "jotai";
import { FaTimes, FaTrash } from "react-icons/fa";
import { PiArrowFatUpFill, PiArrowFatDownFill } from "react-icons/pi";
import { PointerIcon } from "../../../icons";
import { ColorPicker } from "../../../grid-settings/ColorPicker";
import { v4 as uuidv4 } from "uuid";
import { gridModalDockviewAtom } from "../modalAtoms";
import {
  useFieldArray,
  useForm,
  useWatch,
  type UseFormReturn,
  Controller,
} from "react-hook-form";
import {
  type TConditionalRule,
  ruleValidation,
  useResetLimitSelection,
  getNodePeriod,
} from "./helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth0 } from "@auth0/auth0-react";
import {
  relativeRowToRowId,
  rowIdToRelativeRow,
  rowStringToCode,
} from "../../periodHelpers";
import { useState } from "react";
import { generateNKeysBetween } from "fractional-indexing";
import type { TConditionalFormattingRules } from "../../../../triplit/schema";
import toast from "react-hot-toast";
import { useGrid } from "../../stores";
import type { GridApi } from "ag-grid-community";
import {
  ConditionalFormattingHeader,
  conditionalFormattingTableStyle,
} from "./components";
import {
  type TConditionalFormattingRulesGrouped,
  useDeleteConditionalFormattingRules,
  useQueryConditionalFormattingRules,
  useQueryConditionalFormattingRulesGrouped,
  useUpdateConditionalFormattingRules,
} from "./hooks";
import { useThemeMode } from "../../../../context/theme";
import { getColumnPeriods } from "./utils";

export type TConditionalRuleFormatted = TConditionalRule & {
  ids?: Array<string>;
};

function formatRules(
  gridApi: GridApi | null,
  rules: TConditionalFormattingRulesGrouped,
  filter: "page" | "range",
  cells: { columnId: string; rowId: string }[],
) {
  if (!gridApi || !rules) return [];

  const formattingRules = Object.values(rules).flat();

  const formatted = formattingRules
    .map((rule) => {
      if (filter === "range") {
        const isInSelectedCells = cells.some(
          (cell) =>
            cell.columnId === rule.columnId && cell.rowId === rule.rowId,
        );
        if (!isInSelectedCells) {
          return null;
        }
      }

      const isLimitRef = rule.limit.includes(":");
      const parts = isLimitRef ? rule.limit.split(":") : undefined;

      const limitRef = parts
        ? {
            columnId: parts[0],
            rowId: parts[1],
            name: gridApi.getColumn(parts[0])?.getColDef()?.headerName || "",
            period: getNodePeriod(
              gridApi.getRowNode(rowStringToCode(relativeRowToRowId[parts[1]])),
            ),
          }
        : undefined;

      return {
        id: rule.id,
        rule: rule.rule as TConditionalRule["rule"],
        limit: isLimitRef ? "" : rule.limit,
        limitRef,
        formatting: {
          bgColor: rule.bgColor,
          boldText: rule.boldText,
          invertTextColor: rule.invertTextColor,
        },
        note: rule.note,
        stopIfTrue: rule.stopIfTrue,
        columnId: rule.columnId,
        rowId: rule.rowId,
      } as TConditionalRule;
    })
    .filter(Boolean);

  const grouped = formatted.reduce((acc: TConditionalRuleFormatted[], rule) => {
    const match = acc.find(
      (item) =>
        item.rule === rule.rule &&
        item.limit === rule.limit &&
        ((item.limitRef &&
          rule.limitRef &&
          item.limitRef.columnId === rule.limitRef.columnId &&
          item.limitRef.rowId === rule.limitRef.rowId) ||
          (!item.limitRef && !rule.limitRef)) &&
        item.formatting.bgColor === rule.formatting.bgColor &&
        item.formatting.boldText === rule.formatting.boldText &&
        item.formatting.invertTextColor === rule.formatting.invertTextColor &&
        item.note === rule.note &&
        item.stopIfTrue === rule.stopIfTrue,
    );

    if (match?.ids) {
      match.ids.push(rule.id);
    } else {
      const newRule: TConditionalRuleFormatted = {
        ...rule,
        ids: [rule.id],
      };
      acc.push(newRule);
    }

    return acc;
  }, []);

  const groupedWithId = grouped.map((rule) => ({
    ...rule,
    _id: uuidv4(),
  }));

  return groupedWithId;
}

export function ManageConditionalFormattingRow({
  form,
  rule,
  index,
  selectedRules,
  handleSelect,
}: {
  groupedRules?: TConditionalRuleFormatted[];
  form: UseFormReturn<{ rules: TConditionalRule[] & { ids?: Array<string> } }>;
  rule: TConditionalRule;
  index: number;
  selectedRules: Set<string>;
  handleSelect: (_id: string, selected: boolean) => void;
}) {
  const pageId = useOptionalActivePageId();
  const [gridApi] = useGrid();
  const groupedFormattingRules = useQueryConditionalFormattingRulesGrouped(
    pageId ?? "",
  );
  const conditionalFormattingRules = groupedFormattingRules.data ?? {};
  const [showColorPicker, setShowColorPicker] = useState(false);

  const { register, control } = form;
  const output = useWatch({
    control,
    name: "rules",
  });
  const columnPeriods = getColumnPeriods(
    gridApi,
    conditionalFormattingRules,
    rule,
  );

  const affectedString = unique(
    columnPeriods.map((period) => period.string),
  ).join(", ");

  const resetLimitSelection = useResetLimitSelection();

  const mode = useThemeMode();

  const items = {
    select: (
      <td key="select">
        <Checkbox
          checked={selectedRules.has(rule._id)}
          onChange={(e) => handleSelect(rule._id, e.target.checked)}
          size="lg"
          sx={{
            "& .MuiCheckbox-checkbox": {
              width: "22px",
              height: "22px",
              borderRadius: "50%",
            },
            "& svg": {
              fontSize: "22px",
            },
          }}
        />
      </td>
    ),
    rule: (
      <td
        key="rule"
        style={{
          width: "100px",
        }}
      >
        <Controller
          name={`rules.${index}.rule`}
          control={control}
          render={({ field }) => (
            <Select
              sx={{
                height: "30px",
              }}
              value={field.value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              size="sm"
            >
              <Option value={"equals"}>Equals</Option>
              <Option value={"isNotEqual"}>Is not equal</Option>
              <Option value={"lessThan"}>Less than</Option>
              <Option value={"moreThan"}>More than</Option>
              <Option value={"lessThanOrEqual"}>Less than or equal</Option>
              <Option value={"moreThanOrEqual"}>More than or equal</Option>
            </Select>
          )}
        />
      </td>
    ),
    limit: (
      <td
        key="limit"
        style={{
          width: "88px",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Stack flexDirection={"row"} gap={1}>
          {output[index]?.limitRef?.name ? (
            <Stack
              flexDirection={"row"}
              gap={1}
              overflow="hidden"
              width="100%"
              justifyContent={"space-between"}
            >
              <Controller
                name={`rules.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px",
                      border: (theme) =>
                        `1px solid ${theme.palette.neutral.outlinedBorder}`,
                      padding: "4px 8px",
                      paddingRight: "0px",
                      borderRadius: "sm",
                      width: "fit-content",
                      gap: "8px",
                      backgroundColor: (theme) =>
                        theme.palette.background.surface,
                      maxWidth: "106px",
                    }}
                  >
                    <Tooltip
                      title={`${output[index]?.limitRef?.name}[${output[index]?.limitRef?.period}]`}
                      placement="top-start"
                      arrow
                    >
                      <Typography
                        fontSize="xs"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {output[index]?.limitRef?.name}[
                        {output[index]?.limitRef?.period}]
                      </Typography>
                    </Tooltip>

                    <Button
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={() => {
                        field.onChange(undefined);
                      }}
                      sx={{
                        minWidth: "auto",
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </Box>
                )}
              />
            </Stack>
          ) : (
            <>
              <Input
                {...register(`rules.${index}.limit`)}
                type="number"
                size="sm"
                sx={{
                  height: "30px",
                  gap: "10px",
                  width: "49px",
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
              />
              <Controller
                name={`rules.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    sx={{
                      display: "flex",
                      minWidth: "49px",
                      height: "30px",
                      padding: "8px 16px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    onClick={() => {
                      if (
                        gridApi?.getGridOption("rowClass") ===
                        "ag-cell-selection"
                      ) {
                        resetLimitSelection();
                        return;
                      }

                      gridApi?.setGridOption("onCellClicked", (e) => {
                        resetLimitSelection();

                        const columnId = e.column.getColId();
                        const name = e.colDef.headerName;
                        const period = getNodePeriod(e.node);
                        const rowId = period
                          ? rowIdToRelativeRow[period]
                          : e.node.id;

                        field.onChange({
                          columnId,
                          rowId,
                          name,
                          period,
                        });
                      });
                      document.querySelectorAll(".ag-row").forEach((cell) => {
                        cell.classList.add("ag-cell-selection");
                      });
                    }}
                  >
                    <PointerIcon mode={mode} />
                  </Button>
                )}
              />
            </>
          )}
        </Stack>
      </td>
    ),
    formatting: (
      <td
        key="formatting"
        style={{
          width: "65px",
        }}
      >
        <Controller
          name={`rules.${index}.formatting`}
          control={control}
          render={({ field }) => (
            <ColorPicker
              show={showColorPicker}
              setShow={setShowColorPicker}
              onChange={(newValue) => {
                field.onChange({
                  bgColor: newValue.color,
                  boldText: newValue.boldText,
                  invertTextColor: newValue.invertTextColor,
                });
              }}
              value={{
                color: output[index].formatting.bgColor,
                boldText: output[index].formatting.boldText,
                invertTextColor: output[index].formatting.invertTextColor,
              }}
              showSwatches
              showTextOptions
              width={"73px"}
              height={"26px"}
            />
          )}
        />
      </td>
    ),
    affected: (
      <td key="affected" width="90px">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "30px",
            border: (theme) =>
              `1px solid ${theme.palette.neutral.outlinedBorder}`,
            padding: "4px 8px",
            paddingRight: "0px",
            borderRadius: "sm",
            gap: "8px",
            backgroundColor: (theme) => theme.palette.background.surface,
          }}
        >
          <Tooltip title={affectedString} placement="top-start" arrow>
            <Typography
              fontSize="xs"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                paddingRight: "5px",
              }}
            >
              {affectedString}
            </Typography>
          </Tooltip>
        </Box>
      </td>
    ),
    note: (
      <td key="note" width="70px">
        <Input
          sx={{
            height: "30px",
            width: "70px",
          }}
          {...register(`rules.${index}.note`)}
          size="sm"
        />
      </td>
    ),
    stopIfTrue: (
      <td
        key="stopIfTrue"
        width="60px"
        style={{
          paddingLeft: "0px",
        }}
      >
        <Controller
          name={`rules.${index}.stopIfTrue`}
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={output[index].stopIfTrue}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              size="lg"
              sx={{
                float: "left",
                paddingBottom: "3.5px",
                "& .MuiCheckbox-checkbox": {
                  width: "25px",
                  height: "25px",
                },
                "& svg": {
                  fontSize: "25px",
                },
              }}
            />
          )}
        />
      </td>
    ),
  };
  const gridItems = [
    items.select,
    items.affected,
    undefined,
    items.rule,
    items.limit,
    items.formatting,
    items.note,
    items.stopIfTrue,
  ];

  return <tr key={rule._id}>{gridItems}</tr>;
}

export function ManageConditionalFormattingRules() {
  const pageId = useActivePageId();
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.conditional?.selectedRange || [];
  const rules = useQueryConditionalFormattingRules(pageId);
  const groupedRules = useQueryConditionalFormattingRulesGrouped(pageId);
  const updateRule = useUpdateConditionalFormattingRules();
  const deleteRule = useDeleteConditionalFormattingRules();
  const conditionalFormattingRules = groupedRules.data ?? {};
  const [selectedRules, setSelectedRules] = useState<Set<string>>(new Set());
  const [rulesToShow, setRulesToShow] = useState<"page" | "range">("range");
  const [gridApi] = useGrid();
  const handleSelect = (_id: string, selected: boolean) => {
    setSelectedRules((prevSelected) => {
      const newSelected = new Set(prevSelected);
      selected ? newSelected.add(_id) : newSelected.delete(_id);
      return newSelected;
    });
  };

  const handleGoToSelected = () => {
    if (selectedRules.size !== 1) return;

    const selectedId = Array.from(selectedRules)[0];
    const selectedRule = fields.find((field) => field._id === selectedId);

    if (selectedRule) {
      const columnPeriods = getColumnPeriods(
        gridApi,
        conditionalFormattingRules,
        selectedRule,
      );

      const firstPeriod = columnPeriods[0]?.periods?.[0];
      const columnId = columnPeriods[0]?.columnId;
      const rowId = firstPeriod ? rowStringToCode(firstPeriod) : null;

      if (rowId && columnId && gridApi) {
        const rowNode = gridApi.getRowNode(rowId);

        if (rowNode && rowNode.rowIndex !== null) {
          rowNode.setSelected(true, true);

          gridApi.ensureIndexVisible(rowNode.rowIndex);
          gridApi.ensureColumnVisible(columnId);
          gridApi.setFocusedCell(rowNode.rowIndex, columnId);
        }
      }
    }
  };

  const handleDeleteSelected = () => {
    const indicesToRemove = fields
      .map((field, index) => ({ id: field._id, index }))
      .filter(({ id }) => selectedRules.has(id))
      .map(({ index }) => index);

    indicesToRemove.sort((a, b) => b - a).forEach((index) => remove(index));

    setSelectedRules(new Set());
  };
  const cells =
    rangeSelection
      .flatMap((range) => {
        return range.map((cell) => {
          const rowNode = gridApi?.getRowNode(cell.rowId);
          const period = getNodePeriod(rowNode);
          const rowId = period ? rowIdToRelativeRow[period] : rowNode?.id;

          if (rowId) {
            return {
              columnId: cell.columnId,
              rowId,
            };
          }
        });
      })
      .filter(Boolean) || [];

  const form = useForm({
    defaultValues: {
      rules: formatRules(
        gridApi,
        conditionalFormattingRules,
        rulesToShow,
        cells,
      ),
    },
    resolver: zodResolver(ruleValidation),
  });

  const { fields, replace, remove } = useFieldArray({
    control: form.control,
    name: "rules",
    keyName: "_fieldId",
  });

  const handleMoveUp = () => {
    if (selectedRules.size === 0) return;

    const selectedIndices = fields
      .map((field, index) => (selectedRules.has(field._id) ? index : -1))
      .filter((index) => index !== -1);

    selectedIndices.sort((a, b) => a - b);

    const newFields = fields.map((field) => ({ ...field }));

    selectedIndices.forEach((index) => {
      if (index > 0 && !selectedRules.has(newFields[index - 1]._id)) {
        [newFields[index - 1], newFields[index]] = [
          newFields[index],
          newFields[index - 1],
        ];
      }
    });

    replace(newFields);
  };

  const handleMoveDown = () => {
    if (selectedRules.size === 0) return;

    const selectedIndices = fields
      .map((field, index) => (selectedRules.has(field._id) ? index : -1))
      .filter((index) => index !== -1);

    selectedIndices.sort((a, b) => b - a);

    const newFields = fields.map((field) => ({ ...field }));

    selectedIndices.forEach((index) => {
      if (
        index < newFields.length - 1 &&
        !selectedRules.has(newFields[index + 1]._id)
      ) {
        [newFields[index], newFields[index + 1]] = [
          newFields[index + 1],
          newFields[index],
        ];
      }
    });

    replace(newFields);
  };

  const formData = useWatch({
    control: form.control,
    name: "rules",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  const canSave = formData.every((rule) => rule.limit || rule.limitRef);

  function filterRulesBy(filter: "page" | "range") {
    setRulesToShow(filter);
    form.reset({
      rules: formatRules(gridApi, conditionalFormattingRules, filter, cells),
    });
  }

  const fieldIds = formData.map((data) => data.id);

  // When rules are grouped, they have an "ids" property. fieldIds would not take this into account. So we need to loop over the fieldIds, check which item in "fields" has an "ids" property with the ID in it, and return an array of TConditionalRuleFormatted with the "ids" property. This is what we will use to update all the grouped rules.
  const formDataGrouped = fieldIds
    .map((id) => {
      const data = formData.find((data) => data.id === id);
      const fieldIds = fields.find((field) => field?.ids?.includes(id))?.ids;
      if (fieldIds && data) {
        return {
          ...data,
          ids: fieldIds,
        };
      }
    })
    .filter(Boolean) satisfies Array<TConditionalRuleFormatted>;

  const resetLimitSelection = useResetLimitSelection();

  async function handleSave() {
    try {
      resetLimitSelection();

      if (!pageId) throw new Error("pageId is not defined");
      if (!userId) throw new Error("userId is not defined");

      const allExistingRules = rules.data || [];
      const cellIds = cells.map((cell) => `${cell.columnId}-${cell.rowId}`);

      const existingRules =
        rulesToShow === "page"
          ? allExistingRules
          : allExistingRules.filter((rule) =>
              cellIds.includes(`${rule.columnId}-${rule.rowId}`),
            );

      if (!formData?.length) {
        const deletes = existingRules.map((rule) =>
          deleteRule.mutateAsync(rule.id),
        );
        await Promise.all(deletes);
      }

      const formIds = formDataGrouped
        .flatMap((data) => data.ids)
        .filter(Boolean);

      const rulesToDelete = existingRules
        .filter((rule) => {
          const ruleId = rule.id;
          const ruleData = formDataGrouped.find((data) =>
            data.ids?.includes(ruleId),
          );
          return !ruleData;
        })
        .map((rule) => rule.id);

      const sortedExistingRules = formIds
        .map((id) => existingRules.find((rule) => rule.id === id))
        .filter(Boolean)
        .filter((rule) => !rulesToDelete.includes(rule.id));

      const priorityIdxs = generateNKeysBetween(
        null,
        null,
        sortedExistingRules.length,
      );

      const updatedRules = sortedExistingRules
        .map((existing, index) => {
          if (
            rulesToShow === "range" &&
            !cellIds.includes(`${existing.columnId}-${existing.rowId}`)
          )
            return;

          const rule = formDataGrouped.find(
            (data) =>
              data?.ids?.includes(existing.id) || data.id === existing.id,
          );

          if (rule) {
            const limit = rule?.limitRef
              ? `${rule.limitRef.columnId}:${rule.limitRef.rowId}`
              : rule.limit;

            const updated = {
              _id: rule._id,
              id: existing.id,
              rule: rule.rule,
              limit,
              priorityIdx: priorityIdxs[index],
              bgColor: rule.formatting.bgColor || undefined,
              boldText: rule.formatting.boldText,
              invertTextColor: rule.formatting.invertTextColor,
              note: rule.note,
              stopIfTrue: rule.stopIfTrue,
              columnId: existing.columnId,
              rowId: existing.rowId || "",
              userId,
              pageId,
            } satisfies TConditionalFormattingRules;

            return updated;
          }
        })
        .filter(Boolean);

      const deletes = rulesToDelete.map((id) => deleteRule.mutateAsync(id));

      const updates = updatedRules
        .map((rule) => {
          return updateRule.mutateAsync({
            id: rule.id,
            updaterFn: (existing) => {
              existing.rule = rule.rule;
              existing.limit = rule.limit;
              existing.priorityIdx = rule.priorityIdx;
              existing.bgColor = rule.bgColor;
              existing.boldText = rule.boldText;
              existing.invertTextColor = rule.invertTextColor;
              existing.note = rule.note;
              existing.stopIfTrue = rule.stopIfTrue;
            },
          });
        })
        .filter(Boolean);

      await Promise.all([...deletes, ...updates]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to save conditional formatting rules");
    }
  }
  return (
    <GridModalContainer
      panel="conditional"
      padding={0}
      body={
        <>
          <Box
            sx={{
              padding: "15px 10px",
            }}
          >
            <Typography textColor="text.primary" sx={{ paddingBottom: "2px" }}>
              Selected Column and Cell
            </Typography>
            <GridModalSelectionInfo selectedRange={rangeSelection} />
          </Box>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box>
              <Select
                value={rulesToShow}
                onChange={(event, value) => {
                  if (value) setRulesToShow(value as "page" | "range");
                  filterRulesBy(value as "page" | "range");
                }}
                size="sm"
                sx={{
                  width: "100px",
                  marginLeft: "15px",
                }}
              >
                <Option value="page">Page</Option>
                <Option value="range">Range</Option>
              </Select>
            </Box>
            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <Box
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    maxHeight: "170px",
                    overflowY: "auto",
                  })}
                >
                  <Table sx={conditionalFormattingTableStyle} borderAxis="x">
                    <ConditionalFormattingHeader type="manage" />
                    <tbody>
                      {fields.map((field, index) => (
                        <ManageConditionalFormattingRow
                          key={field._id}
                          form={form}
                          rule={field}
                          index={index}
                          selectedRules={selectedRules}
                          handleSelect={handleSelect}
                        />
                      ))}
                    </tbody>
                  </Table>
                </Box>
                <Box sx={{ marginLeft: "15px" }}>
                  <Typography>Actions</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Tooltip title="Delete the selected rule(s)">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleDeleteSelected}
                        disabled={selectedRules.size === 0}
                      >
                        <FaTrash />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Go to the referenced grid cell">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleGoToSelected}
                        disabled={selectedRules.size !== 1}
                      >
                        Go To
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move the selected rule(s) higher in priority">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleMoveUp}
                        disabled={selectedRules.size === 0}
                      >
                        <PiArrowFatUpFill style={{ fontSize: "12px" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move the selected rule(s) lower in priority">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleMoveDown}
                        disabled={selectedRules.size === 0}
                      >
                        <PiArrowFatDownFill style={{ fontSize: "12px" }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"conditional"}
          onCancel={() => {
            resetLimitSelection();
          }}
          onSave={() => handleSave()}
          saveDisabled={!canSave}
        />
      }
    />
  );
}
