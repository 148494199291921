import { useUpdateColumnFromCellByValueAndOperation } from "./useUpdateColumnFromCellByValueAndOperation";
import { useUpdateCellByValueAndOperation } from "./useUpdateCellByValueAndOperation";
import { copyWithHeadersAndMonths } from "../copyRangeSelectionHelpers";
import { toggleCellBold } from "../modals/formatCellHelpers";
import type { TGridKeyboardShortcut } from "./utils";

export const useKeyboardShortcuts = () => {
  const updateColumnFromCellByValueAndOperation =
    useUpdateColumnFromCellByValueAndOperation();
  const updateCellsByValueAndOperation = useUpdateCellByValueAndOperation();
  return [
    {
      name: "Increment Column",
      description: "Increment the selected column(s)",
      shortcuts: [
        "ctrl+alt+ArrowUp",
        "cmd+alt+ArrowUp",
        "ctrl+shift+i",
        "cmd+shift+i",
      ],
      action: ({ params }) =>
        updateColumnFromCellByValueAndOperation({ params, operation: "+" }),
    },
    {
      name: "Decrement Column",
      description: "Decrement the selected column(s)",
      shortcuts: [
        "ctrl+alt+ArrowDown",
        "cmd+alt+ArrowDown",
        "ctrl+shift+d",
        "cmd+shift+d",
      ],
      action: ({ params }) =>
        updateColumnFromCellByValueAndOperation({ params, operation: "-" }),
    },
    {
      name: "Increment Cell",
      description: "Increment the selected cell(s)",
      shortcuts: ["ctrl+ArrowUp", "cmd+ArrowUp", "ctrl+i", "cmd+i"],
      action: ({ params }) => {
        updateCellsByValueAndOperation({ params, operation: "+" });
      },
    },
    {
      name: "Decrement Cell",
      description: "Decrement the selected cell(s)",
      shortcuts: ["ctrl+ArrowDown", "cmd+ArrowDown", "ctrl+d", "cmd+d"],
      action: ({ params }) => {
        updateCellsByValueAndOperation({ params, operation: "-" });
      },
    },
    {
      name: "Copy with Headers and Months",
      description: "Copy the selected range with headers and months",
      shortcuts: ["ctrl+shift+c", "cmd+shift+c"],
      action: ({ params }) => copyWithHeadersAndMonths(params),
    },
    {
      name: "Make Cell Bold",
      description: "Format the selected cell to be bold",
      shortcuts: ["ctrl+b", "cmd+b"],
      action: ({ params, pageId }) => toggleCellBold(params.api, pageId || ""),
    },
  ] satisfies TGridKeyboardShortcut[];
};
