import { atom, useSetAtom } from "jotai";
import { useEffect } from "react";

import { parseAdhocSpreadsJSON } from "./timespreadHelpers";
import type { TAdhocSpread } from "./timespreadHelpers";
import { useActivePageId, usePage } from "../../../data";

export const adhocSpreadsAtom = atom<TAdhocSpread>([]);
adhocSpreadsAtom.debugLabel = "adhocSpreadsAtom";

export function useAdhocSpreads() {
  const pageId = useActivePageId();
  const setAdhocSpreads = useSetAtom(adhocSpreadsAtom);
  const page = usePage(pageId);

  const adhocSpreads = parseAdhocSpreadsJSON(page.data?.adhocSpreadsJSON);

  const formatted: Record<
    string,
    Omit<(typeof adhocSpreads)[number], "rowId">
  > = {};

  useEffect(() => {
    if (page) {
      adhocSpreads.map((spread) => {
        formatted[spread.rowId] = {
          from: spread.from,
          to: spread.to,
        };
      });

      setAdhocSpreads(adhocSpreads || []);
    }
  }, [page, setAdhocSpreads, adhocSpreads]);

  return {
    fetching: page.isFetching,
    adhocSpreads,
    formattedAdhocSpreads: formatted,
  };
}
