import { Typography } from "@mui/joy";

export const alertTableStyle = {
  backgroundColor: (theme: { palette: { mode: string } }) =>
    theme.palette.mode === "light"
      ? "var(--artis-light-grey)"
      : "var(--artis-dark-grey)",
  tableLayout: "fixed" as const,
  th: {
    minWidth: 22,
    paddingLeft: "9px",
    paddingRight: "9px",
    textAlign: "left",
  },
  td: {
    minWidth: 22,
    paddingLeft: "9px",
    paddingRight: "9px",
    textAlign: "center",
    paddingBottom: 1.5,
    paddingTop: 1.5,
  },
};

export function AlertHeader({ type }: { type: "create" | "manage" }) {
  const headers = {
    select: (
      <th
        key="select"
        style={{
          width: "50px",
        }}
      >
        <Typography fontSize={"sm"}> </Typography>
      </th>
    ),

    limit: (
      <th
        key="limit"
        style={{
          width: "120px",
          paddingLeft: type === "create" ? "30px" : "10px",
        }}
      >
        <Typography fontSize={"xs"}>Limit</Typography>
      </th>
    ),
    note: (
      <th
        key="note"
        style={{
          width: type === "create" ? "150px" : "130px",
          paddingRight: "20px",
        }}
      >
        <Typography fontSize={"xs"}>Note</Typography>
      </th>
    ),
    actions: (
      <th
        key="actions"
        style={{
          width: type === "create" ? 70 : 120,
          paddingLeft: "20px",
        }}
      >
        <Typography fontSize={"xs"}>Actions</Typography>
      </th>
    ),
    affected: (
      <th key="affected" style={{ width: "110px" }}>
        <Typography fontSize={"xs"}>Affected</Typography>
      </th>
    ),
    status: (
      <th key="status" style={{ width: "60px" }}>
        <Typography fontSize={"xs"}>Status</Typography>
      </th>
    ),
    recuring: (
      <th key="recurring" style={{ width: "60px" }}>
        <Typography fontSize={"xs"}>Recurring</Typography>
      </th>
    ),

    sound: (
      <th key="sound" style={{ width: "60px" }}>
        <Typography fontSize={"xs"}>Sound</Typography>
      </th>
    ),
  };

  const headerItems =
    type === "create"
      ? [
          headers.limit,
          headers.note,
          headers.actions,
          headers.recuring,
          headers.sound,
        ]
      : [
          headers.select,
          headers.affected,
          headers.limit,
          headers.note,
          headers.status,
          headers.recuring,
          headers.sound,
        ];

  return (
    <thead>
      <tr>{headerItems}</tr>
    </thead>
  );
}
