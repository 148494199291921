import { chartWidth } from "../grid-charts/constants";

function renderEmptyChart(mode: "dark" | "light") {
  const fillColor = mode === "dark" ? "#2F2F2F" : "#E3E3E3";
  return (
    <svg
      className="empty-chart-icon"
      width={chartWidth - 1}
      height="200"
      viewBox="0 19 400 200"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transition: "background-color 0.3s ease",
        backgroundColor: mode === "dark" ? "var(--artis-dark-bg)" : "#fafafa",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor =
          mode === "dark" ? "#141528" : "#FAFAFA";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor =
          mode === "dark" ? "var(--artis-dark-bg)" : "#fafafa";
      }}
    >
      <title>Add Chart</title>
      <rect height="100%" fill="#CACACB" />

      <rect x="10" y="10" height="180" fill="white" stroke="#8D9AA6" />

      <rect x="30" y="130" width="35" height="60" fill={fillColor} />
      <rect x="90" y="100" width="35" height="90" fill={fillColor} />
      <rect x="150" y="120" width="35" height="70" fill={fillColor} />
      <rect x="210" y="80" width="35" height="110" fill={fillColor} />
      <rect x="270" y="100" width="35" height="90" fill={fillColor} />
      <rect x="330" y="110" width="35" height="80" fill={fillColor} />

      <line
        x1="20"
        y1="190"
        x2="95%"
        y2="190"
        stroke={fillColor}
        strokeWidth="1.5"
      />

      <line
        x1="20"
        y1="50"
        x2="20"
        y2="190"
        stroke={fillColor}
        strokeWidth="1.5"
      />

      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        fill="#8D9AA6"
        fontSize="16"
        fontWeight="bold"
        dy=".3em"
      />
    </svg>
  );
}

export default renderEmptyChart;
