import { useAtomValue } from "jotai";
import {
  genLiveUpdatesTimeKey,
  liveUpdatesTimeAtom,
} from "./useLivelyUpdateMarketData";

// How long before current candle is considered stale in seconds.
const staleTime = 5;

export function useCurrentCandleStale({
  chartId,
  productId,
  sampleTime,
}: {
  chartId: string;
  productId: string;
  sampleTime: string;
}) {
  const liveTimeKey = genLiveUpdatesTimeKey({ chartId, productId, sampleTime });
  const liveTimeValue = useAtomValue(liveUpdatesTimeAtom);
  const liveTime = liveTimeValue[liveTimeKey];

  if (!liveTime) return true;
  return Date.now() / 1000 - liveTime > staleTime;
}
