import {
  MismatchDirection,
  type ISeriesApi,
  type OhlcData,
  type UTCTimestamp,
} from "lightweight-charts";
import { useIntervalEffect } from "@react-hookz/web";
import { useAuth0 } from "@auth0/auth0-react";
import { atom } from "jotai";
import { isNullish } from "remeda";
import { useState } from "react";
import dayjs from "dayjs";

import {
  sampleRoundingIndex,
  sampleTimeToSeconds,
  validSampleOrFirst,
} from "../utils";
import type { TGridDataRowId } from "../../calculations-worker/sharedStores";
import { monthCodeToOffset, monthStringToCode } from "../../market-grid";

import { calcWorker } from "../../calculations-worker/hooks";
import { parse } from "../../numbers";

import { mergeHistoricalAndLiveData } from "./useMergeHistoricalAndLiveData";
import { fetchQuestData } from "./helpers";
import type { TLiveChart } from "./types";
import { defaultSelector } from "../../market-grid/copyRangeSelectionHelpers";
import { store } from "../../sharedHooks";

type TLiveUpdatesTime = Record<string, number>;
export const liveUpdatesTimeAtom = atom<TLiveUpdatesTime>({});
liveUpdatesTimeAtom.debugLabel = "liveUpdatesTimeAtom";

// to add the tick data to the chart
export function useLivelyUpdateMarketData({
  latestHistoricalCandle,
  chartMetadata,
  chart,
  currentCandleCache,
  setCurrentCandleCache,
  fetchNextPage,
}: {
  latestHistoricalCandle: Partial<OhlcData> | undefined;
  chartMetadata: TLiveChart;
  chart: ISeriesApi<"Custom"> | null;
  currentCandleCache: OhlcData | undefined | null;
  setCurrentCandleCache: React.Dispatch<
    React.SetStateAction<OhlcData | undefined>
  >;
  fetchNextPage: () => void;
}) {
  const { artisType, productId, periodFrom } = chartMetadata;
  const sampleTime = validSampleOrFirst(chartMetadata.sampleTime);

  if (!productId || !sampleTime || !periodFrom) {
    throw new Error("productId and sampleTime are required");
  }
  const worker = calcWorker?.().proxy;

  const rowDate = periodFrom.slice(0, 10); // periodFrom is ISO string - this gets the date part
  const rowCode = monthStringToCode(rowDate);
  const rowIdx = rowCode && monthCodeToOffset(rowCode);
  const rowId = rowIdx && (["mth", rowCode, rowIdx] satisfies TGridDataRowId);
  const [currentCandle, setCurrentCandle] = useState<OhlcData | null>(null);
  const columnId = `chart-${productId}-${periodFrom}-${sampleTime}`;
  async function getGridValue(productId: string) {
    if (!rowId || !artisType) return;
    const data = await worker?.getGridData({
      rowIds: [rowId],
      columns: [
        {
          columnId,
          productId,
          eodId: null,
          artisType,
          hasSharedCell: artisType === "customer_curve",
          selector: defaultSelector(artisType),
          status: "listen",
          isPermissioned: true,
        },
      ],
    });
    const res = parse(data?.[0]?.[columnId]?.Ok);
    return res;
  }

  const { getAccessTokenSilently } = useAuth0();

  useIntervalEffect(async () => {
    const sampleTimeSeconds = sampleTimeToSeconds[sampleTime];
    const { round, unit } = sampleRoundingIndex[sampleTime];
    const fromDate = dayjs()
      .utc()
      .round(round, unit)
      .subtract(sampleTimeSeconds, "seconds");

    const value = await (async () => {
      if (artisType) {
        return await getGridValue(productId);
      }

      const fromTimestamp = latestHistoricalCandle?.time
        ? dayjs.utc(Number(latestHistoricalCandle.time) * 1000).toISOString()
        : fromDate.toISOString();

      const questResponse = await fetchQuestData({
        fromUTCDate: fromTimestamp,
        toUTCDate: null,
        product: productId,
        field: "value",
        token: await getAccessTokenSilently(),
        sampleTime,
        periodFrom,
        isCalc: true,
      });

      return questResponse.data?.[0]?.[0]?.[2];
    })();

    // roughly accounts for the latency of the data
    // this date is whats shown to the user in the blue box
    // so needs to roughly represent actual market ts
    // ideally this would come from a server but since
    // currently we're using the grid to get the data
    // we can't do that yet
    const date = dayjs().subtract(200, "millisecond");
    if (!isNullish(value)) {
      storeLiveUpdatesTime({
        time: date.unix(),
        id: genLiveUpdatesTimeKey({
          chartId: chartMetadata.id,
          productId,
          sampleTime,
        }),
      });
    }
    const currentCandleStart = date.round(round, unit);
    const time = (currentCandleStart.valueOf() / 1000) as UTCTimestamp;
    if (isNullish(value)) {
      console.log("no value found");
      return {
        time,
      };
    }

    const prevCandleStart =
      currentCandleStart.subtract(round, unit).valueOf() / 1000;
    const latestHistoricalCandleTime = latestHistoricalCandle?.time || 0;

    const staleHistoricalData =
      latestHistoricalCandleTime &&
      latestHistoricalCandleTime !== prevCandleStart &&
      latestHistoricalCandleTime !== time;

    if (staleHistoricalData) {
      if (currentCandle && !currentCandleCache) {
        setCurrentCandleCache(currentCandle as OhlcData);
      }

      console.log("historical is stale", {
        prevCandleStart: dayjs(prevCandleStart * 1000).toISOString(),
        time: dayjs(time * 1000).toISOString(),
        latestHistoricalCandle: latestHistoricalCandle,
        latestHistoricalCandleDate: dayjs(
          Number(latestHistoricalCandle?.time || 0) * 1000,
        ).toISOString(),
      });
      fetchNextPage();
    } else {
      setCurrentCandleCache(undefined);
    }

    setCurrentCandle((old) => {
      if (isNullish(value)) return old;
      if (time !== old?.time) {
        return {
          time,
          open: value,
          close: value,
          high: value,
          low: value,
        };
      }
      const updated = {
        time,
        open: old.open,
        close: value,
        high: old.high != null ? Math.max(value, old.high) : value,
        low: old.low != null ? Math.min(value, old.low) : value,
      };
      if (latestHistoricalCandle?.time === updated.time) {
        return mergeHistoricalAndLiveData(
          latestHistoricalCandle,
          updated,
        ) as OhlcData;
      }
      return updated;
    });

    if (chart && currentCandle) {
      const currentData = chart.dataByIndex(
        Number.MAX_SAFE_INTEGER,
        MismatchDirection.NearestLeft,
      );
      if (!currentData) return;
      const latestTimeInChart = currentData?.time as
        | UTCTimestamp
        | undefined
        | null;
      const candleTime = currentCandle.time as UTCTimestamp | undefined | null;
      if (
        currentCandle.close == null ||
        currentCandle.high == null ||
        currentCandle.low == null ||
        currentCandle.open == null ||
        currentCandle.time == null
      ) {
        return;
      }

      const newCandle = {
        time: currentCandle.time || (0 as UTCTimestamp),
        open: currentCandle.open || 0,
        close: currentCandle.close || 0,
        high: currentCandle.high || 0,
        low: currentCandle.low || 0,
      };

      if (latestTimeInChart && candleTime && latestTimeInChart <= candleTime) {
        if (chart.data().length > 0) {
          chart.update(newCandle);
        } else {
          chart.setData([newCandle]);
        }
      }
    }
  }, 500);
  if (
    isNullish(currentCandle) ||
    isNullish(currentCandle.close) ||
    isNullish(currentCandle.high) ||
    isNullish(currentCandle.low) ||
    isNullish(currentCandle.open) ||
    isNullish(currentCandle.time)
  ) {
    return {
      currentCandle: undefined,
      setCurrentCandle,
    };
  }
  return {
    currentCandle: currentCandle as OhlcData,
    setCurrentCandle,
  };
}

export function storeLiveUpdatesTime({
  time,
  id,
}: {
  time: number;
  id: string;
}) {
  store.set(liveUpdatesTimeAtom, (prev) => ({
    ...prev,
    [id]: time,
  }));
}

export function genLiveUpdatesTimeKey({
  chartId,
  productId,
  sampleTime,
}: {
  chartId: string;
  productId: string;
  sampleTime: string;
}) {
  return `${productId}-${sampleTime}-${chartId}`;
}
