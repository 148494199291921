import type { IHeaderParams } from "ag-grid-community";

import { getColorFromVarName, getStatusClass } from "../classRules";
import { useSelectGridSettings } from "../../grid-settings";
import { calculateTextColor } from "../../colorUtils";
import { colParams } from "../../utils";
import { LastEditedInfoHeader } from "./LastEditedInfoHeader";
import { EodHeader } from "./EodHeader";

export function ColumnHeaderStatus(
  params: IHeaderParams & { headerColor: string; status: string },
) {
  const colInfo = colParams(params.column);
  const status = params.status;
  const eodCurve = colInfo?.artisType === "eod" || status === "eod";
  const statusColour = useSelectGridSettings((x) =>
    eodCurve
      ? x.eodColour
      : getColorFromVarName(
          getStatusClass(
            eodCurve ? "eod" : (params.status ?? (status || "listen")),
          ),
        ),
  );

  const isGlobal = status.includes("-");

  const textColour = calculateTextColor(statusColour, false);
  return (
    <div
      className="column-header flex w-full items-center justify-center text-center"
      style={{
        backgroundColor: statusColour ?? "",
        color: textColour,
      }}
    >
      {eodCurve ? (
        <EodHeader />
      ) : status === "listen" || status === "hybrid_broadcast" || isGlobal ? (
        <LastEditedInfoHeader colInfo={colInfo} status={status} />
      ) : null}
    </div>
  );
}
