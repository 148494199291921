import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export async function measureMemory() {
  try {
    if (
      typeof performance === "undefined" ||
      !("measureUserAgentSpecificMemory" in performance)
    ) {
      return;
    }
    // @ts-ignore - measureUserAgentSpecificMemory is not yet part of the spec but it's available in Chrome
    const memorySample = await performance.measureUserAgentSpecificMemory();
    // Transform the breakdown into key-value pairs
    const memoryDetails = memorySample.breakdown.reduce(
      (
        acc: Record<string, number>,
        item: {
          types?: string[];
          attribution?: { scope: string; url: string }[];
          bytes: number;
        },
      ) => {
        const type = item.types?.[0] || "Unknown";
        const attribution = item.attribution?.[0] || {
          scope: "Unknown",
          url: "Unknown",
        };
        const key = `${type} - ${attribution.scope} ${attribution.url}`;
        acc[key] = item.bytes;
        return acc;
      },
      {},
    );

    // Add the total bytes to the memory details
    const memoryUsageData = {
      totalBytes: memorySample.bytes,
      ...memoryDetails,
    };
    // Send memory usage data to Datadog
    datadogLogs.logger.debug("memory-usage-sample", memoryUsageData);
    datadogRum.setGlobalContextProperty("memory-usage-sample", memoryUsageData);

    // Schedule the next measurement
    setTimeout(measureMemory, 5 * 60 * 1000);
  } catch (error) {
    console.error("Failed to measure memory:", error);
  }
}
