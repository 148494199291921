import type {
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
} from "ag-grid-community";

import { useMemoCallback } from "../../../utils/useMemoCallback";

import { useGetMenuItemsGridSettings } from "./useGetMenuItemsGridSettings";
import { useGetMenuItemsCurveDetails } from "./useGetMenuItemsCurveDetails";
import { useGetMenuItemsHeaderSize } from "./useGetMenuItemsHeaderSize";
import { useGetMenuItemsFieldValue } from "./useGetMenuItemsFieldValue";
import { useGetMenuItemsColumnType } from "./useGetMenuItemsColumnType";
import { useGetMenuItemsColumnInsert } from "./useGetMenuItemsColumnInsert";
import { useGetMenuItemsShowFormula } from "./useGetMenuItemsShowFormula";
import { useGetMenuItemsRemoveColumn } from "./useGetMenuItemsRemoveColumn";
import { useGetMenuItemsEditCurve } from "./useGetMenuItemsEditCurve";
import { useGetMenuItemsFormatting } from "./useGetMenuItemsFormatting";
import { useGetMenuItemsConditionalFormatting } from "./useGetMenuItemsConditionalFormatting";
import { useGetMenuItemsAlerts } from "./useGetMenuItemsAlerts";
import { useGetMenuItemsQuickAccess } from "./useGetMenuItemsQuickAccess";
import { useGetMenuItemsClipboard } from "./useGetMenuItemsClipboard";
import { useGetMenuItemsCharts } from "./useGetMenuItemsCharts";
import { useGetMenuItemsExportData } from "./useGetMenuItemsExportData";
import { useGetMenuItemsColumn } from "./useGetMenuItemsColumn";

import { getMenuItemContext } from "./helpers";
import { useGetMenuItemsChartRange } from "./useGetMenuItemsChartRange";

export const useGetProductsContextMenue = (p: { isHeaderMenu: boolean }) => {
  const getGridSettings = useGetMenuItemsGridSettings();
  const getCurveDetails = useGetMenuItemsCurveDetails();
  const getHeaderSize = useGetMenuItemsHeaderSize();
  const getField = useGetMenuItemsFieldValue();
  const getColumnType = useGetMenuItemsColumnType();
  const getInsertColumn = useGetMenuItemsColumnInsert();
  const getShowFormula = useGetMenuItemsShowFormula();
  const getRemoveCulumn = useGetMenuItemsRemoveColumn();
  const getEditCurve = useGetMenuItemsEditCurve();
  const getColumn = useGetMenuItemsColumn();
  const getFormat = useGetMenuItemsFormatting();
  const getConditionalFormatting = useGetMenuItemsConditionalFormatting();
  const getAlerts = useGetMenuItemsAlerts();
  const getQuickAccess = useGetMenuItemsQuickAccess();
  const getCliboard = useGetMenuItemsClipboard();
  const getCharts = useGetMenuItemsCharts();
  const getChartRange = useGetMenuItemsChartRange();
  const getExport = useGetMenuItemsExportData();

  return useMemoCallback(
    (params: GetContextMenuItemsParams | GetMainMenuItemsParams) => {
      const ctx = getMenuItemContext({ params, isHeaderMenu: p.isHeaderMenu });
      return [
        ...getGridSettings(ctx),
        ...getCurveDetails(ctx),
        ...getHeaderSize(ctx),
        ...(ctx.isHeaderMenu && ctx.isProduct ? ["separator"] : []),
        ...getField(ctx),
        ...getColumnType(ctx),
        ...getInsertColumn(ctx),
        ...getShowFormula(ctx),
        ...getEditCurve(ctx),
        ...getRemoveCulumn(ctx),
        ...getColumn(ctx),
        "separator",
        ...getFormat(ctx),
        "separator",
        ...getConditionalFormatting(ctx),
        ...getAlerts(ctx),
        "separator",
        ...getQuickAccess(ctx),
        ...getCliboard(ctx),
        ...getCharts(ctx),
        ...getChartRange(ctx),
        ...getExport(ctx),
      ];
    },
  );
};
