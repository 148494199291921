import type { ColumnResizedEvent } from "ag-grid-community";
import { useEffect, useMemo, useRef } from "react";
import { debounce } from "remeda";

import { useUpdateGridSettings } from "../grid-settings";
import { useUpdatePageProducts } from "../../data";
import { mainMonthColumnId } from "./column-defs";
import { useUserId } from "../../context/auth";
import { colParams } from "../utils";

export const useOnColumnResized = () => {
  const updatePage = useUpdatePageProducts();
  const update = useUpdateGridSettings();
  const userId = useUserId();

  const onColumnResized = (params: ColumnResizedEvent) => {
    const width = params?.column?.getActualWidth();
    if (params.source === "api" || !width) return;

    const columnFieldId = params?.column?.getColId();
    if (columnFieldId === mainMonthColumnId) {
      update.mutate({
        id: userId,
        updaterFn: (data) => {
          data.mainMonthColumnWidth = width;
        },
      });
    }

    // resizing any other column
    const columnId = colParams(params.column)?.gridId;

    if (!columnId) throw new Error("columnId is undefined");

    updatePage.mutateAsync({
      id: columnId,
      updaterFn: (entry) => {
        entry.columnWidth = width;
      },
    });
  };

  const handler = useRef(debounce(onColumnResized, { waitMs: 300 }));
  handler.current = debounce(onColumnResized, { waitMs: 300 });
  useEffect(() => () => handler.current.cancel(), []);

  return useMemo(() => handler.current.call, []);
};
