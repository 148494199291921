import type { GetContextMenuSection } from "./helpers";
import type { GridApi, IMenuActionParams } from "ag-grid-community";
import { requestDockviewModal } from "../modals/modalComponents";
import { useActivePageId, useUpdatePageProducts } from "../../../data";
import { columnWidth } from "../../globals";
import { colParams } from "../../utils";
import { getIcon } from "./icons";
import { useGridSettingsIncrementMap } from "../../grid-settings";
import { openFormattingDockview } from "./useDockviewModal";

export const useGetMenuItemsColumn = (): GetContextMenuSection => {
  const update = useUpdatePageProducts();
  const incrementMap = useGridSettingsIncrementMap();
  const pageId = useActivePageId();

  const setColumnWidthsToCurrent = async (
    api: GridApi,
    actualWidth?: number,
  ) => {
    const currentWidth = actualWidth || columnWidth;

    const changes =
      api
        ?.getColumns()
        ?.map((column) => {
          const col = colParams(column);
          const gridId = col?.gridId;
          if (!gridId || !col?.artisType) return null;
          return update.mutateAsync({
            id: gridId,
            updaterFn: (current) => {
              current.columnWidth = currentWidth;
            },
          });
        })
        .filter(Boolean) || [];

    await Promise.all(changes);
  };

  return (ctx) => {
    const items = [
      {
        name: "Column",
        icon: getIcon("width"),
        subMenu: [
          {
            name: "Set All Column Widths",
            action: () => {
              requestDockviewModal({
                parentPanel: "set-all-column-widths",
                params: {
                  width: 400,
                },
              });
            },
          },
          {
            name: "Set Column Widths to Current",
            action: (e: IMenuActionParams) =>
              setColumnWidthsToCurrent(e.api, ctx.columnWidth),
          },
        ],
      },
    ];

    if (ctx.isProduct) {
      items[0].subMenu.push({
        name: "Column Settings",
        action: () => {
          openFormattingDockview({
            pageId,
            type: "column",
            currentTab: "settings",
            incrementMap,
            columnId: ctx.columnId,
            selectedRange: ctx.selectedRange,
            productId: ctx.productId,
          });
        },
      });
    }

    return items;
  };
};
