import { useQuery as useApolloQuery } from "@apollo/client";
import { gql } from "@apollo/client";

import { useSanityClient } from "../context/sanity";
import type {
  LicenseAgreementsQuery,
  NewFeaturesQuery,
  PricingQuery,
  UserGuideByIdQuery,
  UserGuideByIdQueryVariables,
  UserGuideQuery,
} from "../__generated__/sanity/graphql-request";

export const ALL_USER_GUIDES = gql`
  query UserGuide {
    allUserGuide {
      _id
      title
      index
      mainImage {
        asset {
          _id
          url
        }
      }
      publishedAt
    }
  }
`;

const USER_GUIDE_BY_ID = gql`
  query UserGuideById($id: ID!) {
    UserGuide(id: $id) {
      _id
      title
      mainImage {
        asset {
          _id
          url
        }
      }
      publishedAt
      bodyRaw
    }
  }
`;

export const ALL_NEW_FEATURES = gql`
  query newFeatures {
    allNewFeature {
      _id
      title
      publishedAt
      bodyRaw
    }
  }
`;

const PRICING = gql`
  query Pricing {
    allPricing {
      _id
      title
      subscripts
      body {
        _key
        rows {
          cells
        }
      }
      publishedAt
    }
  }
`;

const LICENSE_AGREEMENTS = gql`
  query LicenseAgreements {
    allLicenseAgreement {
      _id
      title
      slug {
        current
      }
      publishedAt
      bodyRaw
      index
    }
  }
`;

export function useAllLicenseAgreements() {
  const client = useSanityClient();
  return useApolloQuery<LicenseAgreementsQuery>(LICENSE_AGREEMENTS, {
    client,
  });
}

type UserGuide = UserGuideQuery["allUserGuide"][number] & {
  _id: string;
  index: number;
};

export function useAllUserGuides() {
  const client = useSanityClient();
  const { data, ...rest } = useApolloQuery<UserGuideQuery>(ALL_USER_GUIDES, {
    client,
  });
  const sortedPosts =
    data?.allUserGuide
      .filter((page): page is UserGuide => !!page.index && !!page._id)
      .sort((a, b) => a.index - b.index) || [];
  return {
    data: { ...data, allUserGuide: sortedPosts },
    ...rest,
  };
}

export function useUserGuideById(id: string) {
  const client = useSanityClient();
  return useApolloQuery<UserGuideByIdQuery, UserGuideByIdQueryVariables>(
    USER_GUIDE_BY_ID,
    {
      client,
      variables: { id },
    },
  );
}

export function useNewFeatures() {
  const client = useSanityClient();
  return useApolloQuery<NewFeaturesQuery>(ALL_NEW_FEATURES, {
    client,
  });
}

export function usePricing() {
  const client = useSanityClient();
  return useApolloQuery<PricingQuery>(PRICING, {
    client,
  });
}
