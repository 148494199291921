import type { GridOptions } from "ag-grid-community";
import { useMemo } from "react";

import { processDataFromClipboard } from "./copyRangeSelectionHelpers";
import { requestDockviewModal } from "./modals/modalComponents";
import { rowHeight, separatorHeight } from "../globals";
import { LoadingOverlay } from "../components/Loading";
import { handleRedo, handleUndo } from "./undo-redo";
import { useOnCellKeyDown } from "./keyboard";
import { mainMonthColumnId } from "./column-defs";
import { isMobile } from "../../shared/hooks";

export const useGridOptions = () => {
  const onCellKeyDown = useOnCellKeyDown();

  return useMemo(() => {
    return {
      getRowHeight: (params) => {
        if (params.data?.period) {
          const firstRow = params.data?.firstOfType;
          const isNotMonth = params.data?.rowType !== "mth";

          return firstRow && isNotMonth
            ? rowHeight + separatorHeight
            : rowHeight;
        }
      },
      loadingOverlayComponent: LoadingOverlay,
      noRowsOverlayComponent: LoadingOverlay,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      onUndoStarted: handleUndo,
      onRedoStarted: handleRedo,
      onCellKeyDown,
      rowClassRules: {
        borderRow: (params) => {
          const isHlvAndH2Period =
            params.data?.rowType === "hlv" &&
            params.data?.period?.includes("H2");
          const isQtrAndQ4Period =
            params.data?.rowType === "qtr" &&
            params.data?.period?.includes("Q4");
          return !!(
            params.data?.year ||
            params.data?.quarter ||
            isHlvAndH2Period ||
            isQtrAndQ4Period
          );
        },
        separator: (params) => {
          const notMonth = params.data?.rowType !== "mth";
          const firstRow = params.data?.firstOfType;
          return firstRow && notMonth;
        },
      },
      processDataFromClipboard,
      postProcessPopup: (params) => {
        if (params.type === "columnMenu" && !params.column) {
          params.ePopup.style.display = "none";
        }
      },
      onCellClicked: (params) => {
        const isMonthColumn = params.column.getColId() === mainMonthColumnId;
        const isAdhoc = params.data?.rowType === "adhoc";

        if (isMonthColumn && isAdhoc) {
          const height = isMobile ? window.innerHeight : 320;
          const width = isMobile ? window.innerWidth : 450;

          requestDockviewModal({
            parentPanel: "tspds",
            offsetX: 20,
            offsetY: -100,
            params: {
              height,
              width,
              rowId: params.data?.id,
            },
          });
        }
      },
    } satisfies GridOptions;
  }, [onCellKeyDown]);
};
