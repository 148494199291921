import { Box, Button } from "@mui/joy";
import { AgCharts } from "ag-charts-react";
import { memo, useCallback, useMemo } from "react";
import { deepEqual } from "@tanstack/react-router";
import { monthCodeToShortMonth } from "../market-grid/periodHelpers";
import {
  Autocomplete,
  type TOption,
} from "../components/Autocomplete/Autocomplete";
import { useAutocomplete } from "../components/Autocomplete/hooks";
import type {
  ProductFragmentGridFragment,
  Product_Artis_Type_Enum,
} from "../../__generated__/gql/graphql";
import { defaultFieldNameSelector, isListOnlyPermissions } from "../utils";

import { client } from "../../triplit/triplit";
import { useThemeMode } from "../../context/theme";
import {
  selectorsData,
  type TSelectorExchange,
} from "../market-grid/selectorsData";
import {
  fieldNameOverrides,
  getSelectors,
} from "../market-grid/context-menu/useGetMenuItemsFieldValue";
import renderEmptyChart from "../icons/EmptyChart";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const periodsOptions: TOption[] = Array.from({ length: 24 }, (_, i) => {
  const val = (i + 1).toString();
  return { label: val, value: val, groupValue: "period" } satisfies TOption;
});

export function genSelectorOptions(
  product: ProductFragmentGridFragment | undefined,
): TOption[] {
  const selectedExchange =
    product?.packageByPackage?.sourceBySource?.exchange?.code;
  const selectedMaturity = product?.maturity;
  const defaultFieldNameSelectorOption = genDefaultOption(product?.artis_type);

  if (product && selectedExchange && selectedMaturity) {
    return getSelectors({
      productId: product.id,
      exchange: validateSelectorExchange(selectedExchange),
      maturity: selectedMaturity,
    });
  }
  return [defaultFieldNameSelectorOption];
}

export function genDefaultOption(
  artisType: Product_Artis_Type_Enum | undefined,
): TOption {
  const defaultFieldNameSelectorValue = defaultFieldNameSelector(
    artisType || "customer_curve",
  );

  const selectorLabel =
    fieldNameOverrides[defaultFieldNameSelectorValue] ||
    defaultFieldNameSelectorValue;

  return {
    label: selectorLabel.toUpperCase(),
    value: defaultFieldNameSelectorValue,
    groupValue: "field",
  };
}

const PeriodButton = ({
  value,
  isSelected,
  onClick,
  isLeftButton,
}: {
  value: string;
  isSelected: boolean;
  onClick: () => void;
  isLeftButton?: boolean;
}) => (
  <Button
    variant="plain"
    size="sm"
    onClick={onClick}
    sx={{
      width: "25px",
      height: "23px",
      "--Button-minHeight": "5px",
      "--Button-paddingInline": "0px",
      fontSize: "10px",
      color: isSelected ? "inherit" : "#bbbbbb",
      backgroundColor: "#F5F5F5",
      borderRadius: "5px",
      ...(isLeftButton
        ? {
            borderRight: "0.5px solid #dfdfdf",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
          }
        : {
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          }),
      "&:hover": {
        backgroundColor: isSelected ? "#F0F0F0" : "#E0E0E0",
      },
    }}
  >
    {value}
  </Button>
);

const ChartRenderer = memo(
  ({
    id,
    idx,
    productId,
    selector,
    period,
    userId,
    allProductOptions,
    data,
    chartsWidth,
    fetchingAllProducts,
    emptyChartId,
  }: {
    id: string;
    idx: number;
    productId?: string;
    selector?: string;
    period?: string;
    userId: string;
    allProductOptions?: TOption[];
    data: { type: string; value: number | undefined }[];
    chartsWidth: number;
    fetchingAllProducts: boolean;
    emptyChartId: string | undefined;
  }) => {
    const mode = useThemeMode();

    const product = allProductOptions?.find((p) => p.value === productId);

    const permissions = product?.metadata?.packageByPackage.permissions;
    const isListOnly = isListOnlyPermissions(permissions);
    const isPermissioned = permissions?.length && !isListOnly;
    const productOptions = allProductOptions?.filter((option) => {
      const exchangeCode =
        option.metadata?.packageByPackage?.sourceBySource?.exchange?.code;
      return !exchangeCode || exchangeCode in selectorsData;
    });
    //
    const defaultOption = productOptions?.find((p) => p.value === productId);

    const useProductAutocompleteProps = useAutocomplete({
      multiple: false,
      defaultValue: defaultOption,
    });

    const overlayText = isListOnly
      ? "List only permissions"
      : !permissions?.length
        ? "You don't have permission to plot this Curve"
        : productId
          ? "No data to show"
          : "";
    const handleRemove = useCallback(async () => {
      await client.delete("gridCharts", id);
    }, [id]);

    const isEmptyChart = id === emptyChartId;
    const [selectedProduct] = useProductAutocompleteProps.singleState;

    const handleChangeProduct = useCallback(
      async (product?: TOption) => {
        const constructChartId = (userId: string, newIndex: number): string =>
          `${userId}-${newIndex}`;

        const extractIndex = (id: string): number => {
          const regex = /-(\d+)$/;
          const match = id.match(regex);
          if (match?.[1]) {
            const index = Number(match[1]);
            if (!Number.isNaN(index)) {
              return index;
            }
          }
          throw new Error(
            `Invalid ID format, cannot extract index from: ${id}`,
          );
        };

        const incrementIndex = (currentIndex: number): number =>
          currentIndex + 1;

        const mainChart = {
          id,
          idx: idx,
          productId: product?.value,
          selector: defaultFieldNameSelector(
            product?.metadata?.artis_type || "customer_curve",
          ),
          period: 12,
          userId,
        };
        const currentIndex = extractIndex(mainChart.id);
        const newIndex = incrementIndex(currentIndex);
        const emptyChartId = constructChartId(mainChart.userId, newIndex);

        const emptyChart = {
          id: emptyChartId,
          idx: newIndex,
          userId,
        };

        try {
          await client.insert("gridCharts", mainChart);
          if (id !== emptyChartId)
            await client.insert("gridCharts", emptyChart);
        } catch (error) {
          console.error("Error saving charts:", error);
        }
      },
      [id, idx, userId],
    );

    const selectorOptions = useMemo(
      () => genSelectorOptions(selectedProduct?.metadata),
      [selectedProduct],
    );

    console.log("selectorOptions", selectorOptions);

    const defaultSelector = selectorOptions?.find((s) => s.value === selector);

    const useSelectorAutocompleteProps = useAutocomplete({
      multiple: false,
      defaultValue: defaultSelector,
    });

    const handleChangeSelector = useCallback(
      async (selector?: string) => {
        const mainChart = {
          id,
          idx: idx,
          productId,
          selector: selector,
          period: 12,
          userId,
        };

        try {
          await client.insert("gridCharts", mainChart);
        } catch (error) {
          console.error("Error saving charts:", error);
        }
      },
      [id, idx, userId, productId],
    );

    const handleChangePeriod = useCallback(
      async (period?: string) => {
        const mainChart = {
          id,
          idx: idx,
          productId,
          selector: defaultFieldNameSelector(
            selectedProduct?.metadata?.artis_type || "customer_curve",
          ),
          period: Number.parseInt(period || "12"),
          userId,
        };

        try {
          await client.insert("gridCharts", mainChart);
        } catch (error) {
          console.error("Error saving charts:", error);
        }
      },
      [id, idx, userId, productId, selectedProduct],
    );

    if (fetchingAllProducts) {
      return (
        <Box
          sx={{
            width: chartsWidth,
            height: 180,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      );
    }

    return (
      <Box
        key={id}
        sx={{
          width: chartsWidth,
          border: `0.5px solid ${mode === "dark" ? "#2D2D2D" : "#E5E5E5"}`,
          borderLeft: "none",
          borderBottom: "none",
          position: "relative",
          overflow: "visible",
          background: mode === "dark" ? "#141528" : "#FFFFFF",
        }}
      >
        {!isEmptyChart && (
          <FontAwesomeIcon
            onClick={handleRemove}
            style={{
              position: "absolute",
              top: 10,
              right: 15,
              zIndex: 10,
              cursor: "pointer",
              fontSize: "15px",
            }}
            icon={faXmark}
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: 2,
            marginBottom: 1,
            marginTop: 1,
            gap: 1,
          }}
        >
          <Autocomplete
            {...useProductAutocompleteProps}
            multiple={false}
            placeholder="Curve"
            options={productOptions || []}
            sx={{ width: 200, marginTop: 0.3 }}
            onSelectValue={async (v: TOption) => {
              await handleChangeProduct(v);
            }}
          />
          <Autocomplete
            {...useSelectorAutocompleteProps}
            multiple={false}
            placeholder="Field"
            listHeight={selectorOptions?.length === 1 ? 65 : 200}
            options={selectorOptions || []}
            sx={{ width: 100, marginTop: 0.3 }}
            onSelectValue={async (v: TOption) => {
              await handleChangeSelector(v.value);
            }}
          />

          <Box
            sx={{
              marginTop: "3px",
            }}
          >
            <PeriodButton
              value="12"
              isSelected={period === "12"}
              onClick={() => handleChangePeriod("12")}
              isLeftButton
            />
            <PeriodButton
              value="24"
              isSelected={period === "24"}
              onClick={() => handleChangePeriod("24")}
            />
          </Box>
        </Box>

        {productId ? (
          <AgCharts
            options={{
              data: isPermissioned ? data : [],
              theme: mode === "dark" ? "ag-default-dark" : "ag-default",
              background: { fill: mode === "dark" ? "#141528" : "#FFFFFF" },
              overlays: {
                noData: { text: overlayText },
              },
              padding: { top: 15, right: 5, bottom: 5, left: 5 },
              width: chartsWidth - 1,
              height: 180,
              title: undefined,
              series: [
                {
                  type: "bar",
                  xKey: "type",
                  yKey: "value",
                  itemStyler: (params) => {
                    const posColor = "#337A3B";
                    const negColor = "#9B3636";
                    return {
                      fill: params.datum[params.yKey] < 0 ? negColor : posColor,
                      stroke:
                        params.datum[params.yKey] < 0 ? negColor : posColor,
                      strokeWidth: 0,
                    };
                  },
                },
              ],
              axes: [
                {
                  type: "category",
                  position: "bottom",
                  label: {
                    fontSize: 10,
                    formatter: (params) =>
                      monthCodeToShortMonth(params.value as string),
                    rotation: -90,
                  },
                  tick: { size: 4 },
                },
                { type: "number", position: "left", tick: { size: 0 } },
              ],
            }}
          />
        ) : (
          renderEmptyChart(mode)
        )}
      </Box>
    );
  },
  deepEqual,
);

export default ChartRenderer;

export function validateSelectorExchange(number: number): TSelectorExchange {
  if (number in selectorsData) {
    return number as TSelectorExchange;
  }
  throw new Error(`${number} is not a valid exchange.`);
}
