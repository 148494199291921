import type { IHeaderParams } from "ag-grid-community";

import { agGridCellAutoPadding, headerHeight } from "../../globals";
import { ChangeAllStatusButton } from "./ChangeAllStatusButton";
import { useUserSubscriptionTier } from "../../../context/auth";
import { calculateTextColor } from "../../colorUtils";
import { HeaderArtisLogo } from "./HeaderArtisLogo";
import { getColorFromVarName } from "../classRules";
import { mainMonthColumnId } from "../column-defs";

export function ColumnMonths(params: IHeaderParams) {
  const isMainMonthColumn = params.column.getColId() === mainMonthColumnId;

  return <ColumnMonthsRaw isMainMonthColumn={isMainMonthColumn} />;
}

function ColumnMonthsRaw({
  isMainMonthColumn,
}: {
  isMainMonthColumn: boolean;
}) {
  const { disabledFeatures } = useUserSubscriptionTier();
  return (
    <div className="flex h-full w-full self-start justify-between flex-col [background-color:var(--ag-header-background-color)]">
      {isMainMonthColumn && !disabledFeatures?.statusRow ? (
        <ChangeAllStatusButton />
      ) : (
        <div />
      )}
      <div className="flex flex-col gap-2">
        <HeaderArtisLogo />
        <div
          className="w-full flex items-center font-bold [background-color:var(--headerColumnColour)]"
          style={{
            paddingLeft: agGridCellAutoPadding,
            height: headerHeight,
            color: calculateTextColor(
              getColorFromVarName("--headerColumnColour"),
              false,
            ),
          }}
        >
          Month
        </div>
      </div>
    </div>
  );
}
