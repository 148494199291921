import type { CanvasRenderingTarget2D } from "fancy-canvas";
import type {
  Coordinate,
  DataChangedScope,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  SeriesAttachedParameter,
  SeriesDataItemTypeMap,
  SeriesPrimitivePaneViewZOrder,
  SeriesType,
  Time,
} from "lightweight-charts";
import { PluginBase } from "../plugin-base";

interface NoDataBackgroundRendererData {
  x: Coordinate | number;
  color: string;
}

class NoDataBackgroundPaneRenderer implements ISeriesPrimitivePaneRenderer {
  _viewData: NoDataBackgroundViewData;
  constructor(data: NoDataBackgroundViewData) {
    this._viewData = data;
  }
  draw(target: CanvasRenderingTarget2D) {
    const points: NoDataBackgroundRendererData[] = this._viewData.data;

    // biome-ignore lint/correctness/useHookAtTopLevel: Not actually a hook, just has use in the name.
    target.useBitmapCoordinateSpace((scope) => {
      const ctx = scope.context;
      const yTop = 0;
      const height = scope.bitmapSize.height;
      const halfWidth =
        (scope.horizontalPixelRatio * this._viewData.barWidth) / 2;
      const cutOff = -1 * (halfWidth + 1);

      if (points.length === 0) return;
      const firstPoint = points[0];
      if (!firstPoint) return;
      const firstPointXScaled = firstPoint.x * scope.horizontalPixelRatio;

      // Draws a rectangle from all the way to the left of the chart to just before the first candlestick/point.
      points.forEach((point) => {
        const xScaled = point.x * scope.horizontalPixelRatio;
        if (xScaled < cutOff) return;
        ctx.fillStyle = point.color || "rgba(0, 0, 0, 0)";
        const x1 = Math.max(0, Math.round(firstPointXScaled - halfWidth));
        const x2 = 0;
        ctx.fillRect(x1, yTop, x2 - x1, height);
      });
    });
  }
}

interface NoDataBackgroundViewData {
  data: NoDataBackgroundRendererData[];
  options: Required<NoDataBackgroundOptions>;
  barWidth: number;
}

class NoDataBackgroundPaneView implements ISeriesPrimitivePaneView {
  _source: NoDataBackground;
  _data: NoDataBackgroundViewData;

  constructor(source: NoDataBackground) {
    this._source = source;
    this._data = {
      data: [],
      barWidth: 6,
      options: this._source._options,
    };
  }

  update() {
    const timeScale = this._source.chart.timeScale();
    this._data.data = this._source._backgroundColors.map((d) => {
      return {
        x: timeScale.timeToCoordinate(d.time) ?? -100,
        color: d.color,
      };
    });
    if (this._data.data.length > 1) {
      this._data.barWidth = this._data.data[1].x - this._data.data[0].x;
    } else {
      this._data.barWidth = 6;
    }
  }

  renderer() {
    return new NoDataBackgroundPaneRenderer(this._data);
  }

  zOrder(): SeriesPrimitivePaneViewZOrder {
    return "bottom";
  }
}

// biome-ignore lint/suspicious/noEmptyInterface:
export interface NoDataBackgroundOptions {}

const defaults: Required<NoDataBackgroundOptions> = {};

interface BackgroundData {
  time: Time;
  color: string;
}

export type NoDataBackgroundHighlighter = (date: Time) => string;

export class NoDataBackground
  extends PluginBase
  implements ISeriesPrimitive<Time>
{
  _paneViews: NoDataBackgroundPaneView[];
  _seriesData: SeriesDataItemTypeMap[SeriesType][] = [];
  _backgroundColors: BackgroundData[] = [];
  _options: Required<NoDataBackgroundOptions>;
  _highlighter: NoDataBackgroundHighlighter;

  constructor(
    highlighter: NoDataBackgroundHighlighter,
    options: NoDataBackgroundOptions = {},
  ) {
    super();
    this._highlighter = highlighter;
    this._options = { ...defaults, ...options };
    this._paneViews = [new NoDataBackgroundPaneView(this)];
  }

  updateAllViews() {
    this._paneViews.forEach((pw) => pw.update());
  }

  paneViews() {
    return this._paneViews;
  }

  attached(p: SeriesAttachedParameter<Time>): void {
    super.attached(p);
    this.dataUpdated("full");
  }

  dataUpdated(_scope: DataChangedScope) {
    // plugin base has fired a data changed event
    // TODO: only update the last value if the scope is 'update'
    this._backgroundColors = this.series.data().map((dataPoint) => {
      return {
        time: dataPoint.time,
        color: this._highlighter(dataPoint.time),
      };
    });
    this.requestUpdate();
  }
}
