import { Box, Option, type SelectOption, Typography } from "@mui/joy";
import type { TStatusOption } from "../statuses/consts";
import { headerHeight } from "../../globals";

// needed because our security headers block imgur images
// theres a redirect in the netlify toml to handle this
function formatLogoUrl(
  logo: string | null | undefined,
): string | null | undefined {
  return logo?.replace("https://i.imgur.com/", "/imgur-images/");
}

export function SelectPrettyValue({
  renderValueParams,
  logo,
  options,
  selectHeightPx = headerHeight,
}: {
  renderValueParams: SelectOption<string> | null;
  logo: string | null | undefined;
  options: TStatusOption[];
  selectHeightPx?: number;
}) {
  if (!renderValueParams) return null;
  const option = options.find((opt) => opt.value === renderValueParams.value);
  if (!option) console.error("option not found");
  const formattedLogo = formatLogoUrl(option?.logo ?? logo);
  return (
    <Box
      sx={{
        ...option?.style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: selectHeightPx,
      }}
    >
      {formattedLogo && (
        <img style={{ height: "15px" }} alt="artis logo" src={formattedLogo} />
      )}
      {option?.icon && <option.icon />}
      <Typography
        paddingLeft={formattedLogo ? "4px" : "0px"}
        fontSize="xs"
        fontWeight={formattedLogo ? "normal" : "bold"}
      >
        {option?.label}
      </Typography>
    </Box>
  );
}

type TStatusSelectOption = TStatusOption & {
  artisLogo: string | undefined | null;
  value: string;
  statusOptionsToRender: TStatusOption[];
};

export function SelectPrettyOption(option: TStatusSelectOption) {
  const { statusOptionsToRender } = option;
  const isVisible = statusOptionsToRender.some(
    (renderOption) => renderOption.value === option.value,
  );
  const formattedLogo = formatLogoUrl(option.logo ?? option.artisLogo);
  return (
    <Option
      sx={{
        ...option?.style,
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        gap: "4px",
        textOverflow: "ellipsis",
        display: isVisible ? "flex" : "none",
      }}
      value={option.value}
    >
      {formattedLogo && (
        <img
          style={{
            maxHeight: "1.5em",
            maxWidth: "100%",
          }}
          alt="artis logo"
          src={formattedLogo}
        />
      )}
      {option?.icon && <option.icon />}
      <Typography fontSize="xs">{option.label}</Typography>
    </Option>
  );
}
