import type { CellKeyDownEvent } from "ag-grid-community";

import { useUpdateCellByValueAndOperation } from "./useUpdateCellByValueAndOperation";
import { useSelectGridSettings } from "../../grid-settings";
import { initGridSettings } from "../defaultSettings";

export const useUpdateColumnFromCellByValueAndOperation = () => {
  const updateCellsByValueAndOperation = useUpdateCellByValueAndOperation();
  const months =
    useSelectGridSettings((x) => x.months) ?? initGridSettings.months;
  return ({
    params,
    operation,
  }: {
    params: CellKeyDownEvent;
    operation: "+" | "-";
  }) => {
    const api = params.api;

    if (!api) return;

    const selectedColumn = api.getColumn(params.column.getColId());

    if (!selectedColumn) return;

    const selectedIndex = api.getCellRanges()?.sort((a, b) => {
      if (!a?.startRow || !b?.startRow) return 0;
      return a?.startRow?.rowIndex - b?.startRow?.rowIndex;
    })[0]?.startRow?.rowIndex;

    api.clearRangeSelection();

    api.addCellRange({
      columns: [selectedColumn],
      rowStartIndex: selectedIndex ?? 0,
      rowEndIndex: months - 1,
    });

    updateCellsByValueAndOperation({
      params,
      operation,
      clearSelection: true,
      reselect: {
        index: selectedIndex ?? 0,
        column: selectedColumn,
      },
    });
  };
};
