function Icon({ mode }: { mode: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"Limit"}</title>
      <path
        d="M8.10001 7.49996L12.2667 17.5L13.7667 13.1666L18.1 11.6666L8.10001 7.49996Z"
        fill={mode === "light" ? "#1C1C21" : "#ffffff"}
      />
      <path
        d="M6.59997 1.83325L7.26664 4.24992M4.84997 6.66659L2.4333 5.99992M12.2667 3.41671L10.6 5.00004M5.60009 9.99996L4.01676 11.6666M8.10001 7.49996L12.2667 17.5L13.7667 13.1666L18.1 11.6666L8.10001 7.49996Z"
        stroke={mode === "light" ? "#1C1C21" : "#ffffff"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PointerIcon({ mode }: { mode: string }) {
  return <Icon mode={mode} />;
}
