import {
  Box,
  Button,
  Input,
  Select,
  Option,
  Stack,
  Typography,
  Tooltip,
} from "@mui/joy";
import { FaTimes } from "react-icons/fa";
import { PointerIcon } from "../../../icons";
import { ColorPicker } from "../../../grid-settings/ColorPicker";
import {
  Controller,
  type UseFormReturn,
  type Control,
  type UseFormRegister,
} from "react-hook-form";
import type { TConditionalRule } from "./helpers";
import { useThemeMode } from "../../../../context/theme";
import type { GridApi } from "ag-grid-community";
import type { Dispatch, SetStateAction } from "react";

export function RuleFormFields({
  form,
  index,
  showColorPicker,
  setShowColorPicker,
  resetLimitSelection,
  output,
  gridApi,
}: {
  form: UseFormReturn<{ rules: TConditionalRule[] }>;
  index: number;
  showColorPicker: boolean;
  setShowColorPicker: Dispatch<SetStateAction<boolean>>;
  resetLimitSelection: () => void;
  output: TConditionalRule[];
  gridApi: GridApi | null;
}) {
  const { register, control } = form;
  const mode = useThemeMode();

  return (
    <>
      <Controller
        name={`rules.${index}.rule`}
        control={control}
        render={({ field }) => (
          <Select
            sx={{ height: "30px" }}
            value={field.value}
            onChange={(_, value) => field.onChange(value)}
            size="sm"
          >
            <Option value={"equals"}>Equals</Option>
            <Option value={"isNotEqual"}>Is not equal</Option>
            <Option value={"lessThan"}>Less than</Option>
            <Option value={"moreThan"}>More than</Option>
            <Option value={"lessThanOrEqual"}>Less than or equal</Option>
            <Option value={"moreThanOrEqual"}>More than or equal</Option>
          </Select>
        )}
      />

      <Stack flexDirection={"row"} gap={1}>
        {output[index]?.limitRef?.name ? (
          <LimitRefField index={index} output={output} control={control} />
        ) : (
          <LimitInputField
            index={index}
            register={register}
            control={control}
            resetLimitSelection={resetLimitSelection}
            gridApi={gridApi}
            mode={mode}
          />
        )}
      </Stack>

      <Controller
        name={`rules.${index}.formatting`}
        control={control}
        render={({ field }) => (
          <ColorPicker
            show={showColorPicker}
            setShow={setShowColorPicker}
            onChange={(newValue) => {
              field.onChange({
                bgColor: newValue.color,
                boldText: newValue.boldText,
                invertTextColor: newValue.invertTextColor,
              });
            }}
            value={{
              color: output[index].formatting.bgColor,
              boldText: output[index].formatting.boldText,
              invertTextColor: output[index].formatting.invertTextColor,
            }}
            showSwatches
            showTextOptions
            width={"73px"}
            height={"26px"}
          />
        )}
      />
    </>
  );
}

interface LimitRefFieldProps {
  index: number;
  output: TConditionalRule[];
  control: Control<{ rules: TConditionalRule[] }>;
}

function LimitRefField({ index, output, control }: LimitRefFieldProps) {
  return (
    <Stack
      flexDirection={"row"}
      gap={1}
      overflow="hidden"
      width="100%"
      justifyContent={"space-between"}
    >
      <Controller
        name={`rules.${index}.limitRef`}
        control={control}
        render={({ field }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "30px",
              border: (theme) =>
                `1px solid ${theme.palette.neutral.outlinedBorder}`,
              padding: "4px 8px",
              paddingRight: "0px",
              borderRadius: "sm",
              width: "fit-content",
              gap: "8px",
              backgroundColor: (theme) => theme.palette.background.surface,
              maxWidth: "106px",
            }}
          >
            <Tooltip
              title={`${output[index]?.limitRef?.name}[${output[index]?.limitRef?.period}]`}
              placement="top-start"
              arrow
            >
              <Typography
                fontSize="xs"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {output[index]?.limitRef?.name}[
                {output[index]?.limitRef?.period}]
              </Typography>
            </Tooltip>

            <Button
              size="sm"
              variant="plain"
              color="neutral"
              onClick={() => {
                field.onChange(undefined);
              }}
              sx={{
                minWidth: "auto",
              }}
            >
              <FaTimes />
            </Button>
          </Box>
        )}
      />
    </Stack>
  );
}

interface LimitInputFieldProps {
  index: number;
  register: UseFormRegister<{ rules: TConditionalRule[] }>;
  control: Control<{ rules: TConditionalRule[] }>;
  resetLimitSelection: () => void;
  gridApi: GridApi | null;
  mode: string;
}

function LimitInputField({
  index,
  register,
  control,
  resetLimitSelection,
  gridApi,
  mode,
}: LimitInputFieldProps) {
  return (
    <>
      <Input
        {...register(`rules.${index}.limit`)}
        type="number"
        size="sm"
        sx={{
          height: "30px",
          gap: "10px",
          width: "49px",
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
        }}
      />
      <Controller
        name={`rules.${index}.limitRef`}
        control={control}
        render={({ field }) => (
          <Button
            size="sm"
            variant="soft"
            color="neutral"
            sx={{
              display: "flex",
              minWidth: "49px",
              height: "30px",
              padding: "8px 16px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={() => {
              if (gridApi?.getGridOption("rowClass") === "ag-cell-selection") {
                resetLimitSelection();
                return;
              }
              gridApi?.setGridOption("onCellClicked", (e) => {
                resetLimitSelection();
                field.onChange({
                  columnId: e.column.getColId(),
                  rowId: e.node.id,
                  name: e.colDef.headerName,
                  period: e.node.data?.period?.toString(),
                });
              });
              document.querySelectorAll(".ag-row").forEach((cell) => {
                cell.classList.add("ag-cell-selection");
              });
            }}
          >
            <PointerIcon mode={mode} />
          </Button>
        )}
      />
    </>
  );
}

export const conditionalFormattingTableStyle = {
  backgroundColor: (theme: { palette: { mode: string } }) =>
    theme.palette.mode === "light"
      ? "var(--artis-light-grey)"
      : "var(--artis-dark-grey)",
  tableLayout: "fixed" as const,
  th: {
    minWidth: 22,
    paddingLeft: "9px",
    paddingRight: "9px",
    textAlign: "left",
  },
  td: {
    minWidth: 22,
    paddingLeft: "9px",
    paddingRight: "9px",
    textAlign: "center",
    paddingBottom: 1.5,
    paddingTop: 1.5,
  },
};

export function ConditionalFormattingHeader({
  type,
}: {
  type: "create" | "manage";
}) {
  const headers = {
    select: (
      <th
        key="select"
        style={{
          width: "22px",
        }}
      >
        <Typography fontSize={"sm"}> </Typography>
      </th>
    ),
    affected: (
      <th
        key="affected"
        style={{
          width: "90px",
        }}
      >
        <Typography fontSize={"sm"}>Affected</Typography>
      </th>
    ),
    rule: (
      <th
        key="rule"
        style={{
          width: "100px",
        }}
      >
        <Typography fontSize={"sm"}>Rule</Typography>
      </th>
    ),
    limit: (
      <th
        key="limit"
        style={{
          width: "88px",
        }}
      >
        <Typography fontSize={"sm"}>Limit</Typography>
      </th>
    ),
    formatting: (
      <th
        key="formatting"
        style={{
          width: type === "create" ? "73px" : "65px",
        }}
      >
        <Typography fontSize={"sm"}>Formatting</Typography>
      </th>
    ),
    note: (
      <th
        key="note"
        style={{
          width: type === "create" ? "77px" : "70px",
          paddingRight: type === "create" ? "9px" : "0px",
        }}
      >
        <Typography fontSize={"sm"}>Note</Typography>
      </th>
    ),
    actions: (
      <th
        key="recurring"
        style={{
          width: "53px",
        }}
      >
        <Typography fontSize={"sm"}>Actions</Typography>
      </th>
    ),
    stopIfTrue: (
      <th
        key="recurring"
        style={{
          width: "60px",
          paddingLeft: type === "create" ? "9px" : "0px",
        }}
      >
        <Typography fontSize={"sm"}>StopIfTrue</Typography>
      </th>
    ),
  };

  const headerItems =
    type === "create"
      ? [
          headers.rule,
          headers.limit,
          headers.formatting,
          undefined,
          headers.note,
          headers.actions,
          headers.stopIfTrue,
        ]
      : [
          headers.select,
          headers.affected,
          headers.rule,
          headers.limit,
          headers.formatting,
          headers.note,
          headers.stopIfTrue,
        ];

  return (
    <thead>
      <tr>{headerItems}</tr>
    </thead>
  );
}
