import { unique } from "remeda";
import type { TFormatTypes, TRangeSelection } from "./FormatCell";
import { rowStringToCode, rowIdToRelativeRow } from "..";
import type { validColumnSettings } from "../column-defs";
import { atom, useSetAtom, useStore } from "jotai";
import { client } from "../../../triplit/triplit";
import { getPeriodByRowId } from "./ConditionalFormatting/helpers";
import type { GridApi, IRowNode } from "ag-grid-community";
import { useDeepCompareEffect, useDeepCompareMemo } from "@react-hookz/web";
import {
  compressJsonString,
  parsePageFormatting,
  type TPageFormatting,
} from "../../../utils/compressedStringify";
import { useState } from "react";
import { store } from "../../sharedHooks";
import { getProcessedSelectedCellsByRange } from "../../../tableUtils";
import { useGrid } from "../stores";
import {
  type TConditionalFormattingRulesGrouped,
  useQueryConditionalFormattingRulesGrouped,
} from "./ConditionalFormatting/hooks";
import { usePage } from "../../../data";

export const initialFormattingAndSettingsAtom = atom<{
  formatting: ReturnType<typeof usePageFormatting> | null;
  settings: ReturnType<typeof validColumnSettings> | null;
}>({ formatting: null, settings: null });
initialFormattingAndSettingsAtom.debugLabel =
  "initialFormattingAndSettingsAtom";

export function modalTypeToHighlightType(
  type: string,
): "cellHighlights" | "columnHighlights" | "periodHighlights" {
  switch (type) {
    case "range":
      return "cellHighlights";
    case "column":
      return "columnHighlights";
    case "period":
      return "periodHighlights";
    default:
      return "cellHighlights";
  }
}

export function getCellIds({
  api,
  type,
  ranges,
}: {
  api: GridApi | null;
  type: TFormatTypes;
  ranges: TRangeSelection;
}) {
  const cellIds: string[] = [];
  const rowCodes: string[] = [];

  if (type === "range") {
    if (!ranges.length || !ranges[0].length) return { cellIds, rowCodes };

    ranges.map((range) => {
      range.map((cell) => {
        const colId = cell.columnId;
        const period = getPeriodByRowId(api, cell.rowId);

        if (colId && period) {
          const code = rowStringToCode(period);
          if (code && !rowCodes.includes(code)) {
            rowCodes.push(code);
          }

          const id = `${colId}-${rowIdToRelativeRow[period]}`;
          if (!cellIds.includes(id)) {
            cellIds.push(`${colId}-${rowIdToRelativeRow[period]}`);
          }
        }
      });
    });
  }

  if (type === "column") {
    ranges.map((range) => {
      range.map((cell) => {
        const colId = cell.columnId;
        if (colId && !cellIds.includes(colId)) {
          cellIds.push(colId);
        }
      });
    });
  }

  if (type === "period") {
    ranges.map((range) => {
      range.map((cell) => {
        const id = cell.rowId;
        if (!cellIds.includes(id)) {
          cellIds.push(id);
        }
      });
    });
  }

  return { cellIds: unique(cellIds), rowCodes: unique(rowCodes) };
}

export const pageFormattingAtom = atom<ReturnType<
  typeof usePageFormatting
> | null>(null);
pageFormattingAtom.debugLabel = "pageFormattingAtom";

export const conditionalFormattingRulesAtom =
  atom<TConditionalFormattingRulesGrouped | null>(null);
conditionalFormattingRulesAtom.debugLabel = "conditionalFormattingRulesAtom";

export function useConditionalFormattingRules(pageId: string) {
  const setConditionalFormatting = useSetAtom(conditionalFormattingRulesAtom);
  const grouped = useQueryConditionalFormattingRulesGrouped(pageId);

  const [gridApi] = useGrid();

  useDeepCompareEffect(() => {
    if (grouped.data) setConditionalFormatting(grouped.data);
    if (gridApi?.isDestroyed()) return;
    console.log("refreshing cells in useConditionalFormattingRules");
    gridApi?.refreshCells({
      force: true,
    });
  }, [
    setConditionalFormatting,
    gridApi?.refreshCells,
    gridApi?.isDestroyed,
    grouped.data,
  ]);
}

export function cancelCellFormatting(
  pageId: string,
  initialFormatting: ReturnType<typeof usePageFormatting>,
) {
  setCellFormatting(pageId, "cellHighlights", initialFormatting.cellHighlights);
  setCellFormatting(
    pageId,
    "columnHighlights",
    initialFormatting.columnHighlights,
  );
  setCellFormatting(
    pageId,
    "periodHighlights",
    initialFormatting.periodHighlights,
  );
}

export async function setCellFormatting(
  pageId: string,
  type: "cellHighlights" | "columnHighlights" | "periodHighlights",
  highlights: TPageFormatting,
) {
  const compressedHighlights = await compressJsonString(
    JSON.stringify(highlights),
  );
  await client.update("pages", pageId, (p) => {
    p[type] = compressedHighlights;
  });
}

export function usePageFormatting(pageId: string) {
  const [gridApi] = useGrid();
  const store = useStore();

  const page = usePage(pageId);

  const [formatting, setFormatting] = useState<
    Awaited<ReturnType<typeof parsePageFormatting>>
  >({
    cellHighlights: {},
    columnHighlights: {},
    periodHighlights: {},
  });

  useDeepCompareMemo(() => {
    if (page.data) parsePageFormatting(page.data).then(setFormatting);
  }, [page.data]);

  useDeepCompareEffect(() => {
    if (gridApi?.isDestroyed()) return;
    store.set(pageFormattingAtom, {
      cellHighlights: formatting.cellHighlights,
      columnHighlights: formatting.columnHighlights,
      periodHighlights: formatting.periodHighlights,
      fetching: page.isFetching,
    });

    console.log("refreshing cells in usePageFormatting");
    gridApi?.refreshCells({
      force: true,
    });
  }, [formatting]);

  return {
    cellHighlights: formatting.cellHighlights,
    columnHighlights: formatting.columnHighlights,
    periodHighlights: formatting.periodHighlights,
    fetching: page.isFetching,
  };
}

export async function toggleCellBold(api: GridApi | null, pageId: string) {
  if (!api || !pageId) return;

  const pageFormatting = store.get(pageFormattingAtom);
  const selectedRange = getProcessedSelectedCellsByRange({ api });
  const { cellIds, rowCodes } = getCellIds({
    api,
    ranges: selectedRange,
    type: "range",
  });

  const highlightType = modalTypeToHighlightType("range");
  const highlights = pageFormatting?.[highlightType] || {};

  const rowNodes: IRowNode[] = rowCodes
    .map((code) => {
      return api.getRowNode(code);
    })
    .filter(Boolean);

  for (const cellId of cellIds) {
    const highlight = highlights[cellId] || {};
    const bold = highlight.boldText || false;

    highlights[cellId] = {
      color: highlight?.color || null,
      boldText: !bold,
      invertTextColor: highlight?.invertTextColor || false,
    };
  }

  await setCellFormatting(pageId, "cellHighlights", highlights);

  api.refreshCells({
    force: true,
    rowNodes,
  });
}
