import { Box, DialogTitle } from "@mui/joy";
import type { MouseEventHandler } from "react";
import { useSetAtom } from "jotai";
import { Button } from "@mui/joy";
import {
  Autocomplete,
  type TOption,
} from "../../components/Autocomplete/Autocomplete";
import { useAutocomplete } from "../../components/Autocomplete/hooks";
import { useUserSubscriptionTier } from "../../../context/auth";
import { defaultFieldNameSelector } from "../../utils";
import { useUserId } from "../../../context/auth";
import { modalAtom } from "../../sharedHooks";
import { useGrid } from "../stores";
import { useInsertPageProducts } from "../../../data";
import { useCallback, useState } from "react";
import { useAllProductsOptions } from "../../curve-management";
import { client } from "../../../triplit/triplit";
import { useLocalStorageValue } from "@react-hookz/web";
import { selectorsData } from "../selectorsData";

function CancelButton({
  onClick,
  label = "Cancel",
}: {
  onClick?: MouseEventHandler<HTMLElement>;
  label?: string;
}) {
  const setModal = useSetAtom(modalAtom);
  return (
    <Button
      size="sm"
      variant="outlined"
      color="neutral"
      type="button"
      onClick={(e) => {
        onClick?.(e);
        setModal(null);
      }}
    >
      {label}
    </Button>
  );
}

function SaveButton({
  onClick,
  disabled,
  loading,
  label = "Save",
  maxWidth = "100%",
}: {
  onClick: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  loading?: boolean;
  label: string;
  maxWidth?: number | string;
}) {
  const setModal = useSetAtom(modalAtom);
  return (
    <Button
      type="submit"
      disabled={disabled}
      loading={loading}
      onClick={(e) => {
        onClick(e);
        setModal(null);
      }}
      variant="solid"
      color="primary"
      size="sm"
      sx={{
        width: "100%",
        maxWidth,
      }}
    >
      {label}
    </Button>
  );
}

export default function InsertChartModal({ allowCancel = true }) {
  const userId = useUserId();
  const [api] = useGrid();

  const { liteUser } = useUserSubscriptionTier();
  const insert = useInsertPageProducts();
  const { data: allProductOptions } = useAllProductsOptions();
  const productOptions = allProductOptions?.filter((option) => {
    const exchangeCode =
      option.metadata?.packageByPackage?.sourceBySource?.exchange?.code;
    return !exchangeCode || exchangeCode in selectorsData;
  });

  const useProductAutocompleteProps = useAutocomplete({
    multiple: false,
  });

  const [selectedProduct] = useProductAutocompleteProps.singleState;

  const { set: setIsBottomPanelOpen } = useLocalStorageValue(
    "bottomPanelOpen",
    {
      defaultValue: false,
      initializeWithValue: true,
    },
  );

  const [formState, setFormState] = useState({
    productId: "",
    selector: "",
    period: "",
    metadata: selectedProduct?.metadata,
  });

  const isAnyFieldEmpty = !selectedProduct;

  const idx = 0;

  const id = `${userId}-${idx}`;
  const handleSave = useCallback(async () => {
    const mainChart = {
      id,
      idx: idx,
      productId: formState.productId,
      selector: formState.selector || undefined,
      period: Number.parseInt(formState.period),
      userId,
    };

    const emptyChart = {
      id: `${userId}-${idx + 1}`,
      idx: idx + 1,
      userId,
    };
    try {
      await client.insert("gridCharts", emptyChart);
      await client.insert("gridCharts", mainChart);
    } catch (error) {
      console.error("Error saving charts:", error);
    }
  }, [formState.productId, formState.selector, formState.period, id, userId]);

  return liteUser ? (
    "Please upgrade your subscription to access this feature"
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "22dvw",
        maxWidth: 400,
        minWidth: 300,
      }}
    >
      <DialogTitle sx={{ alignItems: "center" }}>Add Chart</DialogTitle>
      <Box>
        <Autocomplete
          {...useProductAutocompleteProps}
          multiple={false}
          placeholder="Curve"
          options={productOptions || []}
          onSelectValue={async (v: TOption) => {
            const value = v.value;

            setFormState((prev) => {
              return {
                ...prev,
                productId: value,
                selector: value
                  ? defaultFieldNameSelector(
                      v.metadata?.artis_type || "customer_curve",
                    )
                  : "",
                period: "12",
              };
            });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          justifyContent: "flex-start",
        }}
      >
        <SaveButton
          label="Add"
          disabled={isAnyFieldEmpty}
          loading={insert.isPending}
          onClick={async () => {
            try {
              await handleSave();
              setIsBottomPanelOpen(true);
            } catch (error) {
              console.error("Error saving:", error);
            }
          }}
        />
        {api && allowCancel && <CancelButton />}
      </Box>
    </Box>
  );
}
