import { useEffect } from "react";

import { useUserSubscriptionTier } from "../../../context/auth";
import { useSelectGridSettings } from "../../grid-settings";
import { useVisibleColumns } from "../useVisibleColumns";
import { useActivePageId, usePage } from "../../../data";
import { useCssVar } from "../../sharedHooks";
import { headerHeight } from "../../globals";
import { useGrid } from "../stores";

export function useDynamicCssExtraHeightRow() {
  const { disabledFeatures } = useUserSubscriptionTier();
  const pageId = useActivePageId();
  const [api] = useGrid();
  const visibleColumns = useVisibleColumns();
  const setVar = useCssVar();
  const hideStatusRow = useSelectGridSettings((x) => x.hideStatusRow);
  const page = usePage(pageId);
  const extraHeaderHeight = page.data?.extraHeaderHeightEnabled
    ? 75
    : headerHeight + 0.25 * headerHeight;

  useEffect(() => {
    if (!api || api.isDestroyed()) return;

    console.log("useDynamicCssExtraHeightRow");

    console.log("Update grid extra height through grid API");
    console.log("setHeaderHeight", extraHeaderHeight);
    setVar("--extraHeaderHeight", `${extraHeaderHeight}px`);
    setVar("--headerHeight", `${headerHeight}px`);

    const statusColumnHeight =
      !hideStatusRow && !disabledFeatures?.statusRow ? headerHeight : 0;
    const lastUpdatedColumnHeight = headerHeight;
    const allHeadersMinusNameColumnHeight =
      (visibleColumns.length - 1) * headerHeight;
    const nameColumnHeight = extraHeaderHeight;

    const heightOfAllHeaders =
      statusColumnHeight +
      lastUpdatedColumnHeight +
      allHeadersMinusNameColumnHeight +
      nameColumnHeight;

    api.setGridOption("headerHeight", heightOfAllHeaders);
  }, [
    api,
    extraHeaderHeight,
    visibleColumns,
    setVar,
    hideStatusRow,
    disabledFeatures?.statusRow,
  ]);

  return null;
}
