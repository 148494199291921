import { createContext, useCallback, useContext, type ReactNode } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import posthog from "posthog-js";

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "../../globals";

const LogoutContext = createContext(() => Promise.resolve());
export const LogoutProvider = LogoutContext.Provider;
export const useLogout = () => useContext(LogoutContext);

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
      onRedirectCallback={(state) => {
        posthog.capture("login", {
          $set_once: {
            first_login: new Date().toISOString(),
          },
        });
        if (state?.returnTo) {
          window.location.href = window.location.origin + state.returnTo;
        }
      }}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "hasura",
      }}
    >
      <ProvideLogout>{children}</ProvideLogout>
    </Auth0Provider>
  );
}

const ProvideLogout = (p: { children: React.ReactNode }) => {
  const { logout } = useAuth0();

  const onLogout = useCallback(() => {
    const isUmi = window.location.pathname.includes("admin");

    posthog.capture("user logout");
    posthog.reset();
    sessionStorage.clear();
    localStorage.clear();

    return logout({
      logoutParams: {
        returnTo: `${window.location.origin}${isUmi ? "/admin/orgs" : ""}`,
      },
    });
  }, [logout]);

  return (
    <LogoutContext.Provider value={onLogout}>
      {p.children}
    </LogoutContext.Provider>
  );
};
