import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";

import { useGlobalEnv } from "../context/environment";
import Loading from "../webapp/components/Loading";
import { client } from "../triplit/triplit";
import { parse } from "../webapp/numbers";
import type { JWT } from "../globals";
import { useCallback } from "react";
import { LogoutProvider, useLogout } from "../context/auth";

// Doesn't actually provide anything but requires data from useAuth0
// in order to connect to the Triplit API
export const TriplitProvider = (p: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const clientEnv = useGlobalEnv();
  const logout = useLogout();

  const result = useQuery({
    queryKey: ["auth", "triplit", clientEnv, isAuthenticated],
    queryFn: async () => {
      if (!isAuthenticated) return;
      const token = await getAccessTokenSilently();
      const jwt = jwtDecode<JWT>(token);
      const auth0OrgId = jwt.org_id;
      const hasuraOrgId = parse(
        jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-org-id"],
      );
      const globals = {
        userId: jwt.sub,
        auth0OrgId,
        hasuraOrgId,
        env: clientEnv,
      };
      if (client.token) {
        client.updateSessionToken(token);
      } else {
        await client.startSession(token, false);
      }
      if (client.connectionStatus !== "OPEN") {
        await client.connect();
      }
      client.db.updateGlobalVariables({
        ...globals,
      });
      return null;
    },
  });

  const onLogout = useCallback(async () => {
    await client.endSession().catch(console.error);
    await client.reset().catch(console.error);
    return logout();
  }, [logout]);

  if (result.isLoading) return <Loading showLogo syncing={false} />;

  return <LogoutProvider value={onLogout}> {p.children}</LogoutProvider>;
};
