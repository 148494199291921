import {
  Box,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Option,
  Radio,
  RadioGroup,
  Select,
} from "@mui/joy";
import dayjs from "dayjs";
import { type TLiveChart, useChartSettings } from "./hooks";
import {
  type TSample,
  bottomBarHeight,
  sampleByQuestOptions,
  sampleConfig,
  timeWindowOptions,
  validSampleOrFirst,
} from "./utils";
import type { ITimeScaleApi, Time } from "lightweight-charts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaChevronDown, FaRegCalendar } from "react-icons/fa";

const smallScreenBreakpoint = 360;

function useLiveChartWidth(
  liveChartRef: React.MutableRefObject<HTMLDivElement | null>,
) {
  const [width, setWidth] = useState(0);

  const handleResize = useCallback(() => {
    setWidth(liveChartRef?.current?.getBoundingClientRect().width ?? 0);
  }, [liveChartRef.current]);

  useEffect(() => {
    if (liveChartRef.current) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, liveChartRef.current]);

  return width;
}

function SampleTimeSelector({
  chartMetadata,
  liveChartRef,
}: {
  chartMetadata: TLiveChart;
  liveChartRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const { sampleTime } = chartMetadata;
  const { settings, setSettings } = useChartSettings(chartMetadata.id);
  const panelWidth = useLiveChartWidth(liveChartRef);

  function sampleOnChange(sample: TSample) {
    if (!settings) return;
    setSettings({ ...settings, sampleTime: sample });
  }

  const selectStyle = {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
    },
    display: panelWidth
      ? panelWidth <= smallScreenBreakpoint
        ? "inherit"
        : "none"
      : "none",
  };

  const radioGroupDisplayStyle = {
    display: panelWidth
      ? panelWidth > smallScreenBreakpoint
        ? "inherit"
        : "none"
      : "none",
  };
  return (
    <Box
      sx={{
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Select
        size="sm"
        sx={selectStyle}
        value={sampleTime}
        onChange={(_e, newValue) => {
          if (!newValue) return;
          sampleOnChange(newValue);
        }}
      >
        {sampleByQuestOptions.map(({ label, value }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
      <RadioGroup
        size="sm"
        orientation="horizontal"
        value={sampleTime}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value as typeof sampleTime;
          if (!newValue) return;
          sampleOnChange(newValue);
        }}
        sx={{
          height: "100%",
          borderRadius: 0,
          "--RadioGroup-gap": "0px",
          "--Radio-actionRadius": "0px",
          ...radioGroupDisplayStyle,
        }}
      >
        {sampleByQuestOptions.map(({ label, value }) => (
          <Radio
            key={value}
            value={value}
            disableIcon
            label={label}
            variant="plain"
            sx={{
              alignItems: "center",
              textAlign: "center",
              width: 35,
              fontWeight: value === sampleTime ? "bold" : "normal",
            }}
            slotProps={{
              action: ({ checked }) => ({
                sx: {
                  "&:hover": {
                    boxShadow: "none",
                  },
                  ...(checked && {
                    boxShadow: "none",
                  }),
                },
              }),
            }}
          />
        ))}
      </RadioGroup>
    </Box>
  );
}

// Technically these just act as buttons, but styling a button to look like a radio group is a lot harder and hacky than just using a radio with onClick.
function TimeWindowButtons({
  chartMetadata,
  timeScaleRef,
  liveChartRef,
}: {
  chartMetadata: TLiveChart;
  timeScaleRef: React.MutableRefObject<ITimeScaleApi<Time> | null>;
  liveChartRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const panelWidth = useLiveChartWidth(liveChartRef);

  const sampleTime = validSampleOrFirst(chartMetadata.sampleTime);
  const sample = sampleConfig[sampleTime];

  const timeWindows = useMemo(() => {
    return Object.entries(timeWindowOptions).map(([value, timeWindow]) => {
      const disabled = !sample.timeWindows.includes(value);

      const { seconds } = timeWindow;
      const now = dayjs();

      const to = (now.toDate().getTime() / 1000) as Time;
      const from = (now
        .subtract(seconds + sample.seconds, "seconds")
        .toDate()
        .getTime() / 1000) as Time;

      return { value, from, to, disabled };
    });
  }, [sample]);

  const radioGroupDisplayStyle = {
    display: panelWidth
      ? panelWidth > smallScreenBreakpoint
        ? "inherit"
        : "none"
      : "none",
  };

  return (
    <>
      {panelWidth
        ? panelWidth <= smallScreenBreakpoint && (
            <Dropdown>
              <MenuButton
                size="sm"
                variant="plain"
                endDecorator={<FaChevronDown />}
              >
                <FaRegCalendar />
              </MenuButton>
              <Menu>
                {timeWindows.map(({ value, from, to, disabled }) => {
                  return (
                    <MenuItem
                      key={value}
                      disabled={disabled}
                      onClick={() => {
                        if (disabled) return;
                        console.log(
                          `Clicked on ${value}, from: ${from}, to: ${to}`,
                        );
                        timeScaleRef?.current?.setVisibleRange({ from, to });
                      }}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Dropdown>
          )
        : null}
      <RadioGroup
        size="sm"
        orientation="horizontal"
        sx={(theme) => ({
          height: "100%",
          borderRadius: 0,
          "--RadioGroup-gap": "0px",
          "--Radio-actionRadius": "0px",
          ".Mui-checked": {
            color: `${theme.palette.neutral.plainColor} !important`,
          },
          ".Mui-checked:hover": {
            background: `${theme.palette.neutral.plainHoverBg} !important`,
          },
          ...radioGroupDisplayStyle,
        })}
      >
        {timeWindows.map(({ value, from, to, disabled }) => {
          return (
            <Box
              key={value}
              onClick={() => {
                if (disabled) return;
                console.log(`Clicked on ${value}, from: ${from}, to: ${to}`);
                timeScaleRef?.current?.setVisibleRange({ from, to });
              }}
            >
              <Radio
                value={value}
                disableIcon
                disabled={disabled}
                label={value}
                variant="plain"
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                  width: 35,
                  height: "100%",
                }}
                slotProps={{
                  action: ({ checked }) => ({
                    sx: {
                      "&:hover": {
                        boxShadow: "none",
                      },
                      ...(checked && {
                        boxShadow: "none",
                      }),
                    },
                  }),
                }}
              />
            </Box>
          );
        })}
      </RadioGroup>
    </>
  );
}

export function TradingBottomPanel({
  chartMetadata,
  timeScaleRef,
  liveChartRef,
}: {
  chartMetadata: TLiveChart;
  timeScaleRef: React.MutableRefObject<ITimeScaleApi<Time> | null>;
  liveChartRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        height: bottomBarHeight,
        backgroundColor: (theme) => theme.palette.background.surface,
        borderTop: "1px solid",
        borderColor: (theme) => theme.palette.divider,
      }}
    >
      <SampleTimeSelector
        chartMetadata={chartMetadata}
        liveChartRef={liveChartRef}
      />
      <Box
        sx={{ height: "100%", display: "flex", flexDirection: "row", gap: 0.5 }}
      >
        <TimeWindowButtons
          chartMetadata={chartMetadata}
          timeScaleRef={timeScaleRef}
          liveChartRef={liveChartRef}
        />
      </Box>
    </Box>
  );
}
