import { useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "jotai";

import { exposeMainThreadApi } from "../calculations-worker/messaging/client";
import { useOptionalActivePageId, usePageProducts } from "../../data";
import { useCurrentUser, type TSession } from "../../context/auth";
import type { TData } from "../calculations-worker/sharedStores";
import { calcWorker } from "../calculations-worker/hooks";
import { gridApiAtom } from "../market-grid/stores";
import { logoutRequestAtom } from "../sharedHooks";
import { alertProductChanged } from "../alerts";
import { isMobile } from "../../shared/hooks";
import { colParams } from "../utils";
import { queryClient } from "../../context/query";
import { useDebouncedCallback } from "@react-hookz/web";

export function useSetupCalcWorker(session: TSession) {
  const worker = useMemo(() => calcWorker?.(), []);
  const { user: authuser } = useAuth0();
  const initializationPromise = useRef<Promise<void> | null>(null);

  const { username } = useCurrentUser();
  const user = useMemo(
    () => ({
      id: authuser?.sub,
      username: username,
      firstname: authuser?.given_name,
      lastname: authuser?.family_name,
    }),
    [authuser, username],
  );

  useEffect(() => {
    if (!session || !worker || !user) {
      console.debug("Missing dependencies", {
        hasSession: !!session,
        hasWorker: !!worker,
        hasUser: !!user,
      });
      return;
    }

    if (!worker.worker || !worker.proxy) {
      console.error("Worker or worker proxy is undefined");
      return;
    }

    async function start() {
      if (initializationPromise.current) {
        await initializationPromise.current;
        return;
      }

      initializationPromise.current = (async () => {
        console.log("starting worker", { worker, user, session });
        if (!worker || !worker.proxy || !session) {
          console.error("worker or session is undefined", { worker, session });
          return;
        }

        try {
          if ("postMessage" in worker.worker) {
            worker.worker.postMessage({ type: "wakeup" });
          } else if (worker.worker.port) {
            worker.worker.port.postMessage({ type: "wakeup" });
          } else {
            throw new Error("Worker doesn't have valid message interface");
          }

          console.log("trying initWorker");
          await worker.proxy.initWorker(
            import.meta.env,
            user,
            session,
            isMobile,
          );
          console.log("worker inited");

          const token = session.access_token;
          if (token) {
            console.log("starting worker");
            await worker.proxy.start(token);
          } else {
            throw new Error("No access token available");
          }
        } catch (e) {
          console.error("failed to init/start worker", e);
          // Clear the promise ref on error so we can retry
          initializationPromise.current = null;
          throw e;
        }
      })();

      // Wait for initialization to complete so we don't get double worker processes
      await initializationPromise.current;
    }

    start().catch((e) => {
      console.error("failed to start worker", e);
    });

    return () => {
      initializationPromise.current = null;
    };
  }, [user, worker, session]);
}

export const useConnectCalcWorkerClientHandlers = () => {
  const { getAccessTokenSilently } = useAuth0();
  const pageId = useOptionalActivePageId() ?? "";
  const pageProducts = usePageProducts(pageId);
  const store = useStore();

  const refetchAlerts = useDebouncedCallback(
    async () => {
      console.log("Refetch alerts triggered from worker");
      await queryClient.refetchQueries({ queryKey: ["alerts", "Triggered"] });
    },
    [],
    1000,
  );

  useEffect(() => {
    return exposeMainThreadApi({
      alertProductChanged: alertProductChanged,
      logoutRequest: () => {
        store.set(logoutRequestAtom, true);
      },
      renewToken: async () => {
        // Wait 5 seconds. This is because when we update the permission in Hasura, the Auth0 token is not updated immediately, so fetching it right away will return the old token.
        await new Promise((resolve) => setTimeout(resolve, 5000));

        const token = await getAccessTokenSilently({
          cacheMode: "off",
        });

        console.log("Renewing token", token);
        calcWorker?.()?.proxy?.updateJWT(token);

        await new Promise((resolve) => setTimeout(resolve, 1000));

        const gridApi = store.get(gridApiAtom);
        if (!gridApi) return;

        const productsWithKey = pageProducts.data
          ?.filter(
            (product): product is typeof product & { productId: string } =>
              !!product.productId,
          )
          .filter(Boolean);

        const transactions: TData[] = [];
        gridApi.forEachNode((node) => {
          if (node.data?.blank || !node.data) return;
          const rowUpdates = node.data;

          const columns = gridApi.getColumns();
          columns?.map((column) => {
            const params = colParams(column);
            if (
              params?.productId &&
              !productsWithKey?.find((p) => p.id === params.gridId)
            ) {
              rowUpdates[params.gridId] = undefined;
            }
          });

          transactions.push(rowUpdates);
        });

        gridApi.applyTransaction({ update: transactions });
      },
      getToken: async () => {
        const token = await getAccessTokenSilently({ cacheMode: "off" });
        return token;
      },
      triggeredAlert: () => {
        refetchAlerts();
      },
    });
  }, [getAccessTokenSilently, pageProducts.data, store, refetchAlerts]);
};

const SLOW_THRESHOLD = 5000;

export function useConnectionStatus(lastUpdatedAt: Date | null) {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [isSlowConnection, setIsSlowConnection] = useState(false);

  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
      setIsSlowConnection(false);
      console.log("Connection went offline");
    };

    const handleOnline = () => {
      setIsOffline(false);
      console.log("Connection came online");
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  useEffect(() => {
    if (isOffline) return;

    const intervalId = setInterval(() => {
      if (!lastUpdatedAt) {
        setIsSlowConnection(false);
        return;
      }

      const timeSinceLastUpdate = Date.now() - lastUpdatedAt.getTime();
      if (timeSinceLastUpdate > SLOW_THRESHOLD) {
        setIsSlowConnection(true);
        console.log("Connection is slow");
      } else {
        setIsSlowConnection(false);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [isOffline, lastUpdatedAt]);

  return { isOffline, isSlowConnection };
}
