import { type TRangeSelection, store } from "../../sharedHooks";
import type {
  DockviewApi,
  IDockviewPanelProps,
  IDockviewReactProps,
} from "dockview";
import ColumnSettings, {
  type TColumnSettingsModalProps,
} from "./ColumnSettings";
import FormatCell, { type TFormatCellProps } from "./FormatCell";
import { CreateAlert } from "./Alerts/CreateAlert";
import { ManageAlerts } from "./Alerts/ManageAlerts";

import { CreateConditionalFormattingRule } from "./ConditionalFormatting/CreateRule";
import { ManageConditionalFormattingRules } from "./ConditionalFormatting/ManageRules";
import { SetAllColumnWidths } from "./SetAllColumnWidths";
import { objectKeys } from "../../../utils";
import {
  bottomBarHeight,
  gridSettingsWidth,
  sideBarWidth,
  topBarHeight,
} from "../../globals";
import { initialFormattingAndSettingsAtom } from "./formatCellHelpers";
import { FormulaViewer } from "./FormulaViewer";
import type { GridApi } from "ag-grid-community";
import { unique } from "remeda";
import { Box, Sheet, Typography } from "@mui/joy";
import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { ChartsLayoutInner } from "../../live-charts/ChartsLayout";
import TimeSpreadsModal, { type TTimeSpreadsModalProps } from "./TimeSpreads";
import { useGrid } from "../stores";
import { gridSettingsVisibleAtom } from "../../grid-settings";
import { emptyDockview, gridModalDockviewAtom } from "./modalAtoms";

export type TParentPanel =
  | "live-charts"
  | "formatting"
  | "conditional"
  | "alerts"
  | "set-all-column-widths"
  | "formula-viewer"
  | "tspds";

export type TFormattingTabs = "color" | "text" | "settings";

export type TConditionalTabs =
  | "conditional-format-add"
  | "conditional-format-manage";

export type TAlertsTabs = "alert-add" | "alert-manage";

export type TPanelTabs<T extends TParentPanel> = T extends "formatting"
  ? TFormattingTabs
  : TConditionalTabs | TAlertsTabs;

export type TNoTabs =
  | "set-all-column-widths"
  | "formula-viewer"
  | "tspds"
  | "live-charts";

export type THasTabs = "formatting" | "conditional" | "alerts";

type TWidthAndHeight = {
  width?: number;
  height?: number;
};

type TSelectionType = "range" | "column" | "period";

export type TParentPanelPropsMap = {
  "live-charts": {
    close: () => void;
    selectedRange?: TRangeSelection;
  };
  "set-all-column-widths": {
    selectedRange?: TRangeSelection;
    type?: TSelectionType;
  };
  "formula-viewer": {
    selectedRange?: TRangeSelection;
    type?: TSelectionType;
  };
  tspds: TTimeSpreadsModalProps & {
    selectedRange?: TRangeSelection;
    type?: TSelectionType;
  };
  formatting: TFormatCellProps &
    TColumnSettingsModalProps & {
      pageId: string;
      selectedRange: TRangeSelection;
      type?: TSelectionType;
    };
  conditional: {
    selectedRange: TRangeSelection;
    type?: TSelectionType;
  };
  alerts: {
    selectedRange: TRangeSelection;
    type?: TSelectionType;
  };
};

export type TParentPanelProps<T extends TParentPanel> = {
  currentTab?: T extends THasTabs ? TPanelTabs<T> : undefined;
  params: TParentPanelPropsMap[T] & TWidthAndHeight;
};

export type TNoTabComponents = Record<
  TNoTabs,
  (
    props: IDockviewPanelProps & {
      params: TParentPanelProps<TNoTabs>["params"];
    },
  ) => ReturnType<IDockviewReactProps["components"][number]>
>;

export type TFormattingComponents = Record<
  TFormattingTabs,
  (props: {
    params: TParentPanelProps<"formatting">["params"];
  }) => ReturnType<IDockviewReactProps["components"][number]>
>;

export type TConditionalComponents = Record<
  TConditionalTabs,
  (props: {
    params: TParentPanelProps<"conditional">["params"];
  }) => ReturnType<IDockviewReactProps["components"][number]>
>;

export type TAlertsComponents = Record<
  TAlertsTabs,
  (props: {
    params: TParentPanelProps<"alerts">["params"];
  }) => ReturnType<IDockviewReactProps["components"][number]>
>;

export type TGridModalComponents =
  | keyof TNoTabComponents
  | keyof TFormattingComponents
  | keyof TConditionalComponents
  | keyof TAlertsComponents;

export const gridModalComponentPanelTitles = {
  "set-all-column-widths": "Set All Column Widths",
  "formula-viewer": "Formula Viewer",
  tspds: "Time Spreads",
  color: "Color",
  text: "Text",
  settings: "Settings",
  "conditional-format-add": "Add Conditional Format",
  "conditional-format-manage": "Manage Conditional Formats",
  "alert-add": "Add Alert",
  "alert-manage": "Manage Alerts",
  "live-charts": "Live Chart",
} satisfies Record<TGridModalComponents, string>;

export type TNoTabGridModalComponents = Partial<
  Record<TNoTabs, TNoTabComponents[TNoTabs]>
>;

type TGridModalComponentsMap = Record<
  TParentPanel,
  | TNoTabGridModalComponents
  | TFormattingComponents
  | TConditionalComponents
  | TAlertsComponents
>;

const gridModalNoTabComponents = {
  "set-all-column-widths": (_: {
    params: TParentPanelProps<"set-all-column-widths">["params"];
  }) => {
    return <SetAllColumnWidths />;
  },
  "formula-viewer": (_: {
    params: TParentPanelProps<"formula-viewer">["params"];
  }) => {
    return <FormulaViewer />;
  },
  tspds: ({ params }: { params: TParentPanelProps<"tspds">["params"] }) => {
    return <TimeSpreadsModal rowId={params.rowId} />;
  },
  "live-charts": () => {
    return <ChartsLayoutInner floatingWindow={true} />;
  },
} satisfies TNoTabComponents;

const gridModalFormattingComponents = {
  color: ({
    params,
  }: {
    params: TParentPanelProps<"formatting">["params"];
  }) => {
    const { pageId, type } = params;
    return (
      <GridModalSelectionWrapper parentPanel="formatting">
        <FormatCell tab="colors" pageId={pageId} type={type} />
      </GridModalSelectionWrapper>
    );
  },
  text: ({ params }: { params: TParentPanelProps<"formatting">["params"] }) => {
    const { pageId, type } = params;
    return (
      <GridModalSelectionWrapper parentPanel="formatting">
        <FormatCell tab="text" pageId={pageId} type={type} />
      </GridModalSelectionWrapper>
    );
  },
  settings: ({
    params,
  }: {
    params: TParentPanelProps<"formatting">["params"];
  }) => {
    const {
      columnId,
      initialColumnWidth,
      initialThousandsSeparator,
      initialDecimalPlaces,
      pageId,
    } = params;

    return (
      <GridModalSelectionWrapper parentPanel="formatting">
        <ColumnSettings
          columnId={columnId}
          initialColumnWidth={initialColumnWidth}
          initialThousandsSeparator={initialThousandsSeparator}
          initialDecimalPlaces={initialDecimalPlaces}
          pageId={pageId}
        />
      </GridModalSelectionWrapper>
    );
  },
} satisfies TFormattingComponents;

const gridModalConditionalComponents = {
  "conditional-format-add": (_: {
    params: TParentPanelProps<"conditional">["params"];
  }) => {
    return (
      <GridModalSelectionWrapper parentPanel="conditional">
        <CreateConditionalFormattingRule />
      </GridModalSelectionWrapper>
    );
  },
  "conditional-format-manage": (_: {
    params: TParentPanelProps<"conditional">["params"];
  }) => {
    return (
      <GridModalSelectionWrapper parentPanel="conditional">
        <ManageConditionalFormattingRules />
      </GridModalSelectionWrapper>
    );
  },
} satisfies TConditionalComponents;

const gridModalAlertsComponents = {
  "alert-add": (_: { params: TParentPanelProps<"alerts">["params"] }) => {
    return (
      <GridModalSelectionWrapper parentPanel="alerts">
        <CreateAlert />
      </GridModalSelectionWrapper>
    );
  },
  "alert-manage": (_: { params: TParentPanelProps<"alerts">["params"] }) => {
    return (
      <GridModalSelectionWrapper parentPanel="alerts">
        <ManageAlerts />
      </GridModalSelectionWrapper>
    );
  },
} satisfies TAlertsComponents;

export const gridModalComponents = {
  "set-all-column-widths": {
    "set-all-column-widths": gridModalNoTabComponents["set-all-column-widths"],
  },
  "formula-viewer": {
    "formula-viewer": gridModalNoTabComponents["formula-viewer"],
  },
  tspds: {
    tspds: gridModalNoTabComponents.tspds,
  },
  formatting: gridModalFormattingComponents,
  conditional: gridModalConditionalComponents,
  alerts: gridModalAlertsComponents,
  "live-charts": {
    "live-charts": gridModalNoTabComponents["live-charts"],
  },
} satisfies TGridModalComponentsMap;

export type TGridModalDockviewPanel = TParentPanelProps<TParentPanel> &
  ModalProps<TParentPanel> & {
    dockviewApi?: DockviewApi;
    selectedRange?: TRangeSelection;
  };

export type TGridModalDockview = Record<
  TParentPanel | never,
  TGridModalDockviewPanel | never
>;

export type ModalProps<T extends TParentPanel> = {
  parentPanel: T;
  currentTab?: TPanelTabs<T>;
  params: TParentPanelProps<T>["params"];
  x?: number;
  y?: number;
  offsetX?: number;
  offsetY?: number;
};

const preventRepositioningPanels = [
  "conditional",
  "set-all-column-widths",
] satisfies Array<TParentPanel>;

const selectedCellSelector = ".ag-cell-focus.ag-cell-range-selected";

export function refreshSelectedCells({
  api,
  selectedRange,
}: {
  api?: GridApi | null;
  selectedRange?: TRangeSelection;
}) {
  if (!api) return;

  const rowNodes =
    selectedRange
      ?.flatMap((range) => {
        return range.flatMap((cell) => api.getRowNode(cell.rowId));
      })
      .filter(Boolean) || [];

  // Needed to set dashed border around cells in the modal's selected range, especially empty cells that don't refresh frequently / on their own.
  api.refreshCells({ force: true, rowNodes });
}

// Dockview has an onReady event that is triggered when the API is ready. At this stage, the component hasn't rendered. requestDockviewModal adds the Dockview to the store, and GridModal renders the Dockview, at which point onReady is triggered, and openDockviewModal is called to open the modal.
export function requestDockviewModal<T extends TParentPanel>(
  props: ModalProps<T>,
) {
  const modalDockview = store.get(gridModalDockviewAtom);
  const currentModalDockview = { ...modalDockview };

  const { parentPanel } = props;

  // If a formatting Dockview is open, and the user tries to open a conditional Dockview, close the formatting Dockview, and vice versa.
  if (parentPanel === "conditional" && "formatting" in currentModalDockview) {
    closeDockviewModal("formatting");
  }

  if (parentPanel === "formatting" && "conditional" in currentModalDockview) {
    closeDockviewModal("conditional");
  }

  const panelId = getPanelId(parentPanel);

  const currentPanel = document
    .querySelector(`#${panelId} .content-container`)
    ?.getBoundingClientRect();

  const currentPanelPosition = currentPanel
    ? {
        x: currentPanel?.x - sideBarWidth,
        y: currentPanel?.y - topBarHeight - bottomBarHeight,
      }
    : null;

  if (currentModalDockview) {
    const existing = parentPanel in currentModalDockview;

    currentModalDockview[parentPanel] = {
      ...props,
      params: {
        ...props.params,
        width: props?.params?.width,
        height: props?.params?.height,
      },
      selectedRange: props?.params?.selectedRange,
      dockviewApi: currentModalDockview[parentPanel]?.dockviewApi,
    } satisfies TGridModalDockviewPanel;

    store.set(gridModalDockviewAtom, currentModalDockview);

    if (existing) {
      openDockviewModal({ ...props, currentPanelPosition });
    }
  }
}

function getPanelComponents(
  panelComponents: TGridModalComponentsMap[TParentPanel],
): TGridModalComponents[] {
  return objectKeys(panelComponents) || [];
}

export function getPanelId(parentPanel: TParentPanel) {
  return `panel-${parentPanel}`;
}

export function openDockviewModal<T extends TParentPanel>({
  parentPanel,
  currentTab,
  params,
  x,
  y,
  offsetX = 0,
  offsetY = 0,
  currentPanelPosition,
}: ModalProps<T> & {
  currentPanelPosition?: { x: number; y: number } | null;
}) {
  const modalDockview = store.get(gridModalDockviewAtom);
  if (!modalDockview) return null;

  const dockviewApi = modalDockview[parentPanel]?.dockviewApi;
  if (!dockviewApi) return null;

  store.set(initialFormattingAndSettingsAtom, {
    formatting: null,
    settings: null,
  });

  const gridSettingsVisible = store.get(gridSettingsVisibleAtom);

  const selectedRange = modalDockview[parentPanel]?.selectedRange?.flat() || [];

  const multipleSelections = selectedRange.length > 1;
  const multipleSelectionExcludedTabs = ["alert-add"];

  const selectedCell = document
    .querySelector(selectedCellSelector)
    ?.getBoundingClientRect();

  const smallScreen = window.innerWidth < 500;

  const panelX =
    preventRepositioningPanels.includes(parentPanel) && currentPanelPosition
      ? currentPanelPosition.x - 1
      : (selectedCell?.x ? selectedCell.x - sideBarWidth + 20 : x) || 100;
  const panelY =
    preventRepositioningPanels.includes(parentPanel) && currentPanelPosition
      ? currentPanelPosition.y - 1
      : (selectedCell?.y ? selectedCell.y - topBarHeight + 20 : y) || 100;

  const width = smallScreen ? window.innerWidth : params?.width;
  const height = smallScreen
    ? window.innerHeight - bottomBarHeight
    : params?.height;

  // Ensure the modal doesn't go off the screen when opened.
  const adjustedPanelX = smallScreen
    ? 0
    : panelX + (params?.width || 0) > window.innerWidth
      ? window.innerWidth - (params?.width || 0) - 100
      : panelX;

  const adjustedPanelY = smallScreen
    ? -bottomBarHeight
    : panelY + (params?.height || 0) > window.innerHeight
      ? window.innerHeight - (params?.height || 0) - 100
      : panelY;

  // Ensure the modal doesn't go off the screen whne grid settings are open.
  const adjustedPanelXWithSettings = gridSettingsVisible
    ? adjustedPanelX - gridSettingsWidth
    : adjustedPanelX;

  dockviewApi.clear();

  // Prevent the panel/tab from being dragged.
  dockviewApi.onWillDragPanel((e) => {
    e.nativeEvent.preventDefault();
  });

  const components = getPanelComponents(gridModalComponents[parentPanel]);

  // Everything in Dockview is a panel, including tabs. tabComponents are only what's rendered in the tab bar. In order to group panels together, they can be added with a referencePanel and direction.
  const firstPanel = dockviewApi.addPanel({
    id: `${parentPanel}-${components[0]}`,
    component: components[0],
    title: gridModalComponentPanelTitles[components[0]],
    params: {
      ...params,
      width,
      height,
    },
    floating: {
      width,
      height,
      x: adjustedPanelXWithSettings + offsetX,
      y: adjustedPanelY + offsetY,
    },
    inactive: components.length > 1 && currentTab !== components[0],
  });

  components.slice(1).map((component) => {
    if (multipleSelections && multipleSelectionExcludedTabs.includes(component))
      return;

    dockviewApi.addPanel({
      id: `${parentPanel}-${component}`,
      component,
      title: gridModalComponentPanelTitles[component],
      params: {
        ...params,
        width,
        height,
      },
      inactive: currentTab !== component,
      position: {
        referencePanel: firstPanel,
        direction: "right",
      },
    });
  });
}

export function closeDockviewModal(parentPanel: TParentPanel) {
  const modalDockview = store.get(gridModalDockviewAtom);
  if (!modalDockview) return null;

  const panel = modalDockview[parentPanel];

  const dockviewApi = panel?.dockviewApi;

  if (dockviewApi) {
    dockviewApi.closeAllGroups();

    for (const panel of dockviewApi.panels) {
      panel.api.close();
      panel.dispose();
    }

    dockviewApi.clear();
  }

  const currentModalDockview = { ...modalDockview };
  delete currentModalDockview[parentPanel];

  store.set(gridModalDockviewAtom, currentModalDockview);
}

export function closeAllDockviewModals() {
  store.set(gridModalDockviewAtom, emptyDockview);
}

export function GridModalSelectionInfo({
  selectedRange: ranges,
}: {
  selectedRange: TRangeSelection;
}) {
  const rangeText = ranges
    .map((range) => {
      const first = range[0];
      const last = range[range.length - 1];

      const firstPeriod = first?.rowId;
      const lastPeriod = last?.rowId;

      const packageNames = unique(range.map((cell) => cell.headerName));

      if (firstPeriod === lastPeriod)
        return `${packageNames.join(", ")}: ${firstPeriod}`;

      if (packageNames.length > 1) {
        return `${packageNames[0]}: ${firstPeriod} - ${
          packageNames[packageNames.length - 1]
        }: ${lastPeriod}`;
      }

      return `${packageNames[0]}: ${firstPeriod} - ${lastPeriod}`;
    })
    .join(", ");

  return (
    <Box width={"100%"} overflow="auto">
      <Sheet
        variant="outlined"
        sx={{
          padding: 1,
          borderRadius: "md",
          overflow: "auto",
        }}
      >
        <Typography fontSize="sm">{rangeText}</Typography>
      </Sheet>
    </Box>
  );
}

function toggleSelection({ action }: { action: "add" | "remove" }) {
  const positions = ["top", "right", "bottom", "left"];

  for (const position of positions) {
    const className = action === "add" ? "ag" : "dockview";

    // Handles edge case when user selects another cell while the Dockview is open.
    const removals =
      action === "remove"
        ? document.querySelectorAll(`.dockview-cell-range-${position}`)
        : [];

    const cells = [
      ...removals,
      ...document.querySelectorAll(
        `.ag-cell-range-selected.${className}-cell-range-${position}`,
      ),
      ...document.querySelectorAll(
        ".ag-cell-range-selected.ag-cell-range-single-cell",
      ),
    ];

    for (const cell of cells) {
      if (!cell.classList.contains("ag-cell-last-left-pinned")) {
        cell.classList[action](`dockview-cell-range-${position}`);
      }
    }
  }
}

export function GridModalSelectionWrapper({
  parentPanel,
  children,
}: {
  parentPanel: TParentPanel;
  children: React.ReactNode;
}) {
  const modalDockview = useAtomValue(gridModalDockviewAtom);
  const selectedRange = modalDockview[parentPanel]?.selectedRange;

  const [api] = useGrid();

  // Refresh the selected cells when the Dockview is opened or closed.
  useEffect(() => {
    refreshSelectedCells({ api, selectedRange });
    toggleSelection({ action: "add" });

    return () => {
      refreshSelectedCells({ api, selectedRange });
      toggleSelection({ action: "remove" });
    };
  }, [api, selectedRange]);

  return <>{children}</>;
}
