import { CustomTooltip } from "../components/Tooltip";
import { useGridSettingsVisible } from "./hooks";
import { GridSettingsIcon } from "../icons";

export const ToggleGridSettingsButton = () => {
  const [isGridSettingsVisible, setIsGridSettingsVisible] =
    useGridSettingsVisible();
  const toggle = () => setIsGridSettingsVisible(!isGridSettingsVisible);

  return (
    <CustomTooltip title="Grid Settings">
      <div onClick={toggle} onKeyDown={toggle}>
        <GridSettingsIcon />
      </div>
    </CustomTooltip>
  );
};
