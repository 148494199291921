import toast from "react-hot-toast";

import {
  copyRangeToClipboard,
  copyWithHeadersAndMonths,
  copyWithMetadata,
} from "../copyRangeSelectionHelpers";
import { useActivePageId, usePageProducts } from "../../../data";
import type { GetContextMenuSection } from "./helpers";
import { copyAsPng } from "../copyAsPng";
import { getIcon } from "./icons";

export const useGetMenuItemsClipboard = (): GetContextMenuSection => {
  const pageId = useActivePageId();
  const products = usePageProducts(pageId);
  return (ctx) => {
    return [
      {
        name: "Copy",
        shortcut: "Ctrl + C",
        icon: getIcon("copy"),
        action: (params) => copyRangeToClipboard(params.api),
        subMenu: [
          {
            name: "Copy with Headers + Months",
            shortcut: "Shift + Ctrl + C",
            action: (params) => copyWithHeadersAndMonths(params),
          },
          {
            name: "Copy with Metadata",
            action: (params) => {
              copyWithMetadata({ products: products.data ?? [], params });
            },
          },
          {
            name: "Copy as PNG",
            action: (params) => {
              if (ctx.rangeSelection.length > 0) {
                copyRangeToClipboard(params.api);
                const toastId = toast.loading(
                  "Copying... please keep this window open",
                );
                copyAsPng(ctx.rangeSelection, params, toastId);
              } else {
                // TODO bug where a single cell doesn't count as a range
                // probably should use api to get selected cell in that case
                console.error(
                  "No cells selected",
                  ctx.rangeSelection,
                  ctx.selectedRange,
                );
              }
            },
          },
        ],
      },
    ];
  };
};
