import { useUpdatePageProducts } from "../../../data";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsColumnType = (): GetContextMenuSection => {
  const update = useUpdatePageProducts();

  const updateColumnType = (columnId: string, columnType: string) =>
    update.mutateAsync({
      id: columnId,
      updaterFn: (old) => {
        old.columnType = columnType;
      },
    });

  return (ctx) => {
    if (!ctx.isShadowCurve) return [];
    return [
      {
        name: "Column Type",
        icon: getIcon("type"),
        subMenu: [
          {
            name: "Month",
            action: () => updateColumnType(ctx.columnId, "month"),
          },
          {
            name: "Time Spread",
            action: () => updateColumnType(ctx.columnId, "month-timespread"),
          },
          {
            name: "Blank",
            action: () => updateColumnType(ctx.columnId, "blank"),
          },
        ],
      },
    ];
  };
};
